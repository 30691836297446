import React from 'react'

import { Modal, Row, ModalProps } from 'antd'
import { ConfigProvider } from 'antd'
import styled from 'styled-components'

import { Colors } from 'src/constants/colors'
import { FjText, FormHeaderText, DefaultButton, buttonType } from 'src/components/Common'
import { X } from 'react-feather'
import { ConfigProviderProps } from 'antd/lib/config-provider'

interface FjStyledModalProps extends ModalProps {
  hide?: boolean
}

const FjStyledModal = styled(({ hide, ...rest }: FjStyledModalProps) => <Modal {...rest} />)`
  & .ant-modal-content {
    border-radius: 16px;
    visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  }
  & .ant-modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px !important;
  }
  & .ant-modal-body {
    padding: 24px !important;
  }
`

export const FjModal: React.FC<FjStyledModalProps & { configProviderProps?: ConfigProviderProps }> = ({
  width,
  configProviderProps,
  ...rest
}) => {
  return (
    <ConfigProvider
      getPopupContainer={(trigger) => (trigger?.parentNode as HTMLElement) || document.body}
      {...configProviderProps}
    >
      <FjStyledModal {...rest} closeIcon={<X size={20} color={Colors.cottonSeed} />} width={width || '900px'} />
    </ConfigProvider>
  )
}

interface ConfirmDialogProps {
  title?: string
  open?: boolean
  content?: string | JSX.Element
  confirmButtonType?: buttonType
  confirmButtonTitle?: string
  cancelButtonTitle?: string
  onCancel: () => any
  onConfirm: (data?: any) => any
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  open,
  content,
  confirmButtonType,
  confirmButtonTitle,
  cancelButtonTitle,
  onConfirm,
  onCancel,
}) => (
  <FjModal open={open} onCancel={onCancel} footer={null} zIndex={2000}>
    <FormHeaderText heading={title || 'Are you sure?'} />
    {content && (
      <>
        {typeof content === 'string' ? (
          <FjText fontSize="medium" textAlign="left">
            {content}
          </FjText>
        ) : (
          content
        )}
      </>
    )}
    <Row justify="end" align="middle" style={{ marginTop: '24px', gap: '20px' }}>
      <DefaultButton title={cancelButtonTitle || 'Cancel'} clicked={onCancel} buttonType="text" />
      <DefaultButton
        buttonType={confirmButtonType || 'primary'}
        title={confirmButtonTitle || 'Confirm'}
        clicked={onConfirm}
      />
    </Row>
  </FjModal>
)
