import React, { useCallback, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { DefaultButton } from 'src/components/Common/Button'
import { ContainerDiv } from 'src/components/Common/Cards'
import { FjText } from 'src/components/Common/Text'
import { Course } from 'src/models/Course'
import { LearningPath } from 'src/models/LearningPath'
import { confettiModalOpened, getFeedContentTypeName, hasOpenedConfettiModal } from 'src/utils/content'

export type ConfettiModalProps = {
  obj: Course | LearningPath
}

export const ConfettiModal = ({ obj }: ConfettiModalProps) => {
  const intervalRef = useRef<NodeJS.Timer>()
  const [certificateUrl, setCertificateUrl] = useState(obj.userCertificatePdfUrl)

  const fetchCertificate = useCallback(async () => {
    const { userCertificatePdfUrl } = await obj.getCertificatePdfUrl()
    if (userCertificatePdfUrl) {
      setCertificateUrl(userCertificatePdfUrl)
      clearInterval(intervalRef.current)
    }
  }, [obj])

  useEffect(() => {
    if (!obj.certificate) return

    if (!obj.userCertificatePdfUrl) {
      intervalRef.current = setInterval(fetchCertificate, 2000)
    } else {
      setCertificateUrl(obj.userCertificatePdfUrl)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [fetchCertificate, obj.certificate, obj.userCertificatePdfUrl])

  useEffect(() => {
    if (!hasOpenedConfettiModal(obj)) {
      confettiModalOpened(obj)
    }
  }, [obj])

  return (
    <ContainerDiv display="flex" flexDirection="column" alignItems="center" padding="80px 0">
      <Confetti width={window.innerWidth} height={window.innerHeight} style={{ position: 'fixed' }} />
      <FjText fontSize="36px" fontWeight="bold">
        Congratulations!
      </FjText>
      <FjText fontSize="16px" fontWeight="bold500" style={{ marginTop: '8px' }}>
        You've successfully completed this {getFeedContentTypeName({ obj })}.
      </FjText>
      {obj.certificate ? (
        <DefaultButton
          buttonType="primary"
          title={certificateUrl ? 'Download Certificate' : 'Generating Certificate...'}
          style={{ marginTop: '32px' }}
          disabled={!certificateUrl}
          clicked={() => window.open(certificateUrl, '_blank')}
        />
      ) : null}
    </ContainerDiv>
  )
}
