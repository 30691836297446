import { Table, TableProps } from 'antd'
import React from 'react'
import { FeedEmptyContent, Loader } from 'src/components/Common'
import styled from 'styled-components'

const FjStyledTable = styled(Table)`
  .ant-table {
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(29, 30, 33, 0.1);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    padding: 12px 16px;
    border-right: 1px solid rgba(29, 30, 33, 0.1);
    background: #f9f9fa;
    font-size: 14px;
    color: #74716f;
  }
  .ant-table-thead > tr > th:last-child {
    border-right: none;
  }
  .ant-table-tbody > tr > td {
    padding: 16px;
    border-right: 1px solid rgba(29, 30, 33, 0.1);
    & * {
      word-break: unset !important;
    }
  }
  .ant-table-tbody > tr > td:last-child {
    border-right: none;
  }
  .ant-table-tbody > tr > td:first-child * {
    font-weight: 600 !important;
  }
  .ant-table-placeholder td {
    padding: unset !important;
  }
`

export const FjTable = ({ loading, ...props }: TableProps<any>) => {
  return (
    <FjStyledTable
      {...props}
      loading={typeof loading !== 'boolean' ? loading : { spinning: loading, indicator: <Loader /> }}
      locale={{
        emptyText: <FeedEmptyContent title="No data" imgWidth="20%" />,
      }}
    />
  )
}
