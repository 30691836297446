import { Popover } from 'antd'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { tabletQuery } from 'src/pages/public/common'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { FeedTask } from 'src/models/FeedTask'
import { Call } from 'src/models/Call'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { LearningContent } from 'src/utils/content'
import { Clock } from 'react-feather'

type DateInfoPopoverProps = {
  obj: LearningContent | Hub | Page | FeedTask
  viewMode?: 'card' | 'list'
}

export const DateInfoPopover = ({ obj, viewMode = 'list' }: DateInfoPopoverProps) => {
  const isOpportunity = obj instanceof SfOpportunity
  const isCall = obj instanceof Call
  const isHub = obj instanceof Hub
  const isTask = obj instanceof FeedTask
  const isTablet = useMediaQuery(tabletQuery)

  if (isTask || isOpportunity || isHub || (isCall && !obj.scheduled)) return null

  return (
    <Popover
      placement={isTablet ? 'right' : 'top'}
      showArrow
      content={
        <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start">
          {isCall ? (
            <FjText fontSize="small">{`Created ${obj.scheduled.fromNow()}`}</FjText>
          ) : (
            <>
              <FjText fontSize="small">{`Created ${obj.createdAt.fromNow()}`} </FjText>
              <FjText fontSize="small">{`Updated ${obj.lastUpdated.fromNow()}`} </FjText>
              {obj.expiryDate ? <FjText>{`Expires ${obj.expiryDate.format('MM/DD/YYYY')}`} </FjText> : null}
            </>
          )}
        </ContainerDiv>
      }
    >
      {isTablet && viewMode === 'list' ? (
        <Clock color={Colors.cornflowerBlue} size={16} style={{ minWidth: 16, marginRight: 4 }} />
      ) : (
        <FjText
          fontSize="small"
          color={Colors.cottonSeed}
          cursor="pointer"
          style={{ minWidth: '150px' }}
          textAlign="left"
        >
          {isCall
            ? `Created ${obj.scheduled.fromNow()}`
            : `${obj.lastUpdated ? 'Updated' : 'Created'} ${(obj.lastUpdated ?? obj.createdAt).fromNow()}`}
        </FjText>
      )}
    </Popover>
  )
}
