import React from 'react'
import { ContainerDiv, DefaultButton } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { observer } from 'mobx-react'
import { Menu } from 'react-feather'
import { CREATE_MENU_BACKGROUND } from 'src/components/Feed/FeedNavbar'

@observer
export class FloatingPanel extends React.Component<{ openMobileDrawer: (isOpen: boolean) => void }> {
  render() {
    return (
      <ContainerDiv
        display="flex"
        flexDirection="column"
        style={{ position: 'fixed', top: '15px', left: '8px', zIndex: 100 }}
      >
        <DefaultButton
          buttonType="primary"
          image={<Menu color={Colors.chambray} size={16} />}
          style={{ background: CREATE_MENU_BACKGROUND, paddingLeft: '14px', paddingRight: '14px' }}
          clicked={() => this.props.openMobileDrawer(true)}
        />
      </ContainerDiv>
    )
  }
}
