import { handleOpenDuplicateModal, handleOpenArchiveModal } from 'src/components/Common/SharedConfirmDialog'
import { ILibraryCardProps } from 'src/components/Library/LibraryCard'
import { Paths } from 'src/constants/navigation'
import { showNotification } from 'src/hoc/Notification'
import { Asset } from 'src/models/Asset'
import { AutomationRule } from 'src/models/AutomationRule'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { CoachingMomentTrigger } from 'src/models/CoachingMomentTrigger'
import { Course } from 'src/models/Course'
import { Favouritable } from 'src/models/Favouritable'
import { FeedPlaylist, PlaylistElement } from 'src/models/FeedPlaylist'
import { FeedPost } from 'src/models/FeedPost'
import { Call } from 'src/models/Call'
import { ALL_PUBLIC_USERS_GROUP, Group } from 'src/models/Group'
import { Hub, HubElement } from 'src/models/Hub'
import { LearningPath } from 'src/models/LearningPath'
import { Module } from 'src/models/Module'
import { Page } from 'src/models/Page'
import { Prompt } from 'src/models/Prompt'
import { QueueElement, QueueItem } from 'src/models/QueueItem'
import { Rubric } from 'src/models/Rubric'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { APIProvider } from 'src/network/APIProvider'
import { frontendURL } from 'src/network/FlockjayProvider'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import {
  LearningContent,
  getFeedContentClass,
  getFeedContentTargetLink,
  getFeedContentTitle,
  getFeedContentType,
} from 'src/utils/content'
import { getQueryParam } from 'src/utils/urlParams'
import { DealRoom } from 'src/models/DealRoom'
import { copyToClipboard } from 'src/utils/format'
import { TemplatableItem } from 'src/components/Feed/TemplateAuthoringModal'
import { Template } from 'src/models/Template'
import { sharedQueryClient } from 'src/store/QueryClient'

export type EditableElement =
  | FeedPlaylist
  | Course
  | LearningPath
  | Hub
  | Page
  | AutomationRule
  | Prompt
  | Rubric
  | CoachingMomentTrigger
  | Template
export type DuplicatableElement = Course | LearningPath | Page | Asset
export type DeleteableElement =
  | LearningContent
  | Hub
  | Page
  | AutomationRule
  | Group
  | Rubric
  | CoachingMomentTrigger
  | Module
  | Template
export type ArchiveableElement = FeedPost | Asset | Prompt | FeedPlaylist | Course | LearningPath | DealRoom

export const getMenuContext = (
  obj: ILibraryCardProps['obj'],
  options?: {
    hideEdit?: boolean
    hideDuplicate?: boolean
    hideAddToQueue?: boolean
    hideAddToFavorite?: boolean
    hideAddToCollection?: boolean
    hideShareExternally?: boolean
    insidePromotedSearch?: boolean
    onHidePostSuccess?: () => void
    onArchiveSuccess?(): void
    onEditSuccess?(obj: ILibraryCardProps['obj']): void
    onDeleteSuccess?(obj?: ILibraryCardProps['obj']): void
    onEditDriveFileSuccess?: (asset: Asset) => void
    onCreateTemplateSuccess?: (template: Template) => void
    handlePromotedSearchChangeSuccess?: () => Promise<void>
  }
) => {
  const {
    hideEdit,
    hideDuplicate,
    hideAddToQueue,
    hideAddToFavorite,
    hideAddToCollection,
    hideShareExternally,
    insidePromotedSearch,
    onHidePostSuccess,
    onArchiveSuccess,
    onEditSuccess,
    onDeleteSuccess,
    handlePromotedSearchChangeSuccess,
    onEditDriveFileSuccess,
    onCreateTemplateSuccess,
  } = options || {}

  let targetObj = (obj instanceof FeedPost && obj.linkedObject) || obj instanceof QueueItem ? obj.linkedObject : obj
  const isFeedPost = targetObj instanceof FeedPost
  const isPlaylist = targetObj instanceof FeedPlaylist
  const isPrompt = targetObj instanceof Prompt
  const isCourse = targetObj instanceof Course
  const isOpportunity = targetObj instanceof SfOpportunity
  const isCall = targetObj instanceof Call
  const isAsset = targetObj instanceof Asset
  const isHub = targetObj instanceof Hub
  const isPage = targetObj instanceof Page
  const isLearningPath = targetObj instanceof LearningPath
  const isCoachingMoment = targetObj instanceof CoachingMoment
  const isDealRoom = targetObj instanceof DealRoom

  const canAddToQueue =
    !hideAddToQueue &&
    sharedDataStore.userCanModifyQueueItem(obj.id) &&
    !isHub &&
    !isPage &&
    !isDealRoom &&
    !sharedDataStore.user.isFaasPublic() &&
    !sharedDataStore.user.isAnonymous()
  const canAddToFavourites =
    !hideAddToFavorite &&
    !isHub &&
    !isPage &&
    !isCoachingMoment &&
    !isOpportunity &&
    !sharedDataStore.user.isFaasPublic() &&
    !sharedDataStore.user.isAnonymous()

  const canEdit = !hideEdit && obj.canEdit()
  const canAddToCollection = !hideAddToCollection && obj.canAddToCollection()
  const canAddToHub = obj.canAddToHub()
  const canAddToLearningPath = obj.canAddToLearningPath()
  const insideSearch = window.location.href.includes(Paths.search)
  const canAddToPromotedSearch = obj.canAddToPromotedSearch() && !insidePromotedSearch && insideSearch
  const canRemoveFromPromotedSearch = insidePromotedSearch
  const canDelete = obj.canDelete()
  const canDuplicate = !hideDuplicate && obj.canDuplicate()
  const canArchive = obj.canArchive()
  const showGoogleDriveMenu = targetObj instanceof Asset && targetObj.hasGoogleDriveMetadata()
  const canEditDriveFile = targetObj instanceof Asset && targetObj.canEditGoogleDriveFile()
  const insideFeed = window.location.href.includes(Paths.feed)
  const canHide = sharedDataStore.user.isFaasAdminOrManager() && insideFeed
  const canOpenAnalyticsModal = isFeedPost || isAsset || isDealRoom
  const canShareExternally =
    !hideShareExternally && (isAsset || isDealRoom) && (targetObj as DealRoom).canShareExternally()
  const canCreateTemplate = targetObj.canCreateTemplate()

  const handleResyncFromDrive = async ({ domEvent }) => {
    domEvent.preventDefault()
    if (!(targetObj instanceof Asset)) return
    try {
      const isUpdated = await targetObj.resyncFromDrive()
      if (isUpdated) showNotification({ message: `${targetObj.title} has been re-synced from Drive`, type: 'success' })
      else
        showNotification({
          message: `No changes have been made to ${targetObj.title} since last sync.`,
          type: 'info',
        })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  const handleEditDriveFile = async ({ domEvent }) => {
    domEvent.preventDefault()
    if (!(targetObj instanceof Asset)) return
    if (!targetObj.canEditGoogleDriveFile()) return
    const onCancel = async () => {
      const asset = targetObj as Asset
      await sharedAppStateStore.wrapAppLoading(asset.resyncFromDrive(4000), 'Resyncing from Google Drive')
      sharedAppStateStore.editDriveFileModalProps = undefined
      onEditDriveFileSuccess(Asset.fromData({ ...asset }))
    }
    sharedAppStateStore.editDriveFileModalProps = {
      asset: targetObj,
      onCancel: onCancel,
    }
  }

  const handleOpenHideFromFeedModal = ({ domEvent }) => {
    domEvent.preventDefault()
    const hidePost = async () => {
      try {
        await obj.patch('hide')
        sharedQueryClient.invalidateQueries({ queryKey: [FeedPost.apiEndpoint], refetchType: 'none' })
        onHidePostSuccess?.()
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    }
    sharedAppStateStore.sharedConfirmDialogProps = {
      onConfirm: () => hidePost(),
      title: 'Hide Post from Feed',
      content: 'Are you sure you want to hide this post from the Feed?',
      confirmButtonTitle: 'Hide',
    }
  }

  const handleShareExternally = ({ domEvent }) => {
    domEvent.preventDefault()
    if (isAsset) {
      const onCancel = () => (sharedAppStateStore.sharedContentModalProps = undefined)
      sharedAppStateStore.sharedContentModalProps = {
        onCancel: onCancel,
        onSuccess: onCancel,
        asset: targetObj as Asset,
      }
    } else if (isDealRoom) {
      ;(targetObj as DealRoom).openEditSharedContentModal()
    }
  }

  const handleSharePublicly = async ({ domEvent }) => {
    domEvent.preventDefault()
    if (!(targetObj instanceof Course || targetObj instanceof LearningPath)) return

    try {
      const allPublicUsersGroup = sharedDataStore.user.groups.find((group) => group.name === ALL_PUBLIC_USERS_GROUP)
      const newGroupIds = [...targetObj.groupIds, allPublicUsersGroup.id]
      // we don't update object here because backend doesn't send submodules/content in response
      // but in order to hide the "Share Publicly" option in dropdwon menus we need to add this groupId to groupIds
      // so doing it manually below
      await sharedAppStateStore.wrapAppLoading(targetObj.save({ groupIds: newGroupIds }, false, true))
      targetObj.groupIds.push(allPublicUsersGroup.id)
      showNotification({
        message: `${getFeedContentTitle(targetObj)} is now publicly accessible.`,
        type: 'success',
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  const handleLinkCopy = ({ domEvent }) => {
    domEvent.preventDefault()
    copyToClipboard(
      `${frontendURL}${getFeedContentTargetLink(targetObj)}`,
      undefined,
      `Link to ${getFeedContentTitle(targetObj)}`
    )
  }

  const handleEditContent = async ({ domEvent }) => {
    domEvent.preventDefault()
    const closeFeedPostModal = () => (sharedAppStateStore.feedPostModalProps = undefined)
    const closePromptModal = () => (sharedAppStateStore.promptModalProps = undefined)

    const onFeedPostSuccess = (feedPost?: FeedPost) => {
      closeFeedPostModal()
      if (feedPost && onEditSuccess) onEditSuccess(FeedPost.fromData({ ...feedPost }))
    }

    const onPromptSuccess = (prompt: Prompt) => {
      closePromptModal()
      onEditSuccess?.(Prompt.fromData({ ...prompt }))
    }

    const promptDelete = () => {
      closePromptModal()
      onDeleteSuccess?.()
    }
    if (isFeedPost) {
      const feedPost = await sharedAppStateStore.wrapAppLoading(FeedPost.get(targetObj.id))
      sharedAppStateStore.feedPostModalProps = {
        onSuccess: onFeedPostSuccess,
        onCancel: closeFeedPostModal,
        autoStartRecord: false,
        feedPost,
      }
    } else if (isPlaylist) {
      const redirectPath = window.location.pathname.includes('/library') ? Paths.library : undefined
      sharedAppStateStore.navigate(Paths.getPlaylistAuthoringPath(targetObj.id, redirectPath))
      return
    } else if (isPrompt) {
      const prompt = await sharedAppStateStore.wrapAppLoading(
        Prompt.get(targetObj.id, undefined, undefined, { expand: 'hubs' })
      )
      sharedAppStateStore.promptModalProps = {
        onSuccess: onPromptSuccess,
        onCancel: closePromptModal,
        onDelete: promptDelete,
        prompt,
      }
    } else if (isCourse) {
      sharedAppStateStore.navigate(Paths.getCoursePath({ id: targetObj.id, isAuthoring: true }))
    } else if (isAsset) {
      const asset = await sharedAppStateStore.wrapAppLoading(
        Asset.get(targetObj.id, undefined, undefined, { expand: 'hubs' })
      )
      const hideModal = () => (sharedAppStateStore.assetModalProps = undefined)
      const onSuccess = (asset: Asset, close: boolean) => {
        if (close) {
          hideModal()
          onEditSuccess?.(Asset.fromData({ ...asset }))
          return
        }

        sharedAppStateStore.assetModalProps = {
          asset,
          onSuccess: onSuccess,
          onCancel: hideModal,
        }
      }

      sharedAppStateStore.assetModalProps = {
        asset,
        onSuccess: onSuccess,
        onCancel: hideModal,
      }
    } else if (isHub) {
      sharedAppStateStore.navigate(Paths.getHubPath({ id: targetObj.id, isAuthoring: true }))
    } else if (isDealRoom) {
      sharedAppStateStore.navigate(Paths.getDealRoomPath({ id: targetObj.id, isAuthoring: true }))
    } else if (isPage) {
      sharedAppStateStore.navigate(Paths.getPagePath({ id: targetObj.id, isAuthoring: true }))
    } else {
      sharedAppStateStore.navigate(Paths.getLearningPathPath({ id: targetObj.id, isAuthoring: true }))
    }
  }

  const handleToggleFavourite = async ({ domEvent }: { domEvent?: any }) => {
    domEvent?.preventDefault()
    if (!(targetObj instanceof Favouritable)) return
    await targetObj.toggleFavourited()
    const title = getFeedContentTitle(targetObj)
    showNotification({
      message: `${title ? `${title} has been ` : ''}${
        !targetObj.isFavourited ? 'removed from ' : 'added to '
      } Favorites`,
    })
  }

  const handleOpenAddToQueueModal = async ({ domEvent }) => {
    domEvent.preventDefault()
    const hideAddToQueueModal = () => (sharedAppStateStore.addToQueueModalProps = undefined)

    if (sharedDataStore.user.isFaasAdminOrManager()) {
      sharedAppStateStore.addToQueueModalProps = {
        onSuccess: hideAddToQueueModal,
        item: targetObj as QueueElement,
        onCancel: hideAddToQueueModal,
      }
    } else {
      try {
        const userId = sharedDataStore.user.id
        const title = getFeedContentTitle(targetObj)
        await QueueItem.addToQueue(targetObj as QueueElement, [userId])

        showNotification({
          message: `${title ? `${title} has been ` : ''} added to your Tasks`,
        })
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    }
  }

  const handleOpenAddToPlaylistModal = ({ domEvent }) => {
    domEvent.preventDefault()
    const hideAddToPlaylistModal = () => (sharedAppStateStore.addContentToContainerModalProps = undefined)

    sharedAppStateStore.addContentToContainerModalProps = {
      onSuccess: hideAddToPlaylistModal,
      obj: targetObj as PlaylistElement,
      onCancel: hideAddToPlaylistModal,
      containerType: 'playlist',
    }
  }

  const handleOpenAddToLearningPathModal = ({ domEvent }) => {
    domEvent.preventDefault()
    const hideAddToLearningPathModal = () => (sharedAppStateStore.addContentToContainerModalProps = undefined)

    sharedAppStateStore.addContentToContainerModalProps = {
      onSuccess: hideAddToLearningPathModal,
      obj: targetObj as LearningContent,
      onCancel: hideAddToLearningPathModal,
      containerType: 'learningpath',
    }
  }

  const handleOpenAddToHubModal = ({ domEvent }) => {
    domEvent.preventDefault()
    const hideAddToHubModal = () => (sharedAppStateStore.addContentToContainerModalProps = undefined)
    sharedAppStateStore.addContentToContainerModalProps = {
      onSuccess: hideAddToHubModal,
      obj: targetObj as HubElement,
      onCancel: hideAddToHubModal,
      containerType: 'hub',
    }
  }

  const handleAddToPromotedSearch = async ({ domEvent }) => {
    domEvent.preventDefault()
    const searchTerm = getQueryParam('q')
    await APIProvider.addToPromotedSearch({
      searchTerm,
      linkedContentType: getFeedContentType(targetObj),
      linkedObjectId: targetObj.id,
    })
    const title = getFeedContentTitle(targetObj)
    handlePromotedSearchChangeSuccess?.()
    showNotification({
      message: `${title} has been successfully added to promoted search results for ${searchTerm}`,
    })
  }

  const handleRemoveFromPromotedSearch = async ({ domEvent }) => {
    domEvent.preventDefault()
    const searchTerm = getQueryParam('q')
    await APIProvider.removeFromPromotedSearch({
      searchTerm,
      linkedContentType: getFeedContentType(targetObj),
      linkedObjectId: targetObj.id,
    })
    const title = getFeedContentTitle(targetObj)
    handlePromotedSearchChangeSuccess?.()
    showNotification({
      message: `${title} has been successfully removed from promoted search results for ${searchTerm}`,
    })
  }

  const handleDuplicateContent = ({ domEvent }) => {
    domEvent.preventDefault()
    handleOpenDuplicateModal(targetObj as DuplicatableElement)
  }

  const handleArchiveContent = async ({ domEvent }) => {
    domEvent.preventDefault()
    handleOpenArchiveModal(
      targetObj as ArchiveableElement,
      'Are you sure you want to archive this content? All admins will still be able to filter for archived content in the library.',
      onArchiveSuccess
    )
  }

  const handleOpenDeleteModal = ({ domEvent }) => {
    domEvent.preventDefault()
    const deleteContent = async (targetObj: LearningContent) => {
      await targetObj.delete()
      sharedQueryClient.invalidateQueries({
        queryKey: [getFeedContentClass(getFeedContentType(targetObj)).apiEndpoint],
        refetchType: 'none',
      })
      if (sharedDataStore.objectPresentInQueue(targetObj.id)) sharedDataStore.refreshQueue()
      onDeleteSuccess?.(targetObj)
    }

    sharedAppStateStore.deleteDialogProps = {
      onConfirm: deleteContent,
      obj: targetObj as DeleteableElement,
    }
  }

  const handleOpenAnalyticsModal = ({ domEvent }) => {
    domEvent.preventDefault()
    if (targetObj instanceof FeedPost) {
      sharedAppStateStore.postAnalyticsModalProps = {
        learningContent: { id: targetObj.id, title: targetObj.title, groupIds: targetObj.groupIds },
      }
    } else if (targetObj instanceof Asset) {
      sharedAppStateStore.assetSummaryModalProps = {
        learningContent: { id: targetObj.id, title: targetObj.title, groupIds: targetObj.groupIds },
      }
    } else if (targetObj instanceof DealRoom) {
      sharedAppStateStore.dealRoomAnalyticsModalProps = { dealRoom: targetObj }
    }
  }

  const handleCreateTemplate = ({ domEvent }) => {
    domEvent.preventDefault()
    if (!canCreateTemplate) return
    const onCancel = () => (sharedAppStateStore.templateAuthoringModalProps = undefined)
    const onSuccess = async (template: Template) => {
      onCreateTemplateSuccess?.(template)
      showNotification({ message: `Template successfully created from ${targetObj.getTagTitle()}: ${targetObj.title}` })
      onCancel()
    }
    sharedAppStateStore.templateAuthoringModalProps = {
      item: targetObj as TemplatableItem,
      onSuccess,
      onCancel,
    }
  }

  return {
    targetObj,
    isFeedPost,
    isPlaylist,
    isPrompt,
    isCourse,
    isOpportunity,
    isCall,
    isAsset,
    isHub,
    isPage,
    isLearningPath,
    isCoachingMoment,
    isDealRoom,
    canAddToQueue,
    canAddToFavourites,
    canEdit,
    canAddToCollection,
    canAddToLearningPath,
    canAddToHub,
    canAddToPromotedSearch,
    canRemoveFromPromotedSearch,
    canDelete,
    canDuplicate,
    canArchive,
    showGoogleDriveMenu,
    canHide,
    canShareExternally,
    canOpenAnalyticsModal,
    canEditDriveFile,
    canCreateTemplate,
    handleAddToPromotedSearch,
    handleRemoveFromPromotedSearch,
    handleLinkCopy,
    handleResyncFromDrive,
    handleToggleFavourite,
    handleArchiveContent,
    handleDuplicateContent,
    handleEditContent,
    handleOpenDeleteModal,
    handleOpenAddToHubModal,
    handleOpenHideFromFeedModal,
    handleOpenAddToPlaylistModal,
    handleOpenAddToLearningPathModal,
    handleOpenAddToQueueModal,
    handleShareExternally,
    handleSharePublicly,
    handleOpenAnalyticsModal,
    handleEditDriveFile,
    handleCreateTemplate,
  }
}
