import React, { Component, ReactNode, Suspense, lazy } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Send } from 'react-feather'
import { computed, observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import { oembed } from '@loomhq/loom-embed'
import { FeedPost } from 'src/models/FeedPost'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import {
  DefaultButton,
  ContainerDiv,
  FjFormItem,
  Loader,
  DefaultTextarea,
  FjInput,
  FormHeaderText,
  GroupSelector,
  TagsDisplayList,
  FjAvatar,
  FjCard,
} from 'src/components/Common'
import { combineValidations, isMaxLength, isRequired } from 'src/utils/validation'
import { clearFalseyValues, formatDateToPST, getIntersection, getTagsList, truncate } from 'src/utils/format'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { sharedDataStore } from 'src/store/DataStore'
import { mobileQuery } from 'src/pages/public/common'
import { Prompt } from 'src/models/Prompt'
import PostType from 'src/models/enums/PostType'
import {
  LOOM_REGEX,
  YOUTUBE_REGEX,
  VIMEO_REGEX,
  FJ_VIDEO_REGEX,
  GONG_REGEX,
  FJ_API_VIDEO_REGEX,
} from 'src/constants/regex'
import { VideoEmbedUrl, VIDEO_TYPE } from 'src/constants/video'
import { FeedTag } from 'src/models/FeedTag'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { getQueryParam } from 'src/utils/urlParams'
import { showNotification } from 'src/hoc/Notification'
import { getOppTemplatePost } from 'src/constants/oppTemplatePosts'
import { getFeedContentTargetLink } from 'src/utils/content'
import { SfMetadataFormFields } from 'src/components/Feed/SfMetadataFormFields'
import { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'
import { ExpirationDateField } from 'src/components/Feed/ExpirationDateField'
import { Col, Row } from 'antd'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

interface CollapsedFeedPostFormProps {
  openModal: (autoStartRecord?: boolean, shareProTip?: boolean) => void
  closeModal: () => void
}

export const CollapsedFeedPostForm: React.FC<CollapsedFeedPostFormProps> = (props) => {
  const isMobile = useMediaQuery(mobileQuery)

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    props.openModal()
  }

  if (isMobile) {
    return (
      <ContainerDiv textAlign="left">
        <FjCard display="flex" borderRadius="10px" padding="9px">
          <ContainerDiv
            borderRight={`1px solid ${Colors.darkIron}`}
            paddingRight="6px"
            cursor="pointer"
            display="flex"
            alignItems="center"
            onClick={(e) => {
              e.preventDefault()
              sharedAppStateStore.navigate(Paths.getProfilePath(sharedDataStore.user.id))
            }}
          >
            <FjAvatar size={30} author={sharedDataStore.user} />
          </ContainerDiv>

          <ContainerDiv flex="1" display="flex" alignItems="center" flexDirection="column" padding="0px 8px">
            <ContainerDiv width="100%">
              <DefaultTextarea
                paddingVertical="5px"
                paddingHorizontal="10px"
                borderRadius="10px"
                height="40px"
                placeholder="Share an insight, win story, or get feedback from the team"
                changed={handleInputChange}
                clicked={() => props.openModal(false)}
                style={{ resize: 'none', paddingTop: '10px', border: 'none', boxShadow: 'none' }}
              />
            </ContainerDiv>
          </ContainerDiv>
        </FjCard>
      </ContainerDiv>
    )
  }

  return (
    <ContainerDiv textAlign="left">
      <ContainerDiv display="flex" borderRadius="10px" alignItems="center" flexDirection="column">
        <ContainerDiv width="100%" display="flex" alignItems="center" padding="10px">
          <ContainerDiv
            borderRight={`1px solid ${Colors.darkIron}`}
            paddingRight="10px"
            marginRight="10px"
            height="45px"
            cursor="pointer"
            onClick={(e) => {
              e.preventDefault()
              sharedAppStateStore.navigate(Paths.getProfilePath(sharedDataStore.user.id))
            }}
          >
            <FjAvatar size={42} author={sharedDataStore.user} />
          </ContainerDiv>

          <ContainerDiv paddingLeft="10px" paddingTop="6px" width="100%" flex={1}>
            <DefaultTextarea
              paddingVertical="5px"
              paddingHorizontal="10px"
              borderRadius="10px"
              height="40px"
              placeholder="Share an insight, win story, or get feedback from the team"
              changed={handleInputChange}
              clicked={() => props.openModal(false)}
              style={{ resize: 'none', paddingTop: '10px', border: 'none', boxShadow: 'none' }}
            />
          </ContainerDiv>
        </ContainerDiv>
      </ContainerDiv>
    </ContainerDiv>
  )
}

interface FeedPostFormValues {
  feedPostId?: string
  values?: object
}

export interface FeedPostFormProps {
  autoStartRecord?: boolean
  feedPost?: FeedPost
  prompt?: Prompt
  onCancel(): void
  onSuccess?(feedPost?: FeedPost): void
  shareProTipClicked?: boolean
  isFeedPostUpdated?: boolean
  initialValue?: string
}

@observer
export class FeedPostForm extends Component<FeedPostFormProps> {
  @observable submitDisabled = false
  @observable body = ''
  @observable bodyError = undefined
  savedValues: FeedPostFormValues = {}
  @observable suggestedTags: string[] = []
  @observable sfMetadataFormFieldsExpanded = false

  ckEditor: any
  formikRef: any

  constructor(props: FeedPostFormProps) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
    this.body = this.props.initialValue || this.props.feedPost?.body || ''
  }

  componentDidMount() {
    this.handleUrlParams()
    this.fetchTrendingTags()
    if (this.props.feedPost?.sfMetadata) {
      this.sfMetadataFormFieldsExpanded = true
    }
  }

  handleUrlParams = async () => {
    const createPost = getQueryParam('create_post')
    const oppId = getQueryParam('opp_id')
    const template = getQueryParam('template')
    if (createPost && oppId) {
      try {
        const opportunity: SfOpportunity = await sharedAppStateStore.wrapAppLoading(SfOpportunity.get(oppId))
        this.sfMetadataFormFieldsExpanded = true
        const oppFeedPostTemplate = getOppTemplatePost(opportunity, template)
        this.body = oppFeedPostTemplate.body
        this.formikRef.current.setFieldValue('title', oppFeedPostTemplate.title)
        this.formikRef.current.setFieldValue('sfMetadata', {
          id: opportunity.id,
          name: opportunity.name,
          stageName: opportunity.stageName,
        })
      } catch (err) {
        sharedAppStateStore.handleError(err, undefined, true, 'Opportunity not found')
      }
    }
  }

  fetchTrendingTags = async () => {
    try {
      this.suggestedTags = (await FeedTag.list(undefined, undefined, 'trending')).map((tag: FeedTag) => tag.tag)
      if (this.props.shareProTipClicked) this.tagClicked('protips')
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined)
    }
  }

  handleSubmit = async (data: any) => {
    try {
      const { isPublic, metaData, title, postType, promptId, groupIds, sfMetadata, expiryDate } = data
      const feedPost = this.props.feedPost || new FeedPost()
      const tags = getTagsList(this.body)

      if (!this.body) {
        this.setBodyError('This field is required')
        return
      } else if (tags.length <= 0) {
        this.setBodyError('Please add at least 1 tag in the body of your post')
        return
      }
      let trimmedBody = this.body.replace(/^(<p>(&nbsp;)*?<\/p>)*|/, '').replace(/(<p>(&nbsp;)*?<\/p>)*$/, '')

      const feedPostData = {
        isPublic,
        title,
        body: trimmedBody,
        tags,
        expiryDate: formatDateToPST(expiryDate),
        groupIds:
          !this.shouldShowGroupSelector && this.props?.prompt?.groupIds
            ? getIntersection(this.props.prompt.groupIds, sharedDataStore.user.groupIds)
            : groupIds,
        postType,
        videoMetadata: metaData,
        promptId,
        sfMetadata: clearFalseyValues(sfMetadata),
      }
      await sharedAppStateStore.wrapAppLoading(feedPost.save(feedPostData))
      if (feedPost.isInReview()) {
        showNotification({ message: 'Your post has been successfully created and is pending review' })
      } else {
        showNotification({
          message: `${truncate(feedPost.title, 70)} was ${this.props.feedPost ? 'updated' : 'created'} successfully!`,
          duration: 10,
          target: getFeedContentTargetLink(feedPost),
          clickToView: true,
        })
      }
      if (this.props.onSuccess) this.props.onSuccess(feedPost)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  setBodyError = (msg: string) => (this.bodyError = msg)

  clearBodyError = () => {
    if (this.bodyError) this.bodyError = undefined
  }

  handleEditorChange = async (body: string) => {
    if (!this.formikRef.current) return
    this.body = body
    this.clearBodyError()
    sharedAppStateStore.setFeedPostIsUpdated(body)

    const currentVideoData = this.formikRef.current.values['metaData']
    const gongId = this.formikRef.current.values['gongId']
    const loomUrlMatch = body.match(LOOM_REGEX)
    const youtubeUrlMatch = body.match(YOUTUBE_REGEX)
    const vimeoUrlMatch = body.match(VIMEO_REGEX)
    const fjVideoUrlMatch = body.match(FJ_VIDEO_REGEX)
    const fjApiVideoMatch = body.match(FJ_API_VIDEO_REGEX)
    const gongUrlMatch = body.match(GONG_REGEX)

    let videoId = '',
      videoType = ''
    if (loomUrlMatch) {
      videoId = loomUrlMatch[1]
      videoType = VIDEO_TYPE.LOOM
    } else if (youtubeUrlMatch) {
      videoId = youtubeUrlMatch[1]
      videoType = VIDEO_TYPE.YOUTUBE
    } else if (vimeoUrlMatch) {
      videoId = vimeoUrlMatch[1]
      videoType = VIDEO_TYPE.VIMEO
    } else if (fjVideoUrlMatch) {
      videoId = fjVideoUrlMatch[1]
      videoType = VIDEO_TYPE.FLOCKJAY
    } else if (fjApiVideoMatch) {
      videoId = fjApiVideoMatch[1]
      videoType = VIDEO_TYPE.FLOCKJAY
    }

    if (
      body &&
      videoId &&
      videoType !== VIDEO_TYPE.FLOCKJAY &&
      (!currentVideoData || currentVideoData.videoId !== videoId)
    ) {
      this.submitDisabled = true

      let videoData = {
        videoId,
        embedUrl: `${VideoEmbedUrl[videoType]}${videoId}`,
        duration: undefined,
        thumbnailUrl: undefined,
      }
      try {
        if (videoType === VIDEO_TYPE.LOOM) {
          const metaData = await oembed(`https://www.loom.com/share/${videoId}`)
          videoData.duration = metaData['duration']
          videoData.thumbnailUrl = metaData['thumbnail_url']
        }
      } catch (err) {
        sharedAppStateStore.handleError(err, `Failed to pull Loom video metadata for ID: ${videoId}`, false)
      }

      this.formikRef.current.setFieldValue('metaData', videoData)
      this.submitDisabled = false
      if (!this.props.feedPost) {
        this.moveCursorToEnd()
      }
    } else if (currentVideoData && !videoId) {
      this.formikRef.current.setFieldValue('metaData', null)
    }

    if (body && gongUrlMatch && gongUrlMatch[1] !== gongId) {
      this.formikRef.current.setFieldValue('gongId', gongUrlMatch[1])
      if (!this.props.feedPost) {
        this.moveCursorToEnd()
      }
    }
  }

  moveCursorToEnd = () => {
    this.ckEditor.focus()
    this.ckEditor.model.change((writer) =>
      writer.setSelection(writer.createPositionAt(this.ckEditor.model.document.getRoot(), 'end'))
    )
  }

  handleSetEditor = (editor: any) => {
    this.ckEditor = editor
  }

  getHeaderText = () => {
    let heading = `${this.props.feedPost ? 'Edit' : 'Create'} post`
    let subHeading: ReactNode = 'What do you want to share with your team?'

    if (this.props.prompt) {
      heading = `Add your response to "${this.props.prompt.question}"`
      subHeading = FeedHTMLBodyParser(this.props.prompt.description, {
        minimizeHeadings: true,
        showEmbedAsLink: true,
        showMediaAsLink: true,
      })
    }

    return { heading, subHeading }
  }

  getInitialValues = () => {
    const { feedPost, prompt } = this.props
    const promptId = (prompt && prompt.id) || (feedPost && feedPost.prompt && feedPost.prompt.id)
    if (this.savedValues.values && this.savedValues.feedPostId === (feedPost ? feedPost.id : '')) {
      // @todo This code `groupIds: this.formikRef.current?.values?.['groupIds']` was needed to retain preselected groups when Reinitialize happens. needs to fix - Story ID - #184532433
      return {
        ...this.savedValues.values,
        ...(this.formikRef.current?.values ?? {}),
      }
    }
    let { body, isPublic, videoMetadata, title, groupIds, expiryDate } = feedPost || new FeedPost()
    if (prompt?.groupIds.length) groupIds = getIntersection(prompt.groupIds, sharedDataStore.user.groupIds)
    if (!groupIds.length && !sharedDataStore.user.isPartOfManyGroups(true)) {
      groupIds = [sharedDataStore.user.defaultGroup.id]
    }
    const postType = PostType.STANDARD
    const gongUrlMatch = body.match(GONG_REGEX)
    // we don't add body here because ckEditor and formik get out of sync and it causes problems
    return {
      isPublic,
      metaData: videoMetadata,
      title,
      postType,
      promptId,
      gongId: gongUrlMatch ? gongUrlMatch[1] : '',
      sfMetadata: {
        id: feedPost?.sfMetadata?.id,
        name: feedPost?.sfMetadata?.name,
        stageName: feedPost?.sfMetadata?.stageName,
      },
      groupIds,
      expiryDate,
    }
  }

  handleRecordStart = () => {
    sharedAppStateStore.hideVideoRecordModal = true
  }

  tagClicked = (tag: string) => {
    const content = `&nbsp;<a class="tags"  data-tags="#${tag}" href=${Paths.getTagFeedPath(tag)}>#${tag}</a>&nbsp;`
    const viewFragment = this.ckEditor.data.processor.toView(content)
    const modelFragment = this.ckEditor.data.toModel(viewFragment)
    this.ckEditor.model.insertContent(modelFragment)
    this.suggestedTags = this.suggestedTags.filter((t) => t !== tag)
  }

  handleTitleBlur = () => {
    this.savedValues = {
      feedPostId: this.props.feedPost ? this.props.feedPost.id : '',
      values: this.formikRef.current.values,
    }
  }

  @computed get shouldShowGroupSelector() {
    const { prompt } = this.props
    if (prompt?.groupIds && getIntersection(prompt.groupIds, sharedDataStore.user.groupIds).length === 1) return false
    else return sharedDataStore.user.isPartOfManyGroups(true)
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText {...this.getHeaderText()} />
        <Formik
          innerRef={this.formikRef}
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          <Form>
            <FjFormItem name="title" fieldtitle="Title*" validate={combineValidations(isRequired, isMaxLength(100))}>
              <FjInput
                name="title"
                placeholder="Title"
                onChange={(e) => sharedAppStateStore.setFeedPostIsUpdated(e.currentTarget.value)}
                onBlur={this.handleTitleBlur}
              />
            </FjFormItem>
            <SfMetadataFormFields
              expanded={this.sfMetadataFormFieldsExpanded}
              handleSelect={(_value: any, option: any) =>
                this.formikRef.current.setFieldValue('sfMetadata', {
                  id: option.id,
                  name: option.name,
                  stageName: option.stageName,
                })
              }
              handleClear={() => this.formikRef.current.setFieldValue('sfMetadata', null)}
            />
            <FjFormItem name="body" fieldtitle="Post Content*" className="content-editor">
              <Suspense fallback={<Loader />}>
                <FJCKEditor
                  showRecordVideoBtn
                  autoStartRecord={this.props.autoStartRecord}
                  includeMentions
                  data={this.body}
                  errorMsg={this.bodyError}
                  setEditor={this.handleSetEditor}
                  placeholder="Share an insight, win story, or get feedback from the team"
                  handleChange={this.handleEditorChange}
                />
              </Suspense>
            </FjFormItem>
            <FjFormItem name="postType" style={{ textAlign: 'right', display: 'none' }}>
              <FjInput name="postType" />
            </FjFormItem>
            {this.suggestedTags.length ? (
              <FjFormItem name="suggestedTags" fieldtitle="Suggested Tags">
                <TagsDisplayList tags={this.suggestedTags} tagClicked={this.tagClicked} />
              </FjFormItem>
            ) : null}
            <Row gutter={16}>
              {this.shouldShowGroupSelector ? (
                <Col lg={12} md={24}>
                  <FjFormItem name="groupIds" fieldtitle="Groups*" validate={isRequired}>
                    <GroupSelector
                      name="groupIds"
                      selectableGroups={getIntersection(this.props.prompt?.groupIds, sharedDataStore.user.groupIds)}
                      excludePublicUserGroup
                    />
                  </FjFormItem>
                </Col>
              ) : null}
              <Col lg={12} md={24}>
                <ExpirationDateField />
              </Col>
            </Row>
            <ContainerDiv display="flex" justifyContent="flex-end">
              <DefaultButton
                type="submit"
                buttonType="primary"
                image={<Send size={16} />}
                disabled={this.submitDisabled}
                title={this.props.feedPost ? 'Save' : 'Post'}
              />
            </ContainerDiv>
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
