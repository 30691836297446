import React, { Suspense } from 'react'
import { Layout } from 'antd'
import { PageTitle, PageLayout, DefaultLink, ContainerDiv, Anchor, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { PublicFooter } from 'src/pages/public/publicFooter'
import { ContentWrapper } from 'src/components/Page/ContentWrapper'
import { sharedDataStore } from 'src/store/DataStore'
import { frontendURL } from 'src/network/FlockjayProvider'
import { FeedPage } from 'src/components/Page/Page'
import { PublicFeedNavbar } from 'src/components/Feed/FeedNavbar'
import { sharedAppStateStore } from 'src/store/AppStateStore'

export const NotFound: React.FC = () => {
  const isAuthorized = sharedDataStore.authState === 'authorized'

  const LinkComponent = isAuthorized ? (
    <>
      Go back{' '}
      <DefaultLink style={{ fontSize: '25px' }} to={sharedDataStore.getLoginHandlerPath()} color="teal">
        home
      </DefaultLink>
    </>
  ) : (
    <>
      Go back{' '}
      <Anchor style={{ fontSize: '25px' }} href={frontendURL}>
        home
      </Anchor>
    </>
  )

  if (isAuthorized)
    return (
      <FeedPage title="Not Found">
        <ContainerDiv style={{ overflow: 'hidden', marginTop: '8rem' }}>
          <PageTitle>Unfortunately, the page you were looking for could not be found.</PageTitle>
          <FjText fontSize="25px">{LinkComponent}</FjText>
        </ContainerDiv>
      </FeedPage>
    )

  return (
    <PageLayout>
      {sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists() ? (
        <Suspense fallback={<></>}>{sharedAppStateStore.externalAcademy?.getCustomHeaderIfExists()}</Suspense>
      ) : (
        <PublicFeedNavbar />
      )}
      <Layout.Content
        style={{
          backgroundColor: Colors.white,
          minHeight: 'calc(50vh)',
          padding: '50px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ContentWrapper backgroundColor={Colors.white}>
          <ContainerDiv style={{ overflow: 'hidden' }}>
            <PageTitle>Unfortunately, the page you were looking for could not be found.</PageTitle>
            <FjText fontSize="25px">{LinkComponent}</FjText>
          </ContainerDiv>
        </ContentWrapper>
      </Layout.Content>
      {sharedAppStateStore.externalAcademy?.getCustomFooterIfExists() ? (
        <Suspense fallback={<></>}>
          <div className="custom-footer">{sharedAppStateStore.externalAcademy?.getCustomFooterIfExists()}</div>
        </Suspense>
      ) : (
        <PublicFooter />
      )}
    </PageLayout>
  )
}
