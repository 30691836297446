import React from 'react'
import { Collapse, Tooltip } from 'antd'
import { ContainerDiv, FjCard, FjText } from 'src/components/Common'
import { CourseSubmodulePreviewCard } from 'src/components/Course/CourseSubmodulePreviewCard'
import { Colors } from 'src/constants/colors'
import { Course } from 'src/models/Course'
import { ChevronDown, ChevronRight, ChevronsRight } from 'react-feather'

const { Panel } = Collapse

type CourseSubmoduleListProps = {
  course: Course
  preview?: boolean
  updateBreadCrumb?: boolean
  onCollapse?: () => void
}

export const CourseSubmoduleList: React.FC<CourseSubmoduleListProps> = ({
  course,
  preview,
  updateBreadCrumb = true,
  onCollapse,
}) => {
  if (!course) return null
  return (
    <FjCard textAlign="left" overflow="hidden" padding="0">
      <ContainerDiv
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="16px"
        borderBottom={`solid 2px ${Colors.sharkOpacity10}`}
      >
        <FjText fontSize="20px" fontWeight="bold" color={Colors.midnightBlue} display="block" textAlign="left">
          Modules
        </FjText>
        {onCollapse ? (
          <Tooltip title="Hide Modules">
            <ChevronsRight
              size={20}
              color={Colors.cottonSeed}
              style={{ minWidth: 20, cursor: 'pointer' }}
              onClick={onCollapse}
            />
          </Tooltip>
        ) : null}
      </ContainerDiv>
      <Collapse
        bordered={false}
        style={{ border: 'none', maxHeight: preview ? '320px' : 'calc(100vh - 160px)', overflow: 'auto' }}
        defaultActiveKey={course.modules.map((m) => m.id)}
        expandIcon={({ isActive }) =>
          isActive ? <ChevronDown color={Colors.cottonSeed} /> : <ChevronRight color={Colors.cottonSeed} />
        }
      >
        {course.modules.map((module, moduleIndex) => {
          const isLastModuleItem = moduleIndex === course.modules.length - 1
          return (
            <Panel
              header={
                <FjText fontSize="16px" fontWeight="bold" color={Colors.fuscousGray}>
                  {module.title}
                </FjText>
              }
              key={module.id}
            >
              {module.submodules.map((s, submoduleIndex) => {
                const isLastSubmoduleItem = submoduleIndex === module.submodules.length - 1
                const isLastItem = isLastModuleItem && isLastSubmoduleItem
                return (
                  <CourseSubmodulePreviewCard
                    key={`submodule-preview-${course.id}-${s.id}`}
                    style={{
                      width: '100%',
                      ...(isLastItem ? { border: 'none' } : isLastSubmoduleItem ? { borderWidth: '2px' } : {}),
                    }}
                    submodule={s}
                    course={course}
                    updateBreadCrumb={updateBreadCrumb}
                  />
                )
              })}
            </Panel>
          )
        })}
      </Collapse>
    </FjCard>
  )
}
