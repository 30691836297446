/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ChopLines from 'chop-lines'
import { ContainerDiv } from 'src/components/Common'
import PDFRenderer from 'src/components/Common/PDFRenderer'
import { Anchor, DefaultLink } from 'src/components/Common/Link'
import parse, { HTMLReactParserOptions, domToReact, attributesToProps, Element } from 'html-react-parser'
import { Image } from 'antd'
import { MS_DOC_LINK_REGEX, PDF_LINK_REGEX } from 'src/constants/regex'
import ExternalLinkIcon from 'src/assets/icons/ExternalLink.svg'
import { Colors } from 'src/constants/colors'
import TranscriptView from 'src/components/Feed/TranscriptView'
import { Document } from 'src/models/Document'
import { MSDocRenderer } from 'src/components/Common/MSDocRenderer'
import { SharedContentEmbeddedModalAnchor } from 'src/pages/SharedContent'
import styled from 'styled-components'
import { camelize } from 'src/utils/format'

const getStyleObjectFromString = (str: string = '') => {
  const style = {}
  str.split(';').forEach((el) => {
    const [property, value] = el.split(':')
    if (!property) return

    const formattedProperty = camelize(property.trim(), '-')
    style[formattedProperty] = value.trim()
  })

  return style
}

const setDownloadOnControlsList = (props: object, allowDownload?: boolean) => {
  const newProps = { ...props }
  const controlsList = newProps['controlsList'] || ''
  if (allowDownload && controlsList) {
    newProps['controlsList'] = controlsList
      .split(' ')
      .filter((option) => option !== 'nodownload')
      .join(' ')
  } else if (!allowDownload && !controlsList.includes('nodownload')) {
    newProps['controlsList'] += controlsList + ' nodownload'
  }
  return newProps
}

const Ellipsis = styled.a`
  background: white;
  font-size: 14px;
  font-weight: 600;
  outline: none;

  span {
    background: white;
    border-radius: 12px;
    color: ${Colors.cornflowerBlue};
    display: inline-block;
    padding: 0 8px;
  }

  :hover,
  :focus {
    span {
      color: ${Colors.cornflowerBlue};
    }
  }

  :before {
    background: linear-gradient(90deg, transparent, white);
    content: '';
    width: 48px;
  }
`

export const ObserverIFrame: React.FC<
  React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement> & { withOverlay?: boolean }, HTMLIFrameElement>
> = ({ src, withOverlay = false, ...props }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = ([entry]: IntersectionObserverEntry[]) => {
    setIsVisible((currentlyVisible) => currentlyVisible || entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      root: null,
      rootMargin: '300px 0px 0px 0px',
      threshold: 1,
    })
    const current = containerRef.current

    if (current) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [])

  return (
    <ContainerDiv
      height="431px"
      marginBottom="1em"
      position="relative"
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => setShowOverlay(false)}
    >
      <div ref={containerRef} />
      {withOverlay && showOverlay ? (
        <ContainerDiv position="absolute" right={15} top={5} zIndex={2} backgroundColor={Colors.white}>
          <Anchor fontSize="12px" fontWeight="bold" href={src} target="_blank">
            <img alt="" src={ExternalLinkIcon} width="16px" style={{ marginRight: '5px' }} />
            Open in new tab
          </Anchor>
        </ContainerDiv>
      ) : null}
      <iframe
        frameBorder="0"
        src={isVisible ? src : ''}
        {...props}
        style={{
          ...props.style,
          display: isVisible ? 'block' : 'none',
        }}
        width="100%"
        height="100%"
      />
    </ContainerDiv>
  )
}

const FJVideo = ({ allowDownload, children, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>()

  const documentId = useMemo(() => props['data-document-id'], [props])

  const setCurrentTime = useCallback((time: number) => {
    videoRef.current.currentTime = time
  }, [])

  const fetchTranscript = useCallback(async () => await Document.get(documentId), [documentId])
  const videoProps = setDownloadOnControlsList(props, allowDownload)

  return (
    <ContainerDiv display="flex" flexDirection="column" alignItems="center" width="fit-content" margin="auto">
      <div className="rounded-video">
        <video ref={videoRef} {...videoProps} style={{ maxHeight: '650px', maxWidth: '100%' }}>
          {children}
        </video>
      </div>
      {documentId ? <TranscriptView setCurrentTime={setCurrentTime} fetchTranscript={fetchTranscript} /> : null}
    </ContainerDiv>
  )
}

const FJAudio = ({ allowDownload, children, ...props }) => {
  const audioRef = useRef<HTMLAudioElement>()

  const documentId = useMemo(() => props['data-document-id'], [props])

  const setCurrentTime = useCallback((time: number) => {
    audioRef.current.currentTime = time
  }, [])

  const fetchTranscript = useCallback(async () => await Document.get(documentId), [documentId])
  const audioProps = setDownloadOnControlsList(props, allowDownload)

  return (
    <ContainerDiv display="flex" flexDirection="column" alignItems="center" margin="auto">
      <audio {...audioProps}>{children}</audio>
      <ContainerDiv width="70%">
        {documentId ? <TranscriptView setCurrentTime={setCurrentTime} fetchTranscript={fetchTranscript} /> : null}
      </ContainerDiv>
    </ContainerDiv>
  )
}

const LinkSpan = ({ children }: React.PropsWithChildren) => {
  return <span style={{ color: Colors.cornflowerBlue, marginRight: '5px' }}>{children}</span>
}

export const FeedHTMLBodyParser = (
  html: string,
  options?: {
    showLinkAsSpan?: boolean
    showEmbedAsLink?: boolean
    showMediaAsLink?: boolean
    minimizeHeadings?: boolean
    sharedContentId?: string
    sharedContentPassword?: string
    sharedContentTrackingContext?: object
    allowDownload?: boolean
  }
) => {
  const {
    showLinkAsSpan,
    showEmbedAsLink,
    showMediaAsLink,
    minimizeHeadings,
    sharedContentId,
    sharedContentPassword,
    sharedContentTrackingContext,
    allowDownload,
  } = options || {}
  const defaultOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const { name, attribs, children } = domNode

        if (
          minimizeHeadings &&
          (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(name) ||
            (name === 'span' && ['text-big', 'text-huge'].includes(attribs?.class)))
        ) {
          return <p style={{ margin: 0 }}>{domToReact(children, defaultOptions)}</p>
        }

        if (attribs['data-oembed-url'] && showEmbedAsLink) {
          return <LinkSpan>Embed</LinkSpan>
        }

        if (name === 'a' && showLinkAsSpan) {
          return <LinkSpan>{domToReact(children, defaultOptions)}</LinkSpan>
        }

        if (name === 'figure' && attribs?.class === 'table') {
          const props = attributesToProps(attribs)
          return (
            <ContainerDiv width="100%" overflow="auto" textAlign="left">
              <figure {...props}>{domToReact(children, defaultOptions)}</figure>
            </ContainerDiv>
          )
        }

        if (name === 'figure' && attribs?.class === 'image' && showMediaAsLink) {
          return <LinkSpan>Image</LinkSpan>
        }

        if (name === 'div' && attribs?.class === 'raw-html-embed') {
          return <div className="raw-html-embed">{domToReact(children)}</div>
        }

        if (name === 'iframe') {
          if (showEmbedAsLink) {
            return <LinkSpan>Embed</LinkSpan>
          }

          const props = attributesToProps(attribs)
          // check if it's gong url, not embed for going
          if (attribs?.src?.includes('gong') && !attribs?.src?.includes('embed/')) {
            return <ObserverIFrame {...props} />
          }

          if (attribs?.src?.includes('google')) {
            return <ObserverIFrame withOverlay {...props} />
          }

          const flockjayEmbedRegex = /(?:(?:.)+(?:flockjay\.com|amplifyapp\.com|localhost:3000))\/embed/
          if (sharedContentId && attribs?.src?.match(flockjayEmbedRegex)) {
            const url = new URL(attribs.src)
            url.searchParams.append('sharedContentId', sharedContentId)
            if (sharedContentPassword) url.searchParams.append('accessKey', sharedContentPassword)
            attribs.src = url.href
          }
        }

        if (name === 'video') {
          if (showEmbedAsLink) {
            return <LinkSpan>Video</LinkSpan>
          }
          const props = attributesToProps(attribs)
          return (
            <FJVideo {...props} allowDownload={allowDownload}>
              {domToReact(children, defaultOptions)}
            </FJVideo>
          )
        }

        if (name === 'audio') {
          if (showEmbedAsLink) {
            return <LinkSpan>Audio</LinkSpan>
          }
          const props = attributesToProps(attribs)
          return (
            <FJAudio {...props} allowDownload={allowDownload}>
              {domToReact(children, defaultOptions)}
            </FJAudio>
          )
        }

        if (name === 'img' && attribs.src) {
          if (showMediaAsLink) {
            return <LinkSpan>Image</LinkSpan>
          }
          // If image has link, we donn't do zoom effect.
          if (domNode.parent?.['name'] === 'a') return domNode
          const { style, ...props } = attributesToProps(attribs)
          const styleProps = getStyleObjectFromString(attribs.style)
          return <Image src={attribs.src} alt={attribs.alt} {...props} {...styleProps} />
        }

        if (name === 'a') {
          const classroomAssetEmbedMatch = (attribs?.href || '').match(/\/classroom\/?\?assetId=([\w-]+)/)
          if (attribs?.class?.includes('tags')) {
            return (
              <DefaultLink className="tags" to={attribs.href}>
                {domToReact(children, defaultOptions)}
              </DefaultLink>
            )
          } else if (attribs?.class?.includes('mention')) {
            return <DefaultLink to={attribs.href}>{domToReact(children, defaultOptions)}</DefaultLink>
          } else if (attribs.href.match(PDF_LINK_REGEX)) {
            const match = attribs.href.match(PDF_LINK_REGEX)
            return <PDFRenderer title={match[0].substring(match[0].lastIndexOf('/') + 1)} url={attribs.href} />
          } else if (attribs.href.match(MS_DOC_LINK_REGEX)) {
            return <MSDocRenderer fileUrl={attribs.href} />
          } else if (sharedContentId && classroomAssetEmbedMatch) {
            return (
              <SharedContentEmbeddedModalAnchor
                sharedContentId={sharedContentId}
                password={sharedContentPassword}
                assetId={classroomAssetEmbedMatch[1]}
                trackingContext={sharedContentTrackingContext}
                anchorProps={attributesToProps(attribs)}
              >
                {domToReact(children, defaultOptions)}
              </SharedContentEmbeddedModalAnchor>
            )
          }
        }
      }
      return domNode
    },
  }

  return <div className="ck-content content-text-wrap">{parse(html ?? '', defaultOptions)}</div>
}

interface HTMLExpandablePreviewProps {
  html: string
  rows?: number
  expandable?: boolean
  expandSymbol?: ReactNode
  parserOptions?: {
    showEmbedAsLink?: boolean
    showMediaAsLink?: boolean
    minimizeHeadings?: boolean
  }
  backgroundColor?: string
}

const HTMLExpandablePreview: React.FC<HTMLExpandablePreviewProps> = ({
  html,
  rows = 8,
  expandable = true,
  expandSymbol = 'View more',
  parserOptions,
  backgroundColor,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const temp = document.createElement('div')
    temp.innerHTML = html
    if (temp.textContent.length < 250 && expandable) setIsExpanded(true)
  }, [expandable, html])

  if (isExpanded) return <>{FeedHTMLBodyParser(html)}</>

  return (
    <ChopLines
      lines={rows}
      lineHeight={24}
      ellipsis={
        expandable ? (
          <Ellipsis onClick={() => setIsExpanded(true)} style={{ backgroundColor }}>
            <span>{expandSymbol}</span>
          </Ellipsis>
        ) : null
      }
    >
      {FeedHTMLBodyParser(html, parserOptions)}
    </ChopLines>
  )
}

export default HTMLExpandablePreview
