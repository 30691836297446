import React from 'react'
import { Trash } from 'react-feather'
import { ContainerDiv, FjText } from 'src/components/Common'
import { SubmoduleTag } from 'src/components/CourseAuthoring/SubmoduleTag'
import { SortableSubmoduleItemProps } from 'src/components/Sortable/SortableModuleList'
import { Colors } from 'src/constants/colors'

export const SubmoduleTagTitle = ({
  isAuthoring,
  submodule,
  submoduleClicked,
  handleDelete,
}: SortableSubmoduleItemProps) => {
  return (
    <ContainerDiv
      display="flex"
      flexDirection="column"
      gap={12}
      cursor="pointer"
      onClick={() => submoduleClicked(submodule)}
      width="100%"
    >
      <ContainerDiv display="flex" justifyContent="space-between" alignItems="center">
        <SubmoduleTag submodule={submodule} isAuthoring={isAuthoring} />
        <Trash
          color={Colors.burntSienna}
          size={18}
          onClick={(e) => {
            e.stopPropagation()
            handleDelete(submodule)
          }}
        />
      </ContainerDiv>
      <FjText textAlign="left" display="block" color={Colors.fuscousGrey} fontWeight="semi-bold">
        {submodule.assignment ? submodule.assignment.title : submodule.title}
      </FjText>
    </ContainerDiv>
  )
}
