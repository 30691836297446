import React from 'react'
import { ContainerDiv, FjFormItem, FjTextArea, FormHeaderText } from 'src/components/Common'
import { Formik } from 'formik'

export interface ICopyClipboardModalProps {
  text: string
  modalTitle?: string
  onCancel: () => void
}

export const CopyClipboardModal: React.FC<ICopyClipboardModalProps> = ({ text, modalTitle = 'Link' }) => {
  return (
    <ContainerDiv>
      <FormHeaderText heading={modalTitle} />
      <Formik initialValues={{ copy: text }} onSubmit={() => {}}>
        <FjFormItem name="copy">
          <FjTextArea name="copy" rows={8} value={text} disabled style={{ cursor: 'text' }} />
        </FjFormItem>
      </Formik>
    </ContainerDiv>
  )
}
