import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Course } from 'src/models/Course'
import { LearningPath, LearningPathElement, LearningPathElementType } from 'src/models/LearningPath'
import { ContainerDiv, FjProgress, FjTable, FjText, FormHeaderText, Loader } from 'src/components/Common'
import { ContentTypeIcon, getFeedContentData, getFeedContentTitle } from 'src/utils/content'
import { Submodule } from 'src/models/Submodule'
import { Tooltip } from 'antd'
import moment from 'moment'
import type { ColumnsType } from 'antd/es/table'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { extractContentFromHTML } from 'src/utils/format'

export interface ProgressUser {
  id: string
  fullName: string
}

export type ContentProgressDetailsProps = {
  user: ProgressUser
  obj: Course | LearningPath
}

type ContentProgressDetailsType = {
  contentType: 'submodule' | LearningPathElementType
  contentData: Submodule | LearningPathElement
  progress?: {
    completedAt: moment.Moment
    progress: number
  }
}

export const ContentProgressDetails = ({ user, obj }: ContentProgressDetailsProps) => {
  const [progressDetails, setProgressDetails] = useState<ContentProgressDetailsType[]>([])
  const [loading, setLoading] = useState(false)

  const getProgress = useCallback(async () => {
    try {
      setLoading(true)
      let { data: details }: { data: ContentProgressDetailsType[] } = await obj.getProgressDetails(user.id)
      details = details.map((item) => {
        if (item.contentType === 'submodule') {
          return { ...item, contentData: Submodule.fromData(item.contentData) }
        } else {
          return { ...item, contentData: getFeedContentData(item.contentData, item.contentType) }
        }
      })
      setProgressDetails(details)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      setLoading(false)
    }
  }, [obj, user.id])

  useEffect(() => {
    getProgress()
  }, [getProgress])

  const columns: ColumnsType<ContentProgressDetailsType> = useMemo(() => {
    return [
      {
        title: obj instanceof Course ? 'Module' : 'Content',
        key: 'name',
        width: '40%',
        render: (details: ContentProgressDetailsType) => {
          const { contentData, contentType } = details
          if (contentData instanceof Submodule) {
            return (
              <ContainerDiv textAlign="left" display="flex" alignItems="center" gap="8px">
                <Tooltip title={contentData.getSubmoduleTypeDisplay()}>{contentData.getSubmoduleIcon()}</Tooltip>
                <FjText textAlign="left" rows={2}>
                  {contentData.title}
                </FjText>
              </ContainerDiv>
            )
          } else {
            const title =
              contentType === 'task' ? extractContentFromHTML(contentData.title) : getFeedContentTitle(contentData)
            const tooltip =
              contentType === 'feedpost' ? 'Post' : contentType === 'playlist' ? 'collections' : contentType
            return (
              <ContainerDiv
                textAlign="left"
                display="flex"
                padding="6px 0"
                alignItems="center"
                gap="10px"
                className="no-margin"
              >
                <Tooltip title={tooltip.toUpperCase()}>
                  <ContentTypeIcon obj={contentData} />
                </Tooltip>
                <ContainerDiv textAlign="left" overflowWrap="anywhere">
                  <FjText textAlign="left" rows={2}>
                    {title}
                  </FjText>
                </ContainerDiv>
              </ContainerDiv>
            )
          }
        },
      },
      {
        title: 'Status',
        key: 'status',
        width: '30%',
        render: (details: ContentProgressDetailsType) => {
          const { progress } = details.progress || {}
          return <FjProgress style={{ marginTop: '-15px' }} percent={Math.floor(progress * 100)} />
        },
      },
      {
        title: 'Date Completed',
        key: 'completed',
        width: '30%',
        render: (details: ContentProgressDetailsType) => {
          const { completedAt } = details.progress || {}
          if (!completedAt) return null
          return <FjText>{moment(completedAt).format('MM-DD-YYYY hh:mm A')}</FjText>
        },
      },
    ]
  }, [obj])

  return (
    <ContainerDiv>
      <FormHeaderText heading={`${user.fullName}'s progress for ${getFeedContentTitle(obj)}`} />
      {loading ? <Loader /> : <FjTable columns={columns} dataSource={progressDetails} pagination={false} />}
    </ContainerDiv>
  )
}
