import { Column } from '@ant-design/plots'
import { Col, Row, Tabs } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ContainerDiv, FjCard, FjText, FormHeaderText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Asset } from 'src/models/Asset'
import { SharedContent } from 'src/models/SharedContent'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { clearFalseyValues, formatCurrency, formatDuration } from 'src/utils/format'
import { SharedContentTable } from './SharedContentTable'
import { sharedDataStore } from 'src/store/DataStore'
import { DateFilter } from 'src/components/Common/DateFilter'
import { GroupSelect } from 'src/components/Common/GroupSelect'
import { AnalyticsSummaryCard } from 'src/components/Feed/AnalyticsSummaryCard'
import { User } from 'src/models/User'
import { ViewsSummaryTable } from 'src/components/Feed/ViewsSummaryTable'

type SummaryType = { date: string; viewCount: number; sharedContentCount: number }

type AssetSummaryDataType = {
  downloadCount: number
  durationViewed: number
  totalInfluencedRevenue: number
  totalSharedLinks: number
  totalUsers: number
  totalViews: number
  winRate: number
  summary: SummaryType[]
}

export type ViewsSummaryByUserType = {
  totalViews: number
  totalDurationViewed: number
  user: User
}

type AssetActivityTabType = 'external' | 'internal'

export const SectionTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <FjText textAlign="left" display="block" fontWeight="semi-bold" marginBottom marginLeft fontSize="18px">
    {children}
  </FjText>
)

export interface AssetSummaryProps {
  learningContent: { id: string; title: string; groupIds?: string[] }
  initialActiveTab?: AssetActivityTabType
  initialGroupId?: string
  initialStartDate?: moment.Moment
  initialEndDate?: moment.Moment
}

export const AssetSummary: React.FC<AssetSummaryProps> = ({
  learningContent,
  initialActiveTab = 'internal',
  initialGroupId,
  initialStartDate,
  initialEndDate,
}) => {
  const [activeTab, setActiveTab] = useState<AssetActivityTabType>(initialActiveTab)
  const [summaryData, setSummaryData] = useState<AssetSummaryDataType>()
  const [sharedContent, setSharedContent] = useState<SharedContent[]>([])
  const [viewsSummaryByUser, setViewsSummaryByUser] = useState<ViewsSummaryByUserType[]>([])
  const [groupId, setGroupId] = useState<string | undefined>(
    sharedDataStore.user.getAccessibleGroup(initialGroupId, learningContent.groupIds).id
  )
  const [startDate, setStartDate] = useState<moment.Moment>(initialStartDate)
  const [endDate, setEndDate] = useState<moment.Moment>(initialEndDate)
  const [isLoadingViewsSummaryByUser, setIsLoadingViewsSummaryByUser] = useState(false)

  const handleDateFilterChange = (dateRange: moment.Moment[]) => {
    const startDate = dateRange?.[0] ? dateRange[0] : undefined
    const endDate = dateRange?.[1] ? dateRange[1] : undefined

    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleClearDateFilter = () => {
    setStartDate(undefined)
    setEndDate(undefined)
  }

  const fillSummaryDates = (summary: SummaryType[]) => {
    if (!summary.length) return summary

    const summaryClone = summary.map(({ date, ...rest }) => ({ ...rest, date: moment(date) }))
    /*
    @todo: FIXME
    Commenting this function out for now but we should fill in some gaps.
    We should decided on a break threshold depending on the first and last date and not fill in
    day by day beyond that gap
    */
    // const filledSummary = [summaryClone[0]]

    // for (let i = 1; i < summaryClone.length; i++) {
    //   const current = summaryClone[i]
    //   let previous = filledSummary[filledSummary.length - 1]

    //   while (previous.date.clone().add(1, 'day') < current.date) {
    //     filledSummary.push({ viewCount: 0, sharedContentCount: 0, date: previous.date.clone().add(1, 'day') })
    //     previous = filledSummary[filledSummary.length - 1]
    //   }

    //   filledSummary.push(current)
    // }

    return summaryClone.map(({ date, ...rest }) => ({ ...rest, date: date.format('MMM D y') }))
  }

  const getSummary = useCallback(async () => {
    try {
      const { data }: { data: AssetSummaryDataType } = await Asset.getEngagementOverview(
        learningContent.id,
        clearFalseyValues({
          activity_type: activeTab === 'external' ? 'shared_links' : 'views',
          group_id: groupId,
          start_date: startDate?.toISOString(),
          end_date: endDate?.toISOString(),
        })
      )

      data.summary = fillSummaryDates(
        (data.summary || []).sort((a, b) => {
          const aDate = moment(a.date)
          const bDate = moment(b.date)
          return aDate.unix() - bDate.unix()
        })
      )
      setSummaryData(data)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }, [activeTab, learningContent, endDate, startDate, groupId])

  useEffect(() => {
    getSummary()
  }, [getSummary])

  const config = useMemo(() => {
    return {
      data: summaryData?.summary ?? [],
      xField: 'date',
      yField: activeTab === 'internal' ? 'viewCount' : 'sharedContentCount',
      color: Colors.teaGreen,
      xAxis: {
        title: { text: 'Date' },
      },
      yAxis: {
        title: { text: activeTab === 'internal' ? 'Views' : 'Shared Links' },
        formatter: (value) => (value ? value : undefined),
      },
      tooltip: {
        domStyles: {
          'g2-tooltip-list': {
            textAlign: 'left',
          },
        },
        formatter: (item) => {
          if (activeTab === 'internal') {
            return {
              name: 'View Count',
              value: item['viewCount'],
            }
          } else {
            return {
              name: '# of Shared Links',
              value: item['sharedContentCount'],
            }
          }
        },
      },
      maxColumnWidth: 40,
    }
  }, [activeTab, summaryData?.summary])

  const updateSharedContent = useCallback(async () => {
    if (activeTab === 'internal') {
      setSharedContent([])
      return
    }

    try {
      const newSharedContent = await SharedContent.list(
        clearFalseyValues({
          learning_content_object_id: learningContent.id,
          group_id: groupId,
          start_date: startDate ? startDate.toISOString() : undefined,
          end_date: endDate ? endDate.toISOString() : undefined,
        })
      )
      setSharedContent(newSharedContent)
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, true)
    }
  }, [activeTab, learningContent, startDate, endDate, groupId])

  const updateViewsSummaryByUser = useCallback(async () => {
    if (activeTab === 'external') {
      setViewsSummaryByUser([])
      return
    }

    try {
      setIsLoadingViewsSummaryByUser(true)
      const { data } = await Asset.getViewsSummaryByUser(
        learningContent.id,
        clearFalseyValues({
          group_id: groupId,
          start_date: startDate?.toISOString(),
          end_date: endDate?.toISOString(),
        })
      )
      setViewsSummaryByUser(data.map((item) => ({ ...item, user: User.fromData(item.user) })))
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, true)
    } finally {
      setIsLoadingViewsSummaryByUser(false)
    }
  }, [activeTab, endDate, groupId, learningContent.id, startDate])

  useEffect(() => {
    updateSharedContent()
  }, [updateSharedContent])

  useEffect(() => {
    updateViewsSummaryByUser()
  }, [updateViewsSummaryByUser])

  const summaryCardData = (summaryData: AssetSummaryDataType) => {
    if (activeTab === 'external') {
      return [
        {
          title: 'Total Revenue',
          body: formatCurrency(summaryData.totalInfluencedRevenue),
        },
        { title: 'Win Rate', body: summaryData.winRate ? `${Math.floor(summaryData.winRate * 100)}%` : '-' },
        { title: 'Shared Links', body: summaryData.totalSharedLinks },
      ]
    } else {
      return [
        { title: 'Total Users', body: summaryData.totalUsers },
        { title: 'Total Views', body: summaryData.totalViews },
        { title: 'Total View Time', body: formatDuration(summaryData.durationViewed) },
      ]
    }
  }

  return (
    <ContainerDiv>
      <FormHeaderText heading={`Analytics for "${learningContent.title}"`} />
      <Tabs
        activeKey={activeTab}
        onChange={(tab: AssetActivityTabType) => setActiveTab(tab)}
        items={[
          { key: 'external', label: 'External' },
          { key: 'internal', label: 'Internal' },
        ]}
      />
      <ContainerDiv display="flex" justifyContent="end" gap="15px" alignItems="center" marginBottom="15px">
        {sharedDataStore.user.isPartOfManyGroups() ? (
          <GroupSelect value={groupId} onChange={(groupId: string) => setGroupId(groupId)} />
        ) : null}
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          handleDateFilterChange={handleDateFilterChange}
          handleClearDateFilter={handleClearDateFilter}
        />
      </ContainerDiv>
      {summaryData ? (
        <Row gutter={[20, 10]} justify="center">
          {summaryCardData(summaryData).map(({ title, body }) => (
            <Col xs={24} md={8} key={`${title}${body}`}>
              <AnalyticsSummaryCard title={title} body={body} />
            </Col>
          ))}
        </Row>
      ) : null}
      <FjCard padding="12px" marginTop="20px">
        <ContainerDiv marginBottom display="flex" justifyContent="start">
          <SectionTitle>{activeTab === 'external' ? 'Shared Links Over Time' : 'View Count Summary'}</SectionTitle>
        </ContainerDiv>
        {summaryData ? (
          <ContainerDiv marginBottom marginTop="25px">
            <Column {...config} />
          </ContainerDiv>
        ) : null}
      </FjCard>
      {sharedContent.length ? (
        <FjCard marginBottom marginTop="20px" padding="12px">
          <SectionTitle>Shared Links</SectionTitle>
          <SharedContentTable existingLinks={sharedContent} />
        </FjCard>
      ) : null}
      {viewsSummaryByUser.length ? (
        <FjCard marginBottom marginTop="20px" padding="12px">
          <SectionTitle>User Views</SectionTitle>
          <ViewsSummaryTable loading={isLoadingViewsSummaryByUser} viewsSummaryByUser={viewsSummaryByUser} />
        </FjCard>
      ) : null}
    </ContainerDiv>
  )
}
