import { Formik } from 'formik'
import { Form } from 'formik-antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ContainerDiv,
  ContentDataOptionType,
  FJStyledInput,
  FjFormItem,
  FjText,
  FormHeaderText,
  LoadingAutoComplete,
  TemplateButton,
} from 'src/components/Common'
import { Template } from 'src/models/Template'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { getContentCategoryTitle } from 'src/utils/content'
import { TemplateableLearningContentType } from 'src/components/Feed/TemplateAuthoringModal'
import { Alert } from 'antd'
import { Columns } from 'react-feather'
import { SearchFeedContentCard } from 'src/components/Feed/SearchFeedContent'

export const UseTemplateInfoBox: React.FC<{
  contentType: TemplateableLearningContentType
  handleTemplateSelected: (template: Template) => Promise<void> | void
}> = ({ contentType, handleTemplateSelected }) => {
  return (
    <Alert
      message={
        <ContainerDiv display="flex" justifyContent="space-between" alignItems="center">
          <ContainerDiv display="flex" gap="5px" alignItems="center">
            <Columns size={14} style={{ minWidth: 14 }} />
            <FjText display="block" textAlign="left">
              Create {contentType === 'asset' ? 'an' : 'a'}{' '}
              {getContentCategoryTitle(contentType).slice(0, -1).toLowerCase()} from an existing template
            </FjText>
          </ContainerDiv>
          <TemplateButton contentType={contentType} handleTemplateSelected={handleTemplateSelected} />
        </ContainerDiv>
      }
    />
  )
}

export interface ITemplateModalProps {
  contentType: TemplateableLearningContentType
  onCancel: () => void
  onSuccess: (template: Template) => void
}

export const TemplateModal: React.FC<ITemplateModalProps> = ({ contentType, onSuccess }) => {
  const [autoCompleteLoading, setAutoCompleteLoading] = useState(false)
  const [templates, setTemplates] = useState<Template[]>([])
  const [inputValue, setInputValue] = useState<string | undefined>()
  const inputRef = useRef<HTMLInputElement>()

  const heading = useMemo(() => {
    const title = getContentCategoryTitle(contentType)
    return `${title.substring(0, title.length - 1)} Templates`
  }, [contentType])

  const templateOptions = useMemo(() => {
    return templates.map((template) => ({
      key: template.id,
      id: template.id,
      value: template.id,
      label: (
        <ContainerDiv
          display="flex"
          width={sharedAppStateStore.isMobile ? '300px' : '100%'}
          overflow="hidden"
          position="relative"
          whiteSpace="normal"
        >
          <SearchFeedContentCard key={template.id} obj={template} />
        </ContainerDiv>
      ),
      name: template.name,
      contentType,
    }))
  }, [contentType, templates])

  const handleSearch = useCallback(
    async (search = '') => {
      try {
        setAutoCompleteLoading(true)
        const { data } = await Template.list({ content_type: contentType, search })
        setTemplates(data)
      } catch (err) {
        sharedAppStateStore.handleError(err)
        setTemplates([])
      } finally {
        setAutoCompleteLoading(false)
      }
    },
    [contentType, setAutoCompleteLoading]
  )

  const handleSubmit = useCallback(
    (option: ContentDataOptionType) => {
      const template = templates.find((t) => t.id === option.value)
      setInputValue(option['name'])
      sharedAppStateStore.sharedConfirmDialogProps = {
        title: `Are you sure you want to use ${template.name}?`,
        content: `Any existing changes you have made will be overwritten.`,
        onConfirm: () => onSuccess(template),
        onCancel: () => (sharedAppStateStore.sharedConfirmDialogProps = undefined),
      }
    },
    [templates, onSuccess, setInputValue]
  )

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <ContainerDiv textAlign="left">
      <FormHeaderText heading={heading} />
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <FjFormItem name="templateId">
            <ContainerDiv>
              <LoadingAutoComplete
                isLoadingOptions={autoCompleteLoading}
                defaultOpen
                placeholder={`Search for ${getContentCategoryTitle(contentType)}`}
                open
                onChange={(value) => setInputValue(value)}
                options={templateOptions}
                onSearch={handleSearch}
                onFocus={() => handleSearch('')}
                onSelect={(_, option: ContentDataOptionType) => handleSubmit(option)}
                style={{ width: sharedAppStateStore.isMobile ? '300px' : '500px' }}
                value={inputValue}
              >
                <FJStyledInput ref={inputRef} name="templateId" />
              </LoadingAutoComplete>
            </ContainerDiv>
          </FjFormItem>
        </Form>
      </Formik>
    </ContainerDiv>
  )
}
