import MethodTypes from 'src/models/enums/MethodTypes'

type Data = {}
type Params = {}
type Headers = {}

export default class Route {
  method: MethodTypes
  url: string
  data?: Data
  headers?: Headers
  params?: Params
  signal?: AbortSignal

  constructor(
    method: MethodTypes,
    url: string,
    data?: Data,
    headers?: Headers,
    params?: Params,
    abortController?: AbortController
  ) {
    this.method = method
    this.url = url
    this.data = data
    this.headers = headers
    this.params = params
    this.signal = abortController?.signal
  }
}

export const formatPathWithId = (path: string, id?: string | null) => {
  if (id) {
    return { method: MethodTypes.PATCH, path: `${path}${id}/` }
  }
  return { method: MethodTypes.POST, path }
}
