import React from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { FjInput } from '../FjInput'

declare let google: any

const getFormattedLocation = (formatted_address: string, address_components: any) => {
  let cityStateString = formatted_address && formatted_address.split(', USA')[0]
  if (Array.isArray(address_components)) {
    cityStateString = address_components
      .reduce((res, comp) => {
        if (comp.types && comp.types.includes('locality')) res.push(comp.long_name)
        if (comp.types && comp.types.includes('administrative_area_level_1')) res.push(comp.short_name)
        return res
      }, [])
      .join(', ')
  }
  return cityStateString
}

interface GoogleLocationAutocompleteProps {
  name: string
  onPlaceSelect(location: string): void
  placeholder?: string
}

@observer
export class GoogleLocationAutocomplete extends React.Component<GoogleLocationAutocompleteProps> {
  autocomplete: any
  @observable inputValue: string

  constructor(props: Readonly<GoogleLocationAutocompleteProps>) {
    super(props)
    makeObservable(this)
    this.autocomplete = null
  }

  componentDidMount() {
    const searchBox = document.getElementById(`googleLocationAutocomplete${this.props.name}`) as HTMLInputElement
    this.autocomplete = new google.maps.places.Autocomplete(searchBox, { types: ['(cities)'] })
    this.autocomplete.setComponentRestrictions({ country: ['us'] })
    this.autocomplete.setFields(['formatted_address', 'address_components'])
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
    // calling onPlaceSelect(null) sets the same selected value
    // to field, So in case on mount if it's value is "[]" then it gets set to "null"
    // So that Formik can validate it
    this.props.onPlaceSelect(null)
  }

  handlePlaceChanged = () => {
    const { formatted_address, address_components } = this.autocomplete.getPlace()
    this.props.onPlaceSelect(getFormattedLocation(formatted_address, address_components))
    this.inputValue = ''
  }

  handleValueChange = (e: any) => {
    this.inputValue = e.target.value
    this.props.onPlaceSelect(null)
  }

  onBlur = () => {
    this.inputValue = ''
    this.props.onPlaceSelect(null)
  }

  render() {
    return (
      <FjInput
        name={this.props.name}
        id={`googleLocationAutocomplete${this.props.name}`}
        type="text"
        value={this.inputValue}
        onChange={this.handleValueChange}
        onBlur={this.onBlur}
        placeholder={this.props.placeholder}
      />
    )
  }
}
