import { sharedDataStore } from 'src/store/DataStore'
import { LAST_SELECTED_GROUP_ID_KEY, User } from 'src/models/User'
import { Analytics } from 'src/utils/Analytics'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import Route from 'src/network/Route'
import { NoAuthProvider } from 'src/network/NoAuthProvider'
import MethodTypes from 'src/models/enums/MethodTypes'
import { Paths } from 'src/constants/navigation'
import { SessionToken } from 'src/models/SessionToken'
import { FlockjayProvider, baseURL } from 'src/network/FlockjayProvider'
import { CONFIG_STORE_KEY, Config } from 'src/models/Config'
import { getQueryParam } from './urlParams'
import { sharedQueryClient } from 'src/store/QueryClient'

const SSO_DATA_LOCAL_STORAGE_KEY = 'sso_data'

export type AuthHandler = () => void

export class Auth {
  // forceRefresh is used to refresh the token even if accessToken is not expired, we need this behavior to get latest info about user.
  static async refreshUserSession(forceRefresh = false) {
    try {
      const refreshQueryParam = getQueryParam('refreshToken')
      if (refreshQueryParam) {
        const { data } = await User.refreshSession(refreshQueryParam)
        Auth.userAuthorized(data)
        Analytics.login(sharedDataStore.user.id, false)
        sharedAppStateStore.removeQueryParam('refreshToken')
        return
      }

      if (SessionToken.isRefreshTokenExpired()) {
        Auth.logout(false)
        return
      }

      if (SessionToken.isAccessTokenExpired() || forceRefresh) {
        const { data } = await User.refreshSession(sharedDataStore.user.sessionToken.refreshToken)
        Auth.userAuthorized(data)
        if (!forceRefresh) Analytics.login(sharedDataStore.user.id, true)
      } else {
        Auth.userAuthorized(sharedDataStore.user)
      }
    } catch (e) {
      sharedAppStateStore.handleError(e, undefined, false)
      Auth.logout(false)
    }
  }

  static updateAuth() {
    NoAuthProvider.interceptors.request.use((config) => {
      if (!config.url) {
        return config
      }

      config.url = config.url.replace(':userID', sharedDataStore.user.id)
      return config
    })

    FlockjayProvider.interceptors.request.use(async (config) => {
      if (!config.url) {
        return config
      }
      config.url = config.url.replace(':userID', sharedDataStore.user.id)

      if (SessionToken.isAccessTokenExpired()) {
        await Auth.refreshUserSession()
      }

      config.headers['Authorization'] = `Bearer ${sharedDataStore.user.sessionToken.accessToken}`
      return config
    })
  }

  static userAuthorized(data: any) {
    sharedDataStore.user = User.fromData(data)
    Auth.updateAuth()
    Auth.storeSsoData()
    sharedDataStore.authState = 'authorized'
    if (!sharedAppStateStore.isEmbed()) {
      Config.getConfigs()
      if (!sharedDataStore.user.isFaasPublic()) {
        sharedDataStore.user.initalizePendo()
      }
    }
  }

  static storeSsoData = () => {
    localStorage.setItem(
      SSO_DATA_LOCAL_STORAGE_KEY,
      JSON.stringify({
        companyId: sharedDataStore.user.company.id,
        integratedSsoOrganizationId: sharedDataStore.user.company.integratedSsoOrganizationId,
      })
    )
  }

  static getSsoData = (): { companyId: string; integratedSsoOrganizationId: string } | undefined => {
    const data = localStorage.getItem(SSO_DATA_LOCAL_STORAGE_KEY)
    if (!data) return
    return JSON.parse(data)
  }

  static login = async (
    credentials: { email: string; password: string; isLogin: boolean },
    loginHandler: AuthHandler,
    errorHandler?: (msg: string) => void
  ) => {
    try {
      const { data } = await User.login(credentials)
      Auth.userAuthorized(data)
      Analytics.login(sharedDataStore.user.id, false)
      Analytics.trackIdentify(sharedDataStore.user)
      loginHandler()
      User.subscribeUserNotifications()
    } catch (err) {
      let msg = 'Internal Server Error'
      if (err.response && err.response.data && err.response.data.detail) {
        msg = err.response.data.detail
      }
      if (errorHandler) errorHandler(msg)
      else sharedAppStateStore.handleError(new Error(msg))
    }
  }

  static loginSso = async (data?: { email: string }) => {
    const urlSegment = data ? data.email : Auth.getSsoData()?.companyId
    if (!urlSegment) throw new Error('Unable to login with SSO. Please provide a valid email')

    const url = `${baseURL}/saml2/${urlSegment}/login/`
    if (sharedAppStateStore.isInChromeExtension) window.parent.postMessage({ action: 'fj-sso-login', url }, '*')
    else window.location.replace(url)
  }

  static register = async (creds: { email: string; firstName: string; lastName: string; company: string }) => {
    try {
      creds['fullName'] = `${creds.firstName} ${creds.lastName}`
      delete creds['firstName']
      delete creds['lastName']
      const { data } = await NoAuthProvider(new Route(MethodTypes.POST, '/api/auth/register/', creds))
      Auth.userAuthorized(data)
      Analytics.trackIdentify(sharedDataStore.user)
      sharedAppStateStore.navigate(sharedDataStore.getLoginHandlerPath())
    } catch (err) {
      let msg = err.message
      if (err.response && err.response.data && err.response.data.email) {
        msg = err.response.data.email[0]
      } else if (err.response && err.response.data && err.response.data.password) {
        msg = err.response.data.password[0]
      } else if (err.response && err.response.data && err.response.data.phoneNumber) {
        msg = err.response.data.phoneNumber[0]
      }
      sharedAppStateStore.handleError(new Error(msg))
    }
  }

  static handleLinkedinOAuthSuccess = async (oAuthData: { code: string }, isLogin) => {
    try {
      const { data } = await User.login({
        email: oAuthData.code,
        password: Paths.getLinkedInOAuthRedirectURL(),
        isLogin,
      })
      Auth.userAuthorized(data)
      Analytics.trackIdentify(sharedDataStore.user)
      sharedAppStateStore.navigate(sharedDataStore.getLoginHandlerPath())
    } catch (err) {
      let msg = 'Internal Server Error'
      if (err.response && err.response.data && err.response.data.detail) {
        msg = err.response.data.detail
      }
      sharedAppStateStore.handleError(new Error(msg))
      sharedDataStore.authState = 'unauthorized'
    }
  }

  static resetPassword = async (userId: string, timestamp: string, signature: string, password: string) => {
    const { data } = await User.resetPassword(userId, timestamp, signature, password)
    Auth.userAuthorized(data)
    sharedAppStateStore.navigate(sharedDataStore.getLoginHandlerPath())
  }

  static changePassword = async (
    oldPassword: string,
    password: string,
    passwordConfirm: string,
    redirect: string = ''
  ) => {
    await User.changePassword(oldPassword, password, passwordConfirm)
    if (redirect) {
      sharedAppStateStore.navigate(redirect)
    }
  }

  static logout = async (redirect: boolean = true) => {
    const { user } = sharedDataStore
    try {
      // if there is no refreshToken user is already anonymous so no need to make request
      if (user.sessionToken.refreshToken) {
        await NoAuthProvider(
          new Route(MethodTypes.POST, '/api/auth/logout/', { refresh: sharedDataStore.user.sessionToken.refreshToken })
        )
      }
    } catch (err) {
      // 401 means the refresh token is already expired/invalid so no need to send to sentry
      if (err.response.status !== 401) {
        sharedAppStateStore.handleError(err, undefined, false)
      }
    } finally {
      sharedDataStore.authState = 'unauthorized'
      sharedDataStore.config = new Config()
      localStorage.removeItem(CONFIG_STORE_KEY)
      // TODO: Implement a new object to handle local storage management, instead of managing it separately. - Story ID: #188429139
      localStorage.removeItem(LAST_SELECTED_GROUP_ID_KEY)
      let redirectPath = Paths.login
      if (user.isFaasPublic() && sharedAppStateStore.externalAcademy?.data.customHome) {
        redirectPath = sharedAppStateStore.externalAcademy.data.customHome
      }
      sharedDataStore.user = new User()
      Auth.updateAuth()
      sharedQueryClient.invalidateQueries()
      if (redirect) sharedAppStateStore.navigate(redirectPath)
    }
  }
}
