import { Rate } from 'antd'
import { RateProps } from 'antd/lib/rate'
import styled from 'styled-components'
import React from 'react'

const StyledRate = styled(Rate)`
  .ant-rate-star {
    margin: 0 !important;
  }
`

export const RateReview: React.FC<RateProps> = ({ count, ...props }: RateProps) => {
  return <StyledRate {...props} disabled defaultValue={Math.round(count)} />
}
