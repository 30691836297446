import React from 'react'
import { FjCard, ContainerDiv, FjText, DefaultLink, FjTextProps, ContainerDivProps } from 'src/components/Common'
import { sharedDataStore } from 'src/store/DataStore'
import { Colors } from 'src/constants/colors'
import { CheckSquare, Shield } from 'react-feather'
import { Paths } from 'src/constants/navigation'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'

interface BannerProps {
  iconBackgroundColor: string
  icon: React.ReactElement
  value: number | string
  valueStyle?: FjTextProps
  secondaryValue?: number | string
  secondaryValueSuffix?: string
  subtitle: string
  clicked?: () => void
  containerStyles?: ContainerDivProps
}
export class Banner extends React.Component<BannerProps> {
  render() {
    const {
      iconBackgroundColor,
      icon,
      value,
      valueStyle,
      secondaryValue,
      secondaryValueSuffix,
      subtitle,
      clicked,
      containerStyles,
    } = this.props
    return (
      <FjCard
        cursor={clicked ? 'pointer' : undefined}
        textAlign="left"
        display="flex"
        alignItems="center"
        onClick={clicked}
        gap={24}
        {...containerStyles}
      >
        <ContainerDiv
          borderRadius="8px"
          padding="8px"
          backgroundColor={iconBackgroundColor}
          display="flex"
          alignItems="center"
        >
          {icon}
        </ContainerDiv>
        <ContainerDiv display="flex" flexDirection="column">
          <FjText fontWeight="bold500" color={Colors.tapa} fontSize="14px" textAlign="left">
            {subtitle}
          </FjText>
          <ContainerDiv display="flex" gap={8} alignItems="baseline">
            <FjText
              fontWeight="bold"
              color={value ? Colors.shark : Colors.cottonSeed}
              fontSize="22px"
              textAlign="left"
              {...valueStyle}
            >
              {value ?? '-'}
            </FjText>
            {secondaryValue ? (
              <FjText fontWeight="bold500" color={Colors.cottonSeed}>
                {secondaryValue} {secondaryValueSuffix ?? ''}
              </FjText>
            ) : null}
          </ContainerDiv>
        </ContainerDiv>
      </FjCard>
    )
  }
}

@observer
export class FeedBanner extends React.Component {
  render() {
    const { ungradedAssignmentCount, postsToReviewCount, assetsToReviewCount } = sharedDataStore.user
    const itemsToReviewCount = postsToReviewCount + assetsToReviewCount

    return (
      <Row gutter={[18, 18]}>
        {ungradedAssignmentCount ? (
          <Col xs={24} md={12} lg={8}>
            <DefaultLink to={Paths.submittedAssessments}>
              <Banner
                iconBackgroundColor={Colors.whitePointer}
                icon={<Shield size={28} color={Colors.mauve} />}
                value={ungradedAssignmentCount}
                subtitle="Assessments to Grade"
              />
            </DefaultLink>
          </Col>
        ) : null}
        {itemsToReviewCount ? (
          <Col xs={24} md={12} lg={8}>
            <DefaultLink to={Paths.contentReview}>
              <Banner
                iconBackgroundColor={Colors.zumthor}
                icon={<CheckSquare size={28} color={Colors.anakiwa} />}
                value={itemsToReviewCount}
                subtitle="Content to Review"
              />
            </DefaultLink>
          </Col>
        ) : null}
      </Row>
    )
  }
}
