import React, { ComponentType } from 'react'
import { Row, Col } from 'antd'
import { Colors } from 'src/constants/colors'

export const restrictContentWidth = <P extends {}>(
  Wrapped: ComponentType<P>,
  backgroundColor?: string,
  style?: React.CSSProperties
) => {
  let color = backgroundColor ? backgroundColor : Colors.wildSand
  return (props: P) => (
    <div
      style={{
        backgroundColor: color,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        ...style,
      }}
    >
      <div
        style={{
          backgroundColor: color,
          maxWidth: '1600px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <Row justify="center">
          <Col span={23}>
            <Wrapped {...(props as P)} />
          </Col>
        </Row>
      </div>
    </div>
  )
}
