import React from 'react'
import { FjCard, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'

export const AnalyticsSummaryCard: React.FC<{ title: string; body: any }> = ({ title, body }) => {
  return (
    <FjCard display="flex" flexDirection="column" padding="12px">
      <FjText>{title}</FjText>
      <FjText fontSize="26px" color={Colors.chambray}>
        {body}
      </FjText>
    </FjCard>
  )
}
