import React from 'react'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import {
  ContainerDiv,
  DefaultButton,
  FjCard,
  FjFormItem,
  SmallFormItem,
  FjInput,
  FjText,
  FormActionButtons,
  FormHeaderText,
  Loader,
  CopyToClipboard,
} from 'src/components/Common'
import { IntegratedSsoOrganization } from 'src/models/IntegratedSsoOrganization'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { FormContainerModal } from 'src/components/Common/FormContainerModal'
import { FORM_CONTAINER_MODAL_ACTIONS } from 'src/components/Common/FormContainerModal'
import { Formik } from 'formik'
import { Form, Switch } from 'formik-antd'
import { combineValidations, isRequired, isURI } from 'src/utils/validation'
import { IntegratedScimOrganization } from 'src/models/IntegratedScimOrganization'
import { Divider } from 'antd'
import { Colors } from 'src/constants/colors'
import { showNotification } from 'src/hoc/Notification'
import { baseURL } from 'src/network/FlockjayProvider'
import { Edit } from 'react-feather'

type ViewState = 'initialLoad' | 'loading' | 'error' | 'idle'

interface ISsoFormProps {
  integratedSsoOrganizationId?: string
}

interface ISsoFormState {
  viewState: ViewState
  showCertificateField: boolean
  integratedSsoOrganization?: IntegratedSsoOrganization
}

class SsoForm extends React.Component<ISsoFormProps, ISsoFormState> {
  formikRef: any
  constructor(props) {
    super(props)
    this.state = { viewState: 'initialLoad', showCertificateField: false }
    this.formikRef = React.createRef()
  }

  async componentDidMount() {
    try {
      const { integratedSsoOrganizationId } = sharedDataStore.user.company
      if (!integratedSsoOrganizationId) {
        this.setState(() => ({ viewState: 'idle', showCertificateField: true }))
        return
      }
      this.setState(() => ({ viewState: 'loading' }))
      const integratedSsoOrganization = await IntegratedSsoOrganization.get(integratedSsoOrganizationId)
      this.setState(() => ({ viewState: 'idle', integratedSsoOrganization }))
    } catch (err) {
      this.setState(() => ({ viewState: 'error' }))
    }
  }

  handleSubmit = async (data: any) => {
    if ('idpCert' in data && !data.idpCert) delete data.idpCert
    const integratedSsoOrganization = this.state.integratedSsoOrganization || new IntegratedSsoOrganization()
    await sharedAppStateStore.wrapAppLoading(integratedSsoOrganization.save(data))
    sharedDataStore.user.company.integratedSsoOrganizationId = integratedSsoOrganization.id
    this.formikRef.current.setFieldValue('idpCert', '')
    this.setState(() => ({
      integratedSsoOrganization: IntegratedSsoOrganization.fromData({ ...integratedSsoOrganization }),
      showCertificateField: false,
    }))
    showNotification({ message: 'SSO configuration saved!' })
  }

  getInitialValues = () => {
    const { isActive, autoProvisionEnabled, idpSsoUrl, idpSsoEntityId } =
      this.state.integratedSsoOrganization || new IntegratedSsoOrganization()
    return {
      isActive,
      autoProvisionEnabled,
      idpSsoUrl,
      idpSsoEntityId,
      idpCert: undefined,
    }
  }

  allFieldsExist = (values: any) => {
    return (
      values.idpSsoUrl && values.idpSsoEntityId && (values.idpCert || this.state.integratedSsoOrganization?.hasIdpCert)
    )
  }

  getSaveDisabled = (values: any, dirty: boolean) => !this.allFieldsExist(values) || !dirty

  render() {
    const { viewState, integratedSsoOrganization, showCertificateField } = this.state
    const audienceURI = `${baseURL}/saml2/${sharedDataStore.user.company.id}/metadata/`
    const acsURL = `${baseURL}/saml2/${sharedDataStore.user.company.id}/callback/`
    return (
      <ContainerDiv textAlign="left">
        <Formik
          innerRef={this.formikRef}
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {({ values, dirty }) => {
            return (
              <Form>
                {viewState === 'initialLoad' ? null : viewState === 'loading' ? (
                  <Loader />
                ) : viewState === 'idle' ? (
                  <>
                    <ContainerDiv display="flex" justifyContent="space-between">
                      <FjText display="block" textAlign="left" fontWeight="semi-bold" fontSize="medium" marginBottom>
                        Configure SSO Authentication (SAML2)
                      </FjText>
                      <div>
                        {!!integratedSsoOrganization && (
                          <SmallFormItem name="isActive" style={{ display: 'flex', alignItems: 'center' }}>
                            Enabled <Switch name="isActive" />
                          </SmallFormItem>
                        )}
                      </div>
                    </ContainerDiv>

                    <FjCard marginVertical="12px" padding="12px" textAlign="left" backgroundColor={Colors.alto}>
                      <FjText fontWeight="semi-bold" display="block" textAlign="left" marginBottom>
                        Configure your Identity Provider with the following details:
                      </FjText>

                      <FjText fontWeight="semi-bold">Audience URI (SP Identity ID): </FjText>
                      <ContainerDiv display="flex" gap="8px" alignItems="center" marginBottom>
                        {audienceURI}
                        <CopyToClipboard text={audienceURI} modalTitle="Audience URI" />
                      </ContainerDiv>
                      <FjText fontWeight="semi-bold">Single sign-on URL (ACS URL): </FjText>
                      <ContainerDiv display="flex" gap="8px" alignItems="center">
                        {acsURL}
                        <CopyToClipboard text={acsURL} modalTitle="ACS URL" />
                      </ContainerDiv>
                    </FjCard>
                    <Divider style={{ color: Colors.sharkOpacity10 }} />
                    <FjText textAlign="left" fontWeight="semi-bold" display="block" marginBottom>
                      Enter your Identity Provider details below:
                    </FjText>
                    <FjFormItem name="idpSsoUrl" fieldtitle="Sign On URL">
                      <FjInput
                        name="idpSsoUrl"
                        validate={combineValidations(isRequired, isURI)}
                        placeholder="http://example.com/saml2/login/" // DevSkim: ignore DS137138
                      />
                    </FjFormItem>
                    <FjFormItem name="idpSsoEntityId" fieldtitle="Issuer (Entity ID)">
                      <FjInput
                        name="idpSsoEntityId"
                        validate={combineValidations(isRequired, isURI)}
                        placeholder="https://idp.example.com/saml2/idp/"
                      />
                    </FjFormItem>
                    <FjFormItem name="idpCert" fieldtitle="Signing Certificate">
                      {integratedSsoOrganization?.hasIdpCert && !showCertificateField && (
                        <>
                          <FjText>**********</FjText>
                          <DefaultButton
                            image={<Edit size={16} />}
                            clicked={() => this.setState({ showCertificateField: true })}
                          />
                        </>
                      )}
                      <FjInput
                        name="idpCert"
                        placeholder="Enter your Signing Certificate"
                        style={{ display: showCertificateField ? 'inline-block' : 'none' }}
                      />
                    </FjFormItem>
                    <FjFormItem
                      name="autoProvisionEnabled"
                      fieldtitle="Automatically provision users in Flockjay on login"
                    >
                      <Switch name="autoProvisionEnabled" />
                    </FjFormItem>
                    <FormActionButtons primaryDisabled={this.getSaveDisabled(values, dirty)} />
                  </>
                ) : (
                  <>Something unexpected happened. Try refreshing your browser</>
                )}
              </Form>
            )
          }}
        </Formik>
      </ContainerDiv>
    )
  }
}

interface IScimFormProps {
  integratedScimOrganizationId?: IntegratedScimOrganization
}

interface IScimFormState {
  viewState: ViewState
  integratedScimOrganization?: IntegratedScimOrganization
}

class ScimForm extends React.Component<IScimFormProps, IScimFormState> {
  constructor(props) {
    super(props)
    this.state = { viewState: 'initialLoad' }
  }
  async componentDidMount() {
    try {
      const { integratedScimOrganizationId } = sharedDataStore.user.company
      if (!integratedScimOrganizationId) {
        this.setState({ viewState: 'idle' })
        return
      }
      this.setState({ viewState: 'loading' })
      const integratedScimOrganization = await IntegratedScimOrganization.get(integratedScimOrganizationId)
      this.setState({ viewState: 'idle', integratedScimOrganization })
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      this.setState({ viewState: 'error' })
    }
  }

  handleEnableChanged = async (checked: boolean) => {
    try {
      let integratedScimOrganization = this.state.integratedScimOrganization || new IntegratedScimOrganization()
      await integratedScimOrganization.save({ isActive: checked })
      sharedDataStore.user.company.integratedScimOrganizationId = integratedScimOrganization.id
      this.setState({
        integratedScimOrganization: IntegratedScimOrganization.fromData({ ...integratedScimOrganization }),
      })
      showNotification({ message: 'SCIM configuration saved!' })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    const { viewState, integratedScimOrganization } = this.state
    return (
      <ContainerDiv textAlign="left">
        {viewState === 'initialLoad' ? null : viewState === 'loading' ? (
          <Loader />
        ) : viewState === 'idle' ? (
          <>
            <ContainerDiv display="flex" justifyContent="space-between">
              <FjText display="block" textAlign="left" fontWeight="semi-bold" fontSize="medium" marginBottom>
                Configure User Provisioning (SCIM)
              </FjText>
              <Formik
                initialValues={{ isActive: integratedScimOrganization?.isActive }}
                onSubmit={() => {}}
                enableReinitialize
              >
                <Form>
                  <SmallFormItem name="isActive" style={{ display: 'flex', alignItems: 'center' }}>
                    Enabled <Switch name="isActive" onChange={this.handleEnableChanged} />
                  </SmallFormItem>
                </Form>
              </Formik>
            </ContainerDiv>
            {!!integratedScimOrganization?.scimToken && (
              <ContainerDiv textAlign="left" marginTop>
                <FjText>SCIM Token:</FjText>
                <FjCard
                  padding="12px"
                  marginVertical
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  justifyContent="space-between"
                >
                  {integratedScimOrganization.scimToken}
                  <CopyToClipboard text={integratedScimOrganization.scimToken} modalTitle="Token" />
                </FjCard>
              </ContainerDiv>
            )}
          </>
        ) : viewState === 'error' ? (
          <>Something unexpected happened. Try refreshing your browser</>
        ) : null}
      </ContainerDiv>
    )
  }
}

interface IUserManagementAndProvisioningFormProps {}

export const UserManagementAndProvisioningForm: React.FC<IUserManagementAndProvisioningFormProps> = () => {
  return (
    <ContainerDiv>
      <FormHeaderText heading="Authentication & Provisioning" />
      <SsoForm />
      <Divider style={{ color: Colors.sharkOpacity10 }} />
      <ScimForm />
    </ContainerDiv>
  )
}

@observer
export class UserManagementAndProvisioning extends React.Component {
  @observable showUserManagementIntegrationForm = false

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  handleIntegratedSsoOrganizationSave = () => this.handleCloseUserManagementIntegrationForm()

  handleCloseUserManagementIntegrationForm = () => (this.showUserManagementIntegrationForm = false)

  showSsoForm = () => (this.showUserManagementIntegrationForm = true)

  render() {
    if (!sharedDataStore.user.isFaasAdmin()) return null
    return (
      <ContainerDiv display="flex" gap={10} alignItems="center" justifyContent="space-between">
        <FormContainerModal
          open={this.showUserManagementIntegrationForm}
          handleClose={this.handleCloseUserManagementIntegrationForm}
          action={FORM_CONTAINER_MODAL_ACTIONS.UserManagementAndProvisioningForm}
          formComponentProps={{}}
        />
        <FjText fontWeight="semi-bold">User Authentication (SSO) & Provisioning</FjText>
        <DefaultButton title="Configure" clicked={this.showSsoForm} buttonType="primary" />
      </ContainerDiv>
    )
  }
}
