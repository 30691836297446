import React, { useMemo } from 'react'
import { ContainerDiv, FjAvatar, FjTable, FjText } from 'src/components/Common'
import { formatDuration } from 'src/utils/format'
import { ViewsSummaryByUserType } from 'src/components/Feed/AssetSummary'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'

type ViewsSummaryTableProps = {
  loading?: boolean
  viewsSummaryByUser: ViewsSummaryByUserType[]
}

export const ViewsSummaryTable = ({ loading, viewsSummaryByUser = [] }: ViewsSummaryTableProps) => {
  const handleUserClick = (userId) => {
    sharedAppStateStore.assetSummaryModalProps = undefined
    sharedAppStateStore.postAnalyticsModalProps = undefined
    sharedAppStateStore.navigate(Paths.getProfilePath(userId))
  }
  const columns = useMemo(() => {
    return [
      {
        title: 'User',
        key: 'authorName',
        render: (content: ViewsSummaryByUserType) => {
          const { user } = content
          return (
            <ContainerDiv
              cursor="pointer"
              display="flex"
              alignItems="center"
              role="button"
              gap={10}
              onClick={() => handleUserClick(user.id)}
            >
              <FjAvatar size={32} author={user} />
              <FjText>{user.fullName}</FjText>
            </ContainerDiv>
          )
        },
        sorter: (a: ViewsSummaryByUserType, b: ViewsSummaryByUserType) =>
          a.user.fullName.localeCompare(b.user.fullName),
      },
      {
        title: 'Views',
        dataIndex: 'totalViews',
        key: 'totalViews',
        sorter: (a: ViewsSummaryByUserType, b: ViewsSummaryByUserType) => (a.totalViews > b.totalViews ? 1 : -1),
      },
      {
        title: 'View Time',
        key: 'totalDurationViewed',
        render: (content: ViewsSummaryByUserType) => formatDuration(content.totalDurationViewed),
        sorter: (a: ViewsSummaryByUserType, b: ViewsSummaryByUserType) =>
          a.totalDurationViewed > b.totalDurationViewed ? 1 : -1,
      },
    ].filter(Boolean)
  }, [])

  return <FjTable loading={loading} columns={columns} dataSource={viewsSummaryByUser} pagination={false} />
}
