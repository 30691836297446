import React from 'react'
import { Download, ExternalLink, Eye, MessageCircle } from 'react-feather'
import { ContainerDiv, ContainerDivProps } from 'src/components/Common'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedPost } from 'src/models/FeedPost'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { LearningContent } from 'src/utils/content'
import { DealRoom } from 'src/models/DealRoom'
import ClapOutlinedBlack from 'src/assets/icons/ClapOutlinedBlack.svg'

type LibraryStatsProps = { obj: LearningContent | Hub | Page | CoachingMoment; containerStyles?: ContainerDivProps }

export const LibraryStats = ({ obj, containerStyles = {} }: LibraryStatsProps) => {
  const isAsset = obj instanceof Asset
  const isFeedPost = obj instanceof FeedPost
  const isDealRoom = obj instanceof DealRoom
  return (
    <ContainerDiv
      position="absolute"
      bottom={0}
      textAlign="left"
      display="flex"
      alignItems="center"
      gap={16}
      style={{ fontSize: '12px' }}
      {...containerStyles}
    >
      {(isAsset || isDealRoom) && obj?.analytics?.totalViews > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={4}>
          <Eye size={14} /> {obj.analytics.totalViews}
        </ContainerDiv>
      ) : null}
      {isAsset && obj?.analytics?.totalLinkCount > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={4}>
          <ExternalLink size={14} /> {obj.analytics.totalLinkCount}
        </ContainerDiv>
      ) : null}
      {isAsset && obj?.analytics?.totalDownloadCount > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={4}>
          <Download size={14} /> {obj.analytics.totalDownloadCount}
        </ContainerDiv>
      ) : null}
      {(isAsset || isFeedPost) && obj.likeCount > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={4}>
          <img src={ClapOutlinedBlack} alt="like" width={16} height={16} /> {obj.likeCount}
        </ContainerDiv>
      ) : null}
      {(isAsset || isFeedPost) && obj.commentCount > 0 ? (
        <ContainerDiv display="flex" alignItems="center" gap={4}>
          <MessageCircle size={14} /> {obj.commentCount}
        </ContainerDiv>
      ) : null}
    </ContainerDiv>
  )
}
