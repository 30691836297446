import axios from 'axios'
import { PromisePool } from '@supercharge/promise-pool'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { uniqueS3Filename } from 'src/utils/renameFile'
import { APIProvider } from 'src/network/APIProvider'
import { convertXmlToJson } from 'src/utils/format'
import { baseURL } from 'src/network/FlockjayProvider'

const DEFAULT_S3_BUCKET = 'fj-file-uploads'
export const S3_SCORM_BUCKET = 'flockjay-scorm-content'

const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 10 // 10MB

export const uploadToS3 = async (
  file: File,
  bucket = DEFAULT_S3_BUCKET,
  uploadType: 'single' = 'single',
  // uploadType: 'single' | 'multipart' = 'multipart',
  chunkSize = DEFAULT_CHUNK_SIZE,
  uploadProgress: (progress: number) => void = (progress) => sharedAppStateStore.updateAppLoadingProgress(progress)
) => {
  const cleanFilename = uniqueS3Filename(file.name)
  const headers = { 'Content-Type': file.type, 'x-amz-acl': 'private', 'x-amz-server-side-encryption': 'AES256' }
  const location = `${baseURL}/feed/files/${bucket}/${cleanFilename}`

  if (uploadType === 'single') {
    const { url } = (await APIProvider.getPresignedUrl({ operation: 'put_object', bucket: bucket, key: cleanFilename }))
      .data
    await axios.put(url, file, { headers })
    return location
  } else {
    /*
      generating presigned url for complete_multipart_upload is failing because of bug with boto3 on backend
      leaving this section here for when that bug gets fixed
    */
    let res = await APIProvider.getPresignedUrl({
      operation: 'create_multipart_upload',
      bucket: bucket,
      key: cleanFilename,
    })
    res = await axios.post(res.data.url, undefined, { headers })
    const {
      InitiateMultipartUploadResult: { UploadId },
    } = convertXmlToJson(res.data)

    const totalParts = Math.ceil(file.size / chunkSize)

    const { results } = await PromisePool.for(Array.from(Array(totalParts).keys()))
      .withConcurrency(10)
      .onTaskFinished((_, pool) => uploadProgress(pool.processedPercentage() / 100))
      .process(async (i) => {
        const partNumber = i + 1
        const chunkStart = i * chunkSize
        const chunkEnd = Math.min((i + 1) * chunkSize, file.size)

        const { url } = (
          await APIProvider.getPresignedUrl({
            operation: 'upload_part',
            bucket: bucket,
            key: cleanFilename,
            partNumber: partNumber,
            uploadId: UploadId,
          })
        ).data

        const uploadPartRes = await axios.put(url, file.slice(chunkStart, chunkEnd, file.type), { headers })
        return { ETag: uploadPartRes.headers.etag, PartNumber: partNumber }
      })

    res = await APIProvider.getPresignedUrl({
      operation: 'complete_multipart_upload',
      Bucket: bucket,
      Key: cleanFilename,
      UploadId: UploadId,
      Parts: results.sort((a, b) => a.PartNumber - b.PartNumber),
    })

    await axios.post(res.data.url, undefined, { headers })
    return location
  }
}
