import { DataObject } from 'src/models/DataObject'
import moment from 'moment-timezone'
import { formatDateTime } from 'src/utils/format'

export class LxpObject extends DataObject {
  id: string
  releaseDate?: moment.Moment
  dueDate?: moment.Moment
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  isReleased = () => {
    if (this.releaseDate) return moment().diff(this.releaseDate) >= 0
    return true
  }

  releasedDateStr = (displayFormat?: string) => {
    return this.releaseDate ? formatDateTime(this.releaseDate, displayFormat) : 'Coming soon'
  }

  dueDateStr = (displayFormat?: string) => {
    return this.dueDate ? formatDateTime(this.dueDate, displayFormat) : 'N/A'
  }
}
