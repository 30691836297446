import React from 'react'

// Responsive iframe
export const IFrame: React.FC<React.DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>> = (props) => {
  const { title, ...rest } = props
  return (
    <div style={{ position: 'relative', overflow: 'auto', paddingTop: '56.25%' }}>
      <iframe {...rest} style={{ position: 'absolute', top: 0, left: 0, border: 0, ...props.style }} title={title} />
    </div>
  )
}
