import React, { Component } from 'react'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Moment } from 'moment'
import moment from 'moment'
import { SharedContent } from 'src/models/SharedContent'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ContainerDiv, FjTable, FjText, FormHeaderText } from 'src/components/Common'
import { Check } from 'react-feather'
import { Asset } from 'src/models/Asset'
import { titleCase } from 'src/utils/format'
import { Popover } from 'antd'
import { ViewWrapper } from 'src/components/Common/ViewWrapper'

type SharedContentSessionViewer = { name?: string; email?: string }

type SharedContentSessionType = {
  downloadCount?: number
  name: string
  sessionDuration: string
  sessionTime: Moment
  contentType: 'asset' | 'deal_room'
  contentId: string
  contentTitle: string
  viewer: SharedContentSessionViewer
}

interface ISharedContentSessionsTableProps {
  sharedContent: SharedContent
}

@observer
export class SharedContentSessionsTable extends React.Component<ISharedContentSessionsTableProps> {
  @observable viewState: 'initialLoad' | 'loading' | 'error' | 'idle' = 'initialLoad'
  @observable sessions: SharedContentSessionType[]

  constructor(props: ISharedContentSessionsTableProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    try {
      const { sharedContent } = this.props
      this.viewState = 'loading'
      this.sessions = sharedContent.id ? await sharedContent.getSessions() : []
      this.viewState = 'idle'
    } catch (err) {
      this.viewState = 'error'
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  getColumns = () => {
    const { sharedContent } = this.props
    let headerColumns = []

    const viewerColumn = {
      title: 'Viewer',
      key: 'viewer',
      dataIndex: 'viewer',
      render: (viewer: SharedContentSessionViewer) => {
        if (viewer.name && viewer.email)
          return (
            <Popover placement="top" showArrow content={viewer.email}>
              <FjText cursor="pointer">{viewer.name}</FjText>
            </Popover>
          )
        if (viewer.name || viewer.email) return viewer.name || viewer.email
        return 'N/A'
      },
    }

    if (sharedContent.learningContent instanceof Asset) {
      headerColumns.push(
        {
          title: 'Link Name',
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        viewerColumn
      )
    } else {
      headerColumns.push(
        {
          title: 'Content',
          dataIndex: 'contentTitle',
          key: 'contentTitle',
          sorter: (a, b) => a.contentTitle.localeCompare(b.contentTitle),
        },
        viewerColumn,
        {
          title: 'Type',
          key: 'contentType',
          render: ({ contentType }) => titleCase(contentType, '_'),
        }
      )
    }

    return [
      ...headerColumns,
      {
        title: 'Date/Time',
        dataIndex: 'sessionTime',
        key: 'sessionTime',
        render: (sessionTime: string) => `${moment(sessionTime).format('MM-DD-YYYY  hh:mm A')}`,
        sorter: (a, b) => {
          const dateA = new Date(a.sessionTime)
          const dateB = new Date(b.sessionTime)
          return dateB.getTime() - dateA.getTime()
        },
      },
      {
        title: 'Duration',
        key: 'sessionDuration',
        render: ({ downloadCount, sessionDuration }) => {
          if (downloadCount === 1) return '--:--:--'
          return parseFloat(sessionDuration) ? moment.utc(sessionDuration * 1000).format('HH:mm:ss') : '~5s'
        },
        sorter: (a, b) => a.sessionDuration - b.sessionDuration,
      },
      {
        title: 'Is Download',
        dataIndex: 'downloadCount',
        key: 'downloadCount',
        render: (count) => (count === 1 ? <Check size={16} /> : ''),
        sorter: (a, b) => a.downloadCount - b.downloadCount,
      },
    ]
  }

  render() {
    return (
      <ViewWrapper viewState={this.viewState} customErrorUI="Failed to load sessions">
        <FjTable columns={this.getColumns()} dataSource={this.sessions} pagination={false} />
      </ViewWrapper>
    )
  }
}

export interface ISharedContentSessionsModalProps {
  onSuccess: () => void
  onCancel: () => void
  sharedContent: SharedContent
}

export class SharedContentSessionsModal extends Component<ISharedContentSessionsModalProps> {
  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading={`Sessions for "${this.props.sharedContent.title}"`} />
        <SharedContentSessionsTable sharedContent={this.props.sharedContent} />
      </ContainerDiv>
    )
  }
}
