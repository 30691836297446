import React from 'react'
import { FilterDropdownButton, FilterDropdownButtonProps } from 'src/components/Common/Button'
import FjPopover from 'src/components/Common/FjPopover'

type BaseFilterProps = FilterDropdownButtonProps & {
  content: React.ReactNode
}

export const BaseFilter = ({ content, ...props }: BaseFilterProps) => {
  return (
    <FjPopover content={content}>
      <FilterDropdownButton {...props} />
    </FjPopover>
  )
}
