import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Loading } from 'src/components/Icons/Loading'
import { Colors } from 'src/constants/colors'

export interface LoaderProps {
  iconSize?: number
  containerStyle?: React.CSSProperties
  style?: React.CSSProperties
  label?: string
}
export const Loader = (props: LoaderProps) => {
  const { containerStyle, style, label, iconSize } = props
  return (
    <ContainerDiv
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        gap: '20px',
        ...containerStyle,
      }}
    >
      <Loading
        color={Colors.neonCarrot}
        width={iconSize || 48}
        height={iconSize || 48}
        style={{
          color: Colors.neonCarrot,
          fontSize: '3em',
          textAlign: 'center',
          ...style,
        }}
      />
      {label ? <FjText>{label}</FjText> : null}
    </ContainerDiv>
  )
}
