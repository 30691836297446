import moment from 'moment'
import { observable, makeObservable } from 'mobx'
import { Author } from 'src/models/Author'
import { sharedDataStore } from 'src/store/DataStore'
import { DataObject } from 'src/models/DataObject'
import { frontendURL } from 'src/network/FlockjayProvider'
import { Paths } from 'src/constants/navigation'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'

export class Page extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    content: (data) => (data.content ? data.content : ''),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'page',
  }
  static apiEndpoint: string = '/feed/pages/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'page'

  getBulkEditFields = () => []

  id: string = ''
  @observable title: string = ''
  @observable content: string = ''
  @observable thumbnailUrl: string
  templateId: string
  learningContentId: string
  progress: number
  groupIds: string[]
  author: Author
  createdAt: moment.Moment
  lastUpdated: moment.Moment
  publishedAt: moment.Moment
  appearsIn: AppearsInType[]
  @observable expiryDate: moment.Moment

  // These properties don’t actually exist on this model
  // They are here because TS doesn’t retain class information when checks are made from another function
  isFavourited?: false
  sfMetadata?: SfOpportunity

  constructor() {
    super()
    makeObservable(this)
  }

  getHeroBgColor = () => Colors.onahau
  getContentTypeTagColor = () => Colors.anakiwa
  getTagBgColor = () => Colors.blueChalk2
  getTagTitle = () => 'Page'

  imgSrc = () => this.thumbnailUrl

  getTrackingElementId = () => `pageelement-${this.id}`

  getViewTrackingEventType = () => 'page_viewed'

  getViewTrackingObjIdKey = () => 'page_id'

  viewTrackingThresholdReached = (start: moment.Moment) => true

  canEdit = () => !!this.id && (sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin())

  canArchive = () => false

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => false

  canAddToPromotedSearch = () => false

  canDuplicate = () => this.canEdit()

  hasOpportunity = () => false

  hasStageName = () => false

  static getFullPageUrl = (id: string) => `${frontendURL}${Paths.getPagePath({ id })}`

  duplicate = async (redirect = true, customData = {}) => {
    const duplicatedPage = new Page()
    const { title, content, thumbnailUrl, groupIds } = this
    await sharedAppStateStore.wrapAppLoading(
      duplicatedPage.save({
        title: `Copy of ${title}`,
        content,
        thumbnailUrl,
        groupIds,
        ...customData,
      })
    )
    if (redirect) sharedAppStateStore.navigate(Paths.getPagePath({ id: duplicatedPage.id, isAuthoring: true }))
    return duplicatedPage
  }

  canCreateTemplate = () =>
    sharedDataStore.user.isFaasAdminOrManager() && !!this.id && !!this.author?.authorID && !this.templateId
}
