import { Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { ContainerDiv, FjCard, DefaultButton, FjText } from 'src/components/Common'
import { handleOpenArchiveModal } from 'src/components/Common/SharedConfirmDialog'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { getFeedContentTypeName } from 'src/utils/content'
import { ArchiveableElement } from 'src/utils/getMenuContext'

type ArchiveBannerProps = {
  obj: ArchiveableElement
  onEditClick: (obj: ArchiveableElement) => void
  onDeleteConfirm: (obj: ArchiveableElement) => void
  onArchiveSuccess: () => void
}

export const ArchiveBanner = ({ obj, onEditClick, onDeleteConfirm, onArchiveSuccess }: ArchiveBannerProps) => {
  if (!obj || (!obj.isExpired() && !obj.isExpiringSoon())) return null
  const contentTypeName = getFeedContentTypeName({ obj })
  return (
    <ContainerDiv display="flex" flexDirection="column" gap={'8px'} marginBottom>
      {obj.isExpired() ? (
        <FjCard padding display="flex" justifyContent="space-between" alignItems="center">
          <FjText fontWeight="semi-bold" color={Colors.chambray} fontSize="medium" textAlign="left">
            This {contentTypeName} has been archived.
          </FjText>
          <Space>
            <DefaultButton buttonType="primary" title="Restore" clicked={() => onEditClick(obj)} />
            <DefaultButton
              buttonType="destructive"
              title="Delete"
              clicked={() =>
                (sharedAppStateStore.deleteDialogProps = {
                  onConfirm: onDeleteConfirm,
                  obj: obj,
                })
              }
            />
          </Space>
        </FjCard>
      ) : null}
      {obj.isExpiringSoon() ? (
        <FjCard padding display="flex" justifyContent="space-between" alignItems="center">
          <FjText fontWeight="semi-bold" color={Colors.chambray} fontSize="medium" textAlign="left">
            This {contentTypeName} will expire in {Math.ceil(moment.duration(obj.expiryDate.diff(moment())).asDays())}
            d.
          </FjText>
          <Space>
            <DefaultButton buttonType="primary" title="Keep" clicked={() => onEditClick(obj)} />
            <DefaultButton
              buttonType="tertiary"
              title="Archive"
              clicked={() => handleOpenArchiveModal(obj, undefined, onArchiveSuccess)}
            />
          </Space>
        </FjCard>
      ) : null}
    </ContainerDiv>
  )
}
