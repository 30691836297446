class ScrollStore {
  curriculumRef?: HTMLElement | null
  demoRequestRef?: HTMLElement | null
  salesCourseTitlesRef?: HTMLElement | null
  salesCourseRef?: HTMLElement | null
  recentChapterRef?: HTMLElement | null

  scrollToCurriculum = () => {
    if (this.curriculumRef) {
      window.scrollTo(0, this.getYPosition(this.curriculumRef))
    }
  }

  scrollToDemoRequest = () => {
    if (this.demoRequestRef) {
      window.scrollTo(0, this.getYPosition(this.demoRequestRef))
    }
  }

  scrollToSalesCourseTitles = () => {
    if (this.salesCourseTitlesRef) {
      window.scrollTo(0, this.getYPosition(this.salesCourseTitlesRef))
    }
  }

  scrollToSalesCourse = () => {
    if (this.salesCourseRef) {
      window.scrollTo(0, this.getYPosition(this.salesCourseRef) - 100)
    }
  }

  scrollToRecentChapter = () => {
    if (this.recentChapterRef) {
      window.scrollTo(0, this.getYPosition(this.recentChapterRef) - 100)
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  private getYPosition = (target: HTMLElement) => {
    return target.getBoundingClientRect().top + window.pageYOffset || document.documentElement.scrollTop
  }
}

const sharedScrollStore = new ScrollStore()
export { sharedScrollStore }
