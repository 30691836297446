import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { DefaultLink, ContainerDiv, ContainerDivProps } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { ArrowLeft, ChevronRight } from 'react-feather'
import { LibraryCategory } from 'src/pages/Library'
import { Paths } from 'src/constants/navigation'
import { getContentCategoryTitle } from 'src/utils/content'
import { truncate } from 'src/utils/format'

export interface BreadcrumbType {
  breadcrumbName: string
  path: string
  contentType: 'course' | 'hub' | 'learningpath'
  selectedLearningPathContentId: string
}

const MAX_BREADCRUMB_NAME_LENGTH = 35

const BreadcrumbBarSeparator = () => <ChevronRight size={16} color={Colors.cornflowerBlue} />

const BreadcrumbBarItemWrapper = ({ children, ...props }: React.PropsWithChildren<ContainerDivProps>) => (
  <ContainerDiv display="flex" alignItems="center" gap={4} {...props}>
    {children}
  </ContainerDiv>
)

interface BreadcrumbBarItemProps {
  to: string
  onClick?: () => void
}

const BreadcrumbBarItem = ({ children, onClick, to }: React.PropsWithChildren<BreadcrumbBarItemProps>) => {
  const handleBreadcrumbClick = (e: React.MouseEvent) => {
    if (!onClick) return
    e.preventDefault()
    onClick()
  }
  return (
    <DefaultLink
      to={to}
      style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        minWidth: '30px',
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: 700,
        color: Colors.cornflowerBlue,
        cursor: 'pointer',
      }}
      onClick={handleBreadcrumbClick}
    >
      {children}
    </DefaultLink>
  )
}

interface BreadcrumbBarProps {
  contentType?: LibraryCategory
}

@observer
class BreadcrumbBar extends React.Component<BreadcrumbBarProps> {
  render() {
    if (sharedAppStateStore.routes.length === 0) {
      if (!sharedAppStateStore.isInCanvas && !this.props.contentType) return null
      return (
        <BreadcrumbBarItemWrapper marginBottom>
          {sharedAppStateStore.isInCanvas ? (
            <>
              <ArrowLeft color={Colors.tapa} size={16} />
              <BreadcrumbBarItem to="#" onClick={sharedAppStateStore.navigateBack}>
                Back
              </BreadcrumbBarItem>
            </>
          ) : (
            <>
              <BreadcrumbBarItem to={Paths.getLibraryPath(this.props.contentType)}>
                {getContentCategoryTitle(this.props.contentType)}
              </BreadcrumbBarItem>
              <BreadcrumbBarSeparator />
            </>
          )}
        </BreadcrumbBarItemWrapper>
      )
    }

    const firstRouteContentType = sharedAppStateStore.routes[0].contentType
    return (
      <ContainerDiv textAlign="left" display="flex" flexWrap="wrap" width="100%" overflow="hidden" marginBottom gap={4}>
        {['course', 'hub', 'learningpath'].includes(firstRouteContentType) ? (
          <BreadcrumbBarItemWrapper>
            <BreadcrumbBarItem to={Paths.getLibraryPath(firstRouteContentType)}>
              {getContentCategoryTitle(firstRouteContentType)}
            </BreadcrumbBarItem>
            <BreadcrumbBarSeparator />
          </BreadcrumbBarItemWrapper>
        ) : null}
        {sharedAppStateStore.routes.map((route, i) => (
          <BreadcrumbBarItemWrapper key={i}>
            <Tooltip placement="bottom" title={route.breadcrumbName} key={JSON.stringify(route)}>
              <BreadcrumbBarItem
                to={route.path}
                onClick={() => sharedAppStateStore.navigate(route.path, false, { fromBreadcrumb: true })}
              >
                {truncate(route.breadcrumbName, MAX_BREADCRUMB_NAME_LENGTH)}
              </BreadcrumbBarItem>
            </Tooltip>
            {sharedAppStateStore.routes.length - 1 !== i && <BreadcrumbBarSeparator />}
          </BreadcrumbBarItemWrapper>
        ))}
      </ContainerDiv>
    )
  }
}

export default BreadcrumbBar
