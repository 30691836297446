import React from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Field } from 'formik-antd'
import { FormikFieldProps } from 'formik-antd/lib/FieldProps'
import { FieldProps } from 'formik'
import {
  Utils as QbUtils,
  Config,
  ImmutableTree,
  ActionMeta,
  BuilderProps,
  Builder,
  Query,
} from '@react-awesome-query-builder/antd'
import '@react-awesome-query-builder/antd/css/compact_styles.css'
import { DeleteButton } from 'src/components/Common/Button'

interface IFjQueryBuilderProps {
  config: Config
  initialValue?: ImmutableTree
  onChange: (tree: ImmutableTree) => void
}

@observer
class FjQueryBuilder extends React.Component<IFjQueryBuilderProps> {
  @observable tree: ImmutableTree
  // for some reason making config observable breaks the query builder
  config: Config

  constructor(props: IFjQueryBuilderProps) {
    super(props)
    makeObservable(this)
    const { config, initialValue } = this.props
    this.config = { ...config }
    this.tree = initialValue
      ? QbUtils.checkTree(initialValue, this.config)
      : QbUtils.checkTree(QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }), this.config)
  }

  onChange = (immutableTree: ImmutableTree, config: Config, actionMeta?: ActionMeta) => {
    this.tree = immutableTree
    this.config = config
    this.props.onChange(immutableTree)
  }

  renderBuilder = (props: BuilderProps) => {
    return (
      <div className="query-builder-container">
        <div className="query-builder">
          <Builder {...props} />
        </div>
      </div>
    )
  }

  render() {
    this.config.settings.renderIcon = (props, { RCE, W: { Icon } }) => {
      if (props.type === 'delRule') return <DeleteButton fontSize={14} type="filled" onClick={() => {}} />
      if (props.type === 'delGroup')
        return <DeleteButton fontSize={14} type="filled" padding="0px" height="22px" onClick={() => {}} />
      return RCE(Icon, props)
    }

    // TODO: Add button is done but still need to handle and and/or button group
    // this.config.settings.renderButton = (props, { RCE, W: { Button } }) => {
    //   if (props.type === 'addRule')
    //     return (
    //       <DefaultButton
    //         title="Add Rule"
    //         size="small"
    //         image={<Plus size={16} color={Colors.white} />}
    //         buttonType="primary"
    //         clicked={props.onClick}
    //       />
    //     )
    //   return RCE(Button, props)
    // }

    return (
      <div className="query-tree">
        <Query {...this.config} value={this.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />
      </div>
    )
  }
}

interface IFjQueryBuilderFieldProps extends FormikFieldProps {
  config: Config
}

export const FjQueryBuilderField: React.FC<IFjQueryBuilderFieldProps> = ({ config, ...formikFieldProps }) => {
  return (
    <Field {...formikFieldProps}>
      {(fieldProps: FieldProps) => {
        return (
          <FjQueryBuilder
            config={config}
            initialValue={fieldProps.form.initialValues[formikFieldProps.name]}
            onChange={(immutableTree: ImmutableTree) =>
              fieldProps.form.setFieldValue(formikFieldProps.name, immutableTree)
            }
          />
        )
      }}
    </Field>
  )
}
