import moment from 'moment'
import { computed, observable, makeObservable } from 'mobx'
import humps from 'humps'
import { Author } from 'src/models/Author'
import { FeedPost } from 'src/models/FeedPost'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import { Call } from 'src/models/Call'
import { sharedDataStore } from 'src/store/DataStore'
import { Asset } from 'src/models/Asset'
import { LearningPath } from 'src/models/LearningPath'
import { DataObject } from 'src/models/DataObject'
import { APIProvider } from 'src/network/APIProvider'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { CustomMetadataFieldOptions } from 'src/models/Config'

export type MiniHub = { id: string; name: string }

export type HubElement = FeedPost | Course | FeedPlaylist | Prompt | Call | Asset | LearningPath

export type HubCustomMetadataFieldOptions = {
  [key in LearningContentType]?: CustomMetadataFieldOptions
}

export class Hub extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    appearsIn: (data) => data.appearsIn ?? [],
    contentTypeCustomFields: (data) =>
      data.contentTypeCustomFields ? humps.decamelizeKeys(data.contentTypeCustomFields) : {},
    learningContentType: () => 'hub',
  }
  static apiEndpoint: string = '/feed/hub/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'hub'

  getBulkEditFields = () => ['groupIds']

  id: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable thumbnailUrl: string
  @observable learningContents: HubElement[] = []
  @observable contentTypeCustomFields: HubCustomMetadataFieldOptions = {}
  groupIds: string[]
  author: Author
  publishedAt: moment.Moment

  createdAt: moment.Moment
  lastUpdated: moment.Moment

  // These properties don’t actually exist on this model
  // They are here because TS doesn’t retain class information when checks are made from another function
  isFavourited?: false
  sfMetadata?: SfOpportunity
  appearsIn: AppearsInType[]

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get title() {
    return this.name
  }

  // contentTypes is used to determine which tab to display on the Hub view page.
  @computed get contentTypes(): LearningContentType[] {
    // orderedContentTypes determines order of tabs and should be matched with Library page tabs
    const orderedContentTypes = [
      'asset',
      'dealroom',
      'course',
      'learningpath',
      'playlist',
      'feedpost',
      'call',
      'prompt',
    ]
    return Object.keys(this.contentTypeCustomFields).sort(
      (a, b) => orderedContentTypes.indexOf(a) - orderedContentTypes.indexOf(b)
    ) as LearningContentType[]
  }

  getLearningContents = async () => {
    if (this.learningContents?.length > 0) return

    this.learningContents = await APIProvider.fetchLearningContents(this.id)
  }

  getHeroBgColor = () => Colors.mercury
  getContentTypeTagColor = () => Colors.cottonSeed
  getTagBgColor = () => Colors.concrete
  getTagTitle = () => 'Hub'

  imgSrc = () => this.thumbnailUrl

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  canArchive = () => false

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => false

  canAddToPromotedSearch = () => false

  canDuplicate = () => false

  hasOpportunity = () => false

  hasStageName = () => false

  canCreateTemplate = () => false
}
