import React, { useCallback, useEffect, useState } from 'react'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { DefaultLink, ContainerDiv } from 'src/components/Common'
import { ContentTypeIcon, LearningContentType } from 'src/utils/content'
import { Tag, TagProps } from 'antd'
import { Users, X } from 'react-feather'
import { sharedDataStore } from 'src/store/DataStore'
import { deepEquals } from 'src/utils/format'

export type PillType = 'default' | 'tag' | 'group' | 'customField' | 'hub'

interface PillProps extends TagProps {
  pillType?: PillType
}

const PILL_BG_COLORS: { [key in PillType]: string } = {
  default: Colors.silverTree,
  tag: Colors.zumthor,
  group: Colors.chambray,
  customField: Colors.zumthor,
  hub: Colors.zumthor,
}

const PILL_TEXT_COLORS: { [key in PillType]: string } = {
  default: Colors.white,
  tag: Colors.shark,
  group: Colors.white,
  customField: Colors.shark,
  hub: Colors.shark,
}

export const Pill: React.FC<PillProps> = ({ pillType = 'default', icon, closable, onClose, ...props }) => {
  const pillBgColor = PILL_BG_COLORS[pillType]
  const pillTextColor = PILL_TEXT_COLORS[pillType]
  const getPillIcons = () => {
    if (icon) return icon
    if (pillType === 'tag') return '#'
    if (pillType === 'group') return <Users color={Colors.white} size={16} />
    if (pillType === 'hub') return <ContentTypeIcon contentType="hub" size={16} color={pillTextColor} />
    return ''
  }

  return (
    <Tag
      {...props}
      color={pillBgColor}
      style={{
        backgroundColor: pillBgColor,
        color: pillTextColor,
        fontWeight: 500,
      }}
      closeIcon={
        <X
          size={12}
          color={pillTextColor}
          style={{
            cursor: 'pointer',
          }}
        />
      }
      icon={getPillIcons()}
      closable={closable || !!onClose}
      onClose={onClose}
    >
      {props.children}
    </Tag>
  )
}

export const TagsDisplayList: React.FC<{
  scrollable?: boolean
  tags
  contentType?: LearningContentType
  tagClicked?: (tag: string) => void
}> = ({ scrollable, tags, contentType, tagClicked }) => {
  const hasTagAccess = !sharedDataStore.user.isAnonymous() && !sharedDataStore.user.isFaasPublic()
  const handleLinkClick = (tag: string) => (e: React.MouseEvent) => {
    if (tagClicked) {
      e.preventDefault()
      tagClicked(tag)
    }
  }

  return (
    <ContainerDiv
      className={scrollable ? 'scrollbar-less-container' : null}
      display="flex"
      flexWrap={scrollable ? null : 'wrap'}
      flexDirection="row"
      gap="3px"
      alignItems="center"
      margin="5px 0px"
    >
      {tags
        ? tags.map((tag) => (
            <DefaultLink
              className="tags"
              to={hasTagAccess ? Paths.getTagFeedPath(tag, contentType) : undefined}
              key={tag}
              onClick={handleLinkClick(tag)}
              style={{ cursor: !hasTagAccess ? 'default' : 'pointer' }}
            >
              <Pill key={tag} pillType="tag">
                {tag}
              </Pill>
            </DefaultLink>
          ))
        : null}
    </ContainerDiv>
  )
}

export type PillOption = { key: string; label: string }

interface PillListProps {
  values: string[]
  onClose?: (value: string) => void
  getSelectedOption?: (value: string) => Promise<PillOption> | PillOption
  pillType: PillType
  retainOptions?: string[]
}

export const PillList: React.FC<PillListProps> = ({ values, getSelectedOption, onClose, pillType, retainOptions }) => {
  const [selectedOptions, setSelectedOptions] = useState<PillOption[]>([])

  const fetchSelectedOptions = useCallback(async () => {
    const newOptions = []
    for (const value of values) {
      const existingOption = selectedOptions.find((option) => option.key === value)
      if (existingOption) {
        newOptions.push(existingOption)
      } else {
        const option = await getSelectedOption(value)
        newOptions.push(option)
      }
    }
    setSelectedOptions(newOptions)
  }, [getSelectedOption, selectedOptions, values])

  useEffect(() => {
    const selectedOptionKeys = selectedOptions.map((option) => option.key)
    if (!deepEquals(selectedOptionKeys, values)) {
      if (!getSelectedOption) {
        setSelectedOptions(values.map((value) => ({ key: value, label: value })))
      } else {
        fetchSelectedOptions()
      }
    }
  }, [fetchSelectedOptions, getSelectedOption, selectedOptions, values])

  return (
    <ContainerDiv display="flex" flexDirection="row" gap="5px" alignItems="center" flexWrap="wrap" width="100%">
      {selectedOptions.map(({ key, label }) => (
        <Pill
          key={key}
          pillType={pillType}
          onClose={onClose && !retainOptions?.includes(key) ? () => onClose(key) : null}
        >
          {label}
        </Pill>
      ))}
    </ContainerDiv>
  )
}
