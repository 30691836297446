import React from 'react'
import { observer } from 'mobx-react'
import { Colors } from 'src/constants/colors'
import { AssignmentSubmission } from 'src/models/AssignmentSubmission'
import { Paths } from 'src/constants/navigation'
import { DefaultLink, FjText } from 'src/components/Common'
import { CheckCircle, Flag } from 'react-feather'
import { Trophy } from 'src/components/Icons/Trophy'
import { Assignment } from 'src/models/Assignment'

interface GradingStatusProps {
  assignmentSubmission: AssignmentSubmission
  assessment: Assignment
}

export const getScoreColor = (score: number, passScore?: number) => {
  if (passScore) {
    if (score >= passScore) return Colors.emerald
    return Colors.sunsetOrange
  }
  if (score >= 0 && score < 70) return Colors.sunsetOrange
  else if (score >= 70 && score < 90) return Colors.texasRose
  else return Colors.emerald
}

@observer
export class GradingStatus extends React.Component<GradingStatusProps> {
  render() {
    const { assignmentSubmission, assessment } = this.props
    if (assignmentSubmission === undefined) return null
    if (assignmentSubmission.status === 'submitted') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DefaultLink to={Paths.getSubmissionPath(assignmentSubmission.id)}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: Colors.cornflowerBlue,
                padding: '4px 12px',
                color: Colors.white,
                borderRadius: '26px',
                border: `2px solid ${Colors.cornflowerBlue}`,
                width: 'max-content',
              }}
            >
              <CheckCircle size={12} color={Colors.white} style={{ marginRight: '3px' }} />
              <FjText color={Colors.white} fontWeight="bold500" fontSize="small">
                Submitted
              </FjText>
            </div>
          </DefaultLink>
        </div>
      )
    }
    if (assignmentSubmission.status === 'in_progress') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              backgroundColor: Colors.white,
              padding: '4px 12px',
              color: Colors.white,
              borderRadius: '26px',
              border: `2px solid ${Colors.texasRose}`,
              width: 'max-content',
            }}
          >
            <Flag size={12} color={Colors.texasRose} style={{ marginRight: '3px' }} />
            <FjText color={Colors.texasRose} fontWeight="bold500" fontSize="small">
              Started
            </FjText>
          </div>
        </div>
      )
    }
    if (assignmentSubmission.status === 'grading_in_progress') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <DefaultLink to={Paths.getSubmissionPath(assignmentSubmission.id)}>
            <div
              style={{
                backgroundColor: Colors.white,
                padding: '4px 12px',
                color: Colors.white,
                borderRadius: '26px',
                border: `2px solid ${Colors.emerald}`,
                width: 'max-content',
              }}
            >
              <Trophy width={12} height={12} color={Colors.emerald} style={{ marginRight: '3px' }} />
              <FjText color={Colors.emerald} fontWeight="bold500" fontSize="small">
                Grading in Progress
              </FjText>
            </div>
          </DefaultLink>
        </div>
      )
    }
    if (assignmentSubmission.status === 'graded' && assignmentSubmission.totalScore !== null) {
      const scoreColor = getScoreColor(assignmentSubmission.totalScore, assessment.getPassScorePercent())
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
          <DefaultLink to={Paths.getSubmissionPath(assignmentSubmission.id)}>
            <div style={{ borderRadius: '3px', padding: '3px 8px', backgroundColor: scoreColor }}>
              <FjText color={Colors.white} fontWeight="bold500" fontSize="small">
                {assignmentSubmission.totalScore}%
              </FjText>
            </div>
          </DefaultLink>
        </div>
      )
    }
    return <></>
  }
}
