import moment from 'moment'
import { observable, makeObservable, computed } from 'mobx'

import { Favouritable } from 'src/models/Favouritable'
import { Author } from 'src/models/Author'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { sharedDataStore } from 'src/store/DataStore'
import { Colors } from 'src/constants/colors'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'
import { SharedContent } from 'src/models/SharedContent'
import { copyToClipboard, getIntersection } from 'src/utils/format'
import { showNotification } from 'src/hoc/Notification'
import { MiniHub } from 'src/models/Hub'

export class DealRoom extends Favouritable {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    hubs: (data) => (data.hubs ? data.hubs : []),
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: ({ appearsIn }) => appearsIn ?? [],
    learningContentType: () => 'dealroom',
  }

  static apiEndpoint: string = '/feed/dealroom/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'dealroom'

  getBulkEditFields = () => ['groupIds', 'hubs', 'sfMetadata', 'expiryDate']

  id: string
  title: string
  content: string = ''
  author: Author
  groupIds: string[] = []
  @observable hubs: MiniHub[] = []
  @observable isFavourited: false
  @observable expiryDate?: moment.Moment
  @observable thumbnailUrl?: string
  sfMetadata?: SfOpportunity
  sharedContentId?: string
  appearsIn: AppearsInType[]
  learningContentId: string
  templateId?: string
  analytics?: {
    totalViews: number
  }

  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  constructor() {
    super()
    makeObservable(this)
  }

  imgSrc = () => this.thumbnailUrl

  getTagTitle = (config?: { includeFileType?: boolean; showTemplate?: boolean }) => {
    const { showTemplate } = config || {}
    let tagTitle = 'Deal Room'
    if (!!this.templateId && showTemplate) {
      tagTitle = `${tagTitle} - Template`
    }
    return tagTitle
  }

  getTagBgColor = () => Colors.zumthor

  getHeroBgColor = () => Colors.frenchPass

  getContentTypeTagColor = () => Colors.anakiwa

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canEdit = () =>
    this.author ? sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin() : false

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate && this.expiryDate.diff(moment(), 'days') <= 7

  canShareExternally = () => true

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => !!this.id

  canOpenAnalyticsModal = () => !!this.sharedContentId

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  getTrackingElementId = () => `dealroomelement-${this.id}`

  getViewTrackingEventType = () => 'deal_room_viewed'

  getViewTrackingObjIdKey = () => 'deal_room_id'

  viewTrackingThresholdReached = (start: moment.Moment) => true

  openEditSharedContentModal = async () => {
    let obj: DealRoom | SharedContent
    try {
      if (this.sharedContentId) {
        obj = await sharedAppStateStore.wrapAppLoading(SharedContent.get(this.sharedContentId))
      } else {
        obj = this
      }
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }

    const onCancel = () => (sharedAppStateStore.editSharedContentModalProps = undefined)
    const onSuccess = (sharedContent: SharedContent) => {
      if (!this.sharedContentId) {
        this.sharedContentId = sharedContent.id
        copyToClipboard(
          SharedContent.generateShareLink(sharedContent.id),
          'Link created and copied to clipboard!',
          `Link to ${sharedContent.title}`
        )
        onCancel()
      } else {
        showNotification({ message: 'Link Updated!' })
      }
    }

    sharedAppStateStore.editSharedContentModalProps = {
      obj,
      onCancel,
      onSuccess,
      mode: this.sharedContentId ? 'view' : 'edit',
    }
  }

  copySharedContentLink = () => {
    if (!this.sharedContentId) return
    copyToClipboard(
      SharedContent.generateShareLink(this.sharedContentId),
      'Link copied to clipboard!',
      `Link to ${this.title}`
    )
  }

  duplicate = async () => {
    try {
      const saveData = {
        title: `Copy of ${this.title}`,
        content: this.content,
        groupIds: getIntersection(this.groupIds, sharedDataStore.user.groupIds) || [],
        thumbnailUrl: this.thumbnailUrl,
        expiryDate: this.expiryDate ? this.expiryDate.toISOString() : undefined,
      }
      const duplicatedDealRoom = new DealRoom()
      await duplicatedDealRoom.save(saveData)
      sharedAppStateStore.navigate(Paths.getDealRoomPath({ id: duplicatedDealRoom.id, isAuthoring: true }))
    } catch (error) {
      sharedAppStateStore.handleError(error)
    }
  }

  canCreateTemplate = () =>
    sharedDataStore.user.isFaasAdminOrManager() && !!this.id && !!this.author?.authorID && !this.templateId
}
