import { DataObject } from 'src/models/DataObject'
import { User } from 'src/models/User'
import moment from 'moment'
import { observable, makeObservable } from 'mobx'

export class StudentAnswer extends DataObject {
  static OVERRIDE_MAPPINGS = {
    gradedBy: (data) => (data.gradedBy ? User.fromData(data.gradedBy) : undefined),
  }
  id: string
  assignmentSubmissionId: string
  questionId: string
  @observable answer: Array<string>
  @observable score?: number
  @observable commentText: string
  rubricGrading: { rubricId: string; points: string[] }
  gradedBy?: User
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  constructor() {
    super()
    makeObservable(this)
  }

  updateComment(comment: string) {
    this.commentText = comment
  }
}
