import React from 'react'
import { ContainerDiv } from 'src/components/Common'
import { LibraryCardHero } from 'src/components/Library/LibraryCardHero'
import { LibraryCardOverlay } from 'src/components/Library/LibraryCardOverlay'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedTask } from 'src/models/FeedTask'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { QueueItem } from 'src/models/QueueItem'
import { ViewMode } from 'src/store/DataStore'
import { LearningContent, getFeedContentTitle } from 'src/utils/content'

export interface FeedContentImageCardProps {
  obj: LearningContent | Hub | Page | FeedTask | QueueItem | CoachingMoment
  compressView?: boolean
  width?: string
  border?: string
  viewMode?: ViewMode
  borderRadius?: string
}

export const FeedContentImageCard: React.FC<FeedContentImageCardProps> = ({
  obj,
  width = '100%',
  compressView = false,
  viewMode = 'card',
  border,
  borderRadius = '6px',
}) => {
  const isQueueItem = obj instanceof QueueItem
  const targetObj = isQueueItem ? obj.linkedObject : obj
  const isHub = targetObj instanceof Hub

  let imgSrc = targetObj.imgSrc()

  return (
    <ContainerDiv
      style={{
        overflow: 'hidden',
        width,
        minWidth: width,
        aspectRatio: '16/9',
        height: viewMode === 'list' ? '100%' : 'unset',
      }}
      display="flex"
      justifyContent="center"
      alignItems={isHub ? 'left' : 'center'}
      position="relative"
      flexDirection={isHub ? 'column' : 'row'}
      borderRadius={borderRadius}
    >
      {imgSrc ? (
        <img
          src={imgSrc}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          alt={getFeedContentTitle(targetObj)}
        />
      ) : (
        <LibraryCardHero targetObj={targetObj} compressView={compressView} border={border} viewMode={viewMode} />
      )}
      <LibraryCardOverlay obj={obj} viewMode={viewMode} compressView={compressView} />
    </ContainerDiv>
  )
}
