import React from 'react'
import { Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'
import { FjText } from './Text'
import { BasicProps } from 'antd/lib/layout/layout'
import { MarginProps } from './Styles'
import { ContainerDiv } from './Cards'
import { sharedDataStore } from 'src/store/DataStore'
import { Colors } from 'src/constants/colors'

interface FjTagProps extends TagProps {
  optionKey?: string
  text: string
  closable?: boolean
  round?: boolean
  onTagClose?(key: string, value: string): void
}

export const FjTag: React.FC<FjTagProps> = ({
  closable,
  optionKey,
  onTagClose,
  text,
  round,
  style,
  ...props
}: FjTagProps) => {
  return (
    <Tag
      style={{ marginTop: '5px', borderRadius: round ? '30px' : 'unset', ...style }}
      closable={closable}
      onClose={() => onTagClose(optionKey, text)}
      {...props}
    >
      {text}
    </Tag>
  )
}

FjTag.defaultProps = {
  closable: true,
}

interface StageTagProps extends MarginProps {
  interviewStage: string
}

const STAGE_COLORS_IN_ORDER = ['blue', 'geekblue', 'orange', 'volcano']

export const StageTag: React.FC<StageTagProps & BasicProps> = ({ interviewStage, ...props }: StageTagProps) => {
  const stageKeys = Array.from(sharedDataStore.config.interviewStage.keys())
  return (
    <FjText {...props}>
      <FjTag
        closable={false}
        style={{ background: 'unset', borderRadius: '5px' }}
        color={STAGE_COLORS_IN_ORDER[stageKeys.indexOf(interviewStage)]}
        text={sharedDataStore.config.interviewStage.get(interviewStage)}
      />
    </FjText>
  )
}

export interface IFJGenericTagProps extends MarginProps {
  status: string
  statusOptions: string[]
  colorsInOrder: string[]
}

export const FjGenericTag: React.FC<IFJGenericTagProps & BasicProps> = ({
  status,
  statusOptions,
  colorsInOrder,
  ...props
}) => {
  return (
    <FjText fontSize="medium" {...props}>
      <FjTag
        round
        closable={false}
        style={{ background: 'unset' }}
        color={colorsInOrder[statusOptions.indexOf(status)]}
        text={status}
      />
    </FjText>
  )
}

interface IFjCustomTagProps {
  color: string
  text: string
  Icon: JSX.Element
}

export const FjCustomTag: React.FC<IFjCustomTagProps> = ({ color, text, Icon }) => {
  return (
    <ContainerDiv
      padding="0 6px 2px 6px"
      border={`1px solid ${color}`}
      style={{ borderRadius: '2px' }}
      display="inline"
      backgroundColor={`${color}1F`}
    >
      <FjText color={color} fontWeight="bold" fontSize="12px">
        {Icon}
      </FjText>
      <FjText color={Colors.black} fontSize="12px">
        {` ${text}`}
      </FjText>
    </ContainerDiv>
  )
}
