import React, { useEffect, useRef, useState } from 'react'
import {
  ContainerDiv,
  DefaultButton,
  FJStyledAutoComplete,
  FJStyledInput,
  FjFormItem,
  FormHeaderText,
} from 'src/components/Common'
import { Edit3 } from 'react-feather'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Form, Formik, FormikProps } from 'formik'
import { Rubric } from 'src/models/Rubric'
import { DefaultOptionType } from 'antd/lib/select'
import { isRequired } from 'src/utils/validation'

export type RubricFormProps = {
  initialRubricValue?: { id: string; title: string }
  onRubricSelected: (rubricInfo: { id: string; title: string }) => void
}

export const RubricForm = ({ initialRubricValue, onRubricSelected }: RubricFormProps) => {
  const [rubrics, setRubrics] = useState<Rubric[]>([])
  const [rubricOptions, setRubricOptions] = useState<DefaultOptionType[]>([])
  const rubricFormRef = useRef<FormikProps<any>>()

  const getRubricList = async () => {
    const { data } = await Rubric.list()
    setRubrics(data)
    setRubricOptions(data.map((rubric) => ({ ...rubric, label: rubric.title, value: rubric.id })))
  }

  useEffect(() => {
    getRubricList()
  }, [])

  const onRubricAuthoringSuccess = (rubric: Rubric) => {
    sharedAppStateStore.rubricAuthoringFormModalProps = undefined
    getRubricList()
    rubricFormRef.current.setFieldValue('rubric', { id: rubric.id, title: rubric.title })
  }

  const handleRubricCreate = () => {
    sharedAppStateStore.rubricAuthoringFormModalProps = { onSaveSuccess: onRubricAuthoringSuccess }
  }

  const handleRubricEdit = (rubricId) => {
    const onRubricDelete = () => {
      sharedAppStateStore.rubricAuthoringFormModalProps = undefined
      getRubricList()
      rubricFormRef.current.setFieldValue('rubric', { id: undefined, title: undefined })
    }

    const selectedRubric = rubrics.find((rubric) => rubric.id === rubricId)
    sharedAppStateStore.rubricAuthoringFormModalProps = {
      rubric: selectedRubric,
      onSaveSuccess: onRubricAuthoringSuccess,
      onDeleteSuccess: onRubricDelete,
    }
  }

  const handleRubricSearch = (keyword: string) => {
    setRubricOptions(
      rubrics
        .filter((rubric) => `${rubric.title}`.toLowerCase().includes(keyword.toLowerCase()))
        .map((rubric) => ({ ...rubric, label: rubric.title, value: rubric.id }))
    )
  }

  const handleSubmit = (data: { rubric: { id: string; title: string } }) => {
    onRubricSelected(data.rubric)
    sharedAppStateStore.rubricFormModalProps = undefined
  }

  return (
    <ContainerDiv>
      <FormHeaderText heading="Rubric" />
      <Formik
        innerRef={rubricFormRef}
        initialValues={{ rubric: initialRubricValue ?? { id: undefined, title: undefined } }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FjFormItem fieldtitle="Rubric*" name="rubric.title" validate={isRequired}>
              <ContainerDiv textAlign="left" display="flex" justifyContent="space-between" gap="10px">
                <FJStyledAutoComplete
                  name="rubric.title"
                  placeholder="Rubric"
                  allowClear
                  onSearch={handleRubricSearch}
                  onSelect={(_value: any, option: any) =>
                    setFieldValue('rubric', {
                      id: option.id,
                      title: option.label,
                    })
                  }
                  onClear={() => setFieldValue('rubric', { id: undefined, title: undefined })}
                  options={rubricOptions}
                >
                  <FJStyledInput name="rubric.titleInput" />
                </FJStyledAutoComplete>
                <DefaultButton
                  style={{ minWidth: '80px' }}
                  disabled={!values.rubric.id}
                  buttonType="tertiary"
                  title="Edit"
                  clicked={() => handleRubricEdit(values.rubric.id)}
                />
              </ContainerDiv>
            </FjFormItem>
            <ContainerDiv display="flex" justifyContent="space-between">
              <DefaultButton
                buttonType="primary"
                image={<Edit3 size={16} />}
                title="Create a Rubric"
                clicked={handleRubricCreate}
              />
              <DefaultButton type="submit" buttonType="primary" title="Save" />
            </ContainerDiv>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  )
}
