import styled from 'styled-components'
import {
  MarginProps,
  getMarginTop,
  getMarginRight,
  getMarginBottom,
  getMarginLeft,
  PaddingProps,
  getPaddingTop,
  getPaddingRight,
  getPaddingBottom,
  getPaddingLeft,
} from 'src/components/Common/Styles'

enum Layout {
  default = 'none',
  relative = 'relative',
  absolute = 'absolute',
}

interface IconProps {
  fill?: string
  backgroundColor?: string
  color?: string
  filter?: string
  display?: string
  float?: string
  top?: string
  left?: string
  bottom?: string
  right?: string
  position?: string
  align?: string
  borderRadius?: string
  cursor?: string
}

export const Icon = styled.img<MarginProps & IconProps & PaddingProps>`
  align-items: flex-end;
  margin-right: ${(props) => getMarginRight(props)}
  margin-left: ${(props) => getMarginLeft(props)}
  margin-top: ${(props) => getMarginTop(props)}
  margin-bottom: ${(props) => getMarginBottom(props)}
  padding-right: ${(props) => getPaddingRight(props)}
  paddingleft: ${(props) => getPaddingLeft(props)}
  padding-top: ${(props) => getPaddingTop(props)}
  padding-bottom: ${(props) => getPaddingBottom(props)}
  position: ${(props) => (props.position ? props.position : Layout.default)}
  top: ${(props) => (props.top ? props.top : '0px')}
  right: ${(props) => (props.right ? props.right : '0px')}
  bottom: ${(props) => (props.bottom ? props.bottom : '0px')}
  left: ${(props) => (props.left ? props.left : '0px')}
  float: ${(props) => (props.float ? props.float : 'none')}
  filter: ${(props) => (props.filter ? props.filter : 'none')}
  align: ${(props) => (props.align ? props.align : 'none')}
  filter: ${(props) => (props.filter ? props.filter : 'none')}
  cursor: ${(props) => (props.cursor ? props.cursor : 'unset')}
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : 'none')}
  `
