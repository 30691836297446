import React, { Component } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import {
  ContainerDiv,
  FjEmailInput,
  FjFormItem,
  FjInput,
  FormActionButtons,
  FormHeaderText,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { combineValidations, isEmail, isMaxLength, isRequired } from 'src/utils/validation'
import { Course } from 'src/models/Course'
import { Col, Row } from 'antd'
import { User } from 'src/models/User'
import { showNotification } from 'src/hoc/Notification'

export interface IShareCourseFormProps {
  onSuccess: () => void
  onCancel: () => void
  course: Course
}

export class ShareCourseForm extends Component<IShareCourseFormProps> {
  getInitialValues = () => ({
    fullName: undefined,
    email: undefined,
    accessRole: 'public',
  })

  handleSubmit = async (data: any) => {
    try {
      const { course } = this.props
      const user = new User()
      await user.save({ ...data })
      await course.post('share_publicly', { userId: user.id })
      const url = `${window.location.href}?refreshToken=${user.sessionToken.refreshToken}`
      navigator.clipboard.writeText(url)
      showNotification({ message: `${course.name} has been shared with ${user.getFirstName()}` })
      this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading={`Share "${this.props.course.title}" Externally`} />
        <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit}>
          <Form>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <FjFormItem
                  name="fullName"
                  fieldtitle="Full Name"
                  validate={combineValidations(isRequired, isMaxLength(128))}
                >
                  <FjInput name="fullName" placeholder="Full Name" />
                </FjFormItem>
              </Col>
              <Col xs={24} md={12}>
                <FjFormItem name="email" fieldtitle="Email" validate={combineValidations(isRequired, isEmail)}>
                  <FjEmailInput name="email" />
                </FjFormItem>
              </Col>
            </Row>
            <FormActionButtons submitButtonLabel="Share" />
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
