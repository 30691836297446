import React, { Component, useCallback, useMemo, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { Form } from 'formik-antd'
import { Alert, Col, Row, Tabs } from 'antd'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Asset } from 'src/models/Asset'
import { SharedContent } from 'src/models/SharedContent'
import { DealRoom } from 'src/models/DealRoom'
import {
  ContainerDiv,
  DefaultButton,
  FjCheckBox,
  FjDatePicker,
  FjFormItem,
  FjInput,
  FjText,
  FormActionButtons,
  FormHeaderText,
  Loader,
} from 'src/components/Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import moment from 'moment'
import { Copy, Edit } from 'react-feather'
import { combineValidations, isMaxLength, isRequired } from 'src/utils/validation'
import { SfMetadataDisplay, SfMetadataFormFields } from 'src/components/Feed/SfMetadataFormFields'
import { SharedContentTable } from 'src/components/Feed/SharedContentTable'
import { copyToClipboard } from 'src/utils/format'
import { showNotification } from 'src/hoc/Notification'
import { Colors } from 'src/constants/colors'

interface ISharedContentViewProps {
  sharedContent: SharedContent
  editClicked: () => void
}

const SharedContentView: React.FC<ISharedContentViewProps> = ({ sharedContent, editClicked }) => {
  const renderCopyableField = (
    title: string,
    text: string,
    displayText?: string | React.ReactNode,
    copyConfirmationText?: string,
    copyModalTitle?: string
  ) => {
    return (
      <Alert
        message={
          <ContainerDiv justifyContent="space-between" display="flex" gap="5px" flexGrow={1}>
            <ContainerDiv display="flex" flexDirection="column" textAlign="left">
              <FjText display="block" textAlign="left" fontWeight="semi-bold">
                {title}
              </FjText>
              {displayText || text}
            </ContainerDiv>
            <DefaultButton
              image={<Copy size={16} />}
              clicked={() => copyToClipboard(text, copyConfirmationText, copyModalTitle)}
            />
          </ContainerDiv>
        }
      />
    )
  }
  return (
    <ContainerDiv>
      <ContainerDiv display="flex" justifyContent="space-between" alignItems="center">
        <ContainerDiv display="flex" flexDirection="column">
          {sharedContent.sfMetadata && <SfMetadataDisplay obj={sharedContent} marginBottom="1px" />}
          <FjText fontSize="small" color={Colors.tapa} display="block" textAlign="left">
            Expires: {sharedContent.expiryDate ? sharedContent.expiryDate.format('MMM D, YYYY') : 'Never'}
          </FjText>
        </ContainerDiv>
        <DefaultButton image={<Edit size={18} />} size="small" clicked={editClicked} />
      </ContainerDiv>
      <Row align="middle" gutter={[10, 10]} style={{ marginTop: '10px' }}>
        <Col flex="auto">
          {renderCopyableField(
            'Link URL',
            sharedContent.getShareLink(),
            undefined,
            'Link copied to clipboard!',
            `${sharedContent.title} Link`
          )}
        </Col>
        {sharedContent.password && (
          <Col flex="auto">
            {renderCopyableField(
              'Password',
              sharedContent.password,
              '••••••••',
              'Password copied to clipboard',
              `${sharedContent.title} Password`
            )}
          </Col>
        )}
      </Row>
    </ContainerDiv>
  )
}

interface ISharedContentFormProps {
  obj: Asset | DealRoom | SharedContent
  sharedContentSaved: (sharedContent: SharedContent) => void
}

const SharedContentForm: React.FC<ISharedContentFormProps> = ({ obj, sharedContentSaved }) => {
  const [showLinkField, setShowLinkField] = useState<boolean>(obj instanceof SharedContent)

  const formikRef = useRef<FormikProps<any>>()

  const contentObject = useMemo<Asset | DealRoom>(() => {
    if (obj instanceof SharedContent) return obj.learningContent
    return obj
  }, [obj])
  const sfMetadataFormFieldsExpanded = useMemo<boolean>(() => !!obj?.sfMetadata, [obj])
  const shareLink = useMemo(
    () => (obj instanceof SharedContent ? obj.getShareLink() : SharedContent.generateShareLink()),
    [obj]
  )
  const initialValues = useMemo(() => {
    return {
      id: obj instanceof SharedContent ? obj.id : new URL(shareLink).searchParams.get('linkId'),
      title: obj instanceof Asset ? undefined : obj.title,
      learningContent: {
        contentType: contentObject.getTagTitle().replace(' ', '').toLowerCase(),
        objectId: contentObject.id,
      },
      sfMetadata: obj.sfMetadata?.formatMetadataForSave(),
      password: obj instanceof SharedContent ? obj.password : undefined,
      requireViewerInfo: obj instanceof SharedContent ? obj.requireViewerInfo : undefined,
      expiryDate: obj instanceof SharedContent ? obj.expiryDate : moment().add(30, 'days'),
    }
  }, [obj, contentObject, shareLink])
  const submitButtonLabel = useMemo(() => {
    return obj instanceof SharedContent ? 'Update Link' : 'Create Link'
  }, [obj])

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const sharedContent = obj instanceof SharedContent ? obj : new SharedContent()
        const { data: resData } = await sharedContent.save({
          ...data,
          password: data['password'] ? data['password'] : null,
          expiryDate: data['expiryDate'] ? data['expiryDate'].toISOString() : null,
        })
        setShowLinkField(true)
        sharedContentSaved(SharedContent.fromData(resData))
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    },
    [obj, sharedContentSaved]
  )

  return (
    <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {showLinkField && (
          <FjFormItem fieldtitle="Link" name="link">
            <ContainerDiv display="flex" justifyContent="space-between" gap="5px">
              <FjInput value={shareLink} name="link" disabled style={{ cursor: 'default' }} />
              <DefaultButton
                image={<Copy size={16} />}
                buttonType="primary"
                clicked={() => copyToClipboard(shareLink, undefined)}
              />
            </ContainerDiv>
          </FjFormItem>
        )}
        <FjFormItem
          fieldtitle="Link Name"
          name="title"
          validate={combineValidations(isRequired, isMaxLength(255))}
          style={{ display: contentObject instanceof DealRoom ? 'none' : 'block' }}
        >
          <FjInput name="title" placeholder="Link Name" />
        </FjFormItem>
        <Row align="middle" gutter={[10, 10]}>
          <Col xs={24} md={12}>
            <FjFormItem fieldtitle="Expiry Date" name="expiryDate">
              <FjDatePicker
                allowClear={false}
                disabledDate={(date) => date < moment().endOf('day').subtract(1, 'day')}
                name="expiryDate"
                style={{ width: '100%' }}
              />
            </FjFormItem>
          </Col>
          <Col xs={24} md={12}>
            <FjFormItem fieldtitle="Password (Optional)" name="password">
              <FjInput name="password" type="password" placeholder="Password (Optional)" />
            </FjFormItem>
          </Col>
        </Row>
        <FjFormItem name="requireViewerInfo">
          <ContainerDiv display="flex" alignItems="center" gap="10px">
            <FjCheckBox name="requireViewerInfo" />
            <FjText>Require name and email to view content</FjText>
          </ContainerDiv>
        </FjFormItem>
        <SfMetadataFormFields
          expanded={sfMetadataFormFieldsExpanded}
          handleSelect={(_, option) => {
            formikRef.current.setFieldValue('sfMetadata', {
              id: option.id,
              name: option.name,
              stageName: option.stageName,
            })
          }}
          handleClear={() => formikRef.current.setFieldValue('sfMetadata', null)}
        />
        <FormActionButtons submitButtonLabel={submitButtonLabel} />
      </Form>
    </Formik>
  )
}

export interface IEditSharedContentModalProps {
  onSuccess?: (sharedContent: SharedContent) => void
  onCancel: () => void
  obj: DealRoom | SharedContent
  mode?: 'view' | 'edit'
}

export const EditSharedContentModal: React.FC<IEditSharedContentModalProps> = ({ obj, onSuccess, mode = 'edit' }) => {
  const [viewMode, setViewMode] = useState<'view' | 'edit'>(mode)
  const heading = useMemo(() => {
    const isSharedContent = obj instanceof SharedContent
    if (viewMode === 'view') return `${isSharedContent && 'Shareable Link: '}${obj.title}`
    return isSharedContent ? `Edit Link: ${obj.title}` : 'Create a Shareable Link'
  }, [obj, viewMode])

  const sharedContentSaved = useCallback(
    (sharedContent: SharedContent) => {
      setViewMode('view')
      onSuccess?.(sharedContent)
    },
    [onSuccess, setViewMode]
  )

  return (
    <ContainerDiv>
      <FormHeaderText heading={heading} />
      {viewMode === 'view' && obj instanceof SharedContent ? (
        <SharedContentView sharedContent={obj} editClicked={() => setViewMode('edit')} />
      ) : (
        <SharedContentForm obj={obj} sharedContentSaved={sharedContentSaved} />
      )}
    </ContainerDiv>
  )
}

export interface ISharedContentModalProps {
  onSuccess: () => void
  onCancel: () => void
  asset: Asset
  initialTab?: 'createNewLink' | 'existingLinks'
}

@observer
export class SharedContentModal extends Component<ISharedContentModalProps> {
  @observable isLoading = true
  @observable existingLinks: SharedContent[] = []

  constructor(props: ISharedContentModalProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    try {
      this.isLoading = true
      this.existingLinks = await SharedContent.list({ learning_content_object_id: this.props.asset.id })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }

  sharedContentSaved = (sharedContent: SharedContent) => {
    if (sharedContent.learningContent instanceof DealRoom) return
    const index = this.existingLinks.findIndex((c) => c.id === sharedContent.id)
    if (index === -1) {
      this.existingLinks = [sharedContent, ...this.existingLinks]
      copyToClipboard(
        sharedContent.getShareLink(),
        'Link created and copied to clipboard!',
        `Link to ${sharedContent.title}`
      )
    } else {
      const updatedLinks = [...this.existingLinks]
      updatedLinks[index] = sharedContent
      this.existingLinks = updatedLinks
      showNotification({ message: 'Link saved!' })
    }
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading={`Share "${this.props.asset.title}" Externally`} />
        {this.isLoading ? (
          <Loader />
        ) : (
          <Tabs
            defaultActiveKey={this.props.initialTab || 'createNewLink'}
            items={[
              {
                key: 'createNewLink',
                label: 'Create New Link',
                children: <SharedContentForm obj={this.props.asset} sharedContentSaved={this.sharedContentSaved} />,
              },
              {
                key: 'existingLinks',
                label: `Existing Links (${this.existingLinks.length})`,
                children: (
                  <SharedContentTable existingLinks={this.existingLinks} sharedContentSaved={this.sharedContentSaved} />
                ),
              },
            ]}
          />
        )}
      </ContainerDiv>
    )
  }
}
