import React, { FC } from 'react'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { Colors } from 'src/constants/colors'

interface DefaultSelectProps extends SelectProps<any> {
  error?: boolean
}

export const DefaultSelect: FC<DefaultSelectProps> = (props: DefaultSelectProps) => {
  return (
    <Select
      {...props}
      size="large"
      style={{
        ...props.style,
        color: Colors.outerSpace,
        backgroundColor: Colors.white,
        border: `1px solid ${props.error ? Colors.scarlet : Colors.wildSand}`,
        borderRadius: '5px',
        fontSize: '14px',
        outline: 'none',
      }}
    />
  )
}

export const { Option } = Select
