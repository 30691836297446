import React from 'react'
import { DealRoom } from 'src/models/DealRoom'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { SharedContent } from 'src/models/SharedContent'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import { ContainerDiv, FormHeaderText } from 'src/components/Common'
import { AnalyticsSummaryCard } from 'src/components/Feed/AnalyticsSummaryCard'
import { formatDuration } from 'src/utils/format'
import { Col, Row } from 'antd'
import { ViewWrapper } from 'src/components/Common/ViewWrapper'
import { SharedContentSessionsTable } from 'src/components/Feed/SharedContentSessions'

export interface IDealRoomAnalyticsModalProps {
  dealRoomId?: string
  dealRoom?: DealRoom
}

@observer
export class DealRoomAnalyticsModal extends React.Component<IDealRoomAnalyticsModalProps> {
  @observable viewState: 'initialLoad' | 'loading' | 'error' | 'idle' = 'initialLoad'
  sharedContent: SharedContent
  dealRoomObj?: DealRoom

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    this.fetchSharedContent()
  }

  fetchSharedContent = async () => {
    try {
      this.viewState = 'loading'
      this.dealRoomObj = undefined
      const { dealRoom, dealRoomId } = this.props

      if (this.props.dealRoom) {
        this.dealRoomObj = dealRoom
      } else if (dealRoomId) {
        this.dealRoomObj = await DealRoom.get(dealRoomId)
      }

      if (this.dealRoomObj && this.dealRoomObj.sharedContentId) {
        this.sharedContent = await SharedContent.get(this.dealRoomObj.sharedContentId)
      } else {
        this.sharedContent = new SharedContent()
      }

      this.viewState = 'idle'
    } catch (err) {
      this.viewState = 'error'
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  render() {
    const { sharedContent } = this

    return (
      <ContainerDiv>
        <ViewWrapper viewState={this.viewState} customErrorUI="Failed to load analytics">
          {this.viewState === 'idle' ? (
            <>
              <FormHeaderText
                heading={
                  this.dealRoomObj
                    ? `External Analytics for "${this.dealRoomObj.title}"`
                    : 'Deal Room Analytics (No data)'
                }
              />
              <ContainerDiv display="flex" flexDirection="column" gap="20px">
                <Row justify="space-around">
                  <Col xs={24} md={8}>
                    <AnalyticsSummaryCard title="Total Views" body={sharedContent.viewCount} />
                  </Col>
                  <Col xs={24} md={8}>
                    <AnalyticsSummaryCard title="Total View Time" body={formatDuration(sharedContent.durationViewed)} />
                  </Col>
                </Row>
                <SharedContentSessionsTable sharedContent={sharedContent} />
              </ContainerDiv>
            </>
          ) : null}
        </ViewWrapper>
      </ContainerDiv>
    )
  }
}
