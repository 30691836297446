import { Company } from 'src/models/Company'
import { DataObject } from 'src/models/DataObject'
import { User } from 'src/models/User'
import { sharedDataStore } from 'src/store/DataStore'

export const ALL_PARTNERS_GROUP = 'All Partners'
export const ALL_PUBLIC_USERS_GROUP = 'All Public Users'

export class Group extends DataObject {
  static apiEndpoint: string = '/api/groups/'
  static readonly EXPORT_TO_CSV_FIELDS = 'author,created_at,active_users'
  static OVERRIDE_MAPPINGS = {
    key: (data) => data.id,
    company: (data) => (data.company ? Company.fromData(data.company) : undefined),
    users: (data) => (data.users ? User.fromData(data.users) : []),
  }

  key: string
  id: string
  name: string
  company: Company
  users: User[] = []
  activeUsersCount: number

  isAllCompanyGroup = () => {
    return this.id === sharedDataStore.user.company.defaultGroupId
  }

  isAllPartnersGroup = () => {
    return this.name === ALL_PARTNERS_GROUP
  }

  isAllPublicUsersGroup = () => {
    return this.name === ALL_PUBLIC_USERS_GROUP
  }
}
