import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { DefaultButton } from 'src/components/Common'
import { Modal } from 'antd'

@observer
export class ErrorBoundry extends Component<React.PropsWithChildren> {
  componentDidCatch(error: Error) {
    sharedAppStateStore.handleError(error)
  }

  render() {
    return (
      <>
        <Modal
          title="Oops! Something went wrong!"
          open={!!sharedAppStateStore.errMsg}
          footer={[
            <DefaultButton
              key="dismiss"
              clicked={sharedAppStateStore.clearError}
              title="Dismiss"
              buttonType="primary"
            />,
          ]}
          onCancel={sharedAppStateStore.clearError}
          zIndex={10000}
        >
          {sharedAppStateStore.errMsg}
        </Modal>
        {this.props.children}
      </>
    )
  }
}
