import React, { lazy, Suspense, useMemo, useState } from 'react'
import moment from 'moment'
import { Row, Col, Tooltip, Alert } from 'antd'
import { Formik, FormikProps } from 'formik'
import { Form } from 'formik-antd'
import { Edit3, Trash, ArrowRight, Link, AlertCircle } from 'react-feather'
import {
  FjFormItem,
  SmallFormItem,
  FjInput,
  FormHeaderText,
  Loader,
  FormActionButtons,
  FjRadioGroup,
  FjRadioButton,
  DefaultButton,
  FjSelect,
  ContainerDiv,
  FjCheckBox,
  FjRadio,
  FjTextArea,
  FjDragUploader,
  FjDatePicker,
  FjMemoFormItem,
  FJStyledAutoComplete,
  FJStyledInput,
  FjText,
} from 'src/components/Common'
import { Submodule, SubmoduleType } from 'src/models/Submodule'
import {
  isRequired,
  combineValidations,
  isMaxLength,
  maxFileSize,
  isFile,
  acceptedFileTypes,
  isZoomURI,
  isNumber,
  isMinValue,
  isMaxValue,
  isInteger,
} from 'src/utils/validation'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Colors } from 'src/constants/colors'
import { RadioChangeEvent } from 'antd/lib/radio'
import { Assignment } from 'src/models/Assignment'
import { Question, QUESTION_TYPE_OPTIONS_MAP } from 'src/models/Question'
import { LiveSession } from 'src/models/LiveSession'
import { deepEquals } from 'src/utils/format'
import { uploadToS3, S3_SCORM_BUCKET } from 'src/utils/S3Upload'
import { DefaultOptionType } from 'antd/lib/select'
import { User } from 'src/models/User'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { Rubric } from 'src/models/Rubric'
import { sharedDataStore } from 'src/store/DataStore'
import { Author } from 'src/models/Author'

const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

const MIN_OPTION_COUNT = 2

const MIN_QUESTION_COUNT = 1

const MAX_TITLE_LENGTH = 255

/**
 * Form Question Object Structures
  import { QuestionType, QuestionGradeType } from 'src/models/Question'
  
  FormQuestionOptionType = {
    isCorrect: boolean
    value?: string
  }

  MatchingQuestionOptionType = {
    rightOption?: string
    leftOption?: string
  }

  FormQuestionType = {
    id: string
    question: string
    questionType: QuestionType
    formOptions: (FormQuestionOptionType | MatchingQuestionOptionType)[]
    gradeType: QuestionGradeType
  }

  FormAssignment = {
    questions: FormQuestionType[]
  }
 */

// use function here instead of constant so a new object is created instead of shallow copying
const generateFormOption = (question: Question = new Question(), optionIndex: number = -1) => {
  if (question.questionType === 'matching')
    return { leftOption: question.options[optionIndex], rightOption: question.correctAnswer[optionIndex] }
  return {
    isCorrect: question.correctAnswer.includes(`${optionIndex}`),
    value: question.options[optionIndex],
  }
}

const generateFormOptions = (question: Question) => {
  const options = question.options.length ? question.options : [...Array(3).keys()].map(() => undefined)
  return options.map((_, i) => generateFormOption(question, i))
}

// use function here instead of constant so a new object is created instead of shallow copying
const generateFormQuestion = (question: Question = new Question()) => ({
  id: question.id,
  question: question.question,
  questionType: question.questionType,
  formOptions: generateFormOptions(question),
  gradeType: question.gradeType,
  rubricId: question.rubric?.id,
  rubricTitle: question.rubric?.title,
})

const validateFormOptionValues = (option: any, questionData: any) => {
  if (questionData.questionType === 'matching') return !!option.leftOption && !!option.rightOption
  return !!option.value // single/multi_select
}

const validateFormOptionAnswers = (option: any, questionData: any) => {
  if (['multi_select', 'single_select'].includes(questionData.questionType)) return !!option.isCorrect
  return true
}

const validateQuestion = (val: string, questionData: any) => {
  if (questionData.questionType === 'long_text' || questionData.questionType === 'short_text') return

  const msg = isRequired(val)
  if (msg) return msg
  if (!questionData.formOptions.every((op) => validateFormOptionValues(op, questionData)))
    return 'All options must have a value'

  if (!questionData.formOptions.some((op) => validateFormOptionAnswers(op, questionData))) {
    const answerCopy = questionData.questionType === 'single_select' ? 'a' : 'at least 1'
    return `Please select ${answerCopy} correct answer`
  }
}

const validateGraders = (ids: string[]) => {
  if (ids && ids.length > 0) return
  return 'Please select 1 grader'
}

interface AssignReviewerFieldProps {
  formikProps: FormikProps<any>
}

const AssignReviewerField: React.FC<AssignReviewerFieldProps> = ({ formikProps: { values, setValues } }) => {
  const assignmentGradingTypeOptionsMap = new Map<string, string>([
    ['admin_and_manager', 'Assign Review to Any Admin/Manager'],
    ['users_manager', "Assign Review to Learner's Manager"],
    ['assigned_user', 'Assign Specific Reviewer(s)'],
  ])

  const [userOptions, setUserOptions] = useState([])
  const [userListLoading, setUserListLoading] = useState(false)

  const fetchUserList = async (search = '') => {
    try {
      setUserListLoading(true)
      const { data: users } = await User.list({
        is_active: true,
        search,
        access_role: 'standard,admin,manager,partner',
      })
      setUserOptions(
        users
          .filter((user) => !values?.graders?.find((grader) => grader.id === user.id))
          .map((user) => ({ value: user.id, label: user.fullName }))
      )
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      setUserListLoading(false)
    }
  }

  const onUserSearch = (value: string) => {
    fetchUserList(value)
  }

  const onUserSelect = (value: string, option: DefaultOptionType) => {
    setUserOptions(userOptions.filter((userOption) => userOption.value !== option.value))
    setValues({
      ...values,
      graders: [...values.graders, Author.fromData({ id: option.value, fullName: option.label })],
      userSearch: '',
    })
  }

  const onFocus = () => {
    if (!userOptions.length) fetchUserList()
  }

  const handleUserRemove = (u: User) => {
    const graders = values['graders'].filter((grader) => grader.id !== u.id)
    setValues({ ...values, graders })
    setUserOptions([...userOptions, { value: u.id, label: u.fullName }])
  }

  const assignmentGradingTypeChanged = (value: string) => {
    setValues({ ...values, assignmentGradingType: value })
  }

  return (
    <>
      <Row>
        <Col xs={24} lg={14} style={{ padding: '0px 5px' }}>
          <FjFormItem fieldtitle="Reviewer(s)*" name="assignmentGradingType" validate={isRequired}>
            <FjSelect
              name="assignmentGradingType"
              optionsMap={assignmentGradingTypeOptionsMap}
              placeholder="Select Reviewer(s)"
              onChange={assignmentGradingTypeChanged}
            />
          </FjFormItem>
        </Col>
        <Col xs={24} lg={10} style={{ padding: '0px 5px' }}>
          {values.assignmentGradingType === 'assigned_user' ? (
            <ContainerDiv display="flex" flexDirection="row" gap="10px" alignItems="center">
              <FjFormItem
                fieldtitle="Select User(s)"
                name="userSearch"
                validate={(val) => validateGraders(values?.graders)}
              >
                <FJStyledAutoComplete
                  name="userSearch"
                  options={userOptions}
                  onSearch={onUserSearch}
                  onSelect={onUserSelect}
                  onFocus={onFocus}
                  allowClear
                >
                  <FJStyledInput name="input" placeholder="Select User(s)" />
                </FJStyledAutoComplete>
              </FjFormItem>
              {userListLoading ? <Loader /> : null}
            </ContainerDiv>
          ) : null}
        </Col>
      </Row>
      {values.assignmentGradingType === 'assigned_user' ? (
        <Row>
          <Col xs={24} lg={14}>
            <ContainerDiv
              display="flex"
              flexDirection="column"
              gap="20px"
              width="100%"
              marginBottom="20px"
              padding="0px 10px"
            >
              {values?.graders?.map((user) => {
                return (
                  <ContainerDiv
                    key={user.id}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <AuthorDisplay author={user} avatarSize={32} />
                    <Trash
                      style={{ cursor: 'pointer' }}
                      color={Colors.burntSienna}
                      size={16}
                      onClick={() => handleUserRemove(user)}
                    />
                  </ContainerDiv>
                )
              })}
            </ContainerDiv>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

interface QuestionFormItemProps {
  index: number
  formikProps: FormikProps<any>
}

const QuestionFormItem: React.FC<QuestionFormItemProps> = ({ index, formikProps }) => {
  const { values, setValues } = formikProps

  const questionFormKey = `assignment.questions[${index}]`
  const {
    assignment: { questions },
  } = values

  const validateScoreType = (value) => {
    if (!value) return 'This field is required'
    if (value === 'rubric' && !questions[index].rubricId) return 'Please select rubric'
  }

  const gradeTypeOptionsMap = useMemo(() => {
    const rubricTitle = questions?.[index]?.rubricTitle
    return new Map<string, string>([
      ['binary', 'Correct/Incorrect'],
      ['senary', 'Scale of 0-5'],
      ['rubric', rubricTitle ? `Rubric - ${rubricTitle}` : 'Rubric'],
    ])
  }, [index, questions])

  // values from formikProps aren't necessarily kept up to date
  // so we need to update them manually in questionTypeChanged & radioChanged
  const questionTypeChanged = (value: string) => {
    const questions = [...values.assignment.questions]
    questions[index].questionType = value

    // can only be worth 1 point otherwise we can't autograde
    if (['single_select', 'multi_select', 'matching'].includes(value)) questions[index].gradeType = 'binary'

    // map leftOption => value and auto select first answer as correct
    if (['multi_select', 'single_select'].includes(value)) {
      questions[index].formOptions = questions[index].formOptions.map((op, i) => {
        if ('leftOption' in op) return { isCorrect: i === 0, value: op.leftOption }
        return op
      })
    }

    // if moving to single select and multiple options are selected, only select the first one
    if (value === 'single_select') {
      let changed = false
      for (let option of questions[index].formOptions) {
        if (option.isCorrect && !changed) changed = true
        else option.isCorrect = false
      }
    }
    // value => prompt
    if (value === 'matching') {
      questions[index].formOptions = questions[index].formOptions.map((op) => ({
        leftOption: op.value,
        rightOption: undefined,
      }))
    }

    setValues({ ...values, assignment: { ...values.assignment, questions } })
  }

  const gradeTypeCleared = () => {
    const questions = [...values.assignment.questions]
    questions[index].gradeType = undefined
    questions[index].rubricId = undefined
    questions[index].rubricTitle = undefined

    setValues({ ...values, assignment: { ...values.assignment, questions } })
  }

  const gradeTypeChanged = (value: string) => {
    const questions = [...values.assignment.questions]
    questions[index].gradeType = value
    if (value !== 'rubric') {
      questions[index].rubricId = null
      questions[index].rubricTitle = null
    }
    setValues({ ...values, assignment: { ...values.assignment, questions } })
  }

  const radioChanged = (optionIndex: number) => {
    return (e: RadioChangeEvent) => {
      const questions = [...values.assignment.questions]
      const question = questions[index]
      for (let i = 0; i < question.formOptions.length; i++) {
        question.formOptions[i].isCorrect = optionIndex === i
      }
      setValues({ ...values, assignment: { ...values.assignment, questions } })
    }
  }

  const onRubricSelected = (rubric: Rubric) => {
    const questions = [...values.assignment.questions]
    questions[index].rubricId = rubric.id
    questions[index].rubricTitle = rubric.title
    setValues({
      ...values,
      assignment: { ...values.assignment, questions },
    })
  }

  const openRubricModal = () => {
    sharedAppStateStore.rubricFormModalProps = {
      initialRubricValue: {
        id: questions[index].rubricId,
        title: questions[index].rubricTitle,
      },
      onRubricSelected,
    }
  }

  const deleteQuestion = (questionIndex: number) => {
    return () => {
      const questions = [...values.assignment.questions]
      questions.splice(questionIndex, 1)
      setValues({ ...values, assignment: { ...values.assignment, questions } })
    }
  }

  const addOption = () => {
    const questions = [...values.assignment.questions]
    const question = questions[index]
    question.formOptions.push(generateFormOption())
    setValues({ ...values, assignment: { ...values.assignment, questions } })
  }

  const removeOption = (optionIndex: number) => {
    const questions = [...values.assignment.questions]
    const question = questions[index]
    question.formOptions.splice(optionIndex, 1)
    questions[index] = question
    setValues({ ...values, assignment: { ...values.assignment, questions } })
  }

  return (
    <>
      <div
        style={{
          borderRadius: '10px',
          padding: '10px 0px 5px 0px',
          margin: '5px 0px',
        }}
      >
        <FjFormItem name={`${questionFormKey}.id`} style={{ display: 'none' }}>
          <FjInput name={`${questionFormKey}.id`} />
        </FjFormItem>
        <FjFormItem
          name={`${questionFormKey}.question`}
          className="description-editor"
          validate={(val) => validateQuestion(val, questions[index])}
          fieldtitle={`Question #${index + 1}`}
        >
          <Suspense fallback={<Loader />}>
            <FJCKEditor name={`${questionFormKey}.question`} placeholder="Question" showRecordVideoBtn />
          </Suspense>
        </FjFormItem>
        <Row gutter={[10, 10]}>
          <Col xs={12} lg={12}>
            <FjFormItem fieldtitle="Question Type" name={`${questionFormKey}.questionType`}>
              <FjSelect
                name={`${questionFormKey}.questionType`}
                optionsMap={QUESTION_TYPE_OPTIONS_MAP}
                placeholder="Question Type"
                onChange={questionTypeChanged}
              />
            </FjFormItem>
          </Col>
          <Col xs={10} lg={11}>
            <FjFormItem fieldtitle="Score Type" name={`${questionFormKey}.gradeType`} validate={validateScoreType}>
              <ContainerDiv
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                textAlign="left"
              >
                <FjSelect
                  disabled={['single_select', 'multi_select', 'matching'].includes(questions[index].questionType)}
                  name={`${questionFormKey}.gradeType`}
                  optionsMap={gradeTypeOptionsMap}
                  placeholder="Score"
                  onChange={gradeTypeChanged}
                  onClear={gradeTypeCleared}
                  allowClear
                  style={{ width: 'calc(100% - 25px)' }}
                />
                {questions[index].gradeType === 'rubric' ? (
                  <Tooltip title="Select Rubric">
                    <Edit3 color={Colors.cornflowerBlue} size={18} onClick={openRubricModal} />
                  </Tooltip>
                ) : null}
              </ContainerDiv>
            </FjFormItem>
          </Col>
          <Col xs={2} lg={1}>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>
              {values.assignment.questions.length !== MIN_QUESTION_COUNT ? (
                <Tooltip title="Delete Question">
                  <Trash color={Colors.burntSienna} size={18} onClick={deleteQuestion(index)} />
                </Tooltip>
              ) : null}
            </div>
          </Col>
        </Row>
        {questions[index].questionType === 'short_text' ? (
          <FjInput name={`short_text_${index}`} placeholder="Short answer text" disabled />
        ) : null}
        {questions[index].questionType === 'long_text' ? (
          <FjTextArea
            name={`long_text_${index}`}
            autoSize={{ minRows: 5, maxRows: 20 }}
            style={{ borderRadius: '6px' }}
            placeholder="Long answer text"
            disabled
          />
        ) : null}
        {questions[index].questionType === 'multi_select' || questions[index].questionType === 'single_select'
          ? questions[index]['formOptions'].map((op, i) => {
              const optionKey = `${questionFormKey}.formOptions[${i}]`
              const optionIsCorrectKey = `${optionKey}.isCorrect`
              const optionValueKey = `${optionKey}.value`
              return (
                <ContainerDiv display="flex" key={`${optionKey}-formOptions`}>
                  <div style={{ width: '30px', paddingTop: '3px' }}>
                    {questions[index].questionType === 'multi_select' ? (
                      <SmallFormItem name={optionIsCorrectKey} style={{ marginRight: '7px' }}>
                        <FjCheckBox name={optionIsCorrectKey} />
                      </SmallFormItem>
                    ) : (
                      <SmallFormItem name={optionIsCorrectKey}>
                        <FjRadio name={optionIsCorrectKey} checked={op.isCorrect} onChange={radioChanged(i)} />
                      </SmallFormItem>
                    )}
                  </div>
                  <SmallFormItem name={optionValueKey} style={{ flexDirection: 'row', flexGrow: 1 }}>
                    <FjInput name={optionValueKey} placeholder="Option" validate={isRequired} />
                  </SmallFormItem>
                  <div style={{ display: 'inline-block', minWidth: '23px' }}>
                    {questions[index].formOptions.length !== MIN_OPTION_COUNT ? (
                      <Trash
                        color={Colors.burntSienna}
                        size={20}
                        style={{ margin: '10px 0px 0px 7px' }}
                        onClick={() => removeOption(i)}
                      />
                    ) : null}
                  </div>
                </ContainerDiv>
              )
            })
          : null}
        {questions[index].questionType === 'matching'
          ? questions[index]['formOptions'].map((_, i: number) => {
              const optionKey = `${questionFormKey}.formOptions[${i}]`
              const leftOption = `${optionKey}.leftOption`
              const rightOption = `${optionKey}.rightOption`
              return (
                <ContainerDiv display="flex" gap="5px" key={`${optionKey}-formOptions`} alignItems="center">
                  <SmallFormItem name={leftOption} style={{ flexDirection: 'row', flexGrow: 1 }}>
                    <FjInput name={leftOption} placeholder="Prompt" validate={isRequired} />
                  </SmallFormItem>
                  <div style={{ display: 'inline-block' }}>
                    <ArrowRight size={25} />
                  </div>
                  <SmallFormItem name={rightOption} style={{ flexDirection: 'row', flexGrow: 1 }}>
                    <FjInput name={rightOption} placeholder="Answer" validate={isRequired} />
                  </SmallFormItem>
                  <div style={{ display: 'inline-block', minWidth: '23px' }}>
                    {questions[index].formOptions.length !== MIN_OPTION_COUNT ? (
                      <Trash
                        color={Colors.burntSienna}
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeOption(i)}
                      />
                    ) : null}
                  </div>
                </ContainerDiv>
              )
            })
          : null}
        {['multi_select', 'single_select', 'matching'].includes(questions[index].questionType) ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DefaultButton title="+ Add option" buttonType="text" clicked={addOption} />
          </div>
        ) : null}
      </div>
    </>
  )
}

interface SubmoduleTypeFormItemProps {
  submodule?: Submodule
  submoduleTypeChanged: (e: RadioChangeEvent) => void
}

const SubmoduleTypeFormItem: React.FC<SubmoduleTypeFormItemProps> = ({ submodule, submoduleTypeChanged }) => {
  if (submodule) return null
  return (
    <FjFormItem name="submoduleType">
      <FjRadioGroup name="submoduleType" onChange={submoduleTypeChanged}>
        <FjRadioButton value="content_html" name="submoduleType">
          Lesson
        </FjRadioButton>
        <FjRadioButton value="assignment" name="submoduleType">
          Assessment
        </FjRadioButton>
        <FjRadioButton value="content_scorm" name="submoduleType">
          SCORM
        </FjRadioButton>
        <FjRadioButton value="live_session" name="submoduleType">
          Live Session
        </FjRadioButton>
      </FjRadioGroup>
    </FjFormItem>
  )
}

interface SubmoduleFormProps {
  submodule?: Submodule
  onSave(submodule: Submodule): void
  submoduleFormDirty(dirty: boolean): void
}

@observer
export class SubmoduleForm extends React.Component<SubmoduleFormProps> {
  // Don't support content_scorm or live session right now but it made typing easier in this file
  @observable submoduleType: SubmoduleType = 'content_html'
  @observable saveButtonDisabled = false

  constructor(props: SubmoduleFormProps) {
    super(props)
    makeObservable(this)
    if (props.submodule) this.submoduleType = props.submodule.submoduleType
  }

  handleSubmit = async (data: any) => {
    // if in assignment mode, we have to save that first and then update the submodule accordingly
    try {
      this.saveButtonDisabled = true

      const submodule = this.props.submodule || new Submodule()
      let assignment: Assignment

      let submoduleSaveData = data

      if (this.submoduleType === 'assignment') {
        submoduleSaveData = { title: data.title, submodule_type: this.submoduleType }
        assignment = await this.saveAssignment(data)
        submoduleSaveData['assignment_id'] = assignment.id
      } else if (this.submoduleType === 'content_scorm') {
        let scormFile = submodule.scormFile
        if (data.scormFile !== scormFile) {
          scormFile = await sharedAppStateStore.wrapAppLoading(
            uploadToS3(data.scormFile, S3_SCORM_BUCKET),
            'Uploading SCORM files...'
          )
        }

        submoduleSaveData = {
          title: data.title,
          scormFile: scormFile,
          submodule_type: this.submoduleType,
        }
      } else if (this.submoduleType === 'live_session') {
        const { content, title, ...liveSessionData } = data
        const liveSession = await this.saveLiveSession(liveSessionData)
        submoduleSaveData = {
          title,
          submodule_type: this.submoduleType,
          live_session_id: liveSession.id,
          content,
        }
      }

      await sharedAppStateStore.wrapAppLoading(
        submodule.save(submoduleSaveData, true, false, { expand: 'assignment,live_session,assignment.graders' }),
        this.submoduleType === 'content_scorm' ? 'Processing SCORM files...' : undefined
      )
      this.props.onSave(submodule)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.saveButtonDisabled = false
    }
  }

  saveLiveSession = async (formData: any) => {
    const submodule = this.props.submodule || new Submodule()
    const liveSession = submodule.liveSession || new LiveSession()
    await liveSession.save({ ...formData, meetingTime: formData.meetingTime.toISOString() })
    return liveSession
  }

  saveAssignment = async (formData: any) => {
    const submodule = this.props.submodule || new Submodule()
    const assignment = submodule.assignment || new Assignment()

    const existingQuestionMap = {}
    assignment.questions.forEach((q) => (existingQuestionMap[q.id] = q))
    const questionsIds = new Array<string>()

    for (const qData of formData.assignment.questions) {
      const question: Question = existingQuestionMap[qData.id] || new Question()

      // reformat data for db
      const saveData = { ...qData }
      delete saveData.formOptions

      if (['multi_select', 'single_select'].includes(qData.questionType)) {
        saveData.options = qData.formOptions.map((op) => op.value)
        saveData.correctAnswer = qData.formOptions.reduce((acc, option, i) => {
          if (option.isCorrect) acc.push(i)
          return acc
        }, [])
      } else if (qData.questionType === 'matching') {
        saveData.options = qData.formOptions.map((op) => op.leftOption)
        saveData.correctAnswer = qData.formOptions.map((op) => op.rightOption)
      } else {
        saveData.options = []
        saveData.correctAnswer = []
      }

      // save if dirty
      const existingQData = {}
      Object.keys(qData).forEach((key) => (existingQData[key] = question[key]))

      if (!deepEquals(saveData, existingQData)) await question.save(saveData)

      // remove unused options
      qData.formOptions = qData.formOptions.filter((op) => !!op.value)

      questionsIds.push(question.id)
    }

    // save assignment with updated question ids if dirty
    const existingQuestionIds = assignment.questions.map((q) => q.id)
    if (
      !deepEquals(questionsIds, existingQuestionIds) ||
      formData.title !== assignment.title ||
      formData.passScore !== assignment.passScore ||
      formData.maxAttempts !== assignment.maxAttempts ||
      formData.assignmentGradingType !== assignment.assignmentGradingType ||
      !deepEquals(formData.graders, assignment.graders)
    ) {
      await assignment.save({
        title: formData.title,
        questionIds: questionsIds,
        assignmentGradingType: formData.assignmentGradingType,
        graderIds:
          formData.assignmentGradingType === 'assigned_user' ? formData.graders.map((grader) => grader.id) : [],
        passScore: formData.passScore ? Math.round(formData.passScore) / 100 : null,
        maxAttempts: formData.maxAttempts || null,
      })
    }

    return assignment
  }

  getInitialValues = () => {
    const { submodule } = this.props
    const initialValues = {
      title: 'New Module',
      submoduleType: this.submoduleType,
    }

    if (submodule) {
      Object.keys(initialValues).forEach((key) => (initialValues[key] = this.props.submodule[key]))
    }

    if (this.submoduleType === 'content_html') {
      initialValues['content'] = submodule?.content
    } else if (this.submoduleType === 'content_scorm') {
      initialValues['scormFile'] = submodule?.scormFile
    } else if (this.submoduleType === 'assignment') {
      initialValues['maxAttempts'] = 1
      initialValues['userSearch'] = undefined
      initialValues['graders'] = sharedDataStore.user.isFaasPartner() ? [sharedDataStore.user] : []
      initialValues['assignmentGradingType'] = sharedDataStore.user.isFaasPartner()
        ? 'assigned_user'
        : 'admin_and_manager'
      if (submodule && submodule.assignment) {
        initialValues['title'] = submodule.assignment.title
        initialValues['graders'] = [...submodule.assignment.graders]
        initialValues['maxAttempts'] = submodule.assignment.maxAttempts
        initialValues['passScore'] = submodule.assignment.getPassScorePercent()
        initialValues['assignmentGradingType'] = submodule.assignment?.assignmentGradingType
      }
      const questions =
        submodule && submodule.assignment
          ? submodule.assignment.questions.map((q) => generateFormQuestion(q))
          : [generateFormQuestion()]
      initialValues['assignment'] = { questions }
    } else if (this.submoduleType === 'live_session') {
      initialValues['meetingUrl'] = submodule?.liveSession?.meetingUrl
      initialValues['meetingTime'] = submodule?.liveSession?.meetingTime
      initialValues['content'] = submodule?.content
    }

    return initialValues
  }

  addQuestion = (formikProps: FormikProps<any>) => {
    return () => {
      const { values, setValues } = formikProps
      const questions = [...values.assignment.questions]
      questions.push(generateFormQuestion())
      setValues({ ...values, assignment: { ...values.assignment, questions: questions } })
    }
  }

  submoduleTypeChanged = (e: RadioChangeEvent) => (this.submoduleType = e.target.value)

  getScormFileUploadPromptText = () => {
    const { submodule } = this.props
    if (!submodule || !submodule.scormFile) return
    return `Click or drag file to replace "${submodule.scormFile.split('/').slice(-3, -2)[0]}"`
  }

  validateScormFile = (val: any) => {
    const { submodule } = this.props
    if ((submodule && val !== submodule.scormFile) || !submodule) {
      return combineValidations(isFile, maxFileSize(500), acceptedFileTypes('application/zip'))(val)
    }
    return undefined
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading={`${this.props.submodule ? 'Edit' : 'Create'} Module`} />
        {this.submoduleType === 'content_html' && (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
            {(formikProps) => {
              this.props.submoduleFormDirty(!deepEquals(formikProps.values, this.getInitialValues()))

              return (
                <Form>
                  <SubmoduleTypeFormItem
                    submodule={this.props.submodule}
                    submoduleTypeChanged={this.submoduleTypeChanged}
                  />
                  <FjFormItem
                    name="title"
                    fieldtitle="Title*"
                    validate={combineValidations(isRequired, isMaxLength(MAX_TITLE_LENGTH))}
                  >
                    <FjInput name="title" />
                  </FjFormItem>
                  <FjFormItem
                    name="content"
                    fieldtitle="Lesson Content*"
                    className="content-editor"
                    // @todo passing only isRequired is not working, need to fix. - Story ID - #183061252
                    validate={combineValidations(isRequired)}
                  >
                    <Suspense fallback={<Loader />}>
                      <FJCKEditor name="content" placeholder="Create your lesson content here" showRecordVideoBtn />
                    </Suspense>
                  </FjFormItem>
                  <FormActionButtons submitButtonLabel="Save" primaryDisabled={this.saveButtonDisabled} />
                </Form>
              )
            }}
          </Formik>
        )}
        {this.submoduleType === 'assignment' && (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
            {(formikProps) => {
              this.props.submoduleFormDirty(!deepEquals(formikProps.values, this.getInitialValues()))
              return (
                <Form>
                  <SubmoduleTypeFormItem
                    submodule={this.props.submodule}
                    submoduleTypeChanged={this.submoduleTypeChanged}
                  />
                  <FjFormItem
                    name="title"
                    fieldtitle="Title*"
                    validate={combineValidations(isRequired, isMaxLength(MAX_TITLE_LENGTH))}
                  >
                    <FjInput name="title" />
                  </FjFormItem>
                  {!sharedDataStore.user.isFaasPartner() ? <AssignReviewerField formikProps={formikProps} /> : null}
                  <Row gutter={10}>
                    <Col xs={24} lg={14}>
                      <FjFormItem
                        fieldtitle="Passing Score"
                        name="passScore"
                        validate={combineValidations(isNumber, isMinValue(1), isMaxValue(100))}
                      >
                        <FjInput name="passScore" suffix="%" placeholder="Set a passing score for this assessment" />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} lg={10}>
                      <FjFormItem
                        fieldtitle="Number of Max Attempts"
                        name="maxAttempts"
                        tiptitle="Leave this field blank for unlimited attempts"
                        validate={combineValidations(isNumber, isMinValue(1), isInteger)}
                      >
                        <FjInput name="maxAttempts" disabled={!formikProps.values['passScore']} />
                      </FjFormItem>
                    </Col>
                  </Row>
                  {(formikProps.values as any).assignment.questions.map((_, i) => (
                    <QuestionFormItem key={`question-form-${i}`} index={i} formikProps={formikProps} />
                  ))}
                  <div style={{ textAlign: 'left' }}>
                    <DefaultButton buttonType="text" clicked={this.addQuestion(formikProps)} title="Add Question +" />
                  </div>
                  <FormActionButtons primaryDisabled={this.saveButtonDisabled} />
                </Form>
              )
            }}
          </Formik>
        )}
        {this.submoduleType === 'content_scorm' && (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
            <Form>
              <SubmoduleTypeFormItem
                submodule={this.props.submodule}
                submoduleTypeChanged={this.submoduleTypeChanged}
              />
              <FjFormItem
                name="title"
                fieldtitle="Title*"
                validate={combineValidations(isRequired, isMaxLength(MAX_TITLE_LENGTH))}
              >
                <FjInput name="title" />
              </FjFormItem>
              <FjFormItem
                name="scormFile"
                fieldtitle="Files"
                validate={combineValidations(isRequired, this.validateScormFile)}
              >
                <FjDragUploader
                  promptText={this.getScormFileUploadPromptText()}
                  name="scormFile"
                  accept="application/zip"
                />
              </FjFormItem>
              <FormActionButtons primaryDisabled={this.saveButtonDisabled} />
            </Form>
          </Formik>
        )}
        {this.submoduleType === 'live_session' && (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
            {({ values }) => {
              const { meetingTime, meetingUrl } = values || {}
              const isMeetingTimeFuture = moment().diff(meetingTime) < 0
              const isMeetingUrlChanged = this.props.submodule?.liveSession?.meetingUrl !== meetingUrl
              const hasRecording = this.props.submodule?.liveSession?.isRecorded?.()
              const showRecordingAlert = (isMeetingTimeFuture || isMeetingUrlChanged) && hasRecording

              return (
                <Form>
                  <SubmoduleTypeFormItem
                    submodule={this.props.submodule}
                    submoduleTypeChanged={this.submoduleTypeChanged}
                  />
                  <FjFormItem
                    name="title"
                    fieldtitle="Title*"
                    validate={combineValidations(isRequired, isMaxLength(MAX_TITLE_LENGTH))}
                  >
                    <FjInput name="title" />
                  </FjFormItem>
                  <Row gutter={10}>
                    <Col span={12}>
                      <FjFormItem
                        name="meetingUrl"
                        fieldtitle="Meeting Link*"
                        validate={combineValidations(isRequired, isZoomURI)}
                      >
                        <FjInput name="meetingUrl" suffix={<Link size={14} color={Colors.cottonSeed} />} />
                      </FjFormItem>
                    </Col>
                    <Col span={12}>
                      <FjFormItem name="meetingTime" fieldtitle="Meeting Date & Time*" validate={isRequired}>
                        <FjDatePicker
                          name="meetingTime"
                          format={'MMM Do, YYYY h:mma'}
                          showTime={{ minuteStep: 15, showSecond: false }}
                          disabledDate={(date) => date < moment().endOf('day').subtract(1, 'day')}
                          style={{ width: '100%' }}
                        />
                      </FjFormItem>
                    </Col>
                  </Row>
                  {showRecordingAlert ? (
                    <Alert
                      type="info"
                      message={
                        <ContainerDiv display="flex" gap="10px" alignItems="center">
                          <AlertCircle size={18} />
                          <FjText textAlign="left" fontWeight="bold500" color={Colors.tapa}>
                            If meeting link and/or date & time are updated, any existing recordings will be replaced
                            after the new meeting takes place.
                          </FjText>
                        </ContainerDiv>
                      }
                    />
                  ) : null}
                  <FjMemoFormItem name="content" fieldtitle="Description" className="content-editor">
                    <FJCKEditor name="content" placeholder="Add description" showRecordVideoBtn />
                  </FjMemoFormItem>
                  <FormActionButtons primaryDisabled={this.saveButtonDisabled} />
                </Form>
              )
            }}
          </Formik>
        )}
      </ContainerDiv>
    )
  }
}
