import { Typography } from 'antd'
import React from 'react'
import { ContainerDiv } from 'src/components/Common'
import { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'
import { CallAttendeeInfo } from 'src/components/Library/CallAttendeeInfo'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedTask } from 'src/models/FeedTask'
import { Call } from 'src/models/Call'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { ContentTypeIcon, LearningContent, getFeedContentBody } from 'src/utils/content'
import { ViewMode } from 'src/store/DataStore'

export const LibraryCardHero = ({
  targetObj,
  compressView,
  viewMode = 'card',
  border,
}: {
  targetObj: LearningContent | Hub | Page | FeedTask | CoachingMoment
  compressView?: boolean
  border?: string
  viewMode?: ViewMode
}) => {
  const isCall = targetObj instanceof Call
  const body = getFeedContentBody(targetObj)

  const renderContentTypeIcon = () => (
    <ContainerDiv position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
      <ContentTypeIcon
        obj={targetObj}
        size={compressView ? 28 : 56}
        color={targetObj.getContentTypeTagColor()}
        thumbnail
      />
    </ContainerDiv>
  )

  return (
    <ContainerDiv
      textAlign="left"
      padding={compressView ? '5px 44px 5px 5px' : '40px 44px 16px 16px'}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      backgroundColor={targetObj.getHeroBgColor()}
      height="100%"
      width="100%"
      borderRadius={viewMode === 'list' ? 8 : undefined}
      border={border}
      className="hero-content-wrap"
    >
      {viewMode === 'card' ? (
        <>
          {isCall && !compressView && targetObj.parties.length > 0 ? (
            <CallAttendeeInfo targetObj={targetObj} />
          ) : body ? (
            <Typography.Paragraph
              ellipsis={{ rows: compressView ? 3 : 4 }}
              style={{ fontSize: compressView ? '12px' : '14px', fontWeight: 500 }}
            >
              {FeedHTMLBodyParser(body, {
                minimizeHeadings: true,
                showEmbedAsLink: true,
                showMediaAsLink: true,
                showLinkAsSpan: true,
              })}
            </Typography.Paragraph>
          ) : (
            renderContentTypeIcon()
          )}
        </>
      ) : (
        renderContentTypeIcon()
      )}
    </ContainerDiv>
  )
}
