import React from 'react'
import { Check } from 'react-feather'
import { ContainerDiv, DefaultButton } from 'src/components/Common'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'

type TaskModalProps = {
  title: string
  isCompleted?: boolean
  handleClickComplete: () => void
}

export const TaskModal = ({ title, isCompleted = false, handleClickComplete }: TaskModalProps) => {
  return (
    <ContainerDiv textAlign="left" overflow="auto">
      <HTMLExpandablePreview html={title} />
      <ContainerDiv textAlign="right" paddingTop="1rem">
        <DefaultButton
          style={{ textAlign: 'right' }}
          title={isCompleted ? 'Completed' : 'Mark as Completed'}
          buttonType="secondary"
          image={<Check size={16} />}
          clicked={handleClickComplete}
          disabled={isCompleted}
        />
      </ContainerDiv>
    </ContainerDiv>
  )
}
