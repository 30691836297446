import { Drawer, DrawerProps } from 'antd'
import React from 'react'
import { ChevronsRight } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { mobileQuery } from 'src/pages/public/common'
import styled from 'styled-components'

const FjStyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    border-radius: 16px 0px 0px 16px;
    overflow: hidden;
  }
`

const FjDrawer: React.FC<Omit<DrawerProps, 'headerStyle' | 'bodyStyle' | 'width' | 'closeIcon'>> = ({
  children,
  ...props
}) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <FjStyledDrawer
      {...props}
      title={
        <FjText fontSize="24px" fontWeight="bold" color={Colors.shark}>
          {props.title}
        </FjText>
      }
      headerStyle={{
        background: Colors.white,
        borderBottom: 'none',
        paddingTop: '24px',
        paddingInline: '32px',
        paddingBottom: '0px',
      }}
      bodyStyle={{ background: Colors.white, paddingInline: '32px', paddingTop: '16px', overflow: 'hidden' }}
      width={isMobile ? '100%' : 480}
      closeIcon={<ChevronsRight size={24} color={Colors.cottonSeed} />}
    >
      {children}
    </FjStyledDrawer>
  )
}

export default FjDrawer
