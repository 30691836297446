import { Tabs } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import FjDrawer from 'src/components/Common/FjDrawer'
import { QueueList } from 'src/components/Feed/Queue'

@observer
export class QueueDrawer extends React.Component {
  render() {
    return (
      <>
        <FjDrawer
          title="My Tasks"
          destroyOnClose
          open={sharedAppStateStore.showTaskDrawer}
          onClose={() => (sharedAppStateStore.showTaskDrawer = false)}
        >
          <Tabs
            defaultActiveKey="tasks-tab-all"
            destroyInactiveTabPane
            items={[
              {
                key: 'tasks-tab-assigned',
                label: 'Incomplete',
                children: (
                  <QueueList
                    filterBy="assigned"
                    showDivider
                    scrollableTargetId="task-list-assigned"
                    height="calc(100vh - 140px)"
                  />
                ),
              },
              {
                key: 'tasks-tab-completed',
                label: 'Completed',
                children: (
                  <QueueList
                    filterBy="completed"
                    showDivider
                    scrollableTargetId="task-list-completed"
                    height="calc(100vh - 140px)"
                  />
                ),
              },
            ]}
          />
        </FjDrawer>
      </>
    )
  }
}
