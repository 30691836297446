import { removeKeys } from 'src/utils/format'

export function getQueryParam(param: string) {
  let params = new URL(window.location.href).searchParams
  return params.get(param)
}

export const getIDFromPath = (index: number = -1, path?: string) => {
  const pathname = path || window.location.pathname
  const pathArr = pathname.split('/').filter((el) => !!el)
  const id = pathArr[index < 0 ? pathArr.length + index : index]
  return ['create', 'edit', 'referral-leaderboard', 'messages', 'conversations'].includes(id) ? undefined : id
}

export const searchParamsToObject = (params: URLSearchParams, excludeParams = []) => {
  const queryParams = {}
  params.forEach((val, key) => (queryParams[key] = val))
  return removeKeys(queryParams, excludeParams)
}

export const getQueryParams = (excludeParams = []): any =>
  searchParamsToObject(new URLSearchParams(window.location.search), excludeParams)
