import React, { Component } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { combineValidations, isRequired, isEmail } from 'src/utils/validation'
import {
  FjText,
  DefaultButton,
  DefaultLink,
  FjFormItem,
  FjInput,
  ContainerDiv,
  FjEmailInput,
  PageTitle,
  AuthLayout,
} from 'src/components/Common'
import { AuthHandler } from 'src/models/User'
import { Auth } from 'src/utils/Auth'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'
import { Colors } from 'src/constants/colors'
import { getQueryParam } from 'src/utils/urlParams'
import { sharedDataStore } from 'src/store/DataStore'
import { MetaData } from 'src/hoc/MetaData'
import flockjayRoundedLogo from 'src/assets/icons/FJ/logo-rounded.svg'

type LoginType = 'emailPassword' | 'sso'

interface LoginProps {
  loginHandler?: AuthHandler
}

interface LoginState {
  loginType: LoginType
  errorMessage?: string
}

export class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      loginType: 'emailPassword',
      errorMessage: undefined,
    }
  }

  componentDidMount() {
    if (sharedDataStore.authState === 'authorized') {
      sharedAppStateStore.navigate(Paths.feed)
    }
    if (getQueryParam('ssoFailed') === 'true') {
      this.setState(() => ({ errorMessage: 'Unable to login with SSO' }))
    }
    const loginType = getQueryParam('loginType') as LoginType
    if (loginType) this.setState(() => ({ loginType }))
  }

  loginHandler = () => {
    const redirectUri = getQueryParam('redirectUri')
    if (redirectUri) window.location.href = redirectUri
    else if (this.props.loginHandler) this.props.loginHandler()
    else sharedAppStateStore.navigate(sharedDataStore.getLoginHandlerPath())
  }

  login = async (data: any) => {
    this.setState(() => ({ errorMessage: undefined }))
    try {
      if (this.state.loginType === 'emailPassword') {
        await sharedAppStateStore.wrapAppLoading(Auth.login(data, this.loginHandler, this.errorHandler))
      } else {
        sharedAppStateStore.wrapAppLoading(Auth.loginSso(data))
      }
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  errorHandler = (msg: string) => {
    this.setState(() => ({ errorMessage: msg }))
  }

  clearErrorMessage = () => {
    if (this.state.errorMessage !== undefined) {
      this.setState(() => ({ errorMessage: undefined }))
    }
  }

  toggleLoginType = () => {
    try {
      // If we're switching to sso and we have record of a previous login we can attempt sso login automatically
      if (this.state.loginType === 'emailPassword' && !!Auth.getSsoData()?.integratedSsoOrganizationId) {
        Auth.loginSso()
        return
      }
      this.setState(({ loginType }) => ({ loginType: loginType === 'emailPassword' ? 'sso' : 'emailPassword' }))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    const toggleLoginTypeBtnTitle =
      this.state.loginType === 'emailPassword' ? 'Login w/ SSO' : 'Login w/ Email/Password'
    return (
      <MetaData title="Login">
        <AuthLayout>
          <ContainerDiv>
            <img src={flockjayRoundedLogo} alt="Logo" />
          </ContainerDiv>
          <PageTitle fontSize="28px" fontWeight="bold" marginTop="32px" marginBottom="32px">
            Welcome to Flockjay
          </PageTitle>
          <Formik initialValues={{ email: undefined, password: undefined }} onSubmit={this.login}>
            <Form onKeyUp={this.clearErrorMessage}>
              <FjFormItem
                name="email"
                fieldtitle="Email*"
                validate={combineValidations(isRequired, isEmail)}
                style={{ marginBottom: '32px' }}
              >
                <FjEmailInput name="email" width="98%" />
              </FjFormItem>
              {this.state.loginType === 'emailPassword' && (
                <FjFormItem
                  name="password"
                  fieldtitle="Password*"
                  validate={isRequired}
                  style={{ marginBottom: '8px' }}
                >
                  <FjInput type="password" name="password" placeholder="Password" />
                </FjFormItem>
              )}
              {this.state.loginType === 'emailPassword' &&
                !sharedAppStateStore.externalAcademy?.getCustomLoginUrl() && (
                  <DefaultLink to={Paths.forgotPassword}>
                    <FjText color={Colors.cottonSeed} fontSize="12px" fontWeight="bold500">
                      Forgot Password?
                    </FjText>
                  </DefaultLink>
                )}
              <ContainerDiv minHeight="32px">
                {this.state.errorMessage && <FjText error>{this.state.errorMessage}</FjText>}
              </ContainerDiv>
              <DefaultButton
                type="submit"
                title={`Login${this.state.loginType === 'sso' ? ' w/ Single Sign-On (SSO)' : ''}`}
                buttonType="primary"
                width="100%"
              />
              <DefaultButton
                buttonType="tertiary"
                width="100%"
                title={toggleLoginTypeBtnTitle}
                clicked={this.toggleLoginType}
                style={{ marginTop: '32px' }}
              />
              {process.env.REACT_APP_DEMO_FEATURES_ENABLED === 'true' && (
                <ContainerDiv marginTop="32px">
                  <FjText color={Colors.cottonSeed} fontWeight="bold500">
                    Don't have an account? {` `}
                  </FjText>
                  <DefaultLink to={Paths.register}>
                    <FjText color={Colors.cornflowerBlue} fontWeight="bold">
                      Sign up
                    </FjText>
                  </DefaultLink>
                </ContainerDiv>
              )}
            </Form>
          </Formik>
        </AuthLayout>
      </MetaData>
    )
  }
}
