import { DataObject } from 'src/models/DataObject'
import { User } from 'src/models/User'
import { LearningContentType } from 'src/utils/content'
import { computed, observable } from 'mobx'
import { CustomMetadataFieldOptions } from 'src/models/Config'

type GroupOverviewPageType = { group: { id: string; name: string }; page: { id: string; name: string } }

export class Company extends DataObject {
  static OVERRIDE_MAPPINGS = {
    industries: (data) => (data.industries ? data.industries : []),
    jobLocations: (data) => (data.jobLocations ? data.jobLocations : []),
    logoUrl: (data) => (data.logoUrl ? data.logoUrl : undefined),
    partnerManager: (data) => (data.partnerManager ? User.fromData(data.partnerManager) : undefined),
  }

  getOverrideDisplayValueMappings = () => {
    return {
      industries: 'industry',
      size: 'companySize',
    }
  }

  static apiEndpoint = '/api/companies/'
  id: string
  name: string
  description: string
  size: string
  industries: string[] = []
  jobLocations: string[] = []
  logoUrl: string
  partnerManager: User
  integratedSlack: boolean
  integratedGongOrganization: boolean
  integratedZoomOrganization: boolean
  integratedChorusOrganization: boolean
  integratedOutreachOrganization: boolean
  integratedSalesforceOrganizationUrl: string
  @observable integratedSsoOrganizationId?: string
  @observable integratedScimOrganizationId?: string
  integratedClariOrganizationId?: string
  requiresContentCuration: boolean
  defaultGroupId: string
  defaultContentTab: LearningContentType
  customFields: CustomMetadataFieldOptions
  groupOverviewPages: GroupOverviewPageType[] = []
  enableAi: boolean
  enableCustomFieldsSuggestion: boolean
  enableTagsSuggestion: boolean

  @computed get integratedCallOrganization() {
    return this.integratedGongOrganization || this.integratedChorusOrganization || this.integratedClariOrganizationId
  }

  generateSFOppUrl = (opportunityId: string) => {
    return `${this.integratedSalesforceOrganizationUrl}/lightning/r/Opportunity/${opportunityId}/view`
  }
}
