import { DefaultOptionType } from 'antd/lib/select'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { FJStyledAutoComplete, FJStyledInput } from 'src/components/Common'
import { User } from 'src/models/User'

type UserSelectProps = {
  name: string
  placeholder?: string
  // We need initialLabel prop to show label, not value.
  initialLabel?: string
  excludeUserIds?: string[]
}

@observer
export class UserSelect extends Component<UserSelectProps> {
  // We need inputValue state to show label when select operation happens on AutoComplete.
  @observable inputValue: string
  @observable usersOptions: DefaultOptionType[] = []

  constructor(props: UserSelectProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    this.inputValue = this.props.initialLabel
  }

  componentDidUpdate(prevProps: Readonly<UserSelectProps>) {
    if (prevProps.initialLabel !== this.props.initialLabel) {
      this.inputValue = this.props.initialLabel
    }
  }

  setUsersOptions = async (searchValue = '') => {
    const { data: users } = await User.list({ search: searchValue, is_active: true })
    this.usersOptions = users
      .filter((user: User) => !this.props.excludeUserIds?.includes(user.id))
      .map((user: User) => ({
        value: user.id,
        label: user.fullName,
      }))
  }

  handleSearch = async (query: string) => {
    this.inputValue = query
    this.setUsersOptions(query)
  }

  handleSelect = (_value, option: DefaultOptionType) => {
    this.inputValue = option.label as string
  }

  handleFocus = () => {
    if (!this.usersOptions.length) this.setUsersOptions()
  }

  render() {
    return (
      <FJStyledAutoComplete
        name={this.props.name}
        options={this.usersOptions}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        value={this.inputValue}
        allowClear
        onFocus={this.handleFocus}
      >
        <FJStyledInput name={`${this.props.name}-input`} placeholder={this.props.placeholder} />
      </FJStyledAutoComplete>
    )
  }
}
