/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
export const EMAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

export const ZIPCODE_REGEX = /(^\d{5}$)/

export const GMAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@g(oogle)?mail\.com$/

export const YAHOO_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(yahoo|ymail)\.com$/

export const AOL_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@aol\.com$/

export const HOTMAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(hotmail|live)\.com$/

export const OUTLOOK_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@outlook\.com$/

export const ICLOUD_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@icloud\.com$/

export const ZOHO_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@zoho\.com$/

export const PROTON_MAIL_REGEX =
  /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@proton\.com$/

export const HYPERLINK_REGEX = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi

export const HYPERLINK_EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const ZOOM_URL_REGEX = /^https:\/\/[\w-]*\.?zoom.us\/(j|my|meeting)\/[\d\w.?=-]+/

export const PERSONAL_EMAILS_REGEX_ARRAY = [
  GMAIL_REGEX,
  YAHOO_MAIL_REGEX,
  AOL_MAIL_REGEX,
  HOTMAIL_REGEX,
  OUTLOOK_MAIL_REGEX,
  ICLOUD_MAIL_REGEX,
  ZOHO_MAIL_REGEX,
  PROTON_MAIL_REGEX,
]

export const LOOM_REGEX = /data-oembed-url="https:\/\/www.loom\.com\/(?:embed|share)\/([\w-]+)/

export const YOUTUBE_REGEX = /data-oembed-url="https:\/\/www.youtube\.com\/watch\?v=([\w-]+)/

export const VIMEO_REGEX = /data-oembed-url="https:\/\/vimeo\.com\/(\d+)/

export const FJ_VIDEO_REGEX =
  /data-oembed-url="https:\/\/fj-file-uploads\.s3\.us-east-2\.amazonaws\.com\/((?:[\w+]+(?:%[0-9A-Fa-f]{2}|\/))?fjvideo-[\w-]+).([\w-]+)"/

export const FJ_API_VIDEO_REGEX =
  /data-oembed-url="(?:https:\/\/(?:staging-)?api(?:-demo)?\.flockjay\.com|(?:http:\/\/)?localhost:8000)\/feed\/files\/(?:[a-zA-Z0-9\-]+)\/((?:[\w+]+(?:%[0-9A-Fa-f]{2}|\/))?fjvideo-[\w-]+).([\w-]+)"/

export const GONG_REGEX = /data-oembed-url="https:\/\/(?:.+)app\.gong\.io\/embedded-call\?call-id=([\w-]+)/

export const PDF_LINK_REGEX =
  /(?:https:\/\/(?:api|staging-api|api-demo).flockjay.com|http:\/\/localhost:8000)\b([-a-zA-Z0-9()@:%_+.~#?&//=]*\.pdf)/

export const MS_DOC_LINK_REGEX =
  /(?:https:\/\/(?:api|staging-api|api-demo).flockjay.com|http:\/\/localhost:8000)\b([-a-zA-Z0-9()@:%_+.~#?&//=]*\.(docx|doc|xlsx|xls|pptx|ppt))/

export const FULL_NAME_REGEX = /[a-zA-Z]+ [a-zA-Z]+/
