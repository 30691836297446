import Axios, { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios'
import humps from 'humps'

const DEV_URL = 'http://localhost:3000'
const STAGING_URL = 'https://staging.flockjay.com'
const PROD_URL = 'https://flockjay.com'

export const DEFAULT_APP_URLS = [DEV_URL, STAGING_URL, PROD_URL]

const DEV_HOST = 'http://localhost:8000'
const STAGING_HOST = 'https://staging-api.flockjay.com'
const PROD_HOST = 'https://api.flockjay.com'

export let baseURL: string
export let frontendURL: string

export const setFrontendURL = (url?: string) => {
  if (url) {
    frontendURL = url
  } else if (process.env.REACT_APP_FRONTEND_URL) {
    frontendURL = process.env.REACT_APP_FRONTEND_URL
  } else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    frontendURL = DEV_URL
  } else if (process.env.REACT_APP_ENV === 'staging') {
    frontendURL = STAGING_URL
  } else {
    frontendURL = PROD_URL
  }
}

const setBackendURL = (url?: string) => {
  if (url) {
    baseURL = url
  } else if (process.env.REACT_APP_API_URL) {
    baseURL = process.env.REACT_APP_API_URL
  } else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseURL = DEV_HOST
  } else if (process.env.REACT_APP_ENV === 'staging') {
    baseURL = STAGING_HOST
  } else {
    baseURL = PROD_HOST
  }
}

setFrontendURL()
setBackendURL()

export const config: any = {
  baseURL: baseURL,

  transformResponse: [
    ...(Axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data: object[]) => humps.camelizeKeys(data),
  ],

  transformRequest: [
    (data: object[], headers: any) => humps.decamelizeKeys(data),
    ...(Axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
}

export const FlockjayProvider = Axios.create(config)
