import { observable, makeObservable } from 'mobx'
import moment from 'moment'
import { DataObject } from 'src/models/DataObject'

export class FeedTag extends DataObject {
  static apiEndpoint: string = '/feed/tags/'
  static primaryKey: string = 'tag'

  id: string
  tag: string
  @observable isFollowing: boolean
  createdAt: moment.Moment
  lastUpdated: moment.Moment

  constructor() {
    super()
    makeObservable(this)
  }
}
