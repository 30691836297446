import React, { useMemo } from 'react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import {
  ContainerDiv,
  FormHeaderText,
  DefaultButton,
  FjFormItem,
  FjInput,
  FjSelect,
  FjTextArea,
} from 'src/components/Common'
import { Col, Row, Space } from 'antd'
import { FieldArray, Form, Formik } from 'formik'
import { combineValidations, isMaxLength, isRequired } from 'src/utils/validation'
import { Colors } from 'src/constants/colors'
import { Rubric } from 'src/models/Rubric'
import { Trash } from 'react-feather'

const MAX_POINTS_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]

export type RubricAuthoringFormProps = {
  rubric?: Rubric
  onSaveSuccess?: (rubric: Rubric) => void
  onDeleteSuccess?: () => void
}

export const RubricAuthoringForm = ({ rubric, onSaveSuccess, onDeleteSuccess }: RubricAuthoringFormProps) => {
  const initialValues = useMemo(() => {
    const { title, points, criteria } = rubric || new Rubric()
    const maxPoints = points.length ? points.length - 1 : 5

    // Match points data format : [[0, ''], [1, ''], ..., [5, '']]
    const modifiedPoints = [...points]
    for (let i = 0; i <= 5; i++) {
      if (!modifiedPoints[i]) {
        modifiedPoints[i] = []
        modifiedPoints[i][0] = `${i}`
        modifiedPoints[i][1] = ''
      }
    }

    return {
      title,
      points: modifiedPoints,
      criteria: criteria ?? [{ title: '', description: ['', '', '', '', '', ''] }],
      maxPoints,
    }
  }, [rubric])

  const handleSubmit = async (data) => {
    try {
      let { title, criteria, points, maxPoints } = data

      // Normalize points and criteria descriptions based on maxPoints
      points = points.slice(0, maxPoints + 1)
      for (let i = 0; i < criteria.length; i++) {
        let description = [...criteria[i].description]
        criteria[i].description = description.slice(0, maxPoints + 1)
      }

      const newRubric = rubric || new Rubric()
      await newRubric.save({ title, criteria, points })
      onSaveSuccess?.(newRubric)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  const handleRubricDelete = () => {
    const onDeleteRubric = async () => {
      try {
        await sharedAppStateStore.wrapAppLoading(rubric.delete())
        onDeleteSuccess?.()
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    }
    sharedAppStateStore.deleteDialogProps = {
      onConfirm: onDeleteRubric,
      obj: rubric,
    }
  }

  return (
    <ContainerDiv>
      <FormHeaderText heading={`${rubric ? 'Edit' : 'Add'} a Rubric`} />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <Row gutter={[10, 10]}>
              <Col xs={24} lg={18}>
                <FjFormItem
                  name="title"
                  fieldtitle="Rubric Title*"
                  validate={combineValidations(isRequired, isMaxLength(100))}
                >
                  <FjInput name="title" placeholder="Rubric Title" />
                </FjFormItem>
              </Col>
              <Col xs={12} lg={6}>
                <FjFormItem fieldtitle="Max Points" name="maxPoints">
                  <FjSelect name="maxPoints" options={MAX_POINTS_OPTIONS} />
                </FjFormItem>
              </Col>
            </Row>
            <ContainerDiv overflowX="scroll" overflowY="auto">
              <Row wrap={false} gutter={[10, 10]}>
                <Col xs={12} lg={6} />
                {Array.from({ length: values?.maxPoints + 1 }).map((item, index) => (
                  <Col xs={12} lg={6} key={index}>
                    <FjFormItem
                      name={`points.${index}.${1}`}
                      validate={combineValidations(isRequired, isMaxLength(50))}
                    >
                      <FjInput addonBefore={`${index}`} name={`points.${index}.${1}`} placeholder="Point value title" />
                    </FjFormItem>
                  </Col>
                ))}
              </Row>
              <FieldArray
                name="criteria"
                render={(arrayHelpers) => {
                  return Array.from({ length: values?.criteria?.length ?? 1 }).map((item, cIndex) => (
                    <Row wrap={false} gutter={[10, 10]} key={cIndex}>
                      <Col xs={12} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <ContainerDiv display="flex" alignItems="center" gap={5}>
                          <FjFormItem
                            name={`criteria.${cIndex}.title`}
                            validate={combineValidations(isRequired, isMaxLength(50))}
                          >
                            <FjInput name={`criteria.${cIndex}.title`} placeholder="Criterion Title" />
                          </FjFormItem>
                          {values?.criteria?.length > 1 ? (
                            <Trash
                              color={Colors.burntSienna}
                              size={16}
                              style={{ marginTop: '-25px' }}
                              onClick={() => arrayHelpers.remove(cIndex)}
                            />
                          ) : null}
                        </ContainerDiv>
                      </Col>
                      {Array.from({ length: values?.maxPoints + 1 }).map((item, dIndex) => (
                        <Col xs={12} lg={6} key={dIndex}>
                          <FjFormItem name={`criteria.${cIndex}.description.${dIndex}`}>
                            <FjTextArea
                              rows={4}
                              name={`criteria.${cIndex}.description.${dIndex}`}
                              placeholder="Description"
                            />
                          </FjFormItem>
                        </Col>
                      ))}
                    </Row>
                  ))
                }}
              />
            </ContainerDiv>
            <ContainerDiv textAlign="left">
              <DefaultButton
                buttonType="text"
                title="+ Add a criterion"
                clicked={() =>
                  setFieldValue('criteria', [...values.criteria, { title: '', description: ['', '', '', '', '', ''] }])
                }
              />
            </ContainerDiv>
            <ContainerDiv display="flex" justifyContent="end">
              <Space>
                <DefaultButton
                  buttonType="text"
                  title="Cancel"
                  clicked={() => (sharedAppStateStore.rubricAuthoringFormModalProps = undefined)}
                />
                {rubric ? <DefaultButton buttonType="destructive" title="Delete" clicked={handleRubricDelete} /> : null}
                <DefaultButton type="submit" buttonType="primary" title="Save" />
              </Space>
            </ContainerDiv>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  )
}
