import { DataObject } from 'src/models/DataObject'
import { StudentAnswer } from 'src/models/StudentAnswer'
import moment from 'moment'
import { Rubric } from 'src/models/Rubric'

export type QuestionType = 'single_select' | 'multi_select' | 'matching' | 'short_text' | 'long_text'
export type QuestionGradeType = 'binary' | 'senary' | 'rubric'

export const QUESTION_TYPE_OPTIONS_MAP = new Map<string, string>([
  ['single_select', 'Single Select'],
  ['multi_select', 'Multi Select'],
  ['matching', 'Matching'],
  ['short_text', 'Short Form'],
  ['long_text', 'Long Form'],
])

export class Question extends DataObject {
  static OVERRIDE_MAPPINGS = {
    options: (data) => data.options || [],
    studentAnswer: (data) => (data.studentAnswer ? StudentAnswer.fromData(data.studentAnswer) : undefined),
    rubric: (data) => (data.rubric ? Rubric.fromData(data.rubric) : undefined),
    question: ({ question }) => question || '',
  }

  static apiEndpoint = '/api/questions/'

  id: string
  title: string
  questionType: QuestionType = 'single_select'
  question: string = ''
  options: Array<string> = []
  correctAnswer: Array<string | number> = []
  studentAnswer?: StudentAnswer
  gradeType: QuestionGradeType = 'binary'
  subtitle?: string
  rubric?: Rubric
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  /**
   * Get options for matching question containing both the left and right values
   * @returns MatchingQuestionDataType[]
   */
  correctMatchingOptions = () =>
    this.options.map((op, i) => ({ leftOption: op, rightOption: this.correctAnswer[i] as string }))

  /**
   * Generate correct answer to index map to quickly look up index of correct answer
   * @returns Mapping between correctAnswer and it's index in the correctAnswer array
   */
  matchingCorrectAnswerIndexMap = () =>
    this.correctMatchingOptions().reduce((acc, item, index) => {
      acc[item.rightOption] = `${index}`
      return acc
    }, {})

  getPoints = () => {
    return this.gradeType === 'binary'
      ? 1
      : this.gradeType === 'senary'
      ? 5
      : this.rubric.criteria.length * (this.rubric.points.length - 1)
  }
}
