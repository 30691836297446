import React, { useEffect, useState } from 'react'
import { FJStyledInput, FjFormItem, FjSelect } from 'src/components/Common/FjInput'
import { Certificate } from 'src/models/Certificate'
import { sharedAppStateStore } from 'src/store/AppStateStore'

type CertificateFieldProps = {
  name: string
  initialLabel?: string
}

export const CertificateField = ({ name, initialLabel }: CertificateFieldProps) => {
  const [certificateOptions, setCertificateOptions] = useState([])

  useEffect(() => {
    if (!certificateOptions.length) fetchCertificateOptions('')
  }, [certificateOptions.length])

  const fetchCertificateOptions = async (search: string) => {
    try {
      const data = await Certificate.list({ search })
      const options = data.map((item: Certificate) => ({
        key: item.id,
        id: item.id,
        value: item.id,
        label: item.name,
      }))
      setCertificateOptions(options)
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined)
    }
  }

  return (
    <FjFormItem name={name} fieldtitle="Grant Certificate Upon Completion">
      <FjSelect name={name} placeholder="Select certificate" allowClear options={certificateOptions}>
        <FJStyledInput name={`${name}-input`} />
      </FjSelect>
    </FjFormItem>
  )
}
