import React from 'react'
import { Module } from 'src/models/Module'
import { ContainerDiv, FjFormItem, FjInput, FormActionButtons, FormHeaderText } from 'src/components/Common'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { combineValidations, isMaxLength, isRequired } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'

interface IModuleFormProps {
  onSave: (module: Module) => void
  module?: Module
}

export const ModuleForm: React.FC<IModuleFormProps> = ({ onSave, module }) => {
  const handleSubmit = async (data: any) => {
    try {
      const newModule = Module.fromData({ ...module }) || new Module()
      await sharedAppStateStore.wrapAppLoading(newModule.save(data))
      onSave(newModule)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }
  return (
    <ContainerDiv>
      <FormHeaderText heading={`${module ? 'Edit' : 'Create'} Section`} />
      <Formik initialValues={{ title: module ? module.title : undefined }} onSubmit={handleSubmit}>
        <Form>
          <FjFormItem fieldtitle="Title*" name="title" validate={combineValidations(isRequired, isMaxLength(255))}>
            <FjInput name="title" placeholder="Enter a section title" />
          </FjFormItem>
          <FormActionButtons submitButtonLabel="Save" />
        </Form>
      </Formik>
    </ContainerDiv>
  )
}
