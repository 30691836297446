import React, { useState, useEffect, useCallback } from 'react'
import { SearchType } from 'src/pages/Search'
import {
  FjCard,
  ContainerDiv,
  FjText,
  Loader,
  ReactButton,
  FormHeaderText,
  FjFormItem,
  FjTextArea,
  FormActionButtons,
} from 'src/components/Common'
import sparkles from 'src/assets/icons/sparkles.png'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { APIProvider } from 'src/network/APIProvider'
import { Analytics } from 'src/utils/Analytics'
import { sharedDataStore } from 'src/store/DataStore'
import { Form, Formik } from 'formik'
import { Colors } from 'src/constants/colors'
import { Asset } from 'src/models/Asset'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'
import showdown from 'showdown'

const showdownConverter = new showdown.Converter()

type AISummaryRating = 'good' | 'poor'

export interface IAISummaryFeedbackModalProps {
  onSuccess: (reason?: string) => void
  onCancel: () => void
}

export const AISummaryFeedbackModal: React.FC<IAISummaryFeedbackModalProps> = ({ onSuccess }) => {
  return (
    <ContainerDiv>
      <FormHeaderText heading="AI Answer Feedback" />
      <Formik initialValues={{ feedback: undefined }} onSubmit={(data: any) => onSuccess(data.feedback)}>
        <Form>
          <FjFormItem
            fieldtitle="Please provide as much detail as possible for your rating. This will help us improve future answers. (Optional)"
            name="feedback"
          >
            <FjTextArea name="feedback" rows={4} placeholder="Enter feedback here" />
          </FjFormItem>
          <FormActionButtons submitButtonLabel="Submit Feedback" />
        </Form>
      </Formik>
    </ContainerDiv>
  )
}

interface AIGeneratedSummaryProps {
  shouldShowSummary?: boolean
  shouldFetchSummary?: boolean
  fetchSummary: () => Promise<string> | string
  trackSummaryFeedback: (rating: AISummaryRating, summary: string, feedback: string) => void
}

const AIGeneratedSummaryView: React.FC<AIGeneratedSummaryProps> = ({
  shouldShowSummary = true,
  shouldFetchSummary = true,
  fetchSummary,
  trackSummaryFeedback,
}) => {
  const [viewState, setViewState] = useState<'idle' | 'loading'>('idle')
  const [feedbackState, setFeedbackState] = useState<'requestFeedback' | 'feedbackReceived' | 'hidden'>('hidden')
  const [summary, setSummary] = useState('')

  const fetchContent = useCallback(async () => {
    try {
      setViewState('loading')
      setSummary('')
      setFeedbackState('hidden')
      const summary = await fetchSummary()
      setSummary(summary)
      setFeedbackState('requestFeedback')
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      setSummary('Oh no, AI does not know how to answer that :(')
    } finally {
      setViewState('idle')
    }
  }, [fetchSummary])

  useEffect(() => {
    if (!shouldFetchSummary) return
    fetchContent()
  }, [shouldFetchSummary, fetchContent])

  const handleRating = useCallback(
    (rating: AISummaryRating) => {
      const closeModal = () => (sharedAppStateStore.aiSummaryFeedbackModalProps = undefined)
      const onSuccess = (feedback?: string) => {
        trackSummaryFeedback(rating, summary, feedback)
        closeModal()
        setFeedbackState('feedbackReceived')
      }
      sharedAppStateStore.aiSummaryFeedbackModalProps = { onCancel: closeModal, onSuccess }
    },
    [summary, setFeedbackState, trackSummaryFeedback]
  )

  if (!shouldShowSummary) return null

  return (
    <FjCard marginVertical textAlign="left" padding="14px" backgroundColor={Colors.magnolia}>
      {viewState === 'loading' ? (
        <Loader label="Generating AI Summary..." />
      ) : (
        <>
          <ContainerDiv display="flex" gap="10px" alignItems="center" marginBottom>
            <img src={sparkles} alt="sparkles" />
            <FjText fontWeight="semi-bold">AI Summary:</FjText> <br />
          </ContainerDiv>
          <HTMLExpandablePreview
            html={showdownConverter.makeHtml(summary)}
            rows={3}
            backgroundColor={Colors.magnolia}
          />
          <ContainerDiv display="flex" alignItems="center" justifyContent="end" gap="5px">
            {feedbackState === 'requestFeedback' ? (
              <>
                <FjText color={Colors.tapa}>Rating:</FjText>
                <ReactButton
                  reactionType="thumbsUp"
                  reacted={false}
                  clicked={() => handleRating('good')}
                  tooltipTitle="Great!"
                  width="34px"
                />
                <ReactButton
                  reactionType="thumbsDown"
                  reacted={false}
                  clicked={() => handleRating('poor')}
                  tooltipTitle="Poor"
                  width="34px"
                />
              </>
            ) : feedbackState === 'feedbackReceived' ? (
              <FjText color={Colors.tapa}>Thank you for your feedback!</FjText>
            ) : null}
          </ContainerDiv>
        </>
      )}
    </FjCard>
  )
}

interface AISearchSummaryProps {
  searchType: SearchType
  query?: string
}

export const AISearchSummary: React.FC<AISearchSummaryProps> = ({ searchType, query }) => {
  const fetchSummary = useCallback(async () => APIProvider.getAISummary(query), [query])
  const trackAISummaryFeedback = useCallback(
    async (rating: AISummaryRating, summary: string, feedback: string) =>
      Analytics.trackAISummaryFeedback(sharedDataStore.user.id, rating, summary, feedback, { query }),
    [query]
  )

  return (
    <AIGeneratedSummaryView
      shouldShowSummary={searchType === 'aisearch' && !!query}
      shouldFetchSummary={searchType === 'aisearch'}
      fetchSummary={fetchSummary}
      trackSummaryFeedback={trackAISummaryFeedback}
    />
  )
}

interface AssetAISummaryViewProps {
  asset: Asset
}

export const AssetAISummaryView: React.FC<AssetAISummaryViewProps> = ({ asset }) => {
  const trackAISummaryFeedback = useCallback(
    async (rating: AISummaryRating, summary: string, feedback: string) =>
      Analytics.trackAISummaryFeedback(sharedDataStore.user.id, rating, summary, feedback, { assetId: asset.id }),
    [asset]
  )

  return (
    <AIGeneratedSummaryView
      shouldShowSummary={!!asset.summary}
      fetchSummary={() => asset.summary}
      trackSummaryFeedback={trackAISummaryFeedback}
    />
  )
}
