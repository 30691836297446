import React, { Component } from 'react'
import { FilterSelect, FilterSelectLarge } from 'src/components/Feed/FeedPostList'
import { sharedDataStore } from 'src/store/DataStore'
import { Select } from 'antd'
import { getIntersection } from 'src/utils/format'
import { Colors } from 'src/constants/colors'

interface GroupFilterProps {
  type?: 'large' | 'small'
  defaultLabel?: string
  selectedGroup: string
  onGroupSelect: (groupId: string) => void
  limitToGroupIds?: string[]
  hideDefaultLabel?: boolean
}

export class GroupFilter extends Component<GroupFilterProps> {
  filterGroupOptions = () => {
    const { limitToGroupIds, defaultLabel } = this.props
    const map = new Map()
    if (!this.props.hideDefaultLabel) map.set('', defaultLabel || 'Select Group')
    const commonGroupIds = limitToGroupIds
      ? getIntersection(sharedDataStore.user.groupIds, limitToGroupIds)
      : sharedDataStore.user.groupIds
    const groupsToShow = sharedDataStore.user.groups.filter((group) => commonGroupIds.includes(group.id))
    groupsToShow.forEach((group) => {
      map.set(group.id, group.name)
    })
    return map
  }

  render() {
    const { selectedGroup, onGroupSelect, type } = this.props
    const groupOptions = this.filterGroupOptions()
    if (groupOptions.size <= 2 && groupOptions.get(sharedDataStore.user.defaultGroup.id)) return null
    return (
      <>
        {type === 'large' ? (
          <FilterSelectLarge
            style={{ color: selectedGroup ? Colors.cornflowerBlue : Colors.outerSpace }}
            value={selectedGroup || ''}
            onChange={onGroupSelect}
          >
            {Array.from(groupOptions).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </FilterSelectLarge>
        ) : (
          <FilterSelect
            style={{ color: selectedGroup ? Colors.cornflowerBlue : Colors.outerSpace, minWidth: 200 }}
            value={selectedGroup || ''}
            onChange={onGroupSelect}
          >
            {Array.from(groupOptions).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </FilterSelect>
        )}
      </>
    )
  }
}
