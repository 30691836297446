import { Row, Col, Space } from 'antd'
import React from 'react'
import { Component } from 'react'
import { Colors } from 'src/constants/colors'
import { sharedDataStore, ViewMode } from 'src/store/DataStore'
import {
  ContainerDiv,
  FjFormItem,
  FJStyledAutoComplete,
  FJStyledInput,
  FjSelect,
  DefaultButton,
  DefaultLink,
  FjText,
} from 'src/components/Common'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { DefaultOptionType } from 'antd/lib/select'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import SalesforceBlueIcon from 'src/assets/icons/SalesforceBlue.svg'
import { Paths } from 'src/constants/navigation'
import { LearningContent, getFeedContentType } from 'src/utils/content'
import { useMediaQuery } from 'react-responsive'
import { mobileQuery } from 'src/pages/public/common'
import { FastForward } from 'react-feather'
import { Page } from 'src/models/Page'
import { Hub } from 'src/models/Hub'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { SharedContent } from 'src/models/SharedContent'

export interface ISfMetadataFormFieldsProps {
  expanded?: boolean
  handleSelect: (value: any, option: any) => void
  handleClear: () => void
  disabled?: boolean
}

@observer
export class SfMetadataFormFields extends Component<ISfMetadataFormFieldsProps> {
  @observable expanded = this.props.expanded
  @observable opportunities: DefaultOptionType[] = []

  constructor(props: ISfMetadataFormFieldsProps) {
    super(props)
    makeObservable(this)
  }

  componentDidUpdate(prevProps: Readonly<ISfMetadataFormFieldsProps>): void {
    if (prevProps.expanded !== this.props.expanded) this.expanded = this.props.expanded
  }

  fetchSfOpportunity = async (search: string) => {
    if (!search) return

    try {
      const { data } = await SfOpportunity.list({ search })
      this.opportunities = data.map((item) => ({ ...item, value: item.name, label: item.name }))
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined)
    }
  }

  render() {
    if (!sharedDataStore.user.company.integratedSalesforceOrganizationUrl || sharedDataStore.user.isFaasPartner())
      return null

    const { disabled } = this.props

    return (
      <ContainerDiv flex={1} textAlign="left" marginBottom={!this.expanded ? '24px' : undefined} width="100%">
        {this.expanded ? (
          <Row gutter={8}>
            <Col lg={12} md={24}>
              <FjFormItem name="sfMetadata.name" fieldtitle="Opportunity">
                <FJStyledAutoComplete
                  name="sfMetadata.name"
                  placeholder="Add Opportunity"
                  allowClear
                  onSearch={(value) => this.fetchSfOpportunity(value)}
                  onSelect={this.props.handleSelect}
                  onClear={this.props.handleClear}
                  options={this.opportunities}
                  disabled={disabled}
                >
                  <FJStyledInput name="sfMetadata.name" />
                </FJStyledAutoComplete>
              </FjFormItem>
            </Col>
            <Col lg={12} md={24}>
              <FjFormItem
                name="sfMetadata.stageName"
                fieldtitle="Stage Name"
                tiptitle="Updating this field will NOT update stage of opportunity in Salesforce"
              >
                <FjSelect
                  name="sfMetadata.stageName"
                  options={sharedDataStore.stageNameOptions}
                  placeholder="Select a stage"
                  allowClear
                  disabled={disabled}
                />
              </FjFormItem>
            </Col>
          </Row>
        ) : (
          <DefaultButton
            buttonType="text"
            title="Add Opportunity/Stage"
            image={<img src={SalesforceBlueIcon} alt="protips" style={{ height: '12.66px' }} />}
            clicked={() => (this.expanded = true)}
            disabled={disabled}
          />
        )}
      </ContainerDiv>
    )
  }
}

export const SfMetadataDisplay: React.FC<{
  obj: LearningContent | Hub | Page | CoachingMoment | FeedPlaylist | SharedContent
  compressView?: boolean
  viewMode?: ViewMode
  marginBottom?: string
}> = ({ obj, compressView, viewMode = 'card', marginBottom = '12px' }) => {
  const isMobile = useMediaQuery(mobileQuery)
  const { id, name, stageName } = obj.sfMetadata || {}

  // If opportunity exists
  if (obj.hasOpportunity()) {
    return (
      <DefaultLink
        to={Paths.getOpportunityPath({
          id,
          queryParams: {
            contentType:
              obj instanceof SharedContent ? getFeedContentType(obj.learningContent) : getFeedContentType(obj),
          },
        })}
      >
        <ContainerDiv
          textAlign="left"
          gap={7}
          style={
            compressView
              ? {
                  fontSize: viewMode === 'card' ? '14px' : '12px',
                  color: viewMode === 'card' ? Colors.abbey : Colors.tapa,
                  textAlign: 'left',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : {}
          }
          whiteSpace="nowrap"
          marginBottom={marginBottom}
        >
          {viewMode === 'card' ? (
            <img
              src={SalesforceBlueIcon}
              alt="protips"
              style={{ verticalAlign: 'middle', height: '12.66px', paddingRight: '7px', display: 'inline' }}
            />
          ) : null}
          <FjText
            fontWeight={!compressView && viewMode === 'card' ? 'semi-bold' : undefined}
            fontSize={isMobile || viewMode === 'list' ? '12px' : '14px'}
            textAlign="left"
            lineHeight="17px"
          >
            {`${name}${stageName ? ' | ' : ''}`}
            <FjText
              fontStyle="italic"
              fontSize={isMobile || viewMode === 'list' ? '12px' : '14px'}
              textAlign="left"
              lineHeight="17px"
            >
              {stageName}
            </FjText>
          </FjText>
        </ContainerDiv>
      </DefaultLink>
    )
  }

  // Only if stageName exists
  if (obj.hasStageName()) {
    return (
      <ContainerDiv textAlign="left" marginBottom={marginBottom}>
        <Space align="center" size={7}>
          {viewMode === 'card' ? (
            <FastForward color={Colors.cornflowerBlue} size={22} style={{ verticalAlign: 'middle' }} />
          ) : null}
          <FjText
            fontStyle="italic"
            fontSize={isMobile || viewMode === 'list' ? '12px' : '14px'}
            textAlign="left"
            lineHeight="17px"
          >
            {stageName}
          </FjText>
        </Space>
      </ContainerDiv>
    )
  }
  return null
}
