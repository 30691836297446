import React from 'react'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ContainerDiv, DefaultLink, FjProgress } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { sharedDataStore, ViewMode } from 'src/store/DataStore'
import { getFeedContentBody, getFeedContentTargetLink, LearningContent } from 'src/utils/content'
import { QueueItem } from 'src/models/QueueItem'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { breadcrumbContextType } from 'src/components/Library/LibraryGrid'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedContentImageCard } from 'src/components/Library/FeedContentImageCard'
import { SfMetadataDisplay } from 'src/components/Feed/SfMetadataFormFields'
import HTMLExpandablePreview from 'src/components/Common/HTMLExpandablePreview'
import { AppearsIn } from 'src/components/Library/AppearsIn'
import { LibraryStats } from 'src/components/Library/LibraryStats'
import { AuthorAndTitle } from 'src/components/Library/AuthorAndTitle'
import { Call } from 'src/models/Call'
import { CallAttendeeInfo } from 'src/components/Library/CallAttendeeInfo'
import { LibraryActions } from 'src/components/Library/LibraryActions'
import styled from 'styled-components'
import { DealRoom } from 'src/models/DealRoom'
import { Template } from 'src/models/Template'

const ListViewWrapper = styled.div`
  display: grid;
  grid-template-columns: 330px auto;
  overflow: hidden;
  align-items: start;
  grid-column-gap: 32px;
  height: 100%;
  padding: 24px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  background-color: ${Colors.white};
  box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.06);
  @media (max-width: 992px) {
    grid-template-columns: 200px auto;
    grid-column-gap: 8px;
    padding: 8px;
  }
  @media (max-width: 480px) {
    grid-template-columns: 170px auto;
  }
  @media (max-width: 430px) {
    grid-template-columns: 142px auto;
  }
`

export interface ILibraryCardProps {
  // insideQueue reflects that LibraryCard component is rendered from Queue related component
  insideQueue?: boolean
  viewMode?: ViewMode
  insideDraft?: boolean
  insidePromotedSearch?: boolean
  handlePromotedSearchChangeSuccess?: () => Promise<void>
  // @todo - Fix below prop name - #183479764
  differentUserQueue?: boolean
  obj: LearningContent | Hub | Page | QueueItem
  promptId?: string
  playlistId?: string
  showActionsMenu?: boolean
  hideAddToCollection?: boolean
  hideAddToFavorite?: boolean
  hideAddToQueue?: boolean
  progress?: number
  compressView?: boolean
  breadcrumbContextObj?: breadcrumbContextType
  onEditSuccess?(obj: LearningContent | Hub | Page | QueueItem): void
  onArchiveSuccess?(): void
  onDeleteSuccess?(): void
  onCreateTemplateSuccess?(template: Template): void
  shouldHighlight?: boolean
}

@observer
export class LibraryCard extends React.Component<ILibraryCardProps> {
  static defaultProps = {
    viewMode: 'card',
  }
  render() {
    const {
      viewMode,
      insideQueue,
      insideDraft,
      differentUserQueue,
      obj,
      promptId,
      playlistId,
      shouldHighlight,
      compressView,
      breadcrumbContextObj,
    } = this.props

    const targetObj = obj instanceof QueueItem ? obj.linkedObject : obj
    const isOpportunity = targetObj instanceof SfOpportunity
    const isHub = targetObj instanceof Hub
    const isPage = targetObj instanceof Page
    const isCoachingMoment = targetObj instanceof CoachingMoment
    const isCall = targetObj instanceof Call
    const isDealRoom = targetObj instanceof DealRoom

    const hasProgress =
      !isOpportunity &&
      !isHub &&
      !isPage &&
      !isDealRoom &&
      Object.hasOwn(targetObj, 'progress') &&
      targetObj.progress != null &&
      targetObj.progress > 0

    const queueItemToHighlight = insideQueue && !differentUserQueue ? targetObj.id : null
    const linkTarget = getFeedContentTargetLink(targetObj, {
      queueItemToHighlight,
      promptId,
      playlistId,
      isAuthoring: insideDraft || (isCoachingMoment && sharedDataStore.user.isFaasAdminOrManager()),
    })

    const handleContentClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e.metaKey || e.ctrlKey) return

      e.preventDefault()
      sharedAppStateStore.navigate(linkTarget, false, breadcrumbContextObj ? breadcrumbContextObj : undefined)
    }

    const body = getFeedContentBody(targetObj)
    const { isMobile } = sharedAppStateStore

    if (viewMode === 'list')
      return (
        <DefaultLink to={linkTarget} style={{ textDecoration: 'none' }}>
          <ListViewWrapper>
            <ContainerDiv display="flex" flexDirection="column" height="100%">
              <FeedContentImageCard obj={obj} compressView={compressView} viewMode="list" />
              {hasProgress ? (
                <div style={{ marginTop: '-16px', marginBottom: '-6px' }}>
                  <FjProgress
                    percent={Math.floor(targetObj.progress * 100)}
                    trailColor={Colors.brownGray}
                    showInfo={false}
                  />
                </div>
              ) : null}
            </ContainerDiv>
            <ContainerDiv
              position="relative"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              flex="1 0 0"
              width="100%"
              height="100%"
              minWidth={isMobile ? '100%' : '60%'}
              gap={isMobile ? 4 : 16}
              overflow="hidden"
              paddingBottom="24px"
            >
              <ContainerDiv
                display="flex"
                alignItems="flex-start"
                alignSelf="stretch"
                justifyContent="space-between"
                width="100%"
              >
                <ContainerDiv width="100%" maxHeight={50} overflow="hidden">
                  <AuthorAndTitle targetObj={targetObj} viewMode="list" textColor={Colors.chambray} />
                  {!isMobile ? <SfMetadataDisplay obj={targetObj} viewMode="list" /> : null}
                </ContainerDiv>
                <LibraryActions {...this.props} viewMode="list" insideDraft={insideDraft} />
              </ContainerDiv>
              {body ? (
                <ContainerDiv
                  width="100%"
                  textAlign="left"
                  maxHeight={44}
                  height={44}
                  overflow="auto"
                  className="no-margin"
                  marginBottom={isMobile ? '4px' : ''}
                >
                  <HTMLExpandablePreview
                    rows={2}
                    expandable={false}
                    html={body}
                    parserOptions={{ showEmbedAsLink: true, showMediaAsLink: true, minimizeHeadings: true }}
                  />
                </ContainerDiv>
              ) : null}
              {isCall ? <CallAttendeeInfo targetObj={targetObj} /> : null}
              <AppearsIn obj={targetObj} />
              <LibraryStats obj={targetObj} />
            </ContainerDiv>
          </ListViewWrapper>
        </DefaultLink>
      )

    return (
      <ContainerDiv
        height="100%"
        cursor="pointer"
        borderRadius={8}
        overflow="hidden"
        position="relative"
        backgroundColor={Colors.white}
        border={shouldHighlight ? `3px solid ${Colors.neonCarrot}` : undefined}
        boxShadow={`2px 3px 8px 0px ${Colors.blackOpacity4}`}
      >
        <DefaultLink to={linkTarget} style={{ textDecoration: 'none' }}>
          <ContainerDiv
            onClick={handleContentClick}
            display="flex"
            flexDirection="column"
            gap="0px"
            padding="0px"
            position="relative"
          >
            <FeedContentImageCard obj={obj} compressView={compressView} borderRadius="unset" />
            {hasProgress && (
              <FjProgress
                style={{ marginTop: '-16px', marginBottom: '-6px', padding: '0px' }}
                percent={Math.floor(targetObj.progress * 100)}
                showInfo={false}
              />
            )}
          </ContainerDiv>
          <ContainerDiv padding="16px">
            <AuthorAndTitle targetObj={targetObj} />
          </ContainerDiv>
          <LibraryActions {...this.props} insideDraft={insideDraft} />
        </DefaultLink>
      </ContainerDiv>
    )
  }
}
