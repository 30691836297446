import { Col, Row } from 'antd'
import React from 'react'
import { LinkedinLoginButton } from 'src/components/Auth/LinkedinLogin'
import { ContainerDiv, FormActionButtons, FormHeaderText } from 'src/components/Common'
import { Paths } from 'src/constants/navigation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { User } from 'src/models/User'

interface ConnectLinkedinFormProps {
  submitButtonLabel?: string
  secondaryButtonLabel?: string
  onSuccess?(): void
  onCancel?(): void
}

export class ConnectLinkedinForm extends React.Component<ConnectLinkedinFormProps> {
  successHandler = async (oAuthData: any) => {
    try {
      await User.connectLinkedin({ code: oAuthData.code, redirectUri: Paths.getLinkedInOAuthRedirectURL() })
      if (this.props.onSuccess) this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    return (
      <>
        <FormHeaderText
          heading="Connect your LinkedIn"
          subHeading="The information on your LinkedIn page will be shown on your Flockjay profile."
        />
        <Row justify="center">
          <Col span={24}>
            <ContainerDiv marginVertical="20px" textAlign="center">
              <LinkedinLoginButton isLogin successHandler={this.successHandler} />
            </ContainerDiv>
          </Col>
          <Col span={24}>
            <FormActionButtons
              submitButtonLabel={this.props.submitButtonLabel}
              handlePrimaryButton={this.props.onSuccess}
              secondaryButtonLabel={this.props.secondaryButtonLabel}
              onCancel={this.props.onCancel}
            />
          </Col>
        </Row>
      </>
    )
  }
}
