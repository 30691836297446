import moment from 'moment'
import { DataObject } from 'src/models/DataObject'

export type Segment = {
  end: number
  start: number
  text: string
}

type DocumentMetaData = {
  segments: Segment[]
}

export class Document extends DataObject {
  static apiEndpoint: string = '/feed/document/'

  id: string = ''
  contentText: string = ''
  fileType: 'video' | 'audio'
  metadata: DocumentMetaData
  sourceUrl: string
  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
