import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { ContainerDiv, FormHeaderText } from 'src/components/Common'
import { VideoRecorderForm } from 'src/components/Feed/VideoRecorder'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { uploadToS3 } from 'src/utils/S3Upload'

export interface VideoModalFormProps {
  onCancel(): void
  onSuccess?(videoUrl?: string): void
}

@observer
export class VideoModalForm extends Component<VideoModalFormProps> {
  videoRecorded = async (file: File) => {
    try {
      sharedAppStateStore.hideVideoRecordModal = false
      sharedAppStateStore.videoRecorderProps = undefined
      const url = await sharedAppStateStore.wrapAppLoading(uploadToS3(file), 'Uploading video...')
      if (this.props.onSuccess) {
        this.props.onSuccess(url)
      }
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  videoRecordingCancelled = () => {
    sharedAppStateStore.hideVideoRecordModal = false
  }

  handleRecordStart = () => {
    sharedAppStateStore.hideVideoRecordModal = true
  }

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading="Record a video" />
        <VideoRecorderForm
          videoRecorded={this.videoRecorded}
          onCancel={this.videoRecordingCancelled}
          onStart={this.handleRecordStart}
          setDisabled={this.videoRecordingCancelled}
        />
      </ContainerDiv>
    )
  }
}
