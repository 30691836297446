import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ContainerDiv } from 'src/components/Common/Cards'
import { IFrame } from 'src/components/Common/IFrame'
import { Loader } from 'src/components/Common/Loader'

export const DEFAULT_GAP = 1500
export const GAP_MULTIPLIER = 1.25

type MSDocRendererProps = {
  fileUrl: string
}

// This component is designed for previewing MS documents using Google Document Viewer.
// While it generally functions correctly, there are occasional instances where the iframe fails to load.
// This issue is known and can be resolved by simply refreshing the browser, but we simulate refresh behavior by updating the iframe src.
// Another concern arises when users are on slow networks, as the iframe may not load within 1500 ms. Reloading the iframe every 1500 ms could lead to infinite loading behavior.
// So we address this by increasing the interval between reloads. In the code, we achieve this by multiplying the interval by 1.25.
export const MSDocRenderer = ({ fileUrl }: MSDocRendererProps) => {
  const [loaded, setLoaded] = useState(false)
  const iframeInterval = useRef<any>()
  const intervalTimeRef = useRef<number>(DEFAULT_GAP)

  const getIframeLink = useCallback(() => {
    return `https://docs.google.com/gview?url=${fileUrl}&embedded=true`
  }, [fileUrl])

  const updateIframeSrc = useCallback(() => {
    const iframe = document.getElementById(`ms-doc-iframe-${fileUrl}`)
    iframe.setAttribute('src', getIframeLink())

    clearTimeout(iframeInterval.current)
    const newIntervalTime = intervalTimeRef.current * GAP_MULTIPLIER
    intervalTimeRef.current = newIntervalTime
    const interval = setTimeout(updateIframeSrc, newIntervalTime)
    iframeInterval.current = interval
  }, [fileUrl, getIframeLink])

  useEffect(() => {
    const interval = setTimeout(updateIframeSrc, intervalTimeRef.current)
    iframeInterval.current = interval
  }, [updateIframeSrc])

  useEffect(() => {
    return () => {
      clearTimeout(iframeInterval.current)
    }
  }, [iframeInterval])

  const iframeLoaded = () => {
    setLoaded(true)
    clearTimeout(iframeInterval.current)
  }

  return (
    <>
      <ContainerDiv position="relative">
        {!loaded ? (
          <ContainerDiv position="absolute" top={0} bottom={0} left={0} right={0} margin="auto">
            <Loader />
          </ContainerDiv>
        ) : null}
        <IFrame
          id={`ms-doc-iframe-${fileUrl}`}
          onLoad={iframeLoaded}
          onError={updateIframeSrc}
          src={getIframeLink()}
          title={fileUrl}
          width="100%"
          height="100%"
        />
      </ContainerDiv>
    </>
  )
}
