import { cleanFilenameForUrl, snakify } from 'src/utils/format'
import { generateID } from './token'

const VIDEO_EXTS = ['mov', 'mp4', 'mkv', 'webm']
const IMAGE_EXTS = ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff']
const PDF_EXT = 'pdf'

export const renameFile = (file: File): File => {
  let newName = ''
  const parts = file.name.split('.')
  const ext = parts[parts.length - 1].toLowerCase()

  if (VIDEO_EXTS.includes(ext)) {
    newName = `fjvideo-${generateID()}.${ext}`
  } else if (IMAGE_EXTS.includes(ext)) {
    newName = `fjimg-${generateID()}.${ext}`
  } else if (ext === PDF_EXT) {
    newName = `fjpdf-${generateID()}.pdf`
  } else {
    newName = `fjfile-${generateID()}.${ext}`
  }

  const renamed = new File([file], newName, { type: file.type })
  return renamed
}

export const uniqueS3Filename = (name: string) => snakify(`${generateID()}/${cleanFilenameForUrl(name)}`)

export const uniqueS3File = (file: File): File => {
  return new File([file], uniqueS3Filename(file.name), { type: file.type })
}
