import React from 'react'
import { CarouselProps, Carousel } from 'antd'
import { ContainerDiv } from 'src/components/Common'
import { ILibraryCardProps, LibraryCard } from 'src/components/Library/LibraryCard'
import { QueueItem } from 'src/models/QueueItem'
import LeftArrowIcon from 'src/assets/icons/LeftArrow.svg'
import RightArrowIcon from 'src/assets/icons/RightArrow.svg'

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => <span {...props}>{children}</span>

interface ILibraryCardRowProps {
  insideQueue?: boolean
  insideDraft?: boolean
  insidePromotedSearch?: boolean
  handlePromotedSearchChangeSuccess?: () => Promise<void>
  differentUserQueue?: boolean
  objs: Array<ILibraryCardProps['obj']>
  promptId?: string
  playlistId?: string
  showActionsMenu?: boolean
  hideAddToCollection?: boolean
  hideAddToFavorite?: boolean
  hideAddToQueue?: boolean
  toggleQueueNotAllowed?: Array<boolean>
  queueItemToHighlight?: string
}

export const LibraryCardRow: React.FC<ILibraryCardRowProps & CarouselProps> = ({
  insideQueue,
  insideDraft,
  insidePromotedSearch,
  handlePromotedSearchChangeSuccess,
  differentUserQueue,
  objs,
  promptId,
  playlistId,
  hideAddToCollection,
  hideAddToFavorite,
  hideAddToQueue,
  showActionsMenu = true,
  queueItemToHighlight: idToHightlight,
  ...carouselProps
}) => (
  <ContainerDiv className="library-row">
    <Carousel
      lazyLoad="ondemand"
      initialSlide={0}
      arrows
      dots={false}
      infinite={false}
      responsive={[
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]}
      slidesToShow={5}
      slidesToScroll={5}
      swipe={false}
      swipeToSlide
      prevArrow={
        <SlickButtonFix>
          <img src={LeftArrowIcon} alt="" />
        </SlickButtonFix>
      }
      nextArrow={
        <SlickButtonFix>
          <img src={RightArrowIcon} alt="" />
        </SlickButtonFix>
      }
      {...carouselProps}
    >
      {objs &&
        objs.map((obj, i) => {
          const shouldHighlight =
            obj instanceof QueueItem ? obj.linkedObject.id === idToHightlight : obj.id === idToHightlight
          return (
            <div key={`library-card-${obj}`}>
              <LibraryCard
                {...{
                  insideQueue,
                  insideDraft,
                  insidePromotedSearch,
                  handlePromotedSearchChangeSuccess,
                  differentUserQueue,
                  obj: obj,
                  promptId,
                  playlistId,
                  hideAddToCollection,
                  hideAddToFavorite,
                  hideAddToQueue: hideAddToQueue || (obj instanceof QueueItem && !obj.toggleAllowed),
                  showActionsMenu,
                  shouldHighlight,
                }}
              />
            </div>
          )
        })}
    </Carousel>
  </ContainerDiv>
)
