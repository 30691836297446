import React from 'react'
import { Row, Col } from 'antd'
import { restrictContentWidth } from 'src/hoc/ContentWidth'
import { ContainerDiv, FjText, Anchor } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { frontendURL } from 'src/network/FlockjayProvider'
import logo from 'src/assets/icons/FJ/Flockjay_logo_black_rgb.png'
import LinkedInLogo from 'src/assets/icons/LinkedIn.png'
import FacebookLogo from 'src/assets/icons/Facebook.png'
import TwitterLogo from 'src/assets/icons/Twitter.png'
import InstagramLogo from 'src/assets/icons/Instagram.png'

const USECASES_LINKS = [
  {
    copy: 'Capture',
    href: '/capture',
  },
  {
    copy: 'Structure',
    href: '/structure',
  },
  {
    copy: 'Surface',
    href: '/surface',
  },
  {
    copy: 'Integrations',
    href: '/integrations',
  },
  {
    copy: 'Training',
    href: '/training',
  },
  {
    copy: 'Sales Onboarding',
    href: '/sales-onboarding-software',
  },
  {
    copy: 'Sales Management',
    href: '/sales-management-software',
  },
  {
    copy: 'Sales Enablement Software',
    href: '/sales-enablement-software',
  },
  {
    copy: 'Sales Performance',
    href: '/sales-performance-software',
  },
  {
    copy: 'Sales Training',
    href: '/sales-training',
  },
  {
    copy: 'Sales Analytics',
    href: '/sales-analytics',
  },
  {
    copy: 'Sales Engagement',
    href: '/sales-engagement',
  },
  {
    copy: 'Sales Leaderboards',
    href: '/sales-leaderboard-tools',
  },
  {
    copy: 'Sales Coaching Platform',
    href: '/sales-coaching-platform',
  },
  {
    copy: 'Sales Gamification Tools',
    href: '/sales-gamification-tools',
  },
  {
    copy: 'Conversation Intelligence',
    href: '/conversation-intelligence',
  },
  {
    copy: 'Sales Elevation Platform',
    href: '/sales-elevation-platform',
  },
  {
    copy: 'Remote Sales Tools',
    href: '/remote-sales-tools',
  },
  {
    copy: 'Sales Learning Management System (LMS)',
    href: '/sales-learning-management-system',
  },
  {
    copy: 'Sales Content Management',
    href: '/sales-content-management',
  },
  {
    copy: 'Peer-to-Peer Learning',
    href: '/peer-to-peer-learning',
  },
  {
    copy: 'Competitive Enablement',
    href: '/competitive-enablement',
  },
  {
    copy: 'Sales Readiness Software',
    href: '/sales-readiness-software',
  },
  {
    copy: 'Sales Learning Platform',
    href: '/sales-learning-platform',
  },
  {
    copy: '',
    href: '/',
  },
  {
    copy: '',
    href: '/',
  },
]

const PERSONAS_LINKS = [
  {
    copy: 'Sales Leaders',
    href: '/sales-leaders',
  },
  {
    copy: 'Revenue Operations',
    href: '/revenue-operations',
  },
  {
    copy: 'Sales Enablement',
    href: '/sales-enablement',
  },
  {
    copy: 'SMB',
    href: '/smb',
  },
  {
    copy: 'Mid Market',
    href: '/mid-market',
  },
  {
    copy: 'Enterprise',
    href: '/enterprises',
  },
]
const RESOURCES_LINKS = [
  {
    copy: 'Resource Library',
    href: '/resources',
  },
  {
    copy: 'Blog',
    href: '/blog',
  },
  {
    copy: 'Events',
    href: '/events',
  },
  {
    copy: 'People Powered Performance',
    href: '/people-powered-performance',
  },
]

const OFFERING_LINKS = [
  {
    copy: 'Product Overview',
    href: '/product',
  },
]
const MORE_LINKS = [
  {
    copy: 'About Us',
    href: '/about',
  },
  {
    copy: 'Careers',
    href: '/careers',
  },
  {
    copy: 'Regulatory info',
    href: '/regulatory-information',
  },
  {
    copy: 'Log in',
    href: '/login',
  },
  {
    copy: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    copy: 'Terms and Conditions',
    href: '/terms',
  },
]

const SOCIAL_MEDIA_LINKS = [
  {
    icon: LinkedInLogo,
    href: 'https://www.linkedin.com/school/flockjay/',
  },
  {
    icon: FacebookLogo,
    href: 'https://www.facebook.com/flockjayschool',
  },
  {
    icon: TwitterLogo,
    href: 'https://twitter.com/flockjay',
  },
  {
    icon: InstagramLogo,
    href: 'https://www.instagram.com/flockjayschool/',
  },
]

const PublicFooterContent: React.FC = () => {
  return (
    <ContainerDiv textAlign="left" marginVertical="60px">
      <img
        src={logo}
        alt="flockjay-logo"
        width="220px"
        onClick={() => (window.location.href = frontendURL)}
        style={{ cursor: 'pointer', marginBottom: '40px' }}
      />
      <Row justify="start" style={{ paddingLeft: '44px' }}>
        <Col xs={22} md={9}>
          <ContainerDiv maxWidth="240px" textAlign="left">
            <ContainerDiv textAlign="left" marginBottom="20px">
              <FjText fontWeight="semi-bold">340 S Lemon Ave, #3881 Walnut, CA 91789</FjText>
            </ContainerDiv>

            <Anchor type="blackBlack" href="tel:(510)431-8145" target="_blank">
              <FjText>(510) 431-8145</FjText>
            </Anchor>
            <br />
            <Anchor type="blackBlack" href="mailto:hello@flockjay.com" target="_blank">
              <FjText>hello@flockjay.com</FjText>
            </Anchor>
            <ContainerDiv textAlign="left" marginTop="20px" marginBottom="30px">
              <FjText>
                Copyright 2022 Flockjay. All rights reserved. Various trademarks held by their respective owners.
              </FjText>
            </ContainerDiv>

            <Row justify="start">
              <Col>
                {SOCIAL_MEDIA_LINKS.map(({ href, icon }) => (
                  <Anchor key={href} type="blackBlack" href={href} target="_blank">
                    <img src={icon} alt="" style={{ height: '32px', marginRight: '16px' }} />
                  </Anchor>
                ))}
              </Col>
            </Row>
          </ContainerDiv>
        </Col>
        <Col xs={22} md={6}>
          <ContainerDiv textAlign="left" marginBottom="5px">
            <FjText fontWeight="semi-bold" color={Colors.black}>
              USECASES
            </FjText>
          </ContainerDiv>
          {USECASES_LINKS.map((link) => (
            <ContainerDiv textAlign="left" marginBottom="5px">
              <a href={link.href}>
                <FjText color={Colors.black}>{link.copy}</FjText>
              </a>
            </ContainerDiv>
          ))}
        </Col>
        <Col xs={22} md={5}>
          <ContainerDiv textAlign="left" marginBottom="5px">
            <FjText fontWeight="semi-bold" color={Colors.black}>
              PERSONAS
            </FjText>
          </ContainerDiv>
          {PERSONAS_LINKS.map((link) => (
            <ContainerDiv textAlign="left" marginBottom="5px">
              <a href={link.href}>
                <FjText color={Colors.black}>{link.copy}</FjText>
              </a>
            </ContainerDiv>
          ))}
          <ContainerDiv textAlign="left" marginBottom="5px" marginTop="15px">
            <FjText fontWeight="semi-bold" color={Colors.black}>
              RESOURCES
            </FjText>
          </ContainerDiv>
          {RESOURCES_LINKS.map((link) => (
            <ContainerDiv textAlign="left" marginBottom="5px">
              <a href={link.href}>
                <FjText color={Colors.black}>{link.copy}</FjText>
              </a>
            </ContainerDiv>
          ))}
        </Col>
        <Col xs={22} md={4}>
          <ContainerDiv textAlign="left" marginBottom="5px">
            <FjText fontWeight="semi-bold" color={Colors.black}>
              OUR OFFERING
            </FjText>
          </ContainerDiv>
          {OFFERING_LINKS.map((link) => (
            <ContainerDiv textAlign="left" marginBottom="5px">
              <a href={link.href}>
                <FjText color={Colors.black}>{link.copy}</FjText>
              </a>
            </ContainerDiv>
          ))}
          <ContainerDiv textAlign="left" marginBottom="5px" marginTop="15px">
            <FjText fontWeight="semi-bold" color={Colors.black}>
              MORE LINKS
            </FjText>
          </ContainerDiv>
          {MORE_LINKS.map((link) => (
            <ContainerDiv textAlign="left" marginBottom="5px">
              <a href={link.href}>
                <FjText color={Colors.black}>{link.copy}</FjText>
              </a>
            </ContainerDiv>
          ))}
        </Col>
      </Row>
    </ContainerDiv>
  )
}

export const PublicFooter = restrictContentWidth(PublicFooterContent, Colors.grandis)
