import { Author } from 'src/models/Author'
import { SfOpportunity } from 'src/models/SfOpportunity'
import moment from 'moment'
import { observable, makeObservable } from 'mobx'
import { Favouritable } from 'src/models/Favouritable'
import { sharedDataStore } from 'src/store/DataStore'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Segment } from 'src/models/Document'
import { Colors } from 'src/constants/colors'

export class Call extends Favouritable {
  static apiEndpoint: string = '/feed/call/'
  static startedDateFormat = 'M/D/YYYY'

  static OVERRIDE_MAPPINGS = {
    user: ({ user }) => (user ? Author.fromData(user) : undefined),
    author: ({ user }) => (user ? Author.fromData(user) : undefined),
    started: (data) => (data.started ? moment(data.started) : undefined),
    scheduled: (data) => (data.scheduled ? moment(data.scheduled) : undefined),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    appearsIn: (data) => data.appearsIn ?? [],
    parties: ({ parties }) => (parties ? parties : []),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    learningContentType: () => 'call',
  }
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'call'

  getBulkEditFields = () => []

  id: string
  title: string
  stageName: string
  url: string
  duration: number
  parties: string[] = []
  scheduled: moment.Moment
  started: moment.Moment
  user?: Author
  author?: Author
  sfMetadata?: SfOpportunity
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  progress: number
  appearsIn: AppearsInType[]
  learningContentId: string
  transcript: Array<{
    topic: string
    sentences: Segment[]
    speakerId: string
  }>
  source: 'chorus' | 'gong' | 'clari'
  groupIds: string[]

  constructor() {
    super()
    makeObservable(this)
  }

  frontendQueryParamKey = () => 'callId'

  getDisplayObjectName = () => 'call'

  embededCallUrl = () => {
    if (this.source === 'gong') return `https://app.gong.io/embedded-call?call-id=${this.url.split('=')[1]}`
    else if (this.source === 'clari') {
      const callId = this.url.split('/').pop()
      return `https://copilot.clari.com/callembed/${callId}`
    }

    return this.url
  }

  canEdit = () => false

  isExpired = () => false

  canArchive = () => false

  isExpiringSoon = () => false

  canDelete = () => false

  canAddToCollection = () => true

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => false

  getTrackingElementId = () => `callelement-${this.id}`

  getViewTrackingEventType = () => 'gong_viewed'

  getViewTrackingObjIdKey = () => 'gongcall_id'

  viewTrackingThresholdReached = (start: moment.Moment) => moment().unix() - start.unix() >= 5 * 60

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  getSegments = () => {
    let sentences = []
    for (const item of this.transcript ?? []) {
      sentences = [
        ...sentences,
        ...item.sentences.map(({ start, end, text }) => ({ start: start / 1000, end: end / 1000, text })),
      ]
    }
    return sentences
  }

  getHeroBgColor = () => Colors.blueChalk3
  getContentTypeTagColor = () => Colors.blueChalk3
  getTagBgColor = () => Colors.blueChalk2
  getTagTitle = () => 'Call'

  imgSrc = () => null

  canCreateTemplate = () => false
}
