import styled from 'styled-components'
import { Switch } from 'antd'

import { Colors } from 'src/constants/colors'

export const FjSwitch = styled(Switch)`
  &.ant-switch {
    height: 18px !important;
    min-width: 34px !important;
  }
  .ant-switch-handle {
    width: 14px !important;
    height: 14px !important;
  }
  &.ant-switch-checked {
    background-color: ${Colors.cornflowerBlue};
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 14px - 2px) !important;
  }
`

export const FjGreenSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${Colors.silverTree};
  }
`
