import { QueryClient } from '@tanstack/react-query'

const sharedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3, // 3 mins
    },
  },
})

export { sharedQueryClient }
