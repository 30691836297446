import React, { useCallback, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Bell, Edit3, List, LogOut, Search, Settings, Target, User, UserCheck, Users } from 'react-feather'
import {
  ContainerDiv,
  FjFormItem,
  FjText,
  FjAvatar,
  DropdownButton,
  DefaultLink,
  FJStyledInput,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { Paths } from 'src/constants/navigation'
import { Badge, Dropdown, Input, Row, Col, MenuProps, AutoComplete } from 'antd'
import { Auth } from 'src/utils/Auth'
import { FeedPost } from 'src/models/FeedPost'
import KeyCodes from 'src/models/enums/KeyCodes'
import { Asset } from 'src/models/Asset'
import { getQueryParam } from 'src/utils/urlParams'
import { Prompt } from 'src/models/Prompt'
import { ContentTypeIcon } from 'src/utils/content'
import { debounce } from 'src/utils/debounce'
import { APIProvider } from 'src/network/APIProvider'
import { mobileQuery } from 'src/pages/public/common'
import flockjayWordMark from 'src/assets/icons/FJ/Flockjay_logo_black_rgb.png'
import { ContentWrapper } from 'src/components/Page/ContentWrapper'
import { observer } from 'mobx-react'

export const CREATE_MENU_BACKGROUND = `linear-gradient(90deg, #D9ECFF 8.85%, #DDEAFF 44.26%, #EBE0FF 82.27%)`

export const ProfileDropdown: React.FC = () => {
  const isPublicUser = sharedDataStore.user.isFaasPublic()

  const profileOption = {
    key: 'profile',
    label: <DefaultLink to={Paths.getProfilePath(sharedDataStore.user.id)}>Profile</DefaultLink>,
    icon: <User size={16} />,
  }
  const logoutOption = {
    className: 'danger-menu',
    key: 'logout',
    label: 'Logout',
    onClick: () => Auth.logout(),
    icon: <LogOut size={16} />,
  }

  let profileMenuContent: MenuProps = { items: [] }
  if (isPublicUser) {
    profileMenuContent['items'] = [profileOption, logoutOption]
  } else {
    profileMenuContent['items'] = [
      profileOption,
      sharedDataStore.user.isFaasAdmin()
        ? {
            key: 'team',
            label: <DefaultLink to={Paths.team}>Team</DefaultLink>,
            icon: <Users size={16} />,
          }
        : null,
      sharedDataStore.user.isFaasAdmin()
        ? {
            key: 'automations',
            label: <DefaultLink to={Paths.automationRules}>Automations</DefaultLink>,
            icon: <UserCheck size={16} />,
          }
        : null,
      sharedDataStore.user.isFaasAdminOrManager() &&
      (process.env.REACT_APP_DEMO_FEATURES_ENABLED === 'true' || sharedDataStore.user.showFeatureDisabledForOrg)
        ? {
            key: 'triggers',
            label: <DefaultLink to={Paths.coachingMomentTrigger}>Triggers</DefaultLink>,
            icon: <Target size={16} />,
          }
        : null,
      {
        key: 'settings',
        label: <DefaultLink to={Paths.settings}>Settings</DefaultLink>,
        icon: <Settings size={16} />,
      },
      sharedAppStateStore.isMobile ? { type: 'divider' } : null,
      ...(sharedAppStateStore.isMobile
        ? [
            {
              key: 'notifications',
              label: 'Notifications',
              onClick: () => (sharedAppStateStore.showNotificationDrawer = true),
              icon: <Bell size={16} />,
            },
            {
              key: 'tasks',
              label: 'Tasks',
              onClick: () => (sharedAppStateStore.showTaskDrawer = true),
              icon: <List size={16} />,
            },
          ]
        : []),
      { type: 'divider' },
      logoutOption,
    ]
  }
  return (
    <span style={{ cursor: 'pointer' }}>
      <Dropdown menu={profileMenuContent} placement="bottomRight">
        <FjAvatar src={sharedDataStore.user.displayPic} />
      </Dropdown>
    </span>
  )
}

interface ISearchInputProps {
  inputStyle?: React.CSSProperties
  submitSearch: (searchValue?: string) => void
  readDefaultQueryParam?: boolean
}

export const SearchInput: React.FC<ISearchInputProps> = ({ inputStyle, submitSearch, readDefaultQueryParam }) => {
  const defaultInputStyle: React.CSSProperties = { width: '100%', maxWidth: '480px', marginBottom: '0px' }
  const [autocompleteValues, setAutocompleteValues] = useState<{ value: string }[]>([])
  const touched = useRef(false)
  const searchValue = useRef('')

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === KeyCodes.ENTER_KEY) {
      e.preventDefault()
      submitSearch(searchValue.current)
    }
  }

  // eslint-disable-next-line
  const onAutocompleteSearch = useCallback(
    debounce(async (searchStr: string) => {
      touched.current = true
      const {
        data: { results },
      } = await APIProvider.getSearchAutocompleteValues({ search: searchStr, fields: 'search_term' })
      setAutocompleteValues((_) => results.map(({ searchTerm }) => ({ value: searchTerm })))
    }, 300),
    []
  )

  // eslint-disable-next-line
  const onFocus = useCallback(() => {
    if (!touched.current) onAutocompleteSearch(searchValue.current)
  }, [onAutocompleteSearch])

  return (
    <Input.Group compact>
      <FjFormItem className="search-form-item" name="searchStr" style={inputStyle || defaultInputStyle}>
        <AutoComplete
          options={autocompleteValues}
          onSearch={onAutocompleteSearch}
          onSelect={(searchStr: string) => submitSearch(searchStr)}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          onChange={(searchStr) => (searchValue.current = searchStr)}
          defaultValue={readDefaultQueryParam ? getQueryParam('q') : undefined}
        >
          <FJStyledInput
            prefix={<Search size={16} />}
            style={{
              borderRadius: '10px',
              border: 'none',
              height: '44px',
              boxShadow: 'none',
              borderColor: Colors.sharkOpacity25,
            }}
            placeholder="Search for courses, content, win stories…"
            backgroundColor={Colors.white}
            fontSize="medium"
            name="searchStr"
          />
        </AutoComplete>
      </FjFormItem>
    </Input.Group>
  )
}

const NavContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <ContainerDiv
      padding={isMobile ? '12px' : '12px 32px'}
      backgroundColor={Colors.white}
      style={{ overflow: 'visible', minHeight: '64px', borderBottom: `1px solid ${Colors.sharkOpacity10}` }}
    >
      {children}
    </ContainerDiv>
  )
}

@observer
export class FeedNavbar extends React.Component {
  formikRef: any

  constructor(props) {
    super(props)
    this.formikRef = React.createRef()
  }

  componentDidMount() {
    sharedDataStore.user.refreshBadgeCounts()
    const q = getQueryParam('q')
    if (q) this.formikRef.current.setFieldValue('searchStr', q)
  }

  navigateToSearchPage = (searchValue?: string) => {
    if (!this.formikRef.current || !searchValue) return

    const contentType = window.location.href.includes(Paths.search) ? getQueryParam('contentType') : undefined
    const searchType = getQueryParam('searchType')
    let q = searchValue || this.formikRef.current.values.searchStr
    sharedAppStateStore.navigate(Paths.getSearchPath(q, contentType, searchType))
  }

  handleCreatePost = () => {
    const hideModal = () => (sharedAppStateStore.feedPostModalProps = undefined)

    const onSuccess = (feedPost?: FeedPost) => {
      sharedDataStore.handleContentCreated?.(feedPost)
      hideModal()
    }

    sharedAppStateStore.feedPostModalProps = {
      onSuccess: onSuccess,
      onCancel: hideModal,
    }
  }

  handleCreatePrompt = () => {
    const hideModal = () => (sharedAppStateStore.promptModalProps = undefined)

    const onSuccess = (prompt: Prompt) => {
      sharedDataStore.handleContentCreated?.(prompt)
      hideModal()
    }

    sharedAppStateStore.promptModalProps = {
      onSuccess: onSuccess,
      onCancel: hideModal,
    }
  }

  showAssetForm = async () => {
    const hideModal = () => (sharedAppStateStore.assetModalProps = undefined)

    const onSuccess = (asset: Asset, close: boolean) => {
      sharedDataStore.handleContentCreated?.(asset)
      if (close) {
        hideModal()
        return
      }
      sharedAppStateStore.assetModalProps = {
        asset: asset,
        onSuccess: onSuccess,
        onCancel: hideModal,
      }
    }

    sharedAppStateStore.assetModalProps = {
      onSuccess: onSuccess,
      onCancel: hideModal,
    }
  }

  render() {
    const createMenuContent: MenuProps = {
      items: [
        {
          key: 'create-post-button',
          label: 'Create Post',
          onClick: () => this.handleCreatePost(),
          icon: <ContentTypeIcon contentType="feedpost" size={20} />,
        },
        {
          key: 'create-asset-button',
          label: 'Create Asset',
          onClick: this.showAssetForm,
          icon: <ContentTypeIcon contentType="asset" size={20} />,
        },
        !sharedDataStore.user.isFaasPartner()
          ? {
              key: 'create-prompt-button',
              label: 'Create Prompt',
              onClick: () => this.handleCreatePrompt(),
              icon: <ContentTypeIcon contentType="prompt" size={20} />,
            }
          : undefined,
        {
          key: 'create-collection-button',
          label: <DefaultLink to={Paths.getPlaylistAuthoringPath()}>Create Collection</DefaultLink>,
          icon: <ContentTypeIcon contentType="playlist" size={20} />,
        },
        sharedDataStore.user.isFaasAdminOrManager() || sharedDataStore.user.isFaasPartner()
          ? {
              key: 'create-lp-button',
              label: (
                <DefaultLink to={Paths.getLearningPathPath({ isAuthoring: true })}>Create Learning Path</DefaultLink>
              ),
              icon: <ContentTypeIcon contentType="learningpath" size={20} />,
            }
          : null,
        sharedDataStore.user.isFaasAdminOrManager() || sharedDataStore.user.isFaasPartner()
          ? {
              key: 'create-course-button',
              label: <DefaultLink to={Paths.getCoursePath({ isAuthoring: true })}>Create Course</DefaultLink>,
              icon: <ContentTypeIcon contentType="course" size={20} />,
            }
          : null,
        sharedDataStore.user.isFaasAdmin() || sharedDataStore.user.isFaasPartner()
          ? {
              key: 'create-hub-button',
              label: <DefaultLink to={Paths.getHubPath({ isAuthoring: true })}>Create Hub</DefaultLink>,
              icon: <ContentTypeIcon contentType="hub" size={20} />,
            }
          : null,
        sharedDataStore.user.isFaasAdmin() && process.env.REACT_APP_PAGES_ENABLED === 'true'
          ? {
              key: 'create-page-button',
              label: <DefaultLink to={Paths.getPagePath({ isAuthoring: true })}>Create Page</DefaultLink>,
              icon: <ContentTypeIcon contentType="page" size={20} />,
            }
          : null,
        !sharedDataStore.user.isFaasPublic()
          ? {
              key: 'create-deal-room-button',
              label: <DefaultLink to={Paths.getDealRoomPath({ isAuthoring: true })}>Create Deal Room</DefaultLink>,
              icon: <ContentTypeIcon contentType="dealroom" size={20} />,
            }
          : null,
      ],
    }

    const isPublicUser = sharedDataStore.user.isFaasPublic()

    const watermarkSrc = sharedAppStateStore.externalAcademy?.logoUrl || flockjayWordMark

    return (
      <NavContainer>
        <Row gutter={[5, 0]} justify="space-between" align="middle" style={{ overflow: 'visible' }}>
          <Col xs={0} sm={0} md={1} lg={8} xl={6}>
            {isPublicUser ? (
              <DefaultLink to={sharedDataStore.getLoginHandlerPath()}>
                <img src={watermarkSrc} alt="logo" style={{ maxWidth: '180px', marginTop: '5px' }} />
              </DefaultLink>
            ) : null}
          </Col>
          <Col xs={16} sm={16} md={13} lg={8} xl={12}>
            {!isPublicUser && (
              <Formik
                initialValues={{ searchStr: '', searchType: 'feedpost' }}
                onSubmit={() => this.navigateToSearchPage()}
                innerRef={this.formikRef}
              >
                <Form className="search-form">
                  <ContainerDiv
                    marginLeft={sharedAppStateStore.isMobile ? '46px' : ''}
                    textAlign={sharedAppStateStore.isMobile ? 'left' : 'center'}
                  >
                    <SearchInput
                      submitSearch={(searchStr: string) => this.navigateToSearchPage(searchStr)}
                      readDefaultQueryParam
                    />
                  </ContainerDiv>
                </Form>
              </Formik>
            )}
          </Col>
          <Col xs={7} sm={8} md={10} lg={8} xl={6} style={{ overflow: 'visible' }}>
            <ContainerDiv
              display="flex"
              gap={sharedAppStateStore.isMobile ? '6px' : '8px'}
              alignItems="center"
              justifyContent="right"
            >
              {!isPublicUser && !sharedAppStateStore.isMobile && (
                <>
                  <span
                    onClick={() => (sharedAppStateStore.showNotificationDrawer = true)}
                    style={{ cursor: 'pointer', overflow: 'visible', paddingRight: '1rem' }}
                  >
                    <Badge count={sharedDataStore.user.unreadNotificationCount} dot showZero={false}>
                      <Bell color={Colors.sandstone} size={22} />
                    </Badge>
                  </span>
                  <span
                    onClick={() => (sharedAppStateStore.showTaskDrawer = true)}
                    style={{ cursor: 'pointer', overflow: 'visible', paddingRight: '1rem' }}
                  >
                    <Badge count={sharedDataStore.user.incompleteQueueItemCount} dot showZero={false}>
                      <List color={Colors.sandstone} size={22} />
                    </Badge>
                  </span>
                </>
              )}
              {!isPublicUser && (
                <ContainerDiv
                  marginLeft={sharedAppStateStore.isMobile ? undefined : '8px'}
                  marginRight={sharedAppStateStore.isMobile ? undefined : '16px'}
                >
                  <DropdownButton menu={createMenuContent} background={CREATE_MENU_BACKGROUND}>
                    <Edit3
                      size={16}
                      style={{
                        pointerEvents: 'none',
                        color: Colors.chambray,
                        fontSize: '16px',
                      }}
                    />
                    {sharedAppStateStore.isMobile ? null : (
                      <FjText fontWeight="bold500" color={Colors.chambray}>
                        Create
                      </FjText>
                    )}
                  </DropdownButton>
                </ContainerDiv>
              )}
              <ProfileDropdown />
            </ContainerDiv>
          </Col>
        </Row>
      </NavContainer>
    )
  }
}

export interface IPublicFeedNavbarProps {
  wordMarkUrl?: string
  showLogo?: boolean
  rightComponent?: React.ReactNode
}

export const PublicFeedNavbar: React.FC<IPublicFeedNavbarProps> = ({
  wordMarkUrl,
  rightComponent,
  showLogo = true,
}) => {
  const image = (
    <img
      src={wordMarkUrl || sharedAppStateStore.externalAcademy?.logoUrl || flockjayWordMark}
      alt="logo"
      style={{ maxWidth: '180px' }}
    />
  )
  const logo =
    wordMarkUrl || sharedAppStateStore.externalAcademy?.logoUrl ? (
      image
    ) : (
      <DefaultLink to={sharedDataStore.getLoginHandlerPath()}>{image}</DefaultLink>
    )
  return (
    <NavContainer>
      <ContentWrapper backgroundColor={Colors.white} style={{ padding: '0' }}>
        <Row gutter={[5, 0]} justify="space-between" align="middle" style={{ overflow: 'visible' }}>
          <Col xs={20} lg={12} style={{ textAlign: 'left' }}>
            {showLogo ? logo : null}
          </Col>
          <Col xs={4} lg={12} style={{ textAlign: 'right' }}>
            {rightComponent}
          </Col>
        </Row>
      </ContentWrapper>
    </NavContainer>
  )
}
