import humps from 'humps'
import { observable, computed, makeObservable } from 'mobx'
import { LxpObject } from 'src/models/LxpObject'
import { Assignment } from 'src/models/Assignment'
import { AssignmentSubmission, AssignmentSubmissionStatusType } from 'src/models/AssignmentSubmission'
import { LiveSession } from 'src/models/LiveSession'
import { sharedDataStore } from 'src/store/DataStore'
import { FlockjayProvider, baseURL, frontendURL } from 'src/network/FlockjayProvider'
import { Moment } from 'moment'
import { Colors } from 'src/constants/colors'
import React from 'react'
import { Globe, CheckSquare, Video, Clipboard } from 'react-feather'
import MethodTypes from 'src/models/enums/MethodTypes'
import Route from 'src/network/Route'
import { User } from 'src/models/User'

export type SubmoduleType = 'content_html' | 'content_scorm' | 'assignment' | 'live_session'

export class Submodule extends LxpObject {
  static OVERRIDE_MAPPINGS = {
    assignment: (data) => (data.assignment ? Assignment.fromData(data.assignment) : undefined),
    assignmentSubmission: (data) =>
      data.assignmentSubmission ? AssignmentSubmission.fromData(data.assignmentSubmission) : undefined,
    liveSession: (data) => (data.liveSession ? LiveSession.fromData(data.liveSession) : undefined),
    userProgress: (data) =>
      data.userProgress && data.submoduleType === 'content_scorm' ? humps.decamelizeKeys(data.userProgress) : undefined,
  }
  static apiEndpoint: string = 'api/course/:courseId/submodule/'

  id: string
  @observable title: string
  submoduleType: SubmoduleType
  @observable content?: string
  assignmentId?: string
  scormFile?: string
  userProgress?: any
  @observable assignment?: Assignment
  @observable assignmentSubmission?: AssignmentSubmission
  liveSession?: LiveSession
  courseId: string
  @observable progressPercentage: number
  @observable totalScore?: number
  assignmentSubmissionStatus?: AssignmentSubmissionStatusType

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isCompleted() {
    return this.progressPercentage === 1
  }

  retakeAssessment = () => {
    this.assignmentSubmission = null
  }

  getScormFileUrl = () => {
    if (this.scormFile) {
      const path = this.scormFile.split(`${baseURL}/feed/files/flockjay-scorm-content/`)[1]
      return `${frontendURL}/scormcontent/${path}`
    }
    return ''
  }

  getSubmoduleTypeDisplay = (isAuthoring = false) => {
    let contentScormTitle = 'Lesson'
    if (sharedDataStore.user.isFaasAdmin() || isAuthoring) contentScormTitle = 'SCORM'

    return {
      content_html: 'Lesson',
      content_scorm: contentScormTitle,
      assignment: 'Assessment',
      live_session: 'Live Session',
    }[this.submoduleType]
  }

  getSubmoduleTypeColor = () => {
    return {
      content_html: Colors.zumthor,
      content_scorm: Colors.blueChalk,
      assignment: Colors.tusk2,
      live_session: Colors.whitePointer,
    }[this.submoduleType]
  }

  getSubmoduleIcon = (color?: string, size?: number) => {
    const style = { minWidth: size }
    switch (this.submoduleType) {
      case 'content_scorm':
        return <Globe color={color} size={size} style={style} />
      case 'assignment':
        return <CheckSquare color={color} size={size} style={style} />
      case 'live_session':
        return <Video color={color} size={size} style={style} />
      case 'content_html':
        return <Clipboard color={color} size={size} style={style} />
    }
  }

  isLiveSession = () => {
    return this.liveSession && this.submoduleType === 'live_session'
  }

  isAssignment = () => {
    return this.assignment && this.submoduleType === 'assignment'
  }

  isContentHtml = () => {
    return this.content && this.submoduleType === 'content_html'
  }

  isContentScorm = () => {
    return this.scormFile && this.submoduleType === 'content_scorm'
  }

  isContentHTMLOrLiveSession = () => {
    return this.isContentHtml() || this.isLiveSession()
  }

  isLocked = () => {
    return !this.isReleased() && !sharedDataStore.user.isAdmin() && !this.isLiveSession()
  }

  save = async (newData?: Object, updateObject?: boolean, partial: boolean = false, options?: Object) => {
    if (this.id) return await super.save(newData, updateObject, partial, options)
    return await super.post('', newData, true, '/api/submodule/')
  }

  static getUserProgress = async (id: string) => {
    const { path } = this.formatActionEndpoint(id, undefined, 'progress')
    const { data } = await FlockjayProvider(new Route(MethodTypes.GET, path))
    return User.fromData(data)
  }

  markUserProgressAsCompleted = async (userIds: string[]): Promise<User[]> => {
    const { data }: { data: Submodule } = await FlockjayProvider(
      new Route(MethodTypes.POST, `api/submodule/${this.id}/mark_user_progress_as_completed/`, {
        userIds,
      })
    )
    if (this.isLiveSession()) return User.fromData(data.liveSession.attendees)
  }

  getTrackingElementId = () => `submoduleelement-${this.id}`

  getViewTrackingEventType = () => 'submodule_viewed'

  getViewTrackingObjIdKey = () => 'submodule_id'

  viewTrackingThresholdReached = (start: Moment) => true
}
