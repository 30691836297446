import React from 'react'
import { Asset } from 'src/models/Asset'
import { FormHeaderText, IFrame } from 'src/components/Common'

export interface IEditDriveFileModalProps {
  onCancel: () => void
  asset: Asset
}

export const EditDriveFileModal: React.FC<IEditDriveFileModalProps> = ({ asset }) => {
  return (
    <>
      {/* Empty form header text for appropriate vertical spacing */}
      <FormHeaderText />
      <IFrame title={asset.title} src={asset.driveEditUrl()} width="100%" height="100%" />
    </>
  )
}
