import { observable, computed, makeObservable } from 'mobx'
import { Author } from 'src/models/Author'
import { FeedPost } from 'src/models/FeedPost'
import { Favouritable } from 'src/models/Favouritable'
import { sharedDataStore } from 'src/store/DataStore'
import moment from 'moment'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { MiniHub } from 'src/models/Hub'

export class Prompt extends Favouritable {
  static apiEndpoint: string = '/feed/prompt/'
  static OVERRIDE_MAPPINGS = {
    posts: ({ posts }) => (posts ? FeedPost.fromData(posts) : []),
    author: ({ author }) => (author ? Author.fromData(author) : undefined),
    tags: ({ tags }) => (tags ? tags : []),
    groupIds: ({ groupIds }) => (groupIds ? groupIds : []),
    hubs: ({ hubs }) => (hubs ? hubs : []),
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'prompt',
  }
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'prompt'

  getBulkEditFields = () => ['groupIds', 'tags', 'hubs', 'sfMetadata', 'expiryDate']

  id: string = ''
  @observable question: string = ''
  description: string = ''
  author?: Author
  posts: FeedPost[]
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  thumbnailUrl: string = ''
  contentCount: number = 0
  progress: number
  tags: string[] = []
  groupIds: string[] = []
  hubs: MiniHub[] = []
  sfMetadata?: SfOpportunity
  createdAt: moment.Moment
  lastUpdated: moment.Moment = moment()
  appearsIn: AppearsInType[]
  learningContentId: string
  hideResponseFromFeed: boolean

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get title() {
    return this.question
  }

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  getHeroBgColor = () => Colors.caramel
  getContentTypeTagColor = () => Colors.texasRose
  getTagBgColor = () => Colors.karry
  getTagTitle = () => 'Prompt'

  imgSrc = () => this.thumbnailUrl

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate?.diff(moment(), 'days') <= 7

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => false

  hasOpportunity = () => false

  hasStageName = () => false

  hasCustomFields = () => false

  canCreateTemplate = () => false
}
