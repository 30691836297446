import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ConfirmDialog } from 'src/components/Common/Modals'
import { getDeleteDialogContent } from 'src/utils/content'
import { DeleteableElement } from 'src/utils/getMenuContext'

export interface DeleteDialogProps {
  onConfirm(obj: DeleteableElement): void
  obj: DeleteableElement
}

@observer
export class DeleteDialog extends Component<{}> {
  render() {
    const { obj } = sharedAppStateStore.deleteDialogProps
    const { title, content } = getDeleteDialogContent(obj)
    return (
      <>
        {sharedAppStateStore.deleteDialogProps && (
          <ConfirmDialog
            open={!!sharedAppStateStore.deleteDialogProps}
            title={title}
            content={content}
            onCancel={() => (sharedAppStateStore.deleteDialogProps = undefined)}
            onConfirm={() => {
              sharedAppStateStore.deleteDialogProps.onConfirm(obj)
              sharedAppStateStore.deleteDialogProps = undefined
            }}
            confirmButtonTitle="Delete"
            confirmButtonType="destructive"
          />
        )}
      </>
    )
  }
}
