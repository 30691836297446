import React from 'react'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Tabs, Dropdown, Divider } from 'antd'
import { Eye, EyeOff, MoreHorizontal, X } from 'react-feather'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { ContainerDiv, DefaultButton, FjText, Loader, FjAvatar } from 'src/components/Common'
import { Paths } from 'src/constants/navigation'
import { Notification } from 'src/models/Notification'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { APIProvider } from 'src/network/APIProvider'
import { sharedDataStore } from 'src/store/DataStore'
import FjDrawer from 'src/components/Common/FjDrawer'
import { SharedContent } from 'src/models/SharedContent'

interface INotificationItemProps {
  notification: Notification
  onRemove: () => void
}

@observer
class NotificationItem extends React.Component<INotificationItemProps> {
  menuOptions = () => {
    const { notification } = this.props
    const toggleRead = async ({ domEvent }) => {
      domEvent.stopPropagation()
      await this.props.notification.save({ read: !this.props.notification.read })
      if (notification.read) {
        sharedDataStore.user.decreaseUnreadNotificationsCount()
      } else {
        sharedDataStore.user.increaseUnreadNotificationsCount()
      }
    }
    const remove = async ({ domEvent }) => {
      domEvent.stopPropagation()
      await this.props.notification.delete()
      sharedDataStore.user.decreaseUnreadNotificationsCount()
      this.props.onRemove()
    }

    return {
      items: [
        {
          key: 'mark',
          label: `Mark as ${notification.read ? 'unread' : 'read'}`,
          onClick: toggleRead,
          icon: notification.read ? (
            <EyeOff size={20} color={Colors.cornflowerBlue} />
          ) : (
            <Eye size={20} color={Colors.cornflowerBlue} />
          ),
        },
        {
          key: 'remove',
          onClick: remove,
          label: 'Remove',
          icon: <X size={20} color={Colors.cornflowerBlue} />,
        },
      ],
    }
  }

  notificationClicked = async () => {
    const { notification } = this.props
    if (!notification.read) {
      await notification.save({ read: true })
      sharedDataStore.user.decreaseUnreadNotificationsCount()
    }
    sharedAppStateStore.showNotificationDrawer = false
    if (['incomplete_queue_items', 'queue_items_due'].includes(notification.verb)) {
      sharedAppStateStore.showTaskDrawer = true
    } else if (notification.verb === 'expiring_contents') {
      sharedAppStateStore.navigate(
        Paths.getLibraryPath('asset', {
          expiry_date_after: moment().startOf('isoWeek').toISOString(),
          expiry_date_before: moment().endOf('isoWeek').toISOString(),
        })
      )
    } else if (sharedDataStore.user.isFaasPartner() && notification.target instanceof SharedContent) {
      sharedAppStateStore.navigate(
        Paths.getClassroomPath({
          assetId: notification.target.learningContent.id,
          params: { viewAnalytics: true },
        })
      )
    } else {
      sharedAppStateStore.navigate(notification.getTargetPath())
    }
  }

  render() {
    const { notification } = this.props

    const handleClickActor = () => {
      if (sharedDataStore.user.isFaasPublicOrPartner()) return
      sharedAppStateStore.showNotificationDrawer = false
      sharedAppStateStore.navigate(Paths.getProfilePath(notification.actor.id))
    }

    return (
      <>
        <div
          style={{
            padding: sharedAppStateStore.isMobile ? '5px 24px 5px 5px' : '10px 24px 10px 10px',
            cursor: 'pointer',
            borderRadius: notification.read ? '0px' : '10px',
            backgroundColor: notification.read ? Colors.white : Colors.whiteLilac,
          }}
        >
          <ContainerDiv display="flex" justifyContent="left" alignItems="start">
            <ContainerDiv>
              <FjAvatar style={{ float: 'left' }} size={38} author={notification.actor} />
            </ContainerDiv>
            <ContainerDiv
              lineHeight="15px"
              position="relative"
              textAlign="left"
              onClick={this.notificationClicked}
              paddingLeft="10px"
            >
              <ContainerDiv textAlign="left" lineHeight="22px">
                {notification.actor && (
                  <DefaultButton
                    buttonType="text"
                    style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}
                    title={`@${notification.actor.fullName}`}
                    clicked={handleClickActor}
                  />
                )}
                <FjText>{notification.copy}</FjText>
                <br />
                <FjText fontSize="12px" color={Colors.gray}>
                  {moment(notification.createdAt).fromNow()}
                </FjText>
              </ContainerDiv>
            </ContainerDiv>
            <Dropdown trigger={['hover']} menu={this.menuOptions()} placement="bottomLeft">
              <MoreHorizontal
                size={20}
                color={Colors.cottonSeed}
                style={{
                  minWidth: 20,
                  marginLeft: '5px',
                  float: 'right',
                  cursor: 'pointer',
                }}
              />
            </Dropdown>
          </ContainerDiv>
        </div>
        <Divider style={{ margin: '8px 0', borderColor: Colors.sharkOpacity10 }} />
      </>
    )
  }
}

interface NotificationItemListProps {
  unreadOnly?: boolean
}

@observer
export class NotificationItemList extends React.Component<NotificationItemListProps> {
  @observable error = false
  @observable loading = false
  @observable notifications: Notification[] = []
  @observable hasMore: boolean
  page = 1

  constructor(props: NotificationItemListProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    this.loading = true
    try {
      await this.fetchNotifications()
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      this.error = true
    } finally {
      this.loading = false
    }
  }

  fetchNotifications = async () => {
    const queryParams = { page: this.page, expand: 'learning_content' }
    if (this.props.unreadOnly) queryParams['read'] = false
    const { data, next } = await Notification.list(queryParams)
    this.notifications = this.page === 1 ? data : [...this.notifications, ...data]
    this.hasMore = !!next
  }

  fetchNextNotifications = () => {
    this.page += 1
    this.fetchNotifications()
  }

  clearAllNotifications = async () => {
    try {
      await APIProvider.markAllNotificationsAsRead()
      this.notifications.forEach((notification) => {
        notification.read = true
      })
      sharedDataStore.user.markAllNotificationsAsRead()
    } catch (error) {
      sharedAppStateStore.handleError(error, undefined, false)
    }
  }

  render() {
    if (this.error) {
      return (
        <FjText fontWeight="semi-bold" display="block" fontSize="medium">
          Unable to load notifications
        </FjText>
      )
    }

    if (this.loading) {
      return <Loader />
    }

    return this.notifications.length > 0 ? (
      <ContainerDiv>
        <ContainerDiv textAlign="left" marginTop="-10px">
          <DefaultButton buttonType="text" title="Mark all as read" clicked={this.clearAllNotifications} />
        </ContainerDiv>
        <ContainerDiv
          id="scrollable-target-notifications"
          height="calc(100vh - 180px)"
          overflowY="scroll"
          overflowX="hidden"
        >
          <InfiniteScroll
            dataLength={this.notifications.length}
            next={this.fetchNextNotifications}
            hasMore={this.hasMore}
            style={{ overflow: 'hidden' }}
            loader={<Loader />}
            scrollableTarget="scrollable-target-notifications"
          >
            {this.notifications.map((noti, index) => (
              <NotificationItem
                key={noti.id}
                notification={noti}
                onRemove={() => this.notifications.splice(index, 1)}
              />
            ))}
          </InfiniteScroll>
        </ContainerDiv>
      </ContainerDiv>
    ) : (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FjText fontStyle="italic" color={Colors.abbey}>
          No notifications
        </FjText>
      </div>
    )
  }
}

@observer
export class Notifications extends React.Component {
  render() {
    return (
      <>
        <FjDrawer
          title="Notifications"
          destroyOnClose
          open={sharedAppStateStore.showNotificationDrawer}
          onClose={() => (sharedAppStateStore.showNotificationDrawer = false)}
        >
          <Tabs
            defaultActiveKey="notifications-tab-all"
            destroyInactiveTabPane
            items={[
              { key: 'notifications-tab-all', label: 'All', children: <NotificationItemList /> },
              { key: 'notifications-tab-unread', label: 'Unread', children: <NotificationItemList unreadOnly /> },
            ]}
          />
        </FjDrawer>
      </>
    )
  }
}
