import { DataObject } from 'src/models/DataObject'
import { Asset } from 'src/models/Asset'
import { DealRoom } from 'src/models/DealRoom'
import { Page } from 'src/models/Page'
import moment from 'moment'
import { getFeedContentData } from 'src/utils/content'
import { sharedDataStore } from 'src/store/DataStore'

export class Template extends DataObject {
  static OVERRIDE_MAPPINGS = {
    learningContent: ({ learningContent: { contentType, contentData } }) =>
      getFeedContentData(contentData, contentType),
  }

  static apiEndpoint: string = '/feed/template/'

  id: string
  name: string
  learningContent: Asset | DealRoom | Page
  templatedLearningContentCount: number = 0

  canEdit = () => sharedDataStore.user.isFaasAdminOrManager()

  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
