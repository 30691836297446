import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { GradebookTable } from 'src/components/Learn/GradebookTable'
import { Colors } from 'src/constants/colors'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { Prompt } from 'src/models/Prompt'
import { getFeedContentTitle } from 'src/utils/content'

export type TeamProgressModalProps = {
  obj: Course | LearningPath | FeedPlaylist | Prompt
  groupId: string
}

export const TeamProgressModal = ({ obj, groupId }: TeamProgressModalProps) => {
  return (
    <ContainerDiv textAlign="left" paddingTop="20px">
      <FjText
        textAlign="left"
        fontWeight="bold"
        fontSize="25px"
        color={Colors.midnightBlue}
        marginBottom="24px"
        rows={1}
      >
        Team progress for {getFeedContentTitle(obj)}
      </FjText>
      <GradebookTable
        obj={obj}
        initialGroupId={groupId}
        initialTableView="all_tasks"
        hideTableViewFilter
        hideGroupFilter
        hideGradeAssessmentsButton
        hideExportMenu
      />
    </ContainerDiv>
  )
}
