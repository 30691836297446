import React from 'react'
import { Col, Row } from 'antd'
import { Formik } from 'formik'
import { Form } from 'formik-antd'

import {
  FjFormItem,
  FormActionButtons,
  FormHeaderText,
  FjInput,
  FjEmailInput,
  FjSelect,
  GroupSelector,
} from 'src/components/Common'
import { combineValidations, isRequired, isEmail, isFullName } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'

import { User } from 'src/models/User'
import { UserSelect } from 'src/components/Feed/UserSelect'
import { sharedDataStore } from 'src/store/DataStore'
import { observer } from 'mobx-react'
import { clearFalseyValues } from 'src/utils/format'

interface InviteTeammateFormProps {
  user?: User
  onCancel?(): void
  onSuccess?(): void
}
@observer
export class InviteTeammateForm extends React.Component<InviteTeammateFormProps> {
  handleSubmit = async (data: any) => {
    try {
      const { accessRole, managerId } = data
      const newUser = this.props.user || new User()
      await sharedAppStateStore.wrapAppLoading(
        newUser.save({ ...clearFalseyValues(data), accessRole: accessRole.trim(), managerId }, true, true)
      )
      if (this.props.onSuccess) await this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getInitialValues = () => {
    const user = this.props.user || new User()
    let { email, accessRole, fullName, manager, userSpecificGroupIds } = user
    if (!userSpecificGroupIds.length && !sharedDataStore.user.isPartOfManyGroups()) {
      userSpecificGroupIds = [sharedDataStore.user.defaultGroup.id]
    }
    // Antd Select component is not working for empty value for its option, so we add space to fix.
    // When submitting, we apply trim method to send correct value.
    return { fullName, email, accessRole: `${accessRole} `, managerId: manager?.id, groupIds: userSpecificGroupIds }
  }

  render() {
    const { user } = this.props
    return (
      <>
        <FormHeaderText heading={`${user ? 'Edit' : 'Add'} User`} />
        <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
          {({ values }) => (
            <Form>
              <Row gutter={[14, 0]}>
                <Col xs={24} md={12}>
                  <FjFormItem
                    fieldtitle="Full Name*"
                    name="fullName"
                    validate={combineValidations(isRequired, isFullName)}
                  >
                    <FjInput name="fullName" placeholder="Full Name" />
                  </FjFormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FjFormItem fieldtitle="Email*" name="email" validate={combineValidations(isRequired, isEmail)}>
                    <FjEmailInput name="email" placeholder="Email" readOnly={!!user} />
                  </FjFormItem>
                </Col>
                <Col xs={24} md={12}>
                  <FjFormItem fieldtitle="Role" name="accessRole">
                    <FjSelect
                      name="accessRole"
                      options={[
                        { label: 'Standard', value: ' ' },
                        { label: 'Manager', value: 'manager ' },
                        { label: 'Admin', value: 'admin ' },
                        { label: 'Public', value: 'public ' },
                        { label: 'Partner', value: 'partner ' },
                      ]}
                    />
                  </FjFormItem>
                </Col>
                {!['public ', 'partner '].includes(values.accessRole) ? (
                  <>
                    <Col xs={24} md={12}>
                      <FjFormItem fieldtitle="Manager" name="managerId">
                        <UserSelect
                          excludeUserIds={[user?.id]}
                          initialLabel={user?.manager?.fullName}
                          name="managerId"
                          placeholder="Manager"
                        />
                      </FjFormItem>
                    </Col>
                    <Col xs={24} md={12}>
                      {sharedDataStore.user.isPartOfManyGroups() ? (
                        <FjFormItem fieldtitle="Group" name="groupIds">
                          <GroupSelector name="groupIds" retainOptions={[sharedDataStore.user.defaultGroup.id]} />
                        </FjFormItem>
                      ) : null}
                    </Col>
                  </>
                ) : null}
              </Row>
              <FormActionButtons onCancel={this.props.onCancel} submitButtonLabel={user ? 'Save' : 'Send Invite'} />
            </Form>
          )}
        </Formik>
      </>
    )
  }
}
