import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { CourseSubmoduleList } from 'src/components/Course/CourseSubmoduleList'
import { Trophy } from 'src/components/Icons/Trophy'
import { Colors } from 'src/constants/colors'
import { Course } from 'src/models/Course'

export const CoursePreview: React.FC<{ course: Course }> = ({ course }) => {
  return (
    <>
      <ContainerDiv width="100%" marginBottom="10px" marginTop="24px">
        <CourseSubmoduleList course={course} preview />
      </ContainerDiv>
      {course.progress === 1 ? (
        <ContainerDiv display="flex" flexDirection="column" alignItems="center" width="100%">
          <Trophy width={48} height={48} color={Colors.texasRose} />
          <FjText fontSize="medium" fontWeight="semi-bold" color={Colors.squirrel}>
            Completed
          </FjText>
        </ContainerDiv>
      ) : null}
    </>
  )
}
