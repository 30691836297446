import { TextCustomProps } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'

enum Margins {
  default = '10px',
  none = '0px',
}

export interface MarginProps {
  margin?: boolean | string
  marginVertical?: boolean | string
  marginHorizontal?: boolean | string
  marginTop?: boolean | string
  marginRight?: boolean | string
  marginBottom?: boolean | string
  marginLeft?: boolean | string
}

export function getMarginObj(props: MarginProps) {
  return {
    marginTop: getMarginTop(props),
    marginRight: getMarginRight(props),
    marginBottom: getMarginBottom(props),
    marginLeft: getMarginLeft(props),
  }
}

export function getMarginTop(props: MarginProps) {
  if (props.marginTop) {
    return props.marginTop === true ? Margins.default : props.marginTop
  } else if (props.marginVertical) {
    return getMarginVertical(props.marginVertical)
  } else if (props.margin) {
    return getMargin(props.margin)
  }
  return Margins.none
}

export function getMarginRight(props: MarginProps) {
  if (props.marginRight) {
    return props.marginRight === true ? Margins.default : props.marginRight
  } else if (props.marginHorizontal) {
    return getMarginHorizontal(props.marginHorizontal)
  } else if (props.margin) {
    return getMargin(props.margin)
  }
  return Margins.none
}

export function getMarginBottom(props: MarginProps) {
  if (props.marginBottom) {
    return props.marginBottom === true ? Margins.default : props.marginBottom
  } else if (props.marginVertical) {
    return getMarginVertical(props.marginVertical)
  } else if (props.margin) {
    return getMargin(props.margin)
  }
  return Margins.none
}

export function getMarginLeft(props: MarginProps) {
  if (props.marginLeft) {
    return props.marginLeft === true ? Margins.default : props.marginLeft
  } else if (props.marginHorizontal) {
    return getMarginHorizontal(props.marginHorizontal)
  } else if (props.margin) {
    return getMargin(props.margin)
  }
  return Margins.none
}

function getMarginVertical(val: boolean | string) {
  return val === true ? Margins.default : (val as string)
}

function getMarginHorizontal(val: boolean | string) {
  return val === true ? Margins.default : (val as string)
}

function getMargin(val: boolean | string) {
  return val === true ? Margins.default : (val as string)
}

enum Padding {
  default = '10px',
  none = '0px',
}

export interface PaddingProps {
  padding?: boolean | string
  paddingVertical?: boolean | string
  paddingHorizontal?: boolean | string
  paddingTop?: boolean | string
  paddingRight?: boolean | string
  paddingBottom?: boolean | string
  paddingLeft?: boolean | string
}

export function getPaddingObj(props: PaddingProps) {
  return {
    paddingTop: getPaddingTop(props),
    paddingRight: getPaddingRight(props),
    paddingBottom: getPaddingBottom(props),
    paddingLeft: getPaddingLeft(props),
  }
}

export function getPaddingTop(props: PaddingProps) {
  if (props.paddingTop) {
    return props.paddingTop === true ? Padding.default : props.paddingTop
  } else if (props.paddingVertical) {
    return getPaddingVertical(props.paddingVertical)
  } else if (props.padding) {
    return getPadding(props.padding)
  }
  return Padding.none
}

export function getPaddingRight(props: PaddingProps) {
  if (props.paddingRight) {
    return props.paddingRight === true ? Padding.default : props.paddingRight
  } else if (props.paddingHorizontal) {
    return getPaddingHorizontal(props.paddingHorizontal)
  } else if (props.padding) {
    return getPadding(props.padding)
  }
  return Padding.none
}

export function getPaddingBottom(props: PaddingProps) {
  if (props.paddingBottom) {
    return props.paddingBottom === true ? Padding.default : props.paddingBottom
  } else if (props.paddingVertical) {
    return getPaddingVertical(props.paddingVertical)
  } else if (props.padding) {
    return getPadding(props.padding)
  }
  return Padding.none
}

export function getPaddingLeft(props: PaddingProps) {
  if (props.paddingLeft) {
    return props.paddingLeft === true ? Padding.default : props.paddingLeft
  } else if (props.paddingHorizontal) {
    return getPaddingHorizontal(props.paddingHorizontal)
  } else if (props.padding) {
    return getPadding(props.padding)
  }
  return Padding.none
}

function getPaddingVertical(val: boolean | string) {
  return val === true ? Padding.default : (val as string)
}

function getPaddingHorizontal(val: boolean | string) {
  return val === true ? Padding.default : (val as string)
}

function getPadding(val: boolean | string) {
  return val === true ? Padding.default : (val as string)
}

export enum TextSize {
  small = '12px',
  default = '14px',
  medium = '16px',
  large = '30px',
  extraLarge = '40px',
  mobileChevron = '40px',
  chevron = '58px',
}

export enum FontWeight {
  light = 300,
  default = 400,
  bold500 = 500,
  semiBold = 600,
  bold = 700,
  black = 900,
}

export type TextSizeOption = 'small' | 'default' | 'medium' | 'large' | 'extraLarge' | 'mobileChevron' | 'chevron'
export type TextWeightOption = 'light' | 'default' | 'semi-bold' | 'bold' | 'black' | 'bold500'
export type TextAlignOption = 'left' | 'center' | 'right' | 'justify'
export type TextDecorationOption = 'overline' | 'line-through' | 'underline' | 'none'
export type FontStyleOption = 'italic' | 'normal' | 'oblique'
export type WordWrapOption = 'normal' | 'break-word' | 'initial' | 'inherit'

export const pageTitleFontSize = 'clamp(1.25rem, 0.0781rem + 3.75vw, 2.1875rem)'

function getColor(props: any) {
  return props.error ? Colors.scarlet : props.color ? props.color : Colors.shark
}

export function getFontWeight(props: TextCustomProps) {
  if (props.fontWeight && props.fontWeight === 'light') {
    return FontWeight.light
  } else if (props.fontWeight && props.fontWeight === 'semi-bold') {
    return FontWeight.semiBold
  } else if (props.fontWeight && props.fontWeight === 'bold') {
    return FontWeight.bold
  } else if (props.fontWeight && props.fontWeight === 'black') {
    return FontWeight.black
  } else if (props.fontWeight && props.fontWeight === 'bold500') {
    return FontWeight.bold500
  }
  return FontWeight.bold500
}

export function getFontSize(props: TextCustomProps) {
  if (props.fontSize && props.fontSize === 'small') {
    return TextSize.small
  } else if (props.fontSize && props.fontSize === 'medium') {
    return TextSize.medium
  } else if (props.fontSize && props.fontSize === 'large') {
    return TextSize.large
  } else if (props.fontSize && props.fontSize === 'extraLarge') {
    return TextSize.extraLarge
  } else if (props.fontSize && props.fontSize === 'mobileChevron') {
    return TextSize.mobileChevron
  } else if (props.fontSize && props.fontSize === 'chevron') {
    return TextSize.chevron
  } else if (props.fontSize) {
    return props.fontSize
  }
  return TextSize.default
}

export const getCustomStyleObject = (props: any) => ({
  fontSize: getFontSize(props),
  color: getColor(props),
  backgroundColor: props.backgroundColor || props.highlight,
  fontWeight: getFontWeight(props),
  paddingTop: getPaddingTop(props),
  paddingRight: getPaddingRight(props),
  paddingBottom: getPaddingBottom(props),
  paddingLeft: getPaddingLeft(props),
  marginTop: getMarginTop(props),
  marginRight: getMarginRight(props),
  marginBottom: getMarginBottom(props),
  marginLeft: getMarginLeft(props),
})

export type ButtonSizeOption = 'small' | 'medium' | 'large'

export const getButtonSize = (size?: ButtonSizeOption) => {
  if (size === 'small') return '32px'
  if (size === 'medium') return '40px'
  if (size === 'large') return '48px'
  return '47px'
}

export const getButtonFontSize = (size?: ButtonSizeOption) => {
  if (size === 'small') return '14px'
  if (size === 'medium') return '14px'
  if (size === 'large') return '16px'
  return '14px'
}
