import React, { FC, ReactNode, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { sharedAppStateStore } from 'src/store/AppStateStore'

interface NavigationProps extends RouteComponentProps {
  children: ReactNode
}

const useAppNavigation: FC<NavigationProps> = (props: NavigationProps) => {
  useEffect(() => {
    sharedAppStateStore.setNavigation(props)
  }, [props])

  return <>{props.children}</>
}

const AppNavigation = withRouter(useAppNavigation)

export { AppNavigation }
