import { Formik } from 'formik'
import * as moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import React, { useCallback, useMemo, useState } from 'react'
import { Server } from 'react-feather'
import { ContainerDiv, DefaultButton, FjInput, FjText, Loader } from 'src/components/Common'
import { Document, Segment } from 'src/models/Document'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'

momentDurationFormatSetup(moment)

type TranscriptViewProps = {
  initialSegments?: Segment[]
  setCurrentTime?: (time: number) => void
  fetchTranscript?: () => Promise<Document>
}

const TranscriptView: React.FC<TranscriptViewProps> = ({ initialSegments, setCurrentTime, fetchTranscript }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [searchStr, setSearchStr] = useState('')
  const [loading, setLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [segments, setSegments] = useState(initialSegments ?? [])

  const filteredSegments = useMemo(
    () => segments.filter((data) => data.text.toLowerCase().includes(searchStr.toLowerCase())),
    [searchStr, segments]
  )

  const handleToggleVisible = useCallback(async () => {
    setIsVisible((isVisible) => !isVisible)
    if (!isVisible && fetchTranscript) {
      try {
        setLoading(true)
        const document = await fetchTranscript()
        setSegments(document.metadata.segments)
      } catch (err) {
        if (err.response.status === 404) setIsProcessing(true)
        else sharedAppStateStore.handleError(err)
      } finally {
        setLoading(false)
      }
    }
  }, [fetchTranscript, isVisible])

  if (!sharedDataStore.user.isFaasUser()) return null

  return (
    <ContainerDiv
      width="100%"
      textAlign="left"
      padding="16px 24px"
      borderRadius="16px"
      backgroundColor="rgba(216, 227, 249, 0.50)"
      marginTop="16px"
    >
      <DefaultButton
        title={`${isVisible ? 'Hide' : 'Show'} Transcript`}
        buttonType="text"
        clicked={handleToggleVisible}
      />
      {isVisible ? (
        <>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <FjInput
              style={{ marginTop: '12px' }}
              name="keyword"
              placeholder="Search transcript"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
            />
          </Formik>
          <ContainerDiv
            textAlign="left"
            height="200px"
            overflow="auto"
            display="flex"
            flexDirection="column"
            marginTop="12px"
            gap={8}
          >
            {loading ? (
              <Loader />
            ) : isProcessing ? (
              <ContainerDiv>
                <Server />
                <FjText display="block">
                  Beep boop beep boop... generating transcript... please check back later 😊
                </FjText>
              </ContainerDiv>
            ) : (
              filteredSegments.map((metadata) => {
                const startTime = moment.duration(metadata.start, 'seconds').format('hh:mm:ss', { trim: false })
                return (
                  <ContainerDiv display="flex" gap={16} alignItems="start">
                    {setCurrentTime ? (
                      <DefaultButton
                        title={startTime}
                        buttonType="text"
                        clicked={() => setCurrentTime(metadata.start)}
                        style={{ whiteSpace: 'nowrap', minWidth: 69 }}
                      />
                    ) : (
                      <FjText textAlign="left" fontWeight="semi-bold" style={{ minWidth: 69 }}>
                        {startTime}
                      </FjText>
                    )}
                    <FjText textAlign="left">{metadata.text}</FjText>
                  </ContainerDiv>
                )
              })
            )}
          </ContainerDiv>
        </>
      ) : null}
    </ContainerDiv>
  )
}

export default TranscriptView
