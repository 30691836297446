import { sharedDataStore } from 'src/store/DataStore'

type ProfileMenuOption = 'profile' | 'signOut' | 'directory' | 'blog' | 'pay' | 'referral' | 'team'
type DirectorySortOrder = 'batch' | 'fullName' | 'companyName' | 'location' | 'role'
type SortOrder = 'timeAscending' | 'upvotes' | 'postTitle' | 'timeDescending'
type PostOption = 'editPost' | 'deletePost'
type CommentOption = 'deleteComment'
type CandidateProfileOptions =
  | 'editPersonalInfo'
  | 'editSocials'
  | 'uploadResume'
  | 'editVideoQuote'
  | 'editVideo'
  | 'updateBio'
type ClassNavOptions =
  | 'classContent'
  | 'officeHours'
  | 'qualificationInterviews'
  | 'leaderboard'
  | 'trainerFeedback'
  | 'gradebook'
type CareerServicesNavOptions = 'mockInterviews' | 'candidates' | 'messages' | 'jobProfile'

const profileMenuOptions: ProfileMenuOption[] = ['profile', 'directory', 'blog', 'pay', 'referral', 'signOut']
const candidateMenuOptions: ProfileMenuOption[] = ['referral', 'pay', 'signOut']
const partnerMenuOptions: ProfileMenuOption[] = ['team', 'signOut']
const postSortOrders: SortOrder[] = ['timeDescending', 'timeAscending', 'upvotes']
const commentSortOrders: SortOrder[] = ['timeAscending', 'timeDescending']
const directorySortOrders: DirectorySortOrder[] = ['batch', 'fullName', 'companyName', 'location', 'role']
const postOptions: PostOption[] = ['editPost', 'deletePost']
const commentOptions: CommentOption[] = ['deleteComment']
const candidateProfileOptions: CandidateProfileOptions[] = [
  'editPersonalInfo',
  'editSocials',
  'uploadResume',
  'updateBio',
]
const baseClassNavMenuOptions: ClassNavOptions[] = ['classContent']
const careerServicesMenuOptions: CareerServicesNavOptions[] = ['jobProfile', 'mockInterviews']
const careerServicesMenuOptionsForAdmin: CareerServicesNavOptions[] = ['candidates', 'messages', 'mockInterviews']
const careerServicesMenuOptionsForTeachers: CareerServicesNavOptions[] = ['candidates', 'mockInterviews']

export type MenuOptionType =
  | SortOrder
  | ProfileMenuOption
  | DirectorySortOrder
  | PostOption
  | CommentOption
  | CandidateProfileOptions
  | ClassNavOptions
  | CareerServicesNavOptions

export class MenuOption {
  static profileNavOptions(exclude: MenuOptionType[] = []) {
    const options: MenuOption[] = []
    let menuOptions: ProfileMenuOption[] = profileMenuOptions
    if (sharedDataStore.user.isPartner()) {
      menuOptions = partnerMenuOptions
    } else if (sharedDataStore.user.isCandidate()) {
      menuOptions = candidateMenuOptions
    }
    menuOptions.forEach((id) => {
      if (!exclude.includes(id)) {
        options.push(new MenuOption(id))
      }
    })
    return options
  }

  static classNavOptions(
    showQualInterviews: boolean = false,
    showOfficeHours: boolean = false,
    hasTrainerFeedbackAccess: boolean = false,
    hasGradebookAccess: boolean = false
  ) {
    let options = [...baseClassNavMenuOptions]
    if (showOfficeHours) options.push('officeHours')
    if (hasGradebookAccess) options.push('gradebook')
    if (showQualInterviews) options.push('qualificationInterviews')
    options.push('leaderboard')
    if (hasTrainerFeedbackAccess) options.push('trainerFeedback')
    return options.map((id) => new MenuOption(id))
  }

  static careerServicesNavOptions(role: string) {
    let options: MenuOptionType[] = []
    if (role === 'student') options = careerServicesMenuOptions
    else if (role === 'admin') options = careerServicesMenuOptionsForAdmin
    else if (role === 'teacher') options = careerServicesMenuOptionsForTeachers
    return options.map((id) => new MenuOption(id))
  }

  static postSortCategories() {
    return postSortOrders.map((id) => new MenuOption(id))
  }

  static postOptions() {
    return postOptions.map((id) => new MenuOption(id))
  }

  static commentOptions() {
    return commentOptions.map((id) => new MenuOption(id))
  }

  static commentSortCategories() {
    return commentSortOrders.map((id) => new MenuOption(id))
  }

  static directorySortCategories() {
    return directorySortOrders.map((id) => new MenuOption(id))
  }

  static candidateProfileOptions() {
    return candidateProfileOptions.map((id) => new MenuOption(id))
  }

  static candidateProfileVideoOptions(canEditVideo: boolean, canEditQuote: boolean) {
    const options: CandidateProfileOptions[] = []
    if (canEditVideo) options.push('editVideo')
    if (canEditQuote) options.push('editVideoQuote')
    return options.map((id) => new MenuOption(id))
  }

  identifier: MenuOptionType

  constructor(identifier: MenuOptionType) {
    this.identifier = identifier
  }

  displayStr() {
    switch (this.identifier) {
      case 'timeAscending': {
        return 'Oldest'
      }
      case 'timeDescending': {
        return 'Newest'
      }
      case 'upvotes': {
        return 'Upvotes'
      }
      case 'postTitle': {
        return 'Alphabetically'
      }
      case 'profile': {
        return 'Profile'
      }
      case 'directory': {
        return 'Directory'
      }
      case 'blog': {
        return 'Blog'
      }
      case 'pay': {
        return 'Pay'
      }
      case 'signOut': {
        return 'Sign Out'
      }
      case 'batch': {
        return 'Batch'
      }
      case 'fullName': {
        return 'Full Name'
      }
      case 'companyName': {
        return 'Company Name'
      }
      case 'location': {
        return 'Location'
      }
      case 'role': {
        return 'Role'
      }
      case 'editPost': {
        return 'Edit Post'
      }
      case 'deletePost': {
        return 'Delete Post'
      }
      case 'deleteComment': {
        return 'Delete Comment'
      }
      case 'editPersonalInfo': {
        return 'Update Personal Information'
      }
      case 'editSocials': {
        return 'Update Social Links'
      }
      case 'uploadResume': {
        return 'Update Resume'
      }
      case 'editVideo': {
        return 'Edit Video'
      }
      case 'editVideoQuote': {
        return 'Edit Video Quote'
      }
      case 'classContent': {
        return 'Class Content'
      }
      case 'officeHours': {
        return 'Office Hours'
      }
      case 'qualificationInterviews': {
        return 'Qualification Interviews'
      }
      case 'mockInterviews': {
        return 'Mock Interviews'
      }
      case 'candidates': {
        return 'Candidates'
      }
      case 'referral': {
        return 'Refer a Friend'
      }
      case 'messages': {
        return 'Messages'
      }
      case 'leaderboard': {
        return 'Referral Leaderboard'
      }
      case 'updateBio': {
        return 'Update Bio'
      }
      case 'trainerFeedback': {
        return 'Trainer Feedback'
      }
      case 'jobProfile': {
        return 'Job Profile'
      }
      case 'team': {
        return 'Team'
      }
      case 'gradebook': {
        return 'Gradebook'
      }
      default: {
        return ''
      }
    }
  }
}
