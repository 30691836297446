import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { mobileQuery } from 'src/pages/public/common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { LearningContent, getFeedContentTargetLink, getFeedContentTitle } from 'src/utils/content'

type ContentTitleProps = {
  obj: LearningContent | Hub | Page | CoachingMoment
}

export const ContentTitle = ({ obj }: ContentTitleProps) => {
  const isMobile = useMediaQuery(mobileQuery)
  const isAsset = obj instanceof Asset
  return (
    <ContainerDiv
      cursor="pointer"
      textAlign="left"
      onClick={() => sharedAppStateStore.navigate(getFeedContentTargetLink(obj), false, { retain: true })}
      style={{ textDecoration: 'none' }}
    >
      <FjText
        textAlign="left"
        fontSize="clamp(0.8125rem, 0.2656rem + 1.75vw, 1.25rem)"
        lineHeight="30px"
        fontWeight="bold"
        color={Colors.chambray}
      >
        {getFeedContentTitle(obj)}
        {` `}
        {isAsset && obj.isInternal ? (
          <FjText fontStyle="italic" fontSize={isMobile ? '12px' : '16px'} lineHeight="22px">
            (Internal Use Only)
          </FjText>
        ) : null}
      </FjText>
    </ContainerDiv>
  )
}
