import React from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { FJStyledInput, FjInput, FjSelect, LoadingAutoComplete, FjText } from 'src/components/Common'
import { LearningContentType, fetchContent, getFeedContentTitle } from 'src/utils/content'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { debounce } from 'lodash'
import styled from 'styled-components'
import { deepEquals } from 'src/utils/format'
import { Colors } from 'src/constants/colors'

export const FjContentFieldTitleContainer = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5px;
  @media (max-width: 810px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    span:nth-child(2) {
      visibility: hidden;
    }
  }
`

const FjContentFieldContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 0fr 4fr;
  grid-template-rows: auto;
  grid-gap: 5px;
  div:nth-child(3) {
    visibility: hidden;
  }
  @media (max-width: 885px) {
    width: 60%;
    grid-gap: 5px;
    grid-template-columns: 1fr;
    div:nth-child(3) {
      visibility: visible;
    }
  }
`

const FjContentFieldWrapper = styled.div`
  text-align: left;
  max-height: 40px;
`

export const DEFAULT_DUE_DATE_OFFSET = 7

export type ContentDataOptionType = {
  key: string
  value: string
  label: string
  contentType: LearningContentType
  dueDateOffset?: number
}

interface IFjContentFieldProps {
  index: number
  value: ContentDataOptionType
  onChange: (index: number, option: ContentDataOptionType) => void
}

@observer
export class FjContentField extends React.Component<IFjContentFieldProps> {
  @observable contentDataOptions: ContentDataOptionType[] = []
  @observable isLoading = false
  @observable selectedValue?: ContentDataOptionType

  constructor(props: IFjContentFieldProps) {
    super(props)
    makeObservable(this)
    this.selectedValue = props.value
  }

  componentDidUpdate(prevProps: Readonly<IFjContentFieldProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!deepEquals(this.selectedValue || {}, this.props.value)) this.selectedValue = this.props.value
  }

  contentTypeOptionsMap = () =>
    new Map<string, string>([
      ['course', 'Courses'],
      ['playlist', 'Collections'],
      ['prompt', 'Prompts'],
      ['learningpath', 'Learning Paths'],
      ['asset', 'Assets'],
      ['feedpost', 'Posts'],
    ])

  handleSearch = debounce(async (value: string) => {
    try {
      this.isLoading = true
      const queryParams = {
        page: 1,
        sort_by: 'recent',
        search: value,
        fields: 'id,title,name,question',
      }
      if (this.selectedValue.contentType === 'feedpost') queryParams['exclude_linked'] = true
      const { data } = await fetchContent(this.selectedValue.contentType, queryParams)
      this.contentDataOptions = data.map((obj) => ({
        key: obj.id,
        value: obj.id,
        label: getFeedContentTitle(obj),
        contentType: this.selectedValue.contentType,
      }))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.isLoading = false
    }
  }, 300)

  handleContentTypeSelected = (value: LearningContentType) => {
    if (this.selectedValue.contentType !== value) this.contentDataOptions = []
    this.selectedValue = {
      contentType: value,
      key: undefined,
      value: undefined,
      label: undefined,
      dueDateOffset: DEFAULT_DUE_DATE_OFFSET,
    }
    this.props.onChange(this.props.index, this.selectedValue)
  }

  handleAutocompleteFocus = () => {
    if (!this.contentDataOptions.length) this.handleSearch('')
  }

  handleAutocompleteSelect = (option: ContentDataOptionType) => {
    this.selectedValue = { ...option, dueDateOffset: this.selectedValue?.dueDateOffset }
    this.props.onChange(this.props.index, this.selectedValue)
  }

  handleDueDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dueDateOffset = Number(e.target.value)
    if (isNaN(dueDateOffset) || dueDateOffset === 0) dueDateOffset = undefined
    this.selectedValue = { ...this.selectedValue, dueDateOffset }
    this.props.onChange(this.props.index, this.selectedValue)
  }

  handleAutocompleteCleared = () => {
    this.selectedValue = { ...this.selectedValue, key: undefined, value: undefined, label: undefined }
    this.props.onChange(this.props.index, this.selectedValue)
  }

  render() {
    return (
      <FjContentFieldContainer>
        <FjContentFieldWrapper>
          <FjSelect
            className="fj-content-type-select"
            placeholder="Content Type"
            name={''}
            optionsMap={this.contentTypeOptionsMap()}
            onSelect={this.handleContentTypeSelected}
            value={this.selectedValue ? this.contentTypeOptionsMap().get(this.selectedValue.contentType) : undefined}
          />
        </FjContentFieldWrapper>
        <FjContentFieldWrapper>
          <LoadingAutoComplete
            isLoadingOptions={this.isLoading}
            placeholder={
              this.selectedValue?.contentType
                ? `Search ${this.contentTypeOptionsMap().get(this.selectedValue.contentType).toLowerCase()}`
                : 'Select a content type'
            }
            disabled={!this.selectedValue?.contentType}
            allowClear
            options={this.contentDataOptions}
            onSearch={this.handleSearch}
            onFocus={this.handleAutocompleteFocus}
            onSelect={(_, option: ContentDataOptionType) => this.handleAutocompleteSelect(option)}
            onClear={this.handleAutocompleteCleared}
            value={this.selectedValue?.label}
          >
            <FJStyledInput name="" />
          </LoadingAutoComplete>
        </FjContentFieldWrapper>
        <FjContentFieldWrapper>
          <FjText color={Colors.tapa}>Due</FjText>
        </FjContentFieldWrapper>
        <FjContentFieldWrapper>
          <FjInput
            placeholder="e.g. 7"
            name="dueDateOffset"
            value={this.selectedValue?.dueDateOffset || ''}
            suffix="days after assignment"
            onChange={this.handleDueDateChanged}
            style={{ maxWidth: '232px' }}
          />
        </FjContentFieldWrapper>
      </FjContentFieldContainer>
    )
  }
}
