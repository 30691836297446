import moment from 'moment'
import { DataObject } from 'src/models/DataObject'
import { observable, makeObservable } from 'mobx'
import { Author } from 'src/models/Author'
import { Colors } from 'src/constants/colors'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { LearningContentType } from 'src/utils/content'

export class FeedTask extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    learningContentType: () => 'task',
  }
  static apiEndpoint: string = '/feed/task/'

  learningContentType: LearningContentType = 'task'

  getBulkEditFields = () => []

  id: string = ''
  @observable title: string = ''

  author: Author = new Author()
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  progress: number
  sfMetadata?: SfOpportunity

  constructor() {
    super()
    makeObservable(this)
  }

  getHeroBgColor = () => Colors.white
  getContentTypeTagColor = () => Colors.white
  getTagBgColor = () => Colors.pippin
  getTagTitle = () => 'Task'

  imgSrc = () => null

  hasOpportunity = () => false

  hasStageName = () => false
}
