import { Divider } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { BoxShadow, ContainerDiv, FjText } from 'src/components/Common'
import { LearningPathViewContentCard } from 'src/components/Content/LearningPathViewContentCard'
import { Colors } from 'src/constants/colors'
import { LearningPath } from 'src/models/LearningPath'
import { sharedAppStateStore } from 'src/store/AppStateStore'

export type LearningPathContentModalProps = {
  learningPath: LearningPath
  handleLearningPathUpdate: () => void
  selectedContentIndex: number
  stickyBottom: number
}

export const LearningPathContentModal = ({
  learningPath,
  handleLearningPathUpdate,
  selectedContentIndex,
  stickyBottom,
}: LearningPathContentModalProps) => {
  const handleClickOutside = useCallback((event) => {
    const contentModalElement = document.querySelector('.learning-path-content-modal')
    const viewContentsBtnElement = document.querySelector('#lp-nav-view-contents-btn')
    const taskModalElement = document.querySelector('.ant-modal-content')

    // Hide LearningPathContentModal when clicking outside of Modal, View Contents button, Task Modal
    if (
      !(
        contentModalElement.contains(event.target) ||
        viewContentsBtnElement?.contains(event.target) ||
        taskModalElement?.contains(event.target)
      )
    ) {
      sharedAppStateStore.learningPathContentModalProps = undefined
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])

  return (
    <ContainerDiv
      className="learning-path-content-modal"
      position="fixed"
      bottom={stickyBottom + (sharedAppStateStore.isMobile ? 128 : 78)}
      right={16}
      zIndex={999}
      left={sharedAppStateStore.isMobile ? 16 : undefined}
      width={sharedAppStateStore.isMobile ? 'calc(100% - 32px)' : '414px'}
      height={sharedAppStateStore.isMobile ? '50%' : '478px'}
      border={`solid 1px ${Colors.sharkOpacity10}`}
      borderRadius={'12px'}
      overflow="hidden"
      backgroundColor="white"
      boxShadow={BoxShadow.secondary}
    >
      <ContainerDiv textAlign="left" padding="16px 24px">
        <FjText textAlign="left" fontSize="20px" fontWeight="bold" color={Colors.midnightBlue}>
          Learning Path Content
        </FjText>
      </ContainerDiv>
      <Divider style={{ background: Colors.sharkOpacity10, margin: '0 0' }} />
      <ContainerDiv height="calc(100% - 62px)" overflow="auto">
        {learningPath.learningPathContents.map((feedContent, index) => (
          <LearningPathViewContentCard
            insideLearningPathContentModal
            key={feedContent.id}
            selected={selectedContentIndex === index}
            inProgress={learningPath.firstLockedContentIndex() === index}
            locked={learningPath.firstLockedContentIndex() < index && learningPath.requireSequentialCompletion}
            learningPath={learningPath}
            feedContent={feedContent}
            handleLearningPathUpdate={handleLearningPathUpdate}
          />
        ))}
      </ContainerDiv>
    </ContainerDiv>
  )
}
