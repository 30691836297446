import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import moment from 'moment'

export class IntegratedSsoOrganization extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: ({ author }) => (author ? Author.fromData(author) : undefined),
  }

  static apiEndpoint: string = '/feed/sso_settings/'

  id: string
  author: Author
  isActive: boolean
  idpSsoUrl: string
  idpSsoEntityId: string
  hasIdpCert: boolean
  autoProvisionEnabled: boolean

  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
