import { Form, Formik } from 'formik'
import React from 'react'
import { ContainerDiv, FjText, FormActionButtons, FormHeaderText } from 'src/components/Common'
import { UserCheckboxGroup } from 'src/components/Feed/UserCheckboxGroup'
import { showNotification } from 'src/hoc/Notification'
import { Course } from 'src/models/Course'
import { Submodule } from 'src/models/Submodule'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { pluralize } from 'src/utils/format'

export type AdminModuleCompletionsFormProps = {
  moduleCompletedUserIds: string[]
  course: Course
  submodule: Submodule
  onModuleCompletionConfirm: () => void
}

export const AdminModuleCompletionsForm = ({
  moduleCompletedUserIds,
  course,
  submodule,
  onModuleCompletionConfirm,
}: AdminModuleCompletionsFormProps) => {
  const onSave = async (data) => {
    const { userIds } = data
    try {
      await submodule.markUserProgressAsCompleted(userIds)
      onModuleCompletionConfirm()
      const newlyCompletedUsersCount = userIds.length - moduleCompletedUserIds.length
      showNotification({
        message: `Progress has been updated for ${newlyCompletedUsersCount} ${pluralize(
          'user',
          newlyCompletedUsersCount
        )}!`,
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      sharedAppStateStore.adminModuleCompletionsFormProps = undefined
    }
  }

  const handleSubmit = async (data) => {
    // If nothing has changed, just close the modal
    if (data.userIds.length === moduleCompletedUserIds.length) {
      sharedAppStateStore.adminModuleCompletionsFormProps = undefined
      return
    }

    sharedAppStateStore.sharedConfirmDialogProps = {
      onConfirm: () => onSave(data),
      content: 'Are you sure you want to confirm the completion list? Changes made cannot be undone.',
      confirmButtonTitle: 'Yes',
      cancelButtonTitle: 'No',
    }
  }

  return (
    <ContainerDiv textAlign="left">
      <FormHeaderText heading="Confirm Completions" />
      <FjText fontWeight="bold" textAlign="left">
        Select user(s) who have completed this module
      </FjText>
      <ContainerDiv marginTop="24px">
        <Formik initialValues={{ userIds: moduleCompletedUserIds }} onSubmit={handleSubmit}>
          <Form>
            <UserCheckboxGroup
              name="userIds"
              item={course}
              disabledOptionKeys={moduleCompletedUserIds}
              apiQueryParams={{ access_role: 'standard,admin,manager,partner,public' }}
            />
            <FormActionButtons submitButtonLabel="Mark as Completed" />
          </Form>
        </Formik>
      </ContainerDiv>
    </ContainerDiv>
  )
}
