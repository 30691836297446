import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import headerImage from 'src/assets/img/header/og.png'

const DefaultMetaData: React.FC = () => {
  const title = process.env.REACT_APP_OG_TITLE || 'Flockjay'
  const description =
    process.env.REACT_APP_OG_DESCRIPTION ||
    'Flockjay is a knowledge-sharing platform for the growth and development of sales teams.'
  const twitterHandle = process.env.REACT_APP_TWITTER_HANDLE || '@flockjay'
  const keywords =
    process.env.REACT_APP_OG_KEYWORDS ||
    'flockjay,training,sales,sell,bootcamp,academy,education,learn,learning,scale,business,tech,technology,career'
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={headerImage} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={headerImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:creator" content={twitterHandle} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  )
}

interface BaseFeedMetaDataProps {
  title?: string
  description?: string
}

const BaseFeedMetaData: React.FC<BaseFeedMetaDataProps> = ({ title, description }) => {
  const ogTitle = process.env.REACT_APP_OG_TITLE || 'Flockjay'
  const pageTitle = title ? `${ogTitle} | ${title}` : ogTitle
  const pageDescription =
    description ||
    process.env.REACT_APP_OG_DESCRIPTION ||
    'Flockjay is a knowledge-sharing platform for the growth and development of sales teams.'
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="og:title" content={pageTitle} />
      <meta name="og:description" content={pageDescription} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
  )
}

interface MetaDataProps extends React.PropsWithChildren {
  title?: string
}

@observer
export class MetaData extends Component<MetaDataProps> {
  getHeader = () => {
    const { title } = this.props
    const metaData = [<DefaultMetaData key="default-metadata" />]
    if (title) metaData.push(<BaseFeedMetaData title={title} key="custom-metadata" />)
    return metaData
  }

  render() {
    return (
      <>
        {this.getHeader()}
        {this.props.children}
      </>
    )
  }
}
