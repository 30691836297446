import { User } from 'src/models/User'
import { DataObject } from 'src/models/DataObject'
import momentTz from 'moment-timezone'
import { computed, makeObservable } from 'mobx'
import { AppearsInType, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { Author } from 'src/models/Author'

export class SfOpportunity extends DataObject {
  static OVERRIDE_MAPPINGS = {
    user: (data) => (data.user ? User.fromData(data.user) : null),
    author: (data) => (data.user ? User.fromData(data.user) : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'opportunity',
  }
  static apiEndpoint: string = '/feed/sfopportunity/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'opportunity'

  getBulkEditFields = () => []

  id: string
  name: string
  accountName: string
  stageName: string
  opportunityName: string
  contentCount: number
  user: User
  author: Author
  closedDate: momentTz.Moment
  createdAt: moment.Moment
  amount: number
  description: string
  numberOfEmployees: number
  industry: string
  progress: number
  appearsIn: AppearsInType[]
  learningContentId: string

  // These properties don’t actually exist on this model
  // They are here because TS doesn’t retain class information when checks are made from another function
  isFavourited?: false
  sfMetadata?: SfOpportunity

  getDisplayName = () => {
    return this.accountName || this.name
  }

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get title() {
    return this.name
  }

  canEdit = () => false

  canArchive = () => false

  canDelete = () => false

  canAddToCollection = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => false

  canAddToPromotedSearch = () => false

  canDuplicate = () => false

  hasOpportunity = () => false

  hasStageName = () => false

  getHeroBgColor = () => Colors.onahau
  getContentTypeTagColor = () => Colors.anakiwa
  getTagBgColor = () => Colors.zumthor
  getTagTitle = () => 'Opportunity'

  imgSrc = () => null

  // This is meant to format for save to the backend
  // when sfMetadata is connected to another object (e.g. Asset, DealRoom)
  formatMetadataForSave = () => ({ id: this.id, name: this.name, stageName: this.stageName })

  canCreateTemplate = () => false
}
