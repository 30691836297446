import * as React from 'react'
import { SVGProps } from 'react'

export const Drag = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 9.75C6.41421 9.75 6.75 9.41421 6.75 9C6.75 8.58579 6.41421 8.25 6 8.25C5.58579 8.25 5.25 8.58579 5.25 9C5.25 9.41421 5.58579 9.75 6 9.75Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.75 9.75C13.1642 9.75 13.5 9.41421 13.5 9C13.5 8.58579 13.1642 8.25 12.75 8.25C12.3358 8.25 12 8.58579 12 9C12 9.41421 12.3358 9.75 12.75 9.75Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 4.5C6.41421 4.5 6.75 4.16421 6.75 3.75C6.75 3.33579 6.41421 3 6 3C5.58579 3 5.25 3.33579 5.25 3.75C5.25 4.16421 5.58579 4.5 6 4.5Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.75 4.5C13.1642 4.5 13.5 4.16421 13.5 3.75C13.5 3.33579 13.1642 3 12.75 3C12.3358 3 12 3.33579 12 3.75C12 4.16421 12.3358 4.5 12.75 4.5Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 15C6.41421 15 6.75 14.6642 6.75 14.25C6.75 13.8358 6.41421 13.5 6 13.5C5.58579 13.5 5.25 13.8358 5.25 14.25C5.25 14.6642 5.58579 15 6 15Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.75 15C13.1642 15 13.5 14.6642 13.5 14.25C13.5 13.8358 13.1642 13.5 12.75 13.5C12.3358 13.5 12 13.8358 12 14.25C12 14.6642 12.3358 15 12.75 15Z"
      fill="#BFBFBF"
      stroke="#BFBFBF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
