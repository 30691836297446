import React from 'react'
import { FjCheckBox, FjFormItem } from 'src/components/Common'
import { getFeedContentTypeName, LearningContentType } from 'src/utils/content'

type AddGroupsToChildContentsFieldProps = {
  contentType: LearningContentType
  label?: string
  style?: React.CSSProperties
}

export const AddGroupsToChildContentsField = ({ contentType, label, style }: AddGroupsToChildContentsFieldProps) => {
  const formLabel =
    label || `Grant these selected groups access to all content within this ${getFeedContentTypeName({ contentType })}`
  return (
    <FjFormItem name="addGroupsToChildContents" style={style}>
      <FjCheckBox name="addGroupsToChildContents" alignItems="flex-start">
        {formLabel}
      </FjCheckBox>
    </FjFormItem>
  )
}
