import moment from 'moment'
import { Author } from 'src/models/Author'
import { DataObject } from './DataObject'
import { observable, makeObservable } from 'mobx'
import { CommentableItem } from 'src/components/Feed/FeedComments'
import { FeedPost } from 'src/models/FeedPost'
import { Asset } from 'src/models/Asset'

export class FeedComment extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: (data) => Author.create(data.author),
  }
  static apiEndpoint: string = '/feed/:parentObjectType/:parentObjectId/comments/'

  id: string = ''
  postId: string
  assetId: string
  parentId: string = ''
  parentObjectType = ''
  parentObjectId: string = ''
  author: Author = new Author()
  body: string = ''
  bodyPreviewText?: string
  @observable likeCount: number = 0
  @observable liked: boolean = false
  @observable replyCount: number = 0

  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  constructor() {
    super()
    makeObservable(this)
  }

  likeCountDisplay = () => {
    if (this.likeCount === 0) return ''
    return `${this.likeCount}`
  }

  isParent = () => !this.parentId

  static getParentObjectType = (obj) => {
    if (obj instanceof FeedPost) return 'posts'
    if (obj instanceof Asset) return 'assets'
  }

  static getComments = async (param: any, obj: CommentableItem) => {
    return FeedComment.list(param, { parentObjectType: FeedComment.getParentObjectType(obj), parentObjectId: obj.id })
  }

  removeFrom = async (obj: CommentableItem) => {
    this.parentObjectId = obj.id
    this.parentObjectType = FeedComment.getParentObjectType(obj)
    return this.delete()
  }

  add = async (obj: CommentableItem, data: any) => {
    if (data?.existingCommentId) this.id = data.existingCommentId
    this.parentObjectId = obj.id
    this.parentObjectType = FeedComment.getParentObjectType(obj)
    return this.save(data)
  }

  like = async (obj: CommentableItem) => {
    this.parentObjectId = obj.id
    this.parentObjectType = FeedComment.getParentObjectType(obj)
    return this.post('react', { reaction_type: 'like' }, true)
  }
}
