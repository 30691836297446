import React from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { observable, makeObservable } from 'mobx'

import {
  FjFormItem,
  FormActionButtons,
  FormHeaderText,
  FjInput,
  ContainerDiv,
  FJStyledAutoComplete,
  FJStyledInput,
  Loader,
} from 'src/components/Common'
import { isRequired } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'

import { User } from 'src/models/User'
import { Group } from 'src/models/Group'
import { observer } from 'mobx-react'
import { Colors } from 'src/constants/colors'
import { AuthorDisplay } from '../Common/AuthorDisplay'
import { showNotification } from 'src/hoc/Notification'
import { Auth } from 'src/utils/Auth'
import { DefaultOptionType } from 'antd/lib/select'
import { Trash } from 'react-feather'
import { debounce } from 'src/utils/debounce'

const INPUT_BOX_WIDTH = 280

interface CreateGroupFormProps {
  onCancel?: () => void
  onSuccess?: () => void
  groupId?: string
}

@observer
export class CreateGroupForm extends React.Component<CreateGroupFormProps> {
  @observable group: Group = undefined
  @observable users: User[] = []
  @observable userOptions: DefaultOptionType[] = []
  @observable userListLoading: boolean = false
  @observable groupLoading: boolean = false

  formikRef: any

  constructor(props: CreateGroupFormProps) {
    super(props)
    makeObservable(this)
    this.formikRef = React.createRef()
  }

  componentWillMount = async () => {
    await this.fetchGroup()
  }

  fetchGroup = async () => {
    const { groupId } = this.props

    if (!groupId) {
      this.group = new Group()
      return
    }

    try {
      this.groupLoading = true
      this.group = await Group.get(groupId)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.groupLoading = false
    }
  }

  fetchUserList = async (searchValue = '') => {
    try {
      this.userListLoading = true
      // We use forceUpdate here, on change of the userListLoading observable, to make sure the Loader shows
      this.forceUpdate()
      const { data } = await User.list({ is_active: true, search: searchValue })
      this.users = data
      const userIdsInGroup = new Set([...this.group.users.map((user) => user.id)])
      this.userOptions = this.users.flatMap((user) =>
        userIdsInGroup.has(user.id) ? [] : { value: user.id, label: user.fullName }
      )
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.userListLoading = false
      // We forceUpdate here to make sure that the userOptions are populated in the auto complete, and the Loader disappears
      this.forceUpdate()
    }
  }

  handleSubmit = async (data: any) => {
    const { groupId } = this.props
    const { name, users } = data
    try {
      await sharedAppStateStore.wrapAppLoading(this.group.save({ name: name, user_ids: users.map((user) => user.id) }))
      showNotification({
        message: `${data.name} Group has been ${groupId ? 'updated' : 'created'} successfully`,
      })
      // The session needs to be refreshed, to update the groups of the current user
      Auth.refreshUserSession(true)
      if (this.props.onSuccess) this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onSearch = debounce((value: string) => {
    this.formikRef.current.setFieldValue('search', value)
    this.fetchUserList(value)
  })

  onSelect = (value: string, option: DefaultOptionType) => {
    this.formikRef.current.setFieldValue('search', '')
    let selectedUsers = this.formikRef.current.values['users']
    const selectedUser = this.users.find((user) => user.id === option.value)
    selectedUsers.push(selectedUser)
    selectedUsers = [...new Map(selectedUsers.map((user) => [user.id, user])).values()]
    this.formikRef.current.setFieldValue('users', selectedUsers)
    this.userOptions = this.userOptions.filter((userOption) => userOption.value !== selectedUser.id)
  }

  onFocus = () => {
    if (!this.userOptions.length) this.fetchUserList()
  }

  onClear = () => {
    this.formikRef.current.setFieldValue('search', '')
    this.userOptions = [
      ...this.users.map((user) => {
        return { value: user.id, label: user.fullName }
      }),
    ]
  }

  handleRemove = (u: User) => {
    let selectedUsers = this.formikRef.current.values['users']
    selectedUsers = selectedUsers.filter((user) => user.id !== u.id)
    this.formikRef.current.setFieldValue('users', [...selectedUsers])
    this.userOptions = [...this.userOptions, { value: u.id, label: u.fullName }]
  }

  render() {
    const { groupId } = this.props
    const initialValues = { name: this.group?.name, users: this.group?.users, search: '' }
    return (
      <>
        <FormHeaderText heading={groupId ? 'Edit Group' : 'Create Group'} />
        {this.groupLoading ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              innerRef={this.formikRef}
              enableReinitialize
            >
              {({ values }) => (
                <Form>
                  <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                    <FjFormItem fieldtitle="Group Name*" name="name" validate={isRequired}>
                      <FjInput name="name" placeholder="Group Name" style={{ width: INPUT_BOX_WIDTH }} />
                    </FjFormItem>
                    <ContainerDiv display="flex" flexDirection="row" gap="10px" alignItems="center">
                      <FjFormItem fieldtitle="Search for User to add" name="search">
                        <FJStyledAutoComplete
                          name="search"
                          options={this.userOptions}
                          onSearch={this.onSearch}
                          onSelect={this.onSelect}
                          onFocus={this.onFocus}
                          style={{ width: INPUT_BOX_WIDTH }}
                          allowClear
                        >
                          <FJStyledInput name="input" placeholder="Search User" />
                        </FJStyledAutoComplete>
                      </FjFormItem>
                      {this.userListLoading ? <Loader /> : null}
                    </ContainerDiv>

                    <ContainerDiv
                      display="flex"
                      flexDirection="column"
                      gap="20px"
                      width="100%"
                      marginBottom="20px"
                      padding="0px 10px"
                    >
                      {values.users.map((user) => (
                        <ContainerDiv
                          key={user.id}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <AuthorDisplay author={user} avatarSize={32} />
                          <Trash color={Colors.burntSienna} onClick={() => this.handleRemove(user)} />
                        </ContainerDiv>
                      ))}
                    </ContainerDiv>
                  </ContainerDiv>
                  <FormActionButtons
                    onCancel={this.props.onCancel}
                    submitButtonLabel={`${groupId ? 'Save' : 'Create Group'}`}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    )
  }
}
