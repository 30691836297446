import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Colors } from 'src/constants/colors'
import { FjText, Loader } from 'src/components/Common'
import { Progress } from 'antd'

interface LoadingProps extends React.PropsWithChildren {
  isSuspenseFallback?: boolean
}

@observer
export class Loading extends Component<LoadingProps> {
  render() {
    return (
      <>
        {(sharedAppStateStore.isLoading || this.props.isSuspenseFallback) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'fixed',
              height: '100%',
              width: '100%',
              background: 'rgba(0,0,0,0.5)',
              zIndex: 1000000,
            }}
          >
            {sharedAppStateStore.loadingProgress !== undefined ? (
              <Progress
                className="white-text"
                type="circle"
                style={{ textAlign: 'center', margin: '0 auto' }}
                strokeColor={Colors.neonCarrot}
                status="active"
                percent={sharedAppStateStore.loadingProgress}
              />
            ) : (
              <Loader containerStyle={{ height: 'fit-content' }} />
            )}

            <FjText
              marginTop="20px"
              fontWeight="semi-bold"
              display="block"
              width="100%"
              userSelect="none"
              color={Colors.white}
            >
              {sharedAppStateStore.loadingMessage}
            </FjText>
          </div>
        )}
        {this.props.children}
      </>
    )
  }
}
