const badges = {
  'bryant@flockjay.com': [
    { badge: '🏆', tooltip: 'Outbound Champion' },
    { badge: '🔥', tooltip: 'Most Demos Booked' },
  ],
  'ashima@flockjay.com': [
    { badge: '📞', tooltip: 'Cold Call Award' },
    { badge: '🥇', tooltip: '1st Place (Leaderboard)' },
  ],
  'quenton@flockjay.com': [
    { badge: '⭐️', tooltip: 'Most Improved' },
    { badge: '✅', tooltip: 'Quota Attained' },
  ],
  'christopher@flockjay.com': [
    { badge: '✅', tooltip: 'Quota Attained' },
    { badge: '💪', tooltip: 'Objection Handling Award' },
  ],
  'shaan@flockjay.com': [
    { badge: '💰', tooltip: 'Recent Deal Closed' },
    { badge: '👏', tooltip: 'Top Performing Cadence' },
  ],
}

export default badges
