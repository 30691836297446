enum Role {
  Student = 'student',
  Admin = 'admin',
  Teacher = 'teacher',
  Alumni = 'alumni',
  Partner = 'partner',
  Candidate = 'candidate',
  Faas = 'faas',
}

export default Role
