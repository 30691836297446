import { Copy, Edit } from 'react-feather'
import { Tooltip } from 'antd'
import { Moment } from 'moment'
import React, { useCallback, useMemo } from 'react'
import { SharedContent } from 'src/models/SharedContent'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Anchor, ContainerDiv, DefaultButton, FjTable, FjText } from 'src/components/Common'
import { Paths } from 'src/constants/navigation'
import { copyToClipboard } from 'src/utils/format'
import { Colors } from 'src/constants/colors'

type SharedContentTableProps = {
  showContentTitle?: boolean
  existingLinks: SharedContent[]
  sharedContentSaved?: (sharedContent: SharedContent) => void
}

export const SharedContentTable = ({
  showContentTitle = false,
  existingLinks = [],
  sharedContentSaved,
}: SharedContentTableProps) => {
  const handleClickSessionsView = (sharedContent: SharedContent) => {
    const onCancel = () => (sharedAppStateStore.sharedContentSessionModalProps = undefined)
    sharedAppStateStore.sharedContentSessionModalProps = {
      onCancel: onCancel,
      onSuccess: onCancel,
      sharedContent,
    }
  }

  const handleAssetClick = useCallback((assetId: string) => {
    sharedAppStateStore.sharedLinkModalProps = undefined
    sharedAppStateStore.navigate(Paths.getClassroomPath({ assetId }))
  }, [])

  const columns = useMemo(() => {
    return [
      showContentTitle
        ? {
            title: 'Asset',
            key: 'assetTitle',
            render: (content: SharedContent) => (
              <Anchor fontWeight="semi-bold" onClick={() => handleAssetClick(content.learningContent.id)}>
                {content.learningContent.title}
              </Anchor>
            ),
          }
        : undefined,
      {
        title: 'Link Name (Opportunity)',
        key: 'title',
        render: (content: SharedContent) =>
          `${content.title}${content.sfMetadata?.name ? ` (${content.sfMetadata.name})` : ''}`,
      },
      {
        title: 'Author',
        key: 'authorName',
        render: (content: SharedContent) => content.author.fullName,
      },
      {
        title: 'Expires',
        dataIndex: 'expiryDate',
        key: 'expiryDate',
        render: (expiryDate: Moment) => (expiryDate ? expiryDate.fromNow() : null),
      },
      {
        title: 'Views',
        dataIndex: 'viewCount',
        key: 'viewCount',
      },
      {
        title: 'View Time',
        key: 'durationViewed',
        render: (content: SharedContent) => content.getFormattedDurationViewed(),
      },
      {
        title: 'Downloads',
        dataIndex: 'downloadCount',
        key: 'downloadCount',
      },
      {
        title: 'Sessions',
        render: (content: SharedContent) => (
          <ContainerDiv>
            {content.viewCount ? (
              <Anchor fontWeight="semi-bold" onClick={() => handleClickSessionsView(content)}>
                View
              </Anchor>
            ) : null}
          </ContainerDiv>
        ),
      },
      {
        title: 'URL',
        key: 'url',
        render: (sharedContent: SharedContent) => (
          <ContainerDiv
            textAlign="left"
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap="5px"
            style={{ fontSize: '14px' }}
          >
            <Tooltip title="Copy to clipboard">
              <Copy
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  copyToClipboard(sharedContent.getShareLink(), undefined, `Link to ${sharedContent.title}`)
                }
              />
            </Tooltip>
          </ContainerDiv>
        ),
      },
      {
        title: 'Password',
        key: 'password',
        render: (sharedContent: SharedContent) =>
          sharedContent.password ? (
            <ContainerDiv display="flex" gap="2px" alignItems="center" justifyContent="space-between">
              ••••••
              <DefaultButton
                size="small"
                image={<Copy size={16} />}
                clicked={() =>
                  copyToClipboard(
                    sharedContent.password,
                    'Password copied to clipboard!',
                    `${sharedContent.title} Password`
                  )
                }
              />
            </ContainerDiv>
          ) : (
            <FjText color={Colors.tapa}>None</FjText>
          ),
      },
      {
        title: 'Edit',
        key: 'edit',
        render: (sharedContent: SharedContent) => {
          const onCancel = () => (sharedAppStateStore.editSharedContentModalProps = undefined)
          return (
            <DefaultButton
              image={<Edit size={16} color={Colors.cornflowerBlue} />}
              clicked={() => {
                sharedAppStateStore.editSharedContentModalProps = {
                  obj: sharedContent,
                  onSuccess: (sharedContent: SharedContent) => {
                    sharedContentSaved?.(sharedContent)
                    onCancel()
                  },
                  onCancel: onCancel,
                }
              }}
            />
          )
        },
      },
    ].filter(Boolean)
  }, [handleAssetClick, showContentTitle, sharedContentSaved])

  return <FjTable columns={columns} dataSource={existingLinks} pagination={false} />
}
