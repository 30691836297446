import { Moment } from 'moment'
import { DataObject } from 'src/models/DataObject'

export class FjEvent extends DataObject {
  static authRequired: boolean = false
  static apiEndpoint: string = '/api/events/'

  id: string
  eventType: string
  user?: string
  data: object
  createdAt: Moment
  lastUpdated: Moment
}
