import { DatePicker } from 'antd'
import React, { useMemo, useState } from 'react'
import { FilterDropdownButton } from 'src/components/Common/Button'
import FjPopover from 'src/components/Common/FjPopover'

const { RangePicker } = DatePicker

type DateFilterProps = {
  filterLabel?: string
  startDate: moment.Moment
  endDate: moment.Moment
  handleDateFilterChange: (dateRange: moment.Moment[]) => void
  handleClearDateFilter: () => void
}

export const DateFilter = ({
  filterLabel = 'Date',
  startDate,
  endDate,
  handleDateFilterChange,
  handleClearDateFilter,
}: DateFilterProps) => {
  const [startDateValue, setStartDateValue] = useState()
  const [endDateValue, setEndDateValue] = useState()
  const [filterOpen, setFilterOpen] = useState(false)
  const isDateFilterSelected = !!startDate || !!endDate
  const title = useMemo(() => {
    let title = filterLabel
    if (startDate || endDate) title = `${title} |`
    if (startDate) title = `${title} From ${startDate.format('YYYY-MM-DD')}`
    if (endDate) title = `${title} to ${endDate.format('YYYY-MM-DD')}`
    return title
  }, [endDate, filterLabel, startDate])

  const handleChange = (dateRange: moment.Moment[]) => {
    if (dateRange[0] && dateRange[1]) {
      setStartDateValue(undefined)
      setEndDateValue(undefined)
      setFilterOpen(false)
      handleDateFilterChange(dateRange)
    }
  }

  return (
    <FjPopover
      content={
        <RangePicker
          defaultOpen
          allowEmpty={[true, true]}
          value={[startDateValue, endDateValue] as any}
          onChange={handleChange}
          disabledDate={(current: moment.Moment) => current && current.valueOf() > Date.now()}
        />
      }
      open={filterOpen}
      onOpenChange={(open) => setFilterOpen(open)}
    >
      <FilterDropdownButton
        onClick={() => setFilterOpen((open) => !open)}
        selected={isDateFilterSelected}
        title={title}
        onClear={isDateFilterSelected ? handleClearDateFilter : undefined}
      />
    </FjPopover>
  )
}
