import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Submodule } from 'src/models/Submodule'

type SubmoduleTagProps = {
  submodule: Submodule
  isAuthoring?: boolean
}

export const SubmoduleTag = ({ submodule, isAuthoring = false }: SubmoduleTagProps) => {
  return (
    <ContainerDiv
      display="flex"
      alignItems="center"
      gap={6}
      padding="4px 12px 4px 10px"
      backgroundColor={submodule.getSubmoduleTypeColor()}
      borderRadius={26}
    >
      {submodule.getSubmoduleIcon(undefined, 16)}
      <FjText textAlign="left" display="block" fontSize="12px" color={Colors.shark}>
        {submodule.getSubmoduleTypeDisplay(isAuthoring)}
      </FjText>
    </ContainerDiv>
  )
}
