import React, { useState } from 'react'
import styled from 'styled-components'
import { Edit3 } from 'react-feather'
import { ContainerDiv } from 'src/components/Common/Cards'
import { Colors } from 'src/constants/colors'

interface ClickableProps {
  children?: React.ReactNode
  hoverImgOverlay?: boolean
  onClick?(): void
  disabled?: boolean
  showEditIcon?: boolean
  display?: 'inline-block' | 'block'
}

interface ClickableContainerProps {
  isHovering: boolean
  disabled: boolean
}

const ClickableContainer = styled.div<ClickableContainerProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  -webkit-filter: brightness(100%);
  -moz-filter: brightness(100%);
  -o-filter: brightness(100%);
  -ms-filter: brightness(100%);
  filter: brightness(100%);
  ${(props) =>
    props.isHovering &&
    `:hover {
    -webkit-filter: brightness(70%);
    -moz-filter: brightness(70%);
    -o-filter: brightness(70%);
    -ms-filter: brightness(70%);
    filter: brightness(70%);
  }`}
`

const EditIcon = styled(Edit3)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
`

export const Clickable: React.FC<ClickableProps> = ({
  onClick,
  disabled,
  showEditIcon,
  hoverImgOverlay,
  children,
  display = 'inline-block',
}) => {
  const [isHovering, setHovereing] = useState(false)

  return (
    <ContainerDiv
      onClick={disabled ? () => {} : onClick}
      position="relative"
      textAlign="left"
      color={Colors.white}
      display={display}
      onMouseEnter={() => setHovereing(true)}
      onMouseLeave={() => setHovereing(false)}
    >
      <ClickableContainer isHovering={hoverImgOverlay && isHovering} disabled={disabled}>
        {children}
        {showEditIcon && isHovering && <EditIcon onMouseEnter={() => setHovereing(true)} />}
      </ClickableContainer>
    </ContainerDiv>
  )
}
