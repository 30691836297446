import React from 'react'
import { Row, Col, Tooltip } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { observable, computed, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import {
  ContainerDiv,
  FjText,
  VideoRecorderButton,
  FjFormItem,
  FjRadio,
  FjSelect,
  FjRadioGroup,
  DefaultButton,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { renameFile } from 'src/utils/renameFile'
import { formatDuration } from 'src/utils/format'
import { Pause, Play, Trash, Check } from 'react-feather'
import fixWebmDuration from 'webm-duration-fix'

const BUFFER_DUMP_DURATION = 1000

type RecordingMode = 'screenAndCamera' | 'screen' | 'camera'

const RECORDING_PERMISSION_DENIED_COPY = (
  <>
    Permission Denied: We've recently learned of an issue with Google Chrome that prevents the Flockjay app from
    recording.
    <br />
    Here's how to resolve the issue and ensure the best recording experience.
    <br />
    <ol>
      <li>Navigate to Security and Privacy in your System Preferences.</li>
      <li>Go to Screen Recording/Microphone/Camera.</li>
      <li>Unlock this screen by selecting the lock icon in the bottom left corner.</li>
      <li>Unselect and then reselect Google Chrome app.</li>
      <li>Relaunch Chrome and attempt to record in the Flockjay app.</li>
    </ol>
    If you need further assistance, reach out to <a href="mailto:support@flockjay.com">support@flockjay.com</a>.
  </>
)

const VIDEO_SOURCE_IN_USE_ERROR_COPY = (
  <>
    We were unable to start the recording. Please make sure your browser is up to date.
    <br />
    If the problem persists please contact <a href="mailto:support@flockjay.com">support@flockjay.com</a>.
  </>
)

const EMPTY_RECORDING_FILE_COPY = (
  <>
    Whoops! Looks like the video file you recorded is empty. Please check your webcam/microphone and try again.
    <br />
    If you need further assistance, reach out to <a href="mailto:support@flockjay.com">support@flockjay.com</a>.
  </>
)

interface VideoRecorderFormProps {
  videoRecorded: (file: File) => Promise<void> | void
  onStart?: () => void
  onCancel?: () => void
  setDisabled: () => void
}

@observer
export class VideoRecorderForm extends React.Component<VideoRecorderFormProps> {
  @observable videoDevices = new Map<string, string>()
  @observable audioDevices = new Map<string, string>()
  @observable recordingMode: RecordingMode = 'screen'
  @observable selectedVideoDeviceId = 'inactive'
  @observable selectedAudioDeviceId = 'inactive'

  constructor(props: VideoRecorderFormProps) {
    super(props)
    makeObservable(this)
  }

  @computed get videoSelectorDisabled() {
    return !this.recordingMode.toLowerCase().includes('camera')
  }

  async componentDidMount() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      // stop all tracks to turn off webcam and allow recorder to create new connections
      stream.getTracks().forEach((t) => t.stop())
      await this.setupDeviceList()
      if (sharedAppStateStore.isSafari() || sharedAppStateStore.isMobileDevice()) this.recordingMode = 'camera'
      this.refreshRecorderProps()
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      if (this.props.setDisabled) this.props.setDisabled()
      if (this.props.onCancel) this.props.onCancel()
    }
  }

  componentWillUnmount() {
    sharedAppStateStore.videoRecorderProps = undefined
  }

  setupDeviceList = async () => {
    this.videoDevices.clear()
    this.audioDevices.clear()
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = new Map<string, string>()
    const audioDevices = new Map<string, string>()
    for (let d of devices) {
      if (d.kind === 'videoinput') {
        videoDevices.set(d.deviceId, d.label)
        if (videoDevices.size === 1 || d.deviceId === 'default') {
          this.selectedVideoDeviceId = d.deviceId
        }
      } else if (d.kind === 'audioinput') {
        audioDevices.set(d.deviceId, d.label)
        if (audioDevices.size === 1 || d.deviceId === 'default') {
          this.selectedAudioDeviceId = d.deviceId
        }
      }
    }
    videoDevices.set('inactive', 'Inactive')
    audioDevices.set('inactive', 'Inactive')

    if (!this.selectedVideoDeviceId) this.selectedVideoDeviceId = 'inactive'
    if (!this.selectedAudioDeviceId) this.selectedAudioDeviceId = 'inactive'

    this.videoDevices = videoDevices
    this.audioDevices = audioDevices
  }

  videoDeviceSelected = (deviceId: string) => {
    this.selectedVideoDeviceId = deviceId
    this.refreshRecorderProps()
  }

  audioDeviceSelected = (deviceId: string) => {
    this.selectedAudioDeviceId = deviceId
    this.refreshRecorderProps()
  }

  recordingModeChanged = (event: RadioChangeEvent) => {
    this.recordingMode = event.target.value
    this.refreshRecorderProps()
  }

  refreshRecorderProps = () => {
    sharedAppStateStore.videoRecorderProps = {
      recordingMode: this.recordingMode,
      videoDevicedId: this.selectedVideoDeviceId,
      audioDeviceId: this.selectedAudioDeviceId,
      startButtonId: 'recordingStartBtn',
      videoRecorded: this.props.videoRecorded,
      onStart: this.props.onStart,
      onCancel: this.props.onCancel,
    }
  }

  render() {
    return (
      <Formik
        initialValues={{
          recordingMode: this.recordingMode,
          videoDevice: this.selectedVideoDeviceId,
          audioDevice: this.selectedAudioDeviceId,
        }}
        onSubmit={() => {}}
        enableReinitialize
      >
        <Form>
          {!sharedAppStateStore.isSafari() && !sharedAppStateStore.isMobileDevice() ? (
            <Row justify="center">
              <Col xs={24} md={10}>
                <FjFormItem name="recordingMode">
                  <FjText
                    textAlign="left"
                    fontWeight="semi-bold"
                    display="block"
                    marginBottom="5px"
                    color={Colors.squirrel}
                  >
                    Recording Settings
                  </FjText>
                  <FjRadioGroup name="recordingMode" onChange={this.recordingModeChanged}>
                    {/* <FjRadio style={{ display: 'block' }} name="recordingMode" value="screenAndCamera">
                      Screen and Camera
                    </FjRadio> */}
                    <FjRadio style={{ display: 'block' }} name="recordingMode" value="screen">
                      Screen Only
                    </FjRadio>
                    <FjRadio style={{ display: 'block' }} name="recordingMode" value="camera">
                      Camera Only
                    </FjRadio>
                  </FjRadioGroup>
                </FjFormItem>
              </Col>
            </Row>
          ) : null}

          <Row justify="center">
            <Col xs={24} md={10} style={{ textAlign: 'left' }}>
              <FjFormItem name="videoDevice">
                <FjText fontWeight="semi-bold" color={Colors.squirrel}>
                  Camera:
                </FjText>
                <FjSelect
                  style={{ marginLeft: '10px' }}
                  name="videoDevice"
                  optionsMap={this.videoDevices}
                  onChange={this.videoDeviceSelected}
                  disabled={this.videoSelectorDisabled}
                />
              </FjFormItem>
            </Col>
          </Row>

          <Row justify="center">
            <Col xs={24} md={10} style={{ textAlign: 'left' }}>
              <FjFormItem name="audioDevice">
                <FjText fontWeight="semi-bold" color={Colors.squirrel}>
                  Microphone
                </FjText>
                <FjSelect
                  style={{ marginLeft: '10px' }}
                  name="audioDevice"
                  optionsMap={this.audioDevices}
                  onChange={this.audioDeviceSelected}
                />
              </FjFormItem>
            </Col>
          </Row>

          <DefaultButton
            id="recordingStartBtn"
            size="medium"
            image={<Play color={Colors.white} size={18} />}
            title="Start Recording"
            buttonType="primary"
          />
        </Form>
      </Formik>
    )
  }
}

export interface VideoRecorderProps {
  recordingMode: RecordingMode
  videoDevicedId: string
  audioDeviceId: string
  startButtonId: string
  videoRecorded: (file: File) => Promise<void> | void
  onStart?: () => void
  onCancel?: () => void
}

@observer
export class VideoRecorder extends React.Component<VideoRecorderProps> {
  @observable cancelDisabled = true
  @observable pauseDisabled = true
  @observable stopDisabled = true
  @observable paused = false
  captureStream: MediaStream
  @observable videoStream: MediaStream
  audioStream: MediaStream
  mixedStream: MediaStream
  userVideo: HTMLVideoElement
  recorder: MediaRecorder
  @observable timeRecorded: number
  timerId: number
  @observable countdown: number
  @observable innerHeight = window.innerHeight
  countdownId: number
  startBtn: HTMLButtonElement
  chunks: BlobPart[] = []

  constructor(props: VideoRecorderProps) {
    super(props)
    makeObservable(this)
    this.startBtn = document.getElementById(this.props.startButtonId) as HTMLButtonElement
    if (this.startBtn) this.startBtn.onclick = this.record
  }

  handleResize = () => {
    this.innerHeight = window.innerHeight
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.setupVideoStream()
    this.setupAudioStream()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.stopTracks()
  }

  componentDidUpdate(prevProps: VideoRecorderProps) {
    const { videoDevicedId, recordingMode } = this.props
    const videoChanged =
      prevProps.videoDevicedId !== videoDevicedId ||
      recordingMode.toLowerCase().includes('camera') !== prevProps.recordingMode.toLowerCase().includes('camera')
    if (videoChanged) this.setupVideoStream()
    if (prevProps.audioDeviceId !== this.props.audioDeviceId) this.setupAudioStream()
  }

  setupVideoStream = async () => {
    const { videoDevicedId, recordingMode } = this.props
    this.userVideo = document.getElementById('stream-video') as HTMLVideoElement
    if (videoDevicedId === 'inactive' || !recordingMode.toLocaleLowerCase().includes('camera')) {
      this.stopVideoStream()
      this.videoStream = undefined
    } else {
      this.videoStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDevicedId } })
      this.setupVideoFeedback()
    }
  }

  setupAudioStream = async () => {
    const { audioDeviceId } = this.props
    if (audioDeviceId === 'inactive') {
      this.stopAudioStream()
      this.audioStream = undefined
    } else {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioDeviceId, echoCancellation: true, sampleRate: 44100 },
      })
    }
  }

  setupVideoFeedback = () => {
    if (!this.userVideo) return
    this.userVideo.srcObject = this.videoStream
    this.userVideo.play()
  }

  stopCaptureStream = () => {
    if (this.captureStream) this.captureStream.getTracks().forEach((t) => t.stop())
  }

  stopVideoStream = () => {
    if (this.videoStream) this.videoStream.getTracks().forEach((t) => t.stop())
    if (this.userVideo) {
      this.userVideo.pause()
      this.userVideo.srcObject = undefined
    }
  }

  stopAudioStream = () => {
    if (this.audioStream) this.audioStream.getTracks().forEach((t) => t.stop())
  }

  record = async () => {
    try {
      // ask user which display stream they want to use
      if (this.props.recordingMode.toLowerCase().includes('screen')) {
        this.captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true })
      }

      // consolidate streams
      const streams = []
      if (this.captureStream) streams.push(...this.captureStream.getTracks())
      if (this.videoStream) streams.push(...this.videoStream.getTracks())
      if (this.audioStream) streams.push(...this.audioStream.getTracks())

      if (!streams.length) return

      this.mixedStream = new MediaStream(streams)

      // record
      this.recorder = new MediaRecorder(this.mixedStream)
      this.recorder.ondataavailable = this.onDataAvailable
      this.cancelDisabled = true
      this.startCountdown()
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        sharedAppStateStore.handleError(err, undefined, true, RECORDING_PERMISSION_DENIED_COPY)
      } else if (err.name === 'NotReadableError') {
        sharedAppStateStore.handleError(err, undefined, true, VIDEO_SOURCE_IN_USE_ERROR_COPY)
      } else {
        sharedAppStateStore.handleError(err)
      }
    }
  }

  startRecording = () => {
    this.recorder.start(BUFFER_DUMP_DURATION)
    this.startTimer()
    this.startBtn.disabled = true
    this.cancelDisabled = false
    this.pauseDisabled = false
    this.stopDisabled = false
  }

  onDataAvailable = (e: BlobEvent) => this.chunks.push(e.data)

  handleStop = async (e: Event) => {
    if (this.chunks.length < 1 || (this.chunks[0] as Blob).size === 0) {
      sharedAppStateStore.handleError(new Error('empty recorded file'), undefined, true, EMPTY_RECORDING_FILE_COPY)
      return
    }
    const blob = await fixWebmDuration(new Blob(this.chunks, { type: 'video/webm;codecs=vp9' }))
    const file = new File([blob], 'noName.webm', { type: 'video/webm' })
    const renamedFile = renameFile(file)
    this.chunks = []
    this.props.videoRecorded(renamedFile)
  }

  togglePaused = () => {
    if (!this.recorder) return
    if (this.paused) {
      this.paused = false
      this.recorder.resume()
    } else {
      this.paused = true
      this.recorder.pause()
    }
  }

  stop = () => {
    this.recorder.onstop = this.handleStop
    this.recorder.stop()
    this.stopTracks()
    this.clearTimer()
    this.cancelDisabled = true
    this.stopDisabled = true
  }

  showCancelConfirmDialog = () => {
    if (!this.paused) this.togglePaused()
    sharedAppStateStore.sharedConfirmDialogProps = {
      onConfirm: this.cancel,
      onCancel: this.togglePaused,
      content: 'Are you sure you want to delete the recording?',
      confirmButtonTitle: 'Yes',
      cancelButtonTitle: 'No',
    }
  }

  cancel = () => {
    this.stopTracks()
    if (this.recorder) {
      this.recorder.onstop = (e: Event) => {}
      this.recorder.ondataavailable = (e: Event) => {}
      this.recorder.stop()
    }
    this.cancelDisabled = true
    this.stopDisabled = true
    this.pauseDisabled = true
    this.startBtn.disabled = false
    this.paused = false
    this.clearTimer()
    this.setupVideoStream()
    this.setupAudioStream()
    if (this.props.onCancel) this.props.onCancel()
    this.chunks = []
  }

  stopTracks = () => {
    this.stopCaptureStream()
    this.stopVideoStream()
    this.stopAudioStream()
  }

  startTimer = () => {
    this.timeRecorded = 0
    this.timerId = window.setInterval(() => {
      if (this.paused) return
      this.timeRecorded += 1
    }, 1000)
  }

  clearTimer = () => {
    if (!this.timerId) return
    window.clearInterval(this.timerId)
    this.timerId = undefined
    this.timeRecorded = undefined
  }

  startCountdown = () => {
    this.countdown = 5
    if (this.props.onStart) this.props.onStart()
    this.countdownId = window.setInterval(() => {
      if (this.countdown === 1) {
        this.countdown = undefined
        window.clearInterval(this.countdownId)
        this.startRecording()
      } else {
        this.countdown -= 1
      }
    }, 1000)
  }

  render() {
    const videoButtonTooltipPlacement = sharedAppStateStore.isMobile ? 'right' : 'top'
    return (
      <ContainerDiv
        style={{
          position: 'fixed',
          left: '0',
          bottom: '0',
          zIndex: 1100,
          width: '100vw',
          height: '100vh',
          pointerEvents: 'none',
        }}
      >
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
          {typeof this.countdown === 'number' ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                backgroundColor: Colors.outerSpaceOpacity90,
              }}
            >
              <FjText display="block" marginTop="18.5%" fontSize="150px" color={Colors.white}>
                {this.countdown}
              </FjText>
            </div>
          ) : null}
          <div style={{ textAlign: 'center', position: 'absolute', top: '20px', width: '100%' }}>
            <div
              style={{
                backgroundColor: Colors.sunsetOrange,
                padding: '3px',
                color: Colors.white,
                maxWidth: '100px',
                margin: '0 auto',
              }}
            >
              {formatDuration(this.timeRecorded)}
            </div>
          </div>
          <div style={{ position: 'absolute', bottom: '0', left: '20px', right: '20px' }}>
            <Row justify={this.innerHeight > 676 ? 'start' : 'space-between'} align="middle">
              <Col span={8} style={{ textAlign: 'left' }}>
                <div
                  className="stream-video-container"
                  style={{ display: !!this.videoStream ? 'inline-block' : 'none' }}
                >
                  <video id="stream-video" muted />
                </div>
              </Col>
              <Col span={8} style={{ position: 'relative' }}>
                <div
                  style={{
                    padding: '10px',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '10px',
                    display: 'inline-block',
                  }}
                >
                  <Tooltip
                    placement={videoButtonTooltipPlacement}
                    title={this.paused ? 'Resume Recording' : 'Pause Recording'}
                  >
                    <div style={{ display: 'inline-block' }}>
                      <VideoRecorderButton
                        onClick={this.togglePaused}
                        disabled={this.pauseDisabled}
                        style={{ height: '60px', width: '60px', borderRadius: '30px' }}
                      >
                        {this.paused ? (
                          <Play color={Colors.silverTree} size={27} />
                        ) : (
                          <Pause color={Colors.silverTree} size={27} />
                        )}
                      </VideoRecorderButton>
                    </div>
                  </Tooltip>
                  <Tooltip placement={videoButtonTooltipPlacement} title="Finish Recording">
                    <div style={{ display: 'inline-block' }}>
                      <VideoRecorderButton
                        onClick={this.stop}
                        disabled={this.stopDisabled}
                        style={{ textAlign: 'center' }}
                      >
                        <Check color={Colors.pastelGreen} size={30} />
                      </VideoRecorderButton>
                    </div>
                  </Tooltip>
                  <Tooltip placement={videoButtonTooltipPlacement} title="Delete Recording">
                    <div style={{ display: 'inline-block' }}>
                      <VideoRecorderButton
                        onClick={this.showCancelConfirmDialog}
                        disabled={this.cancelDisabled}
                        style={{ height: '60px', width: '60px', borderRadius: '30px' }}
                      >
                        <Trash color={Colors.sunsetOrange} size={27} />
                      </VideoRecorderButton>
                    </div>
                  </Tooltip>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </ContainerDiv>
    )
  }
}
