import { Divider } from 'antd'
import React from 'react'
import { ContainerDiv, FjCard, FjText } from 'src/components/Common'
import { LearningPathViewContentCard } from 'src/components/Content/LearningPathViewContentCard'
import { Trophy } from 'src/components/Icons/Trophy'
import { Colors } from 'src/constants/colors'
import { LearningPath } from 'src/models/LearningPath'

export const LearningPathPreview: React.FC<{ learningPath: LearningPath }> = ({ learningPath }) => {
  const { lastCompletedStep, nextStep, subsequentStep } = learningPath.currentSteps()
  return (
    <>
      <ContainerDiv width="100%" marginBottom="10px" marginTop="24px">
        <FjCard textAlign="left" overflow="hidden" padding="0">
          <FjText
            fontSize="20px"
            fontWeight="bold"
            color={Colors.midnightBlue}
            display="block"
            textAlign="left"
            padding="18px"
            paddingBottom="0"
          >
            Contents
          </FjText>
          <Divider style={{ color: Colors.tapa, margin: '12px 0' }} />
          {lastCompletedStep && (
            <LearningPathViewContentCard
              style={{ width: '100%' }}
              inProgress={false}
              key={lastCompletedStep.id}
              learningPath={learningPath}
              feedContent={lastCompletedStep}
              locked={false}
              handleLearningPathUpdate={() => {}}
              checkboxDisabled
            />
          )}
          {nextStep && (
            <LearningPathViewContentCard
              style={{ width: '100%' }}
              inProgress={true}
              key={nextStep.id}
              learningPath={learningPath}
              feedContent={nextStep}
              locked={false}
              checkboxDisabled
              handleLearningPathUpdate={() => {}}
            />
          )}
          {subsequentStep && (
            <LearningPathViewContentCard
              style={{ width: '100%' }}
              inProgress={false}
              key={subsequentStep.id}
              learningPath={learningPath}
              feedContent={subsequentStep}
              locked
              handleLearningPathUpdate={() => {}}
              checkboxDisabled
            />
          )}
        </FjCard>
      </ContainerDiv>
      {!learningPath.nextStep() ? (
        <ContainerDiv display="flex" flexDirection="column" alignItems="center" width="100%">
          <Trophy width={48} height={48} color={Colors.texasRose} />
          <FjText fontSize="medium" fontWeight="semi-bold" color={Colors.squirrel}>
            Completed
          </FjText>
        </ContainerDiv>
      ) : null}
    </>
  )
}
