import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col, Drawer } from 'antd'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { MenuOption, MenuOptionType } from 'src/models/MenuOptions'
import { ContainerDiv, Icon, DefaultHeader, DefaultNavLink, DefaultDropDown, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import wordMark from 'src/assets/icons/FJ/Flockjay_logo_black_rgb.png'
import MenuNavy from 'src/assets/icons/Menu/MenuNavy.svg'
import { observable, makeObservable } from 'mobx'
import { sharedDataStore } from 'src/store/DataStore'
import { Auth } from 'src/utils/Auth'
import { ContentWrapper } from 'src/components/Page/ContentWrapper'

const navContainer: React.CSSProperties = {
  lineHeight: '70px',
}

interface FJNavbarProps extends RouteComponentProps {
  wordMarkTarget?: string
}

const ProfileDropdown = styled.div`
  border-left: 1px solid ${Colors.lightGray};
  padding-left: 17px;
  padding-right: 17px;
`

@observer
class FJNavbarComponent extends Component<FJNavbarProps> {
  @observable drawerVisible = false

  redirect = (options: MenuOption) => {
    switch (options.identifier) {
      case 'profile':
        sharedAppStateStore.navigate(Paths.getProfilePath(sharedDataStore.user.id))
        break

      case 'gradebook': {
        sharedAppStateStore.navigate(Paths.getDashboardPath({ category: 'gradebook' }))
        break
      }
      case 'signOut':
        Auth.logout()
        break
      case 'team':
        sharedAppStateStore.navigate(Paths.team)
        break
    }
  }

  dropdownmenu = () => {
    let optionsToExclude: MenuOptionType[] = []
    if (sharedDataStore.user.isTeacher()) {
      optionsToExclude.push('pay')
    }
    if (sharedDataStore.user.isFaasUser()) {
      optionsToExclude.push('directory')
      optionsToExclude.push('blog')
      optionsToExclude.push('pay')
    }
    return {
      items: MenuOption.profileNavOptions(optionsToExclude).map((option) => {
        return {
          key: option.identifier,
          onClick: () => this.redirect(option),
          label: option.displayStr(),
        }
      }),
    }
  }

  getNavLinks = (type: 'desktop' | 'mobile') => {
    const isMobile = type === 'mobile'
    const navLinks = []

    if (isMobile) {
      navLinks.push(
        <FjText
          fontSize="medium"
          key="signout"
          margin="0 10px"
          padding="15px"
          color={Colors.white}
          onClick={() => Auth.logout()}
        >
          Sign Out
        </FjText>
      )
    }
    return navLinks
  }

  getLogoLink = () => {
    if (sharedDataStore.user.isFaasUser()) {
      return Paths.feed
    } else if (this.props.wordMarkTarget) {
      return this.props.wordMarkTarget
    } else if (sharedDataStore.user) return Paths.dashboard
  }

  constructor(props: FJNavbarProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    return (
      <>
        <DefaultHeader>
          <ContainerDiv style={navContainer}>
            <ContentWrapper style={NavBarStyle}>
              <Row>
                <Col xs={18} md={6}>
                  <Row justify="start">
                    <DefaultNavLink to={this.getLogoLink()} isActive={() => false} style={{ paddingLeft: 0 }}>
                      <Col xs={24} sm={0}>
                        <Icon src={wordMark} width="140px" />
                      </Col>
                      <Col xs={0} sm={24}>
                        <Icon src={wordMark} width="180px" />
                      </Col>
                    </DefaultNavLink>
                  </Row>
                </Col>

                <Col xs={0} md={18}>
                  <Row justify="end" style={{ height: '70px' }}>
                    {this.getNavLinks('desktop')}
                    {sharedDataStore.user.id && (
                      <ProfileDropdown>
                        <DefaultDropDown
                          dropdownType="nav"
                          fontSize="default"
                          hoverText
                          showArrow
                          trigger={['hover']}
                          menu={this.dropdownmenu()}
                          image={sharedDataStore.user.displayPic}
                          title={sharedDataStore.user.fullName || `@${sharedDataStore.user.username}`}
                        />
                      </ProfileDropdown>
                    )}
                  </Row>
                </Col>

                <Col xs={6} md={0} style={{ textAlign: 'left' }}>
                  <ContainerDiv display="flex" justifyContent="center" alignItems="right">
                    <FjText color={Colors.blackSqueeze} fontSize="18px">
                      <Icon width="20px" src={MenuNavy} onClick={() => (this.drawerVisible = !this.drawerVisible)} />
                    </FjText>
                  </ContainerDiv>
                  <Drawer
                    className="fj-navbar-drawer"
                    title={<FjText color={Colors.white}>Menu</FjText>}
                    drawerStyle={{ backgroundColor: Colors.shark }}
                    headerStyle={{ backgroundColor: Colors.shark }}
                    placement="right"
                    onClose={() => (this.drawerVisible = false)}
                    open={this.drawerVisible}
                  >
                    {this.getNavLinks('mobile')}
                  </Drawer>
                </Col>
              </Row>
            </ContentWrapper>
          </ContainerDiv>
        </DefaultHeader>
      </>
    )
  }
}

const NavBarStyle: React.CSSProperties = {
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))',
}

export const FJNavbar = withRouter(FJNavbarComponent)
