import React, { Component, Suspense, lazy } from 'react'
import { observer } from 'mobx-react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { FeedPost } from 'src/models/FeedPost'
import { FeedComment } from 'src/models/FeedComment'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ContainerDiv, FjFormItem, DefaultButton, Loader } from 'src/components/Common'
import { CommentableItem } from 'src/components/Feed/FeedComments'
import { isRequired } from 'src/utils/validation'
import { XCircle } from 'react-feather'
import PostType from 'src/models/enums/PostType'
import { Tooltip } from 'antd'
const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

interface PostCommentFormProps {
  obj?: CommentableItem
  parentComment?: FeedComment
  existingComment?: FeedComment
  submitButtonTitle?: string
  commentAdded: (comment: FeedComment) => void
  cancelClicked?: () => void
}

@observer
export class PostCommentForm extends Component<PostCommentFormProps> {
  formikForm: any

  handlePostComment = async (data: any) => {
    try {
      const comment = new FeedComment()
      await comment.add(this.props.obj, data)
      this.formikForm.resetForm()
      this.props.commentAdded(comment)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    const { parentComment, obj, existingComment } = this.props
    const isFeedPost = obj instanceof FeedPost

    return (
      <Formik
        innerRef={(ref) => (this.formikForm = ref)}
        initialValues={{
          existingCommentId: existingComment?.id,
          body: existingComment?.body || '',
          parentId: parentComment?.id,
        }}
        onSubmit={this.handlePostComment}
        enableReinitialize
      >
        {(formikProps) => {
          const showVideoRecordButton = !formikProps.values.body.includes('fjvideo-')
          return (
            <Form>
              <ContainerDiv
                paddingLeft="54px"
                paddingBottom="15px"
                paddingTop="15px"
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                className="feedpost-comment-form"
              >
                <ContainerDiv flex="1" position="relative">
                  <FjFormItem name="body" validate={isRequired} style={{ marginBottom: 0, lineHeight: 1.4 }}>
                    <Suspense fallback={<Loader />}>
                      <FJCKEditor
                        showRecordVideoBtn={showVideoRecordButton}
                        showRecordVideoTitle={false}
                        disabled={!obj}
                        hideToolbar={true}
                        name="body"
                        placeholder={
                          !parentComment
                            ? isFeedPost && obj.postType === PostType.SOS
                              ? 'What worked well? What might they work on?'
                              : 'Add a comment...'
                            : 'Add a reply...'
                        }
                        includeMentions
                      />
                    </Suspense>
                  </FjFormItem>
                </ContainerDiv>
                <ContainerDiv textAlign="left" marginLeft="10px">
                  <DefaultButton disabled={!obj} buttonType="primary" title="Send" type="submit" />
                  {existingComment && (
                    <Tooltip title="Close">
                      <DefaultButton
                        style={{ marginLeft: '10px' }}
                        buttonType="text"
                        image={<XCircle size={17} />}
                        clicked={this.props.cancelClicked}
                      />
                    </Tooltip>
                  )}
                </ContainerDiv>
              </ContainerDiv>
            </Form>
          )
        }}
      </Formik>
    )
  }
}
