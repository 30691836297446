import styled from 'styled-components'
import {
  PaddingProps,
  getPaddingTop,
  getPaddingRight,
  getPaddingBottom,
  getPaddingLeft,
  MarginProps,
  getMarginTop,
  getMarginRight,
  getMarginBottom,
  getMarginLeft,
} from 'src/components/Common/Styles'

enum Layout {
  default = 'none',
  relative = 'relative',
  absolute = 'absolute',
}

enum Position {
  default = '320px',
  top = '0px',
  right = '0px',
  bottom = '0px',
  left = '320px',
}

interface ListProps {
  color?: string
  left?: string
  right?: string
  position?: string
  display?: string
  listStyle?: string
}

export const UnorderedList = styled.ul<PaddingProps & ListProps & MarginProps>`
  display: ${(props) => (props.display ? props.display : 'inline-flex')}
  list-style-type: ${(props) => (props.listStyle ? props.listStyle : 'none')}
  padding-top: ${(props) => getPaddingTop(props)}
  padding-right: ${(props) => getPaddingRight(props)}
  padding-bottom: ${(props) => getPaddingBottom(props)}
  padding-left: ${(props) => getPaddingLeft(props)}
  margin-top: ${(props) => getMarginTop(props)}
  margin-right: ${(props) => getMarginRight(props)}
  margin-bottom: ${(props) => getMarginBottom(props)}
  margin-left: ${(props) => getMarginLeft(props)}
`

export const List = styled.li<MarginProps & ListProps>`
  margin-top: ${(props) => getMarginTop(props)}
  margin-right: ${(props) => getMarginRight(props)}
  margin-bottom: ${(props) => getMarginBottom(props)}
  margin-left: ${(props) => getMarginLeft(props)}
  left: ${(props) => (props.left ? props.left : Position.left)}
  right: ${(props) => (props.right ? props.right : Position.right)}
  position: ${(props) => (props.position ? props.position : Layout.default)}
  color: ${(props) => (props.color ? props.color : 'none')}
  display: ${(props) => (props.display ? props.display : 'inline')}
`
