import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import moment from 'moment'

export class IntegratedScimOrganization extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: ({ author }) => (author ? Author.fromData(author) : undefined),
  }

  static apiEndpoint: string = '/feed/scim_settings/'

  id: string
  author: Author
  isActive: boolean
  scimToken: string

  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
