import { observable, computed, makeObservable } from 'mobx'
import moment from 'moment-timezone'
import humps from 'humps'
import { Author } from 'src/models/Author'
import { Favouritable } from 'src/models/Favouritable'
import { Submodule } from 'src/models/Submodule'
import { sharedDataStore } from 'src/store/DataStore'
import { Assignment } from 'src/models/Assignment'
import { Question } from 'src/models/Question'
import { LiveSession } from 'src/models/LiveSession'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'
import Route from 'src/network/Route'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import MethodTypes from 'src/models/enums/MethodTypes'
import { AppearsInType, hasOpenedConfettiModal, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { Module } from 'src/models/Module'
import { ALL_PUBLIC_USERS_GROUP } from 'src/models/Group'
import { Certificate } from 'src/models/Certificate'
import { MiniHub } from 'src/models/Hub'

export class Course extends Favouritable {
  static apiEndpoint = 'api/course/'
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    name: (data) => data.name || data.title,
    modules: (data) => (data.modules ? Module.fromData(data.modules) : []),
    tags: (data) => (data.tags ? data.tags : []),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    hubs: (data) => (data.hubs ? data.hubs : []),
    customFields: (data) => (data.customFields ? humps.decamelizeKeys(data.customFields) : {}),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'course',
  }

  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'course'

  getBulkEditFields = () => [
    'groupIds',
    'tags',
    'hubs',
    'enforceMediaCompletion',
    'requireSequentialCompletion',
    'sfMetadata',
    'expiryDate',
    'customFields',
  ]

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get title() {
    return this.name
  }

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  id: string
  @observable name: string = ''
  startDate: moment.Moment
  endDate: moment.Moment
  description: string = ''
  img?: string
  author?: Author
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  modules: Module[] = []
  progress: number
  createdAt: moment.Moment
  lastUpdated: moment.Moment = moment()
  publishedAt: moment.Moment
  tags: string[] = []
  @observable groupIds: string[]
  hubs: MiniHub[] = []
  @observable thumbnailUrl: string
  customFields: object
  sfMetadata?: SfOpportunity
  appearsIn: AppearsInType[]
  learningContentId: string
  enforceMediaCompletion: boolean
  requireSequentialCompletion: boolean
  certificate: Certificate
  userCertificatePdfUrl: string
  isUserEligibleForCertificate: boolean

  courseHasStarted = () => moment().isSameOrAfter(this.startDate)

  courseHasEnded = () => moment().isSameOrAfter(this.endDate)

  getHeroBgColor = () => Colors.blueChalk4
  getContentTypeTagColor = () => Colors.mauve
  getTagBgColor = () => Colors.whitePointer
  getTagTitle = () => 'Course'

  getLockedFromIndex = () => {
    const submodules = this.getAllSubmodules()
    if (sharedDataStore.user.isFaasAdmin()) return submodules.length
    const lockedFromIndex = submodules.findIndex((s) => s.progressPercentage !== 1)
    return lockedFromIndex === -1 ? submodules.length : lockedFromIndex + 1
  }

  imgSrc = () => this.thumbnailUrl

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate?.diff(moment(), 'days') <= 7

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => this.canEdit()

  canSharePublicly = () => {
    const allPublicUsersGroup = sharedDataStore.user.groups.find((group) => group.name === ALL_PUBLIC_USERS_GROUP)
    if (!allPublicUsersGroup) return false
    return !this.groupIds.includes(allPublicUsersGroup.id) && sharedDataStore.user.isFaasAdmin()
  }

  getAuthor = () => this.author

  getAllSubmodules = () => this.modules.map((module) => module.submodules).flat()

  getAllAssignments = () => this.getAllSubmodules().filter((submodule) => submodule.submoduleType === 'assignment')

  duplicate = async () => {
    const moduleData = []

    const course: Course = await Course.get(this.id, undefined, undefined, {
      expand: 'assignment,live_session',
    })
    for (const module of course.modules) {
      const newModule = new Module()
      await newModule.save({ title: module.title })
      for (const submodule of module.submodules) {
        // Assignment
        const newAssignment: Assignment = new Assignment()
        if (submodule.assignment) {
          const { title, questions, description, assignmentGradingType, graderIds } = submodule.assignment

          // Question
          const questionIds = []
          for (const questionItem of questions) {
            const { title, questionType, question, options, correctAnswer, gradeType, subtitle, rubric } = questionItem
            const newQuestion = new Question()
            await newQuestion.save({
              title,
              questionType,
              question,
              options,
              correctAnswer,
              gradeType,
              subtitle,
              rubricId: rubric?.id,
            })
            questionIds.push(newQuestion.id)
          }

          await newAssignment.save({ title, questionIds, description, assignmentGradingType, graderIds })
        }

        // Live Session
        const newSession: LiveSession = new LiveSession()
        if (submodule.liveSession) {
          const { meetingTime, meetingUrl } = submodule.liveSession
          await newSession.save({ meetingTime: meetingTime.toISOString(), meetingUrl })
        }

        // Submodule
        const newSubmodule: Submodule = new Submodule()
        const { title, submoduleType, content, scormFile } = submodule
        await newSubmodule.save({
          title,
          submoduleType,
          content,
          scormFile,
          assignmentId: newAssignment.id,
          liveSessionId: newSession.id,
        })
        newModule.submodules.push(newSubmodule)
      }
      moduleData.push(newModule.formatForCourseSave())
    }

    const duplicatedCourse = new Course()
    const newCourseData = {
      name: `Copy of ${this.name}`,
      description: this.description,
      moduleData: moduleData,
      tags: this.tags,
      groupIds: this.groupIds,
      thumbnailUrl: this.thumbnailUrl,
      customFields: this.customFields,
    }
    await duplicatedCourse.save(newCourseData)
    sharedAppStateStore.navigate(Paths.getCoursePath({ id: duplicatedCourse.id, isAuthoring: true }), true)
  }

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  hasCustomFields = () => !!Object.keys(this.customFields).length

  getProgressDetails = async (userId: string) => {
    const { path } = Course.formatActionEndpoint(this.id, undefined, `progress/${userId}`)
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, {}))
  }

  itemExistsInLearningSequence = (item: Module | Submodule) => {
    if (item instanceof Module) return this.modules.some((module) => item.id === module.id)
    return this.getAllSubmodules().some((submodule) => item.id === submodule.id)
  }

  getCertificatePdfUrl = async () => {
    return await Course.get(this.id, undefined, undefined, { fields: 'user_certificate_pdf_url' })
  }

  shouldOpenConfettiModal = () => {
    return this.isUserEligibleForCertificate && !hasOpenedConfettiModal(this)
  }

  canCreateTemplate = () => false
}
