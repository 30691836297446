import React, { Component } from 'react'
import { Tooltip } from 'antd'
import { Form } from 'formik-antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { observable, makeObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import { ContainerDiv, FjText, DefaultButton, FeedEmptyContent, Loader } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { LearningPathElement } from 'src/models/LearningPath'
import { FeedContentImageCard } from 'src/components/Library/FeedContentImageCard'
import { Formik } from 'formik'
import { getFeedContentTitle, LearningContent, LearningContentType } from 'src/utils/content'
import { SearchInput } from './FeedNavbar'
import { sharedDataStore } from 'src/store/DataStore'
import { APIProvider } from 'src/network/APIProvider'
import { FjFilter } from 'src/components/Common/FjFilter'
import { RouteComponentProps } from 'react-router-dom'
import { Plus } from 'react-feather'
import { ContentTag } from 'src/components/Feed/ContentTag'
import { Template } from 'src/models/Template'

interface SearchFeedContentCardProps {
  obj: Template | LearningContent
  addContentClicked?: (feedContent: LearningContent) => void
}
export const SearchFeedContentCard: React.FC<SearchFeedContentCardProps> = ({ obj, addContentClicked }) => {
  const isMobile = sharedAppStateStore.isMobile
  const learningContent = obj instanceof Template ? obj.learningContent : obj
  const title = obj instanceof Template ? obj.name : getFeedContentTitle(learningContent)
  return (
    <ContainerDiv
      padding={isMobile ? '8px 0' : '16px 24px 16px 12px'}
      textAlign="left"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={isMobile ? 0 : 24}
      borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
    >
      <ContainerDiv display="flex" alignItems="center" gap={isMobile ? 8 : 24}>
        <ContainerDiv
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={6}
          overflow="hidden"
          minWidth={isMobile ? '120px' : '142px'}
        >
          <FeedContentImageCard compressView obj={learningContent} width={isMobile ? '120px' : '142px'} />
        </ContainerDiv>
        <ContainerDiv display="flex" flexDirection="column" textAlign="left" gap={isMobile ? 4 : 8}>
          <ContentTag obj={learningContent} includeFileType />
          <Tooltip title={title} placement="topLeft">
            <FjText
              textAlign="left"
              fontWeight="bold500"
              wordBreak="break-word"
              color={Colors.shark}
              rows={isMobile ? 2 : 1}
            >
              {title}
            </FjText>
          </Tooltip>
        </ContainerDiv>
      </ContainerDiv>
      {addContentClicked && (
        <DefaultButton
          title={isMobile ? '' : 'Add'}
          image={<Plus size={20} color={Colors.cornflowerBlue} />}
          clicked={() => addContentClicked(learningContent)}
        />
      )}
    </ContainerDiv>
  )
}

interface SearchFeedContentProps {
  searchStr?: string
  searchedContentIds: Record<string, boolean>
  handleAddContent: (feedContent: LearningContent | LearningPathElement) => void
  contentTypes: LearningContentType[]
  containerTitle?: string
  height?: string
  scrollableTargetId?: string
  showHeader?: boolean
}

@observer
export class SearchFeedContent extends Component<RouteComponentProps<{}> & SearchFeedContentProps> {
  @observable feedContents: (LearningContent | LearningPathElement)[] = []
  @observable hasMorePosts: boolean = false
  @observable isSearching: boolean = false
  @observable noResults = false
  @observable pageNum = 1
  @observable queryParams: any = {}
  @observable excludedContentIds: Record<string, boolean> = this.props.searchedContentIds
  formikRef: any
  abortController: AbortController
  currentSearchVal: string

  static defaultProps = {
    showHeader: true,
  }

  constructor(props) {
    super(props)

    makeObservable(this)

    this.formikRef = React.createRef()
  }

  async componentDidMount() {
    this.isSearchAllowed() && this.searchFeedContent('')
  }

  @computed get overrideFilterContentTypes() {
    let contentTypes = [...this.props.contentTypes]
    if (
      (!sharedDataStore.user.company.integratedCallOrganization && !sharedDataStore.user.isFaasAdminOrManager()) ||
      sharedDataStore.user.isFaasPartner()
    )
      contentTypes = contentTypes.filter((option) => option !== 'call')
    return contentTypes
  }

  isSearchAllowed = () => {
    if (this.queryParams?.content_type === 'call' && !sharedDataStore.user.company.integratedCallOrganization)
      return false
    return true
  }

  searchFeedContent = async (searchVal: string, isReload: boolean = true) => {
    if (!this.formikRef.current) return

    const searchStr = searchVal || this.formikRef.current.values.searchStr
    this.currentSearchVal = searchStr

    if (isReload) {
      this.pageNum = 1
      this.isSearching = true
      this.feedContents = []
      this.hasMorePosts = false
    }

    if (!this.queryParams['content_type']) this.queryParams['content_type'] = this.overrideFilterContentTypes.join(',')

    const queryParams = { page: this.pageNum, search: searchStr ?? '', ...this.queryParams }
    this.abortController?.abort()
    this.abortController = new AbortController()
    const { results, next } = await APIProvider.getFullSearch(queryParams, this.abortController)
    this.feedContents = isReload ? [...results] : [...this.feedContents, ...results]

    this.hasMorePosts = Boolean(next)
    this.isSearching = false
    this.noResults = this.feedContents.length === 0
  }

  fetchMorePosts = () => {
    if (!this.isSearchAllowed()) return

    this.pageNum = this.pageNum + 1
    this.searchFeedContent(this.currentSearchVal, false)
  }

  onFiltersChange = (queryParams) => {
    this.queryParams = queryParams
    this.isSearchAllowed() && this.searchFeedContent(this.currentSearchVal, true)
  }

  addContentClicked = (feedContent: LearningContent) => {
    this.excludedContentIds[feedContent.id] = true
    this.props.handleAddContent(feedContent as LearningPathElement)
  }

  render() {
    const isMobile = sharedAppStateStore.isMobile
    let { containerTitle, showHeader } = this.props

    return (
      <Formik
        initialValues={{ searchStr: this.props.searchStr }}
        onSubmit={() => this.searchFeedContent(this.currentSearchVal)}
        innerRef={this.formikRef}
      >
        <Form>
          <ContainerDiv marginTop="26px" textAlign="left">
            {showHeader && containerTitle ? (
              <ContainerDiv
                display="flex"
                flexDirection="row"
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent="space-between"
                flexWrap="wrap"
                WebkitFlexDirection={isMobile ? 'column-reverse' : undefined}
                gap="0.5rem"
              >
                <FjText fontSize="medium" color={Colors.squirrel}>
                  Add content to your {containerTitle}
                </FjText>
              </ContainerDiv>
            ) : null}
            <ContainerDiv display="flex" marginBottom="12px">
              <SearchInput
                submitSearch={(searchVal: string) => this.searchFeedContent(searchVal, true)}
                inputStyle={{ width: '100%' }}
              />
            </ContainerDiv>
            <ContainerDiv marginBottom="25px">
              <FjFilter
                overrideFilterContentTypes={this.overrideFilterContentTypes}
                showSort={false}
                category="fullsearch"
                onFiltersChange={this.onFiltersChange}
                showViewSwitcher={false}
                {...this.props}
              />
            </ContainerDiv>
            {this.isSearching ? (
              <ContainerDiv padding="30px 0" height={this.props.height}>
                <Loader />
              </ContainerDiv>
            ) : (!sharedDataStore.user.company.integratedCallOrganization &&
                this.queryParams?.content_type === 'call') ||
              !this.feedContents.length ? (
              <div style={{ minHeight: this.props.height ?? '300px' }}>
                <FeedEmptyContent
                  title="FIND CONTENT"
                  subtitleLine1={
                    this.formikRef.current?.values?.searchStr
                      ? 'No results found'
                      : 'Use the search box above to look for content.'
                  }
                  imgWidth="154px"
                  imgHeight="103px"
                />
              </div>
            ) : (
              <ContainerDiv id={this.props.scrollableTargetId} height={this.props.height} overflow="auto">
                <InfiniteScroll
                  dataLength={this.feedContents.length}
                  next={this.fetchMorePosts}
                  hasMore={this.hasMorePosts}
                  style={{ overflow: 'hidden' }}
                  loader={<Loader />}
                  scrollableTarget={this.props.scrollableTargetId}
                >
                  {this.feedContents
                    .filter((feedContent) => !this.excludedContentIds[feedContent.id])
                    .map((feedContent) => (
                      <SearchFeedContentCard
                        key={feedContent.id}
                        obj={feedContent as LearningContent}
                        addContentClicked={this.addContentClicked}
                      />
                    ))}
                </InfiniteScroll>
              </ContainerDiv>
            )}
          </ContainerDiv>
        </Form>
      </Formik>
    )
  }
}
