import moment from 'moment'
import humps from 'humps'
import { observable, computed, makeObservable } from 'mobx'
import { FeedPost } from 'src/models/FeedPost'
import { Favouritable } from 'src/models/Favouritable'
import { Author } from 'src/models/Author'
import { AppearsInType, getFeedContentData, LearningContentType } from 'src/utils/content'
import { Call } from 'src/models/Call'
import { Asset } from 'src/models/Asset'
import { sharedDataStore } from 'src/store/DataStore'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { Colors } from 'src/constants/colors'
import { MiniHub } from 'src/models/Hub'

export type PlaylistElement = FeedPost | Call | Asset

export class FeedPlaylist extends Favouritable {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    posts: (data) => (data.posts ? data.posts.map((p) => FeedPost.fromData(p)) : []),
    contents: (data) =>
      data.learningContents
        ? data.learningContents.map((learningContent) =>
            getFeedContentData(learningContent.contentData, learningContent.contentType)
          )
        : [],
    tags: (data) => (data.tags ? data.tags : []),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    hubs: (data) => (data.hubs ? data.hubs : []),
    customFields: (data) => (data.customFields ? humps.decamelizeKeys(data.customFields) : {}),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'playlist',
  }
  static apiEndpoint: string = '/feed/playlist/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'playlist'

  getBulkEditFields = () => ['groupIds', 'tags', 'hubs', 'sfMetadata', 'expiryDate', 'customFields']

  id: string = ''
  @observable name: string = ''
  @observable description: string = ''
  @observable contents: PlaylistElement[] = []
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  @observable thumbnailUrl: string
  contentCount: number = 0
  author: Author = new Author()
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  progress: number
  publishedAt: moment.Moment
  tags: string[] = []
  groupIds: string[]
  @observable hubs: MiniHub[] = []
  customFields: object
  sfMetadata?: SfOpportunity
  appearsIn: AppearsInType[]
  learningContentId: string

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get title() {
    return this.name
  }

  getHeroBgColor = () => Colors.teaGreen
  getContentTypeTagColor = () => Colors.pastelGreen
  getTagBgColor = () => Colors.tusk
  getTagTitle = () => 'Collection'

  imgSrc = () => this.thumbnailUrl

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate?.diff(moment(), 'days') <= 7

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => false

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  hasCustomFields = () => !!Object.keys(this.customFields).length

  canCreateTemplate = () => false
}
