import React, { CSSProperties, MouseEvent } from 'react'
import { Colors } from 'src/constants/colors'
import { Paths } from 'src/constants/navigation'
import { FjText, ContainerDiv, TextWeightOption, FjAvatar } from 'src/components/Common'
import { User } from 'src/models/User'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Author } from 'src/models/Author'
import { Tooltip } from 'antd'
import badges from 'src/constants/badges'
import { sharedDataStore } from 'src/store/DataStore'

interface AuthorDisplayProps {
  author: User | Author
  customTitle?: string
  showName?: boolean
  avatarSize?: 'large' | 'small' | 'default' | number
  fontSize?: string
  fontWeight?: TextWeightOption
  onClick?: (e: any) => void
  style?: CSSProperties
}

export const AuthorDisplay: React.FC<AuthorDisplayProps> = ({
  author,
  showName = true,
  customTitle,
  avatarSize = 'default',
  fontSize,
  fontWeight = 'default',
  onClick,
  style = {},
}) => {
  let handleOnClick = onClick
  if (!handleOnClick) {
    handleOnClick = (e: MouseEvent) => {
      sharedAppStateStore.teamProgressModalProps = undefined
      const userID = author instanceof User ? author.id : author.authorID
      e.preventDefault()
      if (sharedDataStore.user.isFaasPublicOrPartner()) return
      sharedAppStateStore.navigate(Paths.getProfilePath(userID))
    }
  }

  return (
    <ContainerDiv
      textAlign="left"
      display="flex"
      alignItems="center"
      cursor={!sharedDataStore.user.isFaasPublicOrPartner() ? 'pointer' : undefined}
      onClick={handleOnClick}
      style={style}
      gap="5px"
    >
      {showName ? (
        <>
          <FjAvatar size={avatarSize} author={author} />
          <ContainerDiv display="flex" flexDirection="row" alignItems="center" gap="5px">
            <ContainerDiv display="flex" flexDirection="column" alignItems="left" gap="2px">
              <FjText marginLeft="2px" lineHeight="1rem" textAlign="left" color={Colors.abbey}>
                {customTitle}
              </FjText>
              <FjText
                marginLeft="2px"
                fontSize={fontSize}
                fontWeight={fontWeight}
                lineHeight={customTitle ? '1rem' : '20px'}
                color={style?.color ?? Colors.abbey}
                display="block"
                textAlign="left"
                wordBreak="break-word"
              >
                {author.fullName}
              </FjText>
            </ContainerDiv>

            {process.env.REACT_APP_DEMO_FEATURES_ENABLED && badges[author.email] ? (
              <ContainerDiv display="flex" flexDirection="row" alignItems="center">
                {badges[author.email].map(({ tooltip, badge }) => (
                  <Tooltip title={tooltip}>
                    <FjText fontSize="medium">{badge}</FjText>
                  </Tooltip>
                ))}
              </ContainerDiv>
            ) : null}
          </ContainerDiv>
        </>
      ) : (
        <FjAvatar size={avatarSize} author={author} />
      )}
    </ContainerDiv>
  )
}
