import React, { useCallback } from 'react'
import humps from 'humps'
import { getFeedContentClass, LearningContent } from 'src/utils/content'
import { DefaultButton, FjCard, FjText } from 'src/components/Common'
import styled from 'styled-components'
import { pluralize } from 'src/utils/format'
import { Archive, Edit, Trash, X } from 'react-feather'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { showNotification } from 'src/hoc/Notification'
import { Colors } from 'src/constants/colors'

const BulkEditPopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`

export interface IBulkEditPopupProps {
  learningContents: LearningContent[]
  onEditSuccess: (objs: LearningContent[]) => void
  onArchiveSuccess: (objs: LearningContent[]) => void
  onDeleteSuccess: (objs: LearningContent[]) => void
  clearSelection: () => void
}

export const BulkEditPopup: React.FC<IBulkEditPopupProps> = ({
  learningContents,
  onEditSuccess,
  onArchiveSuccess,
  onDeleteSuccess,
  clearSelection,
}) => {
  const handleEdit = useCallback(async () => {
    const closeModalForm = () => (sharedAppStateStore.bulkEditFormModalProps = undefined)
    const onSuccess = (objs: LearningContent[]) => {
      closeModalForm()
      onEditSuccess(objs)
      showNotification({
        message: `Successfully updated ${learningContents.length} ${pluralize('Item', learningContents.length)}!`,
      })
      sharedAppStateStore.bulkEditPopupProps = undefined
    }

    // ideally we'd be able to use list endpoint here instead of individual requests
    // but because table view edit is supported in search we could have mixed content types
    const learningContentsToEdit = await sharedAppStateStore.wrapAppLoading(
      Promise.all(
        learningContents
          .filter((lc) => lc.canEdit())
          .map((lc) => {
            return getFeedContentClass(lc.learningContentType).get(lc.id, undefined, undefined, {
              fields: ['id', ...lc.getBulkEditFields()].map((field) => humps.decamelize(field)).join(','),
              ...(lc.getBulkEditFields().includes('hubs') ? { expand: 'hubs' } : {}),
            })
          })
      )
    )
    sharedAppStateStore.bulkEditFormModalProps = {
      learningContents: learningContentsToEdit,
      onSuccess,
      onCancel: closeModalForm,
    }
  }, [learningContents, onEditSuccess])

  const handleArchive = useCallback(async () => {
    const archiveableElements = learningContents.filter((lc) => lc.canArchive())
    await sharedAppStateStore.wrapAppLoading(
      Promise.all(archiveableElements.map((lc) => lc.patch('archive'))),
      'Archiving...'
    )
    onArchiveSuccess(archiveableElements)
    sharedAppStateStore.sharedConfirmDialogProps = undefined
    showNotification({
      message: `Successfully archived ${learningContents.length} ${pluralize('Item', learningContents.length)}!`,
    })
    sharedAppStateStore.bulkEditPopupProps = undefined
  }, [learningContents, onArchiveSuccess])

  const showArchiveDialog = useCallback(() => {
    const titleCopy = learningContents.length === 1 ? 'this item' : `these ${learningContents.length} items`
    sharedAppStateStore.sharedConfirmDialogProps = {
      title: `Are you sure you want to archive ${titleCopy}?`,
      content:
        'Archiving content removes content from everywhere in Flockjay. Admins may filter for archived content in the library. Do you wish to proceed?',
      confirmButtonTitle: 'Archive',
      onConfirm: handleArchive,
      onCancel: () => (sharedAppStateStore.sharedConfirmDialogProps = undefined),
    }
  }, [learningContents, handleArchive])

  const handleDelete = useCallback(async () => {
    await sharedAppStateStore.wrapAppLoading(
      Promise.all(learningContents.map((learningContent) => learningContent.delete())),
      'Deleting...'
    )
    onDeleteSuccess([...learningContents])
    sharedAppStateStore.sharedConfirmDialogProps = undefined
    showNotification({
      message: `Successfully deleted ${learningContents.length} ${pluralize('Item', learningContents.length)}!`,
    })
    sharedAppStateStore.bulkEditPopupProps = undefined
  }, [learningContents, onDeleteSuccess])

  const showDeleteDialog = useCallback(() => {
    const titleCopy = learningContents.length === 1 ? 'this item' : `these ${learningContents.length} items`
    sharedAppStateStore.sharedConfirmDialogProps = {
      title: `Are you sure you want to delete ${titleCopy}?`,
      content: 'You cannot undo this action.',
      confirmButtonTitle: 'Delete',
      confirmButtonType: 'destructive',
      onConfirm: handleDelete,
      onCancel: () => (sharedAppStateStore.sharedConfirmDialogProps = undefined),
    }
  }, [learningContents, handleDelete])

  return (
    <BulkEditPopupContainer>
      <FjCard
        display="flex"
        gap={8}
        alignItems="center"
        justifyContent={sharedAppStateStore.isMobile ? 'end' : 'center'}
        pointerEvents="auto"
        flexWrap="wrap"
      >
        <X color={Colors.tapa} size={18} style={{ cursor: 'pointer' }} onClick={clearSelection} />
        <FjText fontWeight="semi-bold">
          {learningContents.length} {pluralize('Item', learningContents.length)} Selected
        </FjText>
        <DefaultButton image={<Edit size={16} />} title="Edit" buttonType="primary" clicked={handleEdit} size="small" />
        <DefaultButton
          image={<Archive size={16} />}
          title="Archive"
          buttonType="primary"
          clicked={showArchiveDialog}
          size="small"
        />
        <DefaultButton
          image={<Trash size={16} />}
          title="Delete"
          buttonType="destructive"
          clicked={showDeleteDialog}
          size="small"
        />
      </FjCard>
    </BulkEditPopupContainer>
  )
}
