import React from 'react'
import { Clock, User as UserIcon } from 'react-feather'
import { Avatar, AvatarProps, Popover, PopoverProps } from 'antd'
import { ContainerDiv } from 'src/components/Common/Cards'
import { FjText } from 'src/components/Common/Text'
import { Colors } from 'src/constants/colors'
import { Author } from 'src/models/Author'
import { User } from 'src/models/User'
import { sharedDataStore } from 'src/store/DataStore'

interface FjAvatarProps extends AvatarProps {
  author?: Author | User
  showPopover?: boolean
  popoverProps?: Omit<PopoverProps, 'content' | 'showArrow'>
  showEmail?: boolean
  showWorkInfo?: boolean
}

export const FjAvatar = ({
  style,
  author,
  showPopover = true,
  showEmail = false,
  showWorkInfo = true,
  popoverProps = {},
  ...props
}: FjAvatarProps) => {
  const getIconSize = () => {
    if (typeof props.size === 'number') return props.size / 2
    else if (props.size === 'small') return 12
    else if (props.size === 'default') return 16
    else if (props.size === 'large') return 20
    return 16
  }

  const showPendingStatus = author?.status === 'pending' && sharedDataStore.user.isFaasAdminOrManager()

  return (
    <Popover
      placement="top"
      showArrow={!!author}
      content={
        showPopover && author ? (
          <ContainerDiv display="flex" flexDirection="column" alignItems="flex-start">
            {showPendingStatus ? (
              <FjText fontSize="small">The user is pending activation.</FjText>
            ) : (
              <>
                <FjText fontSize="small">{author.fullName}</FjText>
                {showEmail ? <FjText fontSize="small">{author.email}</FjText> : null}
                {showWorkInfo && author.formatWorkInfo ? (
                  <FjText fontSize="small" color={Colors.gray}>
                    {author.formatWorkInfo()}
                  </FjText>
                ) : null}
              </>
            )}
          </ContainerDiv>
        ) : null
      }
      {...popoverProps}
    >
      <Avatar
        src={author?.displayPic}
        icon={showPendingStatus ? <Clock size={getIconSize()} /> : <UserIcon size={getIconSize()} />}
        style={{
          flexShrink: 0,
          color: showPendingStatus ? Colors.cottonSeed : Colors.hawkesBlue,
          backgroundColor: showPendingStatus ? Colors.mercury : 'white',
          border: `solid 1px ${showPendingStatus ? Colors.mercury : Colors.hawkesBlue}`,
          ...style,
        }}
        {...props}
      />
    </Popover>
  )
}
