import React from 'react'
import { Row, Col, ColProps } from 'antd'
import { Colors } from 'src/constants/colors'

export const MAIN_CONTENT_COL_PROPS: ColProps = {
  xs: 24,
  sm: 24,
  md: 16,
  xl: 18,
}
export const SIDEBAR_COL_PROPS: ColProps = {
  xs: 24,
  sm: 24,
  md: 8,
  xl: 6,
}

interface IContentWrapperProps extends React.PropsWithChildren {
  backgroundColor?: string
  contentMaxWidth?: string
  style?: React.CSSProperties
  className?: string
}

export const ContentWrapper: React.FC<IContentWrapperProps> = ({
  children,
  backgroundColor,
  contentMaxWidth,
  style,
  className,
}) => {
  let color = backgroundColor ? backgroundColor : Colors.wildSand
  const backgroundProps = className
    ? { className }
    : {
        style: {
          backgroundColor: color,
          width: '100%',
          height: '100%',
          ...style,
        },
      }
  return (
    <div {...backgroundProps}>
      <div
        style={{
          backgroundColor: 'transparent',
          maxWidth: contentMaxWidth || '1600px',
          padding: '10px 0',
          marginRight: 'auto',
          marginLeft: 'auto',
          ...style,
        }}
      >
        <Row justify="center">
          <Col span={23}>{children}</Col>
        </Row>
      </div>
    </div>
  )
}
