import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Call } from 'src/models/Call'

export const CallAttendeeInfo = ({ targetObj }: { targetObj: Call }) => {
  if (targetObj?.parties?.length === 0) return null
  const attendeeInfo = targetObj.parties.join(', ')
  return (
    <ContainerDiv display="flex" flexDirection="column" alignItems="start">
      <FjText fontWeight="semi-bold" textAlign="left" rows={4}>
        {`Attendees: ${attendeeInfo}`}
      </FjText>
      {targetObj.started && (
        <FjText color={Colors.tapa}>Started: {targetObj.started.format(Call.startedDateFormat)}</FjText>
      )}
    </ContainerDiv>
  )
}
