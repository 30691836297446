import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BaseFilter } from 'src/components/Common/Filter/BaseFilter'
import { AutoCompleteOption } from 'src/components/Common/FjFilter'
import { FjPopoverContent } from 'src/components/Common/FjPopover'
import { Hub } from 'src/models/Hub'
import { User } from 'src/models/User'
import { debounce } from 'src/utils/debounce'

export type AutoCompleteFilterProps = {
  paramKey: string
  label: string
  initialOptions: AutoCompleteOption[]
  onChange: (params: object) => void
  onSearch: (value: string) => Promise<AutoCompleteOption[]>
}

export const AutoCompleteFilter = ({
  paramKey,
  label,
  initialOptions,
  onChange,
  onSearch,
}: AutoCompleteFilterProps) => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([])
  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([])

  useEffect(() => {
    setSelectedOptions(initialOptions)
  }, [initialOptions])

  const handleSearch = debounce(async (value) => {
    const options = await onSearch(value)
    setOptions(options)
  }, 300)

  const handleAddFilterOption = (option: AutoCompleteOption) => {
    const newOptions = [...selectedOptions, option]
    setSelectedOptions(newOptions)
    onChange({ [paramKey]: newOptions.map((option) => option.id).join(',') || undefined })
  }

  const handleRemoveFilterOption = (option: AutoCompleteOption) => {
    const newOptions = selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
    setSelectedOptions(newOptions)
    onChange({ [paramKey]: newOptions.map((option) => option.id).join(',') || undefined })
  }

  const handleClear = () => {
    setSelectedOptions([])
    onChange({
      [paramKey]: undefined,
    })
  }

  const filterLabel = useMemo(() => {
    let newFilterLabel = label
    if (selectedOptions.length > 0) {
      newFilterLabel =
        selectedOptions.length > 2
          ? `${newFilterLabel} | ${selectedOptions[0].label}, ${selectedOptions[1].label}, + ${
              selectedOptions.length - 2
            } more`
          : `${newFilterLabel} | ${selectedOptions.map((filter) => filter.label).join(', ')}`
    }
    return newFilterLabel
  }, [label, selectedOptions])

  return (
    <BaseFilter
      title={filterLabel}
      content={
        <FjPopoverContent
          options={options}
          values={selectedOptions}
          onSearch={handleSearch}
          addFilterOption={handleAddFilterOption}
          removeFilterOption={handleRemoveFilterOption}
        />
      }
      selected={selectedOptions.length > 0}
      onClear={handleClear}
      onClick={() => handleSearch('')}
    />
  )
}

type HubFilterProps = {
  onChange: (params: object) => void
}

export const HUB_PARAM_KEY = 'hub_id'

export const HubFilter = ({ onChange }: HubFilterProps) => {
  const [initialOptions, setInitialOptions] = useState([])
  const { search } = useLocation()
  const abortController = useRef<AbortController>()

  const initFiltersFromURL = useCallback(async () => {
    const searchParams = new URLSearchParams(search)
    const param = searchParams.get(HUB_PARAM_KEY)
    if (!param) {
      setInitialOptions([])
      return
    }

    abortController.current?.abort?.()
    abortController.current = new AbortController()
    const initialOptions = (
      await Promise.all(
        param.split(',').map((id) => Hub.get(id, undefined, undefined, { fields: 'id,name' }, abortController.current))
      )
    ).map((hub: Hub) => {
      return { ...hub, label: hub.name }
    })
    setInitialOptions(initialOptions)
  }, [search])

  useEffect(() => {
    initFiltersFromURL()
  }, [initFiltersFromURL])

  const handleSearch = async (value: string) => {
    const { data } = await Hub.list({ search: value, fields: 'id,name' })
    const options = data.map((item: Hub) => ({ ...item, value: item.name, label: item.name }))
    return options
  }

  return (
    <AutoCompleteFilter
      label="Hub"
      paramKey={HUB_PARAM_KEY}
      initialOptions={initialOptions}
      onChange={onChange}
      onSearch={handleSearch}
    />
  )
}

type UserFilterProps = {
  onChange: (params: object) => void
}

export const USER_PARAM_KEY = 'userId'

export const UserFilter: React.FC<UserFilterProps> = ({ onChange }) => {
  const [initialOptions, setInitialOptions] = useState([])
  const { search } = useLocation()
  const abortController = useRef<AbortController>()

  const convertUserToOptions = (users: User[]): AutoCompleteOption[] =>
    users.map((user: User) => ({ key: user.id, id: user.id, value: user.id, label: user.fullName }))

  const initFiltersFromURL = useCallback(async () => {
    const searchParams = new URLSearchParams(search)
    const param = searchParams.get(USER_PARAM_KEY)
    if (!param) {
      setInitialOptions([])
      return
    }

    abortController.current?.abort?.()
    abortController.current = new AbortController()
    const initialOptions = await Promise.all(
      param
        .split(',')
        .map((id) => User.get(id, undefined, undefined, { fields: 'mongo_id,full_name' }, abortController.current))
    )
    setInitialOptions(convertUserToOptions(initialOptions.map((data) => User.fromData(data))))
  }, [search])

  const handleSearch = useCallback(async (value: string) => {
    abortController.current?.abort?.()
    abortController.current = new AbortController()
    const { results } = await User.list(
      { search: value, fields: 'mongo_id,full_name' },
      undefined,
      undefined,
      undefined,
      abortController.current
    )
    return convertUserToOptions(User.fromData(results))
  }, [])

  useEffect(() => {
    initFiltersFromURL()
  }, [initFiltersFromURL])

  return (
    <AutoCompleteFilter
      label="User"
      paramKey={USER_PARAM_KEY}
      initialOptions={initialOptions}
      onChange={onChange}
      onSearch={handleSearch}
    />
  )
}
