import React, { lazy } from 'react'
import { DataObject } from 'src/models/DataObject'
import { sharedAppStateStore } from 'src/store/AppStateStore'

const RedisHeader = lazy(() => import('src/components/CustomBranding/RedisHeader'))
const RedisFooter = lazy(() => import('src/components/CustomBranding/RedisFooter'))

export const REDIS_COMPANY_NAME = 'Redis'

const CUSTOM_HEADERS_FOOTERS = {
  [REDIS_COMPANY_NAME]: { header: <RedisHeader />, footer: <RedisFooter /> },
}

interface ExternalAcademyData {
  customHome?: string
  loginUrl?: string
  globalStyleClass?: string
  publicFilters?: Array<string>
}

export class ExternalAcademy extends DataObject {
  static apiEndpoint = '/api/companies/academy/:urlPath/'

  companyId: string
  companyName: string
  isActive: boolean
  urlPath: string
  logoUrl: string
  customStyle: string = ''
  headline: string
  subHeadline: string
  customDomain?: string
  data: ExternalAcademyData
  createdAt: string

  getCustomLoginUrl = (redirectPath?: string) => {
    if (this.data?.loginUrl && sharedAppStateStore.externalAcademy?.isRedis()) {
      const url = new URL(this.data.loginUrl)
      if (redirectPath) {
        url.searchParams.delete('next')
        url.searchParams.append('next', redirectPath)
      }
      return url.href
    } else if (this.data?.loginUrl) {
      return this.data.loginUrl
    }
  }

  getCustomHeaderIfExists = () => CUSTOM_HEADERS_FOOTERS[this.companyName]?.header

  getCustomFooterIfExists = () => CUSTOM_HEADERS_FOOTERS[this.companyName]?.footer

  getOverrideFilters = () => (this.data?.publicFilters ? { course: [], learningpath: [] } : undefined)

  isRedis = () => this.companyName === REDIS_COMPANY_NAME
}
