import moment from 'moment'
import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import { LearningContentType } from 'src/utils/content'
import { sharedDataStore } from 'src/store/DataStore'
import { JsonTree } from '@react-awesome-query-builder/antd'

export class AutomationRule extends DataObject {
  static OVERRIDE_MAPPINGS = {
    key: (data) => data.id,
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
  }

  static apiEndpoint: string = '/feed/automationrule/'

  key: string
  id: string
  author: Author
  title: string
  isActive: boolean
  preCondition: object
  queryTree: JsonTree
  outcome: {
    groupIds?: string[]
    content?: { contentType: LearningContentType; contentId: string; contentTitle: string }[]
  }
  dueDateOffset: number
  createdAt: moment.Moment
  lastUpdated: moment.Moment

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  canArchive = () => false
}
