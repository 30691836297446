import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as pdfjs from 'pdfjs-dist'
import 'pdfjs-dist/webpack'
import { useMediaQuery } from 'react-responsive'
import { Loader } from 'src/components/Common/Loader'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { mobileQuery } from 'src/pages/public/common'
import { DEFAULT_GAP, GAP_MULTIPLIER } from 'src/components/Common/MSDocRenderer'
import { ContainerDiv } from 'src/components/Common/Cards'

const MOBILE_MIN_IFRAME_HEIGHT = 200

type PDFRendererProps = {
  title?: string
  url: string
}

const PDFRenderer: React.FC<PDFRendererProps> = ({ url, title }) => {
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [iframeHeight, setIframeHeight] = useState(550)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const iframeInterval = useRef<any>()
  const intervalTimeRef = useRef<number>(DEFAULT_GAP)

  const isMobile = useMediaQuery(mobileQuery)

  const getPdfHeight = useCallback(async () => {
    if (isMobile) {
      setIframeHeight(MOBILE_MIN_IFRAME_HEIGHT)
      return
    }

    try {
      setLoadingPdf(true)
      const pdf = await pdfjs.getDocument(url).promise
      const page = await pdf.getPage(1)
      if (page.view) {
        const iframe = document.getElementById('pdfRenderer')
        const height = (page.view[3] * iframe.offsetWidth) / page.view[2]
        setIframeHeight(height)
      }
    } catch (error) {
      sharedAppStateStore.handleError(error, undefined, false)
    } finally {
      setLoadingPdf(false)
    }
  }, [isMobile, url])

  useEffect(() => {
    getPdfHeight()
  }, [getPdfHeight])

  const getIframeLink = useCallback(() => {
    return isMobile ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${url}#view=fitW` : `${url}#view=fitW`
  }, [isMobile, url])

  const updateIframeSrc = useCallback(() => {
    if (!isMobile) return
    const iframe = document.getElementById(`ms-doc-iframe-${url}`)
    iframe.setAttribute('src', getIframeLink())

    clearTimeout(iframeInterval.current)
    const newIntervalTime = intervalTimeRef.current * GAP_MULTIPLIER
    intervalTimeRef.current = newIntervalTime
    const interval = setTimeout(updateIframeSrc, newIntervalTime)
    iframeInterval.current = interval
  }, [isMobile, url, getIframeLink])

  useEffect(() => {
    const interval = setTimeout(updateIframeSrc, intervalTimeRef.current)
    iframeInterval.current = interval
  }, [updateIframeSrc])

  useEffect(() => {
    return () => {
      clearTimeout(iframeInterval.current)
    }
  }, [iframeInterval])

  const handleIframeLoaded = () => {
    setIframeLoaded(true)
    clearTimeout(iframeInterval.current)
  }

  return (
    <ContainerDiv id="pdfRenderer" position="relative">
      {(!isMobile && loadingPdf) || (isMobile && !iframeLoaded) ? (
        <ContainerDiv position="absolute" top={0} bottom={0} left={0} right={0} margin="auto">
          <Loader />
        </ContainerDiv>
      ) : null}
      <iframe
        id={`ms-doc-iframe-${url}`}
        onLoad={handleIframeLoaded}
        onError={updateIframeSrc}
        src={getIframeLink()}
        title={title}
        style={{
          minHeight: isMobile ? MOBILE_MIN_IFRAME_HEIGHT : 550,
          height: iframeHeight,
          maxHeight: '95vh',
          width: '100%',
        }}
      />
    </ContainerDiv>
  )
}

export default PDFRenderer
