import React, { useMemo } from 'react'
import { Select } from 'antd'
import { observer } from 'mobx-react'
import { sharedDataStore, ViewMode, ViewModes } from 'src/store/DataStore'
import { camelToSentenceCase } from 'src/utils/format'
import { FilterSelect } from 'src/components/Feed/FeedPostList'

export interface ViewSwitcherProps {
  excludesViewModes?: ViewMode[]
  initialViewMode?: ViewMode
  handleViewModeChange?: (mode: ViewMode) => void
  updateLocalSettingsViewMode?: boolean
}

export const ViewSwitcher = observer(
  ({
    excludesViewModes = [],
    initialViewMode,
    handleViewModeChange,
    updateLocalSettingsViewMode = true,
  }: ViewSwitcherProps) => {
    const viewModeOptions = useMemo(
      () => ViewModes.filter((mode) => !excludesViewModes.includes(mode)),
      [excludesViewModes]
    )

    const currentViewMode = useMemo(() => {
      let targetViewMode = initialViewMode ?? sharedDataStore.localSettings.viewMode
      if (!viewModeOptions.includes(targetViewMode)) {
        targetViewMode = viewModeOptions[0]
      }
      if (updateLocalSettingsViewMode && sharedDataStore.localSettings.viewMode !== targetViewMode) {
        sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: targetViewMode }
      }
      return targetViewMode
    }, [initialViewMode, viewModeOptions, updateLocalSettingsViewMode])

    const onViewModeChange = (mode: ViewMode) => {
      handleViewModeChange?.(mode)
      if (updateLocalSettingsViewMode) {
        sharedDataStore.localSettings = { ...sharedDataStore.localSettings, viewMode: mode }
      }
    }

    return (
      <FilterSelect value={currentViewMode} onChange={onViewModeChange} style={{ width: 'max-content' }}>
        {viewModeOptions.map((mode) => (
          <Select.Option key={mode} value={mode}>
            {camelToSentenceCase(mode)}
          </Select.Option>
        ))}
      </FilterSelect>
    )
  }
)
