import { useEffect, useState } from 'react'

export function useStickyBottom() {
  const [stickyBottom, setStickyBottom] = useState(0) // State to adjust sticky bar position

  useEffect(() => {
    handleScroll()
  }, [])

  const handleScroll = () => {
    const footer = document.getElementsByClassName('custom-footer')?.[0]
    if (!footer) return
    const footerTop = footer.getBoundingClientRect().top
    const windowHeight = window.innerHeight

    if (footerTop < windowHeight) {
      setStickyBottom(windowHeight - footerTop)
    } else {
      setStickyBottom(0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return { stickyBottom }
}
