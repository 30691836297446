import React from 'react'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import emptyContentIcon from 'src/assets/img/empty-content.svg'
import { sharedDataStore } from 'src/store/DataStore'
import {
  ConnectGongButton,
  ConnectSalesforceButton,
  GONG_CLIENT_ID,
  SALESFORCE_CLIENT_ID,
} from 'src/components/Common/Button'
import emptyPrompt from 'src/assets/img/empty-prompt.svg'

export interface FeedEmptyContentProps {
  title?: string
  subtitleLine1?: string
  subtitleLine2?: string
  imgSrc?: string
  imgWidth?: string
  imgHeight?: string
  imgMinWidth?: string
  imgMinHeight?: string
  imgMaxWidth?: string
  imgMaxHeight?: string
  paddingVertical?: string
  titleMarginTop?: string
}

export const FeedEmptyContent: React.FC<FeedEmptyContentProps> = ({
  title = 'No data',
  subtitleLine1,
  subtitleLine2,
  imgSrc,
  imgWidth,
  imgHeight,
  imgMinWidth,
  imgMinHeight,
  imgMaxWidth,
  imgMaxHeight,
  paddingVertical = '30px',
  titleMarginTop = '30px',
}) => {
  return (
    <ContainerDiv
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingVertical={paddingVertical}
    >
      <img
        src={imgSrc || emptyContentIcon}
        alt="no-data"
        width={imgWidth || '60%'}
        height={imgHeight}
        style={{ minWidth: imgMinWidth, minHeight: imgMinHeight, maxWidth: imgMaxWidth, maxHeight: imgMaxHeight }}
      />
      <FjText marginTop={titleMarginTop} fontSize="12px" display="block">
        {title}
      </FjText>
      {(subtitleLine1 || subtitleLine2) && (
        <FjText marginTop="11px" color={Colors.sandstone}>
          {subtitleLine1}
          <br />
          {subtitleLine2}
        </FjText>
      )}
    </ContainerDiv>
  )
}

export const FeedEmptyContentConnectGong = () => {
  return (
    <ContainerDiv display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <FjText marginTop="30px" fontSize="18px" display="block" marginBottom="20px">
        Connect your Gong account with Flockjay to view all calls here.
      </FjText>
      {GONG_CLIENT_ID && (
        <>
          <ContainerDiv display="flex" flexDirection="row" alignItems="center" gap="30px">
            <ConnectGongButton connected={sharedDataStore.user.company.integratedGongOrganization}></ConnectGongButton>
          </ContainerDiv>
        </>
      )}
    </ContainerDiv>
  )
}

export const FeedEmptyContentConnectSalesforce = ({ customLabel }: { customLabel?: string }) => {
  return (
    <ContainerDiv display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <FjText marginTop="30px" fontSize="18px" display="block" marginBottom="20px">
        {customLabel || 'Connect your Salesforce account with Flockjay to view all opportunities here.'}
      </FjText>
      {SALESFORCE_CLIENT_ID && (
        <>
          <ContainerDiv display="flex" flexDirection="row" alignItems="center" gap="30px">
            <ConnectSalesforceButton
              connected={!!sharedDataStore.user.company.integratedSalesforceOrganizationUrl}
            ></ConnectSalesforceButton>
          </ContainerDiv>
        </>
      )}
    </ContainerDiv>
  )
}

export const FeedNoAccessContent: React.FC<FeedEmptyContentProps> = ({ title, ...props }) => (
  <ContainerDiv display="flex" flexDirection="row" justifyContent="center" alignItems="center" padding="2rem">
    <FeedEmptyContent
      title={title || 'Sorry, you do not have permission to view this content.'}
      subtitleLine1={props.subtitleLine1 || 'Please contact your manager or admin to gain access to the content.'}
      imgSrc={emptyPrompt}
      {...props}
    />
  </ContainerDiv>
)

export const FeedNotFound: React.FC<FeedEmptyContentProps> = ({ title, ...props }) => (
  <ContainerDiv display="flex" flexDirection="row" justifyContent="center" alignItems="center" padding="2rem">
    <FeedEmptyContent title={title || "Whoops! We couldn't find what you were looking for"} {...props} />
  </ContainerDiv>
)
