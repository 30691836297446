import React from 'react'
import { observer } from 'mobx-react'
import { ProfilePictureForm } from 'src/components/Profile/ProfilePictureForm'
import { ConnectLinkedinForm } from 'src/components/Common/ConnectLinkedin'
import { InviteTeammateForm } from 'src/components/Team/InviteTeammate'
import { FeedPostForm } from 'src/components/Feed/FeedPostForm'
import { PromptForm } from 'src/components/Feed/PromptForm'
import { ProfileEditForm } from 'src/components/Profile/ProfileEditForm'
import { FjModal } from 'src/components/Common/Modals'
import { AddContentToContentContainerForm } from 'src/components/Feed/AddContentToContentContainerForm'
import { AddToQueueForm } from 'src/components/Feed/AddToQueueForm'
import { SubmoduleForm } from 'src/components/CourseAuthoring/SubmoduleForm'
import { VideoModalForm } from 'src/components/Feed/VideoModalForm'
import { AssetForm } from 'src/components/Feed/AssetForm'
import { CreateGroupForm } from 'src/components/Team/CreateGroupForm'
import { FeedTaskForm } from 'src/components/LearningPath/FeedTaskForm'
import { LazyAutomationRuleForm } from 'src/components/Feed/AutomationRuleForm'
import { EditSharedContentModal, SharedContentModal } from 'src/components/Feed/SharedContentModal'
import { SharedContentSessionsModal } from 'src/components/Feed/SharedContentSessions'
import { ShareCourseForm } from 'src/components/Feed/ShareCourseForm'
import { SharedLinkModal } from 'src/components/Feed/SharedLinkModal'
import { AssetSummary } from 'src/components/Feed/AssetSummary'
import { ContentProgressDetails } from 'src/components/Learn/ContentProgressDetails'
import { RubricForm } from 'src/components/CourseAuthoring/RubricForm'
import { RubricAuthoringForm } from 'src/components/CourseAuthoring/RubricAuthoringForm'
import { CoachingMomentTriggerForm } from 'src/components/Feed/CoachingMomentTriggerForm'
import { QueueModal } from 'src/components/Feed/QueueModal'
import { AddToContentModal } from 'src/components/Feed/AddToContentModal'
import { ModuleForm } from 'src/components/CourseAuthoring/ModuleForm'
import { TeamProgressModal } from 'src/components/Feed/TeamProgressModal'
import { UserManagementAndProvisioningForm } from 'src/components/Feed/UserManagementAndProvisioning'
import { ConfettiModal } from 'src/components/Common/ConfettiModal'
import { DealRoomAnalyticsModal } from 'src/components/Feed/DealRoomAnalyticsModal'
import { EditDriveFileModal } from 'src/components/Feed/EditDriveFileModal'
import { CopyClipboardModal } from 'src/components/Feed/CopyClipboardModal'
import { PostAnalyticsModal } from 'src/components/Feed/PostAnalyticsModal'
import { TemplateAuthoringModal } from 'src/components/Feed/TemplateAuthoringModal'
import { TemplateModal } from 'src/components/Feed/TemplateModal'
import { LiveSessionAttendanceModal } from 'src/components/Learn/LiveSessionAttendanceModal'
import { AdminModuleCompletionsForm } from 'src/components/Course/AdminModuleCompletionsForm'
import { IntegratedClariOrganizationForm } from 'src/components/Feed/CalriIntegrationForm'
import { BulkEditFormModal } from 'src/components/Library/BulkEditForm'
import { ConfigProviderProps } from 'antd/lib/config-provider'

export const FORM_CONTAINER_MODAL_ACTIONS = {
  Wishlist: 'wishlist',
  WorkExperience: 'workExperience',
  EducationHistory: 'educationHistory',
  CurrentLocation: 'currentLocation',
  SendMessage: 'sendMessage',
  Video: 'video',
  ProfilePicture: 'profilePicture',
  Resume: 'resume',
  LinkedIn: 'linkedIn',
  Info: 'info',
  Hide: 'hide',
  InviteTeammate: 'inviteTeammate',
  CreateGroup: 'createGroup',
  FeedPost: 'feedPost',
  Prompt: 'prompt',
  EditProfile: 'editProfile',
  AddContentToContentContainer: 'addContentToContentContainer',
  AddToQueue: 'addToQueue',
  SubmoduleForm: 'submoduleForm',
  VideoModalForm: 'videoModalForm',
  AssetForm: 'assetForm',
  PostAnalyticsModal: 'postAnalyticsModal',
  AssetSummary: 'assetSummary',
  ContentProgressDetails: 'contentProgressDetails',
  ConfettiModal: 'confettiModal',
  FeedTaskForm: 'feedTaskForm',
  AutomationRuleForm: 'automationRuleForm',
  SharedContentModal: 'sharedContentModal',
  EditSharedContentModal: 'editSharedContentModal',
  SharedContentSessions: 'sharedContentSessions',
  ShareCourseForm: 'shareCourse',
  SharedLinkModal: 'sharedLinkModal',
  QueueModal: 'queueModal',
  TeamProgressModal: 'teamProgressModal',
  AddContentModal: 'addContentModal',
  RubricForm: 'rubricForm',
  RubricAuthoringForm: 'rubricAuthoringForm',
  CoachingMomentTriggerForm: 'coachingMomentTriggerForm',
  ModuleForm: 'moduleForm',
  UserManagementAndProvisioningForm: 'userManagementAndProvisioning',
  DealRoomAnalyticsModal: 'dealRoomAnalyticsModal',
  EditDriveFile: 'editDriveFile',
  CopyClipboardModal: 'copyClipboardModal',
  TemplateAuthoringModal: 'templateAuthoringModal',
  TemplateModal: 'templateModal',
  LiveSessionAttendanceModal: 'liveSessionAttendanceModal',
  AdminModuleCompletionsForm: 'adminModuleCompletionsForm',
  IntegratedClariOrganizationForm: 'integratedClariOrganizationForm',
  BulkEditForm: 'bulkEditForm',
}

const ACTION_COMPONENT_MAPPING = {
  [FORM_CONTAINER_MODAL_ACTIONS.ProfilePicture]: ProfilePictureForm,
  [FORM_CONTAINER_MODAL_ACTIONS.LinkedIn]: ConnectLinkedinForm,
  [FORM_CONTAINER_MODAL_ACTIONS.InviteTeammate]: InviteTeammateForm,
  [FORM_CONTAINER_MODAL_ACTIONS.CreateGroup]: CreateGroupForm,
  [FORM_CONTAINER_MODAL_ACTIONS.FeedPost]: FeedPostForm,
  [FORM_CONTAINER_MODAL_ACTIONS.Prompt]: PromptForm,
  [FORM_CONTAINER_MODAL_ACTIONS.EditProfile]: ProfileEditForm,
  [FORM_CONTAINER_MODAL_ACTIONS.AddContentToContentContainer]: AddContentToContentContainerForm,
  [FORM_CONTAINER_MODAL_ACTIONS.AddToQueue]: AddToQueueForm,
  [FORM_CONTAINER_MODAL_ACTIONS.SubmoduleForm]: SubmoduleForm,
  [FORM_CONTAINER_MODAL_ACTIONS.VideoModalForm]: VideoModalForm,
  [FORM_CONTAINER_MODAL_ACTIONS.AssetForm]: AssetForm,
  [FORM_CONTAINER_MODAL_ACTIONS.AssetSummary]: AssetSummary,
  [FORM_CONTAINER_MODAL_ACTIONS.PostAnalyticsModal]: PostAnalyticsModal,
  [FORM_CONTAINER_MODAL_ACTIONS.ContentProgressDetails]: ContentProgressDetails,
  [FORM_CONTAINER_MODAL_ACTIONS.ConfettiModal]: ConfettiModal,
  [FORM_CONTAINER_MODAL_ACTIONS.FeedTaskForm]: FeedTaskForm,
  [FORM_CONTAINER_MODAL_ACTIONS.AutomationRuleForm]: LazyAutomationRuleForm,
  [FORM_CONTAINER_MODAL_ACTIONS.SharedContentModal]: SharedContentModal,
  [FORM_CONTAINER_MODAL_ACTIONS.SharedContentSessions]: SharedContentSessionsModal,
  [FORM_CONTAINER_MODAL_ACTIONS.ShareCourseForm]: ShareCourseForm,
  [FORM_CONTAINER_MODAL_ACTIONS.SharedLinkModal]: SharedLinkModal,
  [FORM_CONTAINER_MODAL_ACTIONS.QueueModal]: QueueModal,
  [FORM_CONTAINER_MODAL_ACTIONS.TeamProgressModal]: TeamProgressModal,
  [FORM_CONTAINER_MODAL_ACTIONS.AddContentModal]: AddToContentModal,
  [FORM_CONTAINER_MODAL_ACTIONS.RubricForm]: RubricForm,
  [FORM_CONTAINER_MODAL_ACTIONS.RubricAuthoringForm]: RubricAuthoringForm,
  [FORM_CONTAINER_MODAL_ACTIONS.CoachingMomentTriggerForm]: CoachingMomentTriggerForm,
  [FORM_CONTAINER_MODAL_ACTIONS.ModuleForm]: ModuleForm,
  [FORM_CONTAINER_MODAL_ACTIONS.UserManagementAndProvisioningForm]: UserManagementAndProvisioningForm,
  [FORM_CONTAINER_MODAL_ACTIONS.DealRoomAnalyticsModal]: DealRoomAnalyticsModal,
  [FORM_CONTAINER_MODAL_ACTIONS.EditDriveFile]: EditDriveFileModal,
  [FORM_CONTAINER_MODAL_ACTIONS.CopyClipboardModal]: CopyClipboardModal,
  [FORM_CONTAINER_MODAL_ACTIONS.TemplateAuthoringModal]: TemplateAuthoringModal,
  [FORM_CONTAINER_MODAL_ACTIONS.TemplateModal]: TemplateModal,
  [FORM_CONTAINER_MODAL_ACTIONS.LiveSessionAttendanceModal]: LiveSessionAttendanceModal,
  [FORM_CONTAINER_MODAL_ACTIONS.AdminModuleCompletionsForm]: AdminModuleCompletionsForm,
  [FORM_CONTAINER_MODAL_ACTIONS.IntegratedClariOrganizationForm]: IntegratedClariOrganizationForm,
  [FORM_CONTAINER_MODAL_ACTIONS.EditSharedContentModal]: EditSharedContentModal,
  [FORM_CONTAINER_MODAL_ACTIONS.BulkEditForm]: BulkEditFormModal,
}

export type FormModalActionTypes =
  | typeof FORM_CONTAINER_MODAL_ACTIONS.Wishlist
  | typeof FORM_CONTAINER_MODAL_ACTIONS.WorkExperience
  | typeof FORM_CONTAINER_MODAL_ACTIONS.EducationHistory
  | typeof FORM_CONTAINER_MODAL_ACTIONS.Info
  | typeof FORM_CONTAINER_MODAL_ACTIONS.CurrentLocation
  | typeof FORM_CONTAINER_MODAL_ACTIONS.SendMessage
  | typeof FORM_CONTAINER_MODAL_ACTIONS.ProfilePicture
  | typeof FORM_CONTAINER_MODAL_ACTIONS.Resume
  | typeof FORM_CONTAINER_MODAL_ACTIONS.Hide
  | typeof FORM_CONTAINER_MODAL_ACTIONS.InviteTeammate
  | typeof FORM_CONTAINER_MODAL_ACTIONS.CreateGroup
  | typeof FORM_CONTAINER_MODAL_ACTIONS.FeedPost
  | typeof FORM_CONTAINER_MODAL_ACTIONS.Prompt
  | typeof FORM_CONTAINER_MODAL_ACTIONS.EditProfile
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AddToQueue
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AddContentToContentContainer
  | typeof FORM_CONTAINER_MODAL_ACTIONS.SubmoduleForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.VideoModalForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AssetForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AssetSummary
  | typeof FORM_CONTAINER_MODAL_ACTIONS.PostAnalyticsModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.ContentProgressDetails
  | typeof FORM_CONTAINER_MODAL_ACTIONS.ConfettiModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.FeedTaskForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AutomationRuleForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.SharedContentModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.ShareCourseForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.SharedLinkModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.QueueModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.TeamProgressModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AddContentModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.CoachingMomentTriggerForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.ModuleForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.UserManagementAndProvisioningForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.DealRoomAnalyticsModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.EditDriveFile
  | typeof FORM_CONTAINER_MODAL_ACTIONS.CopyClipboardModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.TemplateAuthoringModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.TemplateModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.LiveSessionAttendanceModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.AdminModuleCompletionsForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.IntegratedClariOrganizationForm
  | typeof FORM_CONTAINER_MODAL_ACTIONS.EditSharedContentModal
  | typeof FORM_CONTAINER_MODAL_ACTIONS.BulkEditForm

interface FormContainerModalProps {
  action: FormModalActionTypes
  formComponentProps?: object
  maskClosable?: boolean
  handleClose(): void
  afterClose?(): void
  open: boolean
  hide?: boolean
}

const MODAL_WIDTH_MAPPING = {
  [FORM_CONTAINER_MODAL_ACTIONS.ConfettiModal]: '480px',
  [FORM_CONTAINER_MODAL_ACTIONS.AssetForm]: '1236px',
  [FORM_CONTAINER_MODAL_ACTIONS.AddContentModal]: '600px',
  [FORM_CONTAINER_MODAL_ACTIONS.EditDriveFile]: '95%',
  [FORM_CONTAINER_MODAL_ACTIONS.TemplateModal]: '600px',
  [FORM_CONTAINER_MODAL_ACTIONS.SharedContentModal]: '1200px',
}

@observer
export class FormContainerModal extends React.Component<
  FormContainerModalProps & { configProviderProps?: ConfigProviderProps }
> {
  render() {
    const { action, maskClosable, handleClose, afterClose, formComponentProps, open, hide } = this.props
    const FormComponent = ACTION_COMPONENT_MAPPING[action] as React.ElementType
    return (
      <FjModal
        centered
        maskClosable={typeof maskClosable ? maskClosable : true}
        onCancel={handleClose}
        afterClose={afterClose}
        open={open}
        footer={null}
        width={MODAL_WIDTH_MAPPING[action]}
        destroyOnClose
        hide={hide}
        mask={!hide}
        {...this.props}
      >
        {FormComponent && <FormComponent {...formComponentProps} />}
      </FjModal>
    )
  }
}
