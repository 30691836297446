import { DataObject } from 'src/models/DataObject'
import { StudentAnswer } from 'src/models/StudentAnswer'
import moment from 'moment-timezone'
import { observable, computed, makeObservable } from 'mobx'
import { Assignment } from 'src/models/Assignment'
import { formatDateTime } from 'src/utils/format'
import { Author } from 'src/models/Author'
import { Course } from 'src/models/Course'
import { sharedDataStore } from 'src/store/DataStore'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'
import { handleCSVExportResponse } from 'src/utils/fileOperation'

export type AssignmentSubmissionStatusType = 'in_progress' | 'submitted' | 'grading_in_progress' | 'graded'
export const GRADING_PASSED_DUE = 'Grading Passed Due'

export class AssignmentSubmission extends DataObject {
  static OVERRIDE_MAPPINGS = {
    studentAnswers: (data) => (data.studentAnswers ? StudentAnswer.fromData(data.studentAnswers) : []),
    assignment: (data) => (data.assignment ? Assignment.fromData(data.assignment) : undefined),
    user: (data) => (data.user ? Author.fromData(data.user) : undefined),
    totalScore: (data) => (data.totalScore !== undefined ? Math.floor(data.totalScore * 100) : undefined),
    course: (data) => (data.course ? Course.fromData(data.course) : undefined),
  }
  static apiEndpoint = '/api/assignment_submissions/'

  id: string
  courseId: string
  submoduleId: string
  assignmentId: string
  @observable totalScore?: number
  userId?: string
  user?: Author
  @observable status?: AssignmentSubmissionStatusType
  studentAnswers?: StudentAnswer[]
  assignment?: Assignment
  createdAt: moment.Moment = moment()
  @observable lastUpdated: moment.Moment = moment()
  submittedDate?: moment.Moment
  gradedDate?: moment.Moment
  @observable attemptsCount?: number
  course?: Course

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get graderStatusText() {
    let statusText = ''
    if (this.status === 'graded') {
      statusText = 'Graded'
    } else if (this.status === 'in_progress') {
      statusText = 'Not Submitted'
    } else if (this.assignment && this.assignment.isPassedGradingDueDate()) {
      statusText = GRADING_PASSED_DUE
    } else if (this.status === 'submitted') {
      statusText = 'To Be Graded'
    } else if (this.status === 'grading_in_progress') {
      statusText = 'Grading In Progress'
    }
    return statusText
  }

  static async exportAssignmentSubmissions(queryParams?: Object) {
    const fileName = `${sharedDataStore.user.company.name}_flockjay_assignment_submissions`
    const res = await FlockjayProvider(
      new Route(MethodTypes.GET, this.apiEndpoint, {}, { Accept: 'text/csv' }, queryParams)
    )
    await handleCSVExportResponse(res, fileName)
  }

  @computed get assignmentPassed() {
    if (!!this.totalScore && !this.assignment?.passScore) return true
    return this.totalScore >= this.assignment?.passScore * 100
  }

  @computed get remainingAttempts() {
    if (!this.assignment?.maxAttempts) return Infinity
    return this.assignment.maxAttempts - this.attemptsCount
  }

  canRetake = () => {
    return !this.assignmentPassed && this.remainingAttempts > 0
  }

  totalPointsForQuestions = () => {
    if (!this.assignment || this.assignment.totalPoints() === 0) return ''
    return `${this.assignment.scoredPoints()}/${this.assignment.totalPoints()}`
  }

  submittedDateStr = (format?: string) => {
    return this.submittedDate ? formatDateTime(this.submittedDate, format) : ''
  }

  gradedDateStr = (format?: string) => {
    return this.gradedDate ? formatDateTime(this.gradedDate, format) : ''
  }
}
