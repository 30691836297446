import { Form, Formik } from 'formik'
import React from 'react'
import { ContainerDiv, FjText, FormActionButtons, FormHeaderText } from 'src/components/Common'
import { UserCheckboxGroup } from 'src/components/Feed/UserCheckboxGroup'
import { showNotification } from 'src/hoc/Notification'
import { Course } from 'src/models/Course'
import { Submodule } from 'src/models/Submodule'
import { User } from 'src/models/User'
import { sharedAppStateStore } from 'src/store/AppStateStore'

export type LiveSessionAttendanceModalProps = {
  attendees: User[]
  course: Course
  submodule: Submodule
  onAttendanceConfirm: (attendees: User[]) => void
}

export const LiveSessionAttendanceModal = ({
  attendees,
  course,
  submodule,
  onAttendanceConfirm,
}: LiveSessionAttendanceModalProps) => {
  const onSave = async (data) => {
    const { userIds } = data
    try {
      const attendees = await submodule.markUserProgressAsCompleted(userIds)
      onAttendanceConfirm(attendees)
      showNotification({
        message: 'Your attendees list has been successfully saved.',
      })
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      sharedAppStateStore.liveSessionAttendanceModalProps = undefined
    }
  }

  const handleSubmit = async (data) => {
    sharedAppStateStore.sharedConfirmDialogProps = {
      onConfirm: () => onSave(data),
      content: 'Are you sure you want to confirm the attendance list? Changes made cannot be undone.',
      confirmButtonTitle: 'Yes',
      cancelButtonTitle: 'No',
    }
  }

  const attendeeIds = attendees.map(({ id }) => id)

  return (
    <ContainerDiv textAlign="left">
      <FormHeaderText heading="Confirm Attendance" />
      <FjText fontWeight="bold" textAlign="left">
        Select all users who attended this live session.
      </FjText>
      <ContainerDiv marginTop="24px">
        <Formik
          initialValues={{
            userIds: attendeeIds,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <UserCheckboxGroup name="userIds" item={course} disabledOptionKeys={attendeeIds} />
            <FormActionButtons submitButtonLabel="Save" />
          </Form>
        </Formik>
      </ContainerDiv>
    </ContainerDiv>
  )
}
