import React from 'react'
import { Layout, Row, Col, Breadcrumb } from 'antd'
import { observer } from 'mobx-react'
import { LayoutProps } from 'antd/lib/layout'
import { BasicProps } from 'antd/lib/layout/layout'
import { RowProps } from 'antd/lib/row'
import { useMediaQuery } from 'react-responsive'
import { Colors } from 'src/constants/colors'
import { ContainerDiv, DefaultLink, FjText, PageTitle } from 'src/components/Common'
import { ContentWrapper, MAIN_CONTENT_COL_PROPS, SIDEBAR_COL_PROPS } from 'src/components/Page/ContentWrapper'
import groupLogo from 'src/assets/img/newHome/flockjay_bird-group.png'
import salesTraining from 'src/assets/img/sales-training.png'
import { mobileQuery } from 'src/pages/public/common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { frontendURL } from 'src/network/FlockjayProvider'

export const PageLayout: React.FC<LayoutProps> = (props: LayoutProps) => {
  return (
    <Layout
      style={{
        background: 'transparent',
        minHeight: '100vh',
        position: 'relative',
      }}
      {...props}
    />
  )
}

interface PageContentProps {
  backgroundColor?: string
}

type IPageContentProps = PageContentProps & BasicProps

export const PageContent: React.FC<IPageContentProps> = observer(
  ({ style, backgroundColor, ...props }: IPageContentProps) => {
    return (
      <Layout.Content
        style={{
          backgroundColor: backgroundColor || Colors.wildSand,
          minHeight: 'calc(100vh - 210px)',
          ...style,
          paddingBottom: sharedAppStateStore.isNavigatingLearningPath ? '130px' : undefined,
        }}
        {...props}
      />
    )
  }
)

export const CenterContentRow: React.FC<RowProps> = (props: RowProps) => {
  return <Row justify="center" align="middle" style={{ minHeight: '100vh' }} {...props} />
}

export const DefaultHeader: React.FC<BasicProps> = (props: BasicProps) => {
  return (
    <Layout.Header
      {...props}
      style={{
        backgroundColor: 'transparent',
        height: '70px',
        padding: '0px',
      }}
    />
  )
}

interface IPageHeadingProps {
  heading: string | React.ReactElement
  breadcrumbs?: Array<{ title: string; url: string }>
}

@observer
export class PageHeading extends React.Component<IPageHeadingProps> {
  render() {
    const { breadcrumbs, heading } = this.props
    const Heading = () => {
      return typeof heading === 'string' ? (
        <PageTitle textAlign="left" fontSize="30px" marginRight="20px">
          {heading}
        </PageTitle>
      ) : (
        <> {heading}</>
      )
    }
    return (
      <ContentWrapper>
        <ContainerDiv style={{ overflow: 'hidden' }}>
          <Row justify="space-between" align="middle" style={{ minHeight: '90px' }}>
            <Col {...MAIN_CONTENT_COL_PROPS} style={{ textAlign: 'left' }}>
              <Row style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '40px 0px' }}>
                {breadcrumbs ? (
                  <Col xs={24}>
                    <Breadcrumb>
                      {breadcrumbs.map((item) => {
                        return (
                          <Breadcrumb.Item key={item.url}>
                            <DefaultLink to={item.url} style={{ color: Colors.lightDodgerBlue, fontWeight: 'bold' }}>
                              {item.title}
                            </DefaultLink>
                          </Breadcrumb.Item>
                        )
                      })}
                    </Breadcrumb>
                  </Col>
                ) : null}
                <Col xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                  <Heading />
                </Col>
              </Row>
            </Col>
            <Col
              {...SIDEBAR_COL_PROPS}
              xs={0}
              style={{ textAlign: 'right', paddingRight: '18px', marginBottom: '0px', position: 'relative' }}
            >
              <img
                src={groupLogo}
                alt="group"
                height="90px"
                width="180px"
                style={{ right: '0px', top: '-27px', position: 'absolute' }}
              />
            </Col>
          </Row>
        </ContainerDiv>
      </ContentWrapper>
    )
  }
}

export const AuthLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery(mobileQuery)
  return (
    <Row justify="center">
      <Col xs={0} sm={0} md={12}>
        <ContainerDiv
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={Colors.cornflowerBlue}
          minHeight="100vh"
          position="relative"
        >
          <img src={salesTraining} alt="Auth Layout" width="100%" style={{ maxWidth: '450px' }} />
          <ContainerDiv position="absolute" bottom={24}>
            <FjText color="white" fontWeight="bold500">
              Curious to see what Flockjay can do for your team? {` `}
            </FjText>
            <FjText
              color="white"
              fontWeight="bold"
              textDecoration="underline"
              cursor="pointer"
              onClick={() => window.location.replace(frontendURL)}
            >
              See it in action
            </FjText>
          </ContainerDiv>
        </ContainerDiv>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <ContainerDiv display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <ContainerDiv padding={isMobile ? '40px' : '10px'} width={isMobile ? '478px' : 478} textAlign="left">
            {children}
          </ContainerDiv>
        </ContainerDiv>
      </Col>
    </Row>
  )
}
