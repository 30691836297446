import { DataObject } from 'src/models/DataObject'
import { Asset } from 'src/models/Asset'
import { Moment } from 'moment'
import { FlockjayProvider, frontendURL } from 'src/network/FlockjayProvider'
import { Paths } from 'src/constants/navigation'
import { generateID } from 'src/utils/token'
import { NoAuthProvider } from 'src/network/NoAuthProvider'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { formatDuration } from 'src/utils/format'
import { DealRoom } from 'src/models/DealRoom'
import { Author } from 'src/models/Author'
import { getFeedContentData } from 'src/utils/content'

const ANON_USER_DATA_KEY = 'ANON_USER_DATA'
const SHARED_CONTENT_ACCESS_KEY_KEY = 'SHARED_CONTENT_ACCESS_KEY'

export class SharedContent extends DataObject {
  static OVERRIDE_MAPPINGS = {
    learningContent: ({ learningContent }) =>
      getFeedContentData(learningContent.contentData, learningContent.contentType),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    author: ({ author }) => Author.fromData(author),
  }

  static apiEndpoint: string = '/feed/sharedcontent/'

  id: string
  title: string
  author: Author
  learningContent: Asset | DealRoom
  viewCount: number = 0
  downloadCount: number = 0
  expiryDate?: Moment
  durationViewed: number = 0
  sfMetadata?: SfOpportunity
  password?: string
  requireViewerInfo: boolean = false

  createdAt: Moment
  lastUpdated: Moment

  static getPublicSharedContent = async (id: string, password?: string) => {
    const { data } = await NoAuthProvider(
      SharedContent.formatActionEndpoint(id).path,
      password ? { params: { access_key: password } } : undefined
    )
    return SharedContent.fromData(data)
  }

  static generateShareLink = (id: string = generateID()) => `${frontendURL}${Paths.getSharedContentPath(id)}`

  static saveAccessKeyToLocalStorage = (id: string, password: string) => {
    const accessKeys = JSON.parse(localStorage.getItem(SHARED_CONTENT_ACCESS_KEY_KEY) || '{}')
    accessKeys[id] = password
    localStorage.setItem(SHARED_CONTENT_ACCESS_KEY_KEY, JSON.stringify(accessKeys))
  }

  static getAccessKeyIfExists = (id: string) => {
    const accessKeys = JSON.parse(localStorage.getItem(SHARED_CONTENT_ACCESS_KEY_KEY) || '{}')
    return accessKeys[id]
  }

  static saveAnonViewerInfo = (viewerInfo: object) =>
    localStorage.setItem(ANON_USER_DATA_KEY, JSON.stringify(viewerInfo))

  static getAnonViewerInfo = () => {
    const viewerInfo = localStorage.getItem(ANON_USER_DATA_KEY)
    return viewerInfo ? JSON.parse(viewerInfo) : undefined
  }

  getShareLink = () => SharedContent.generateShareLink(this.id)

  getFormattedDurationViewed = () => {
    return formatDuration(this.durationViewed)
  }

  getSessions = async () => {
    const { data } = await FlockjayProvider(SharedContent.formatActionEndpoint(this.id, undefined, 'sessions').path)
    return data
  }

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName
}
