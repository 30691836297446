import React from 'react'
import { Colors } from 'src/constants/colors'
import { ContainerDiv } from 'src/components/Common'
import { Col, Row } from 'antd'
import { CheckCircle, Clock, List, XCircle } from 'react-feather'
import { Banner } from 'src/components/Feed/Banner'
import { ProgressAggregateDataType, TableViewOption } from 'src/components/Learn/GradebookTable'

type ContentProgressAggregateProps = {
  tableView: TableViewOption
  aggregates: ProgressAggregateDataType
}

export const ContentProgressAggregate = ({ tableView, aggregates }: ContentProgressAggregateProps) => {
  return (
    <ContainerDiv width="100%">
      <Row gutter={[16, 16]}>
        <Col xl={6} md={12} xs={24}>
          <Banner
            icon={<List color={Colors.anakiwa} size={'28px'} style={{ minWidth: 28 }} />}
            iconBackgroundColor={Colors.zumthor}
            subtitle={tableView === 'all_users' ? 'Total Users' : 'Total Assigned'}
            value={aggregates.total}
            valueStyle={{ fontSize: '16px' }}
            containerStyles={{ padding: '16px', gap: 12 }}
          />
        </Col>
        <Col xl={6} md={12} xs={24}>
          <Banner
            icon={<CheckCircle color={Colors.pastelGreen} size={'28px'} style={{ minWidth: 28 }} />}
            iconBackgroundColor={Colors.tusk}
            subtitle="Complete"
            value={aggregates.completed}
            valueStyle={{ fontSize: '16px' }}
            secondaryValue={Math.floor((aggregates.completed / aggregates.total) * 100)}
            secondaryValueSuffix="%"
            containerStyles={{ padding: '16px', gap: 12 }}
          />
        </Col>
        <Col xl={6} md={12} xs={24}>
          <Banner
            icon={<Clock color={Colors.confetii} size={'28px'} style={{ minWidth: 28 }} />}
            iconBackgroundColor={Colors.buttermilk}
            subtitle="In Progress"
            value={aggregates.started}
            valueStyle={{ fontSize: '16px' }}
            secondaryValue={Math.floor((aggregates.started / aggregates.total) * 100)}
            secondaryValueSuffix="%"
            containerStyles={{ padding: '16px', gap: 12 }}
          />
        </Col>
        <Col xl={6} md={12} xs={24}>
          <Banner
            icon={<XCircle color={Colors.cottonSeed} size={'28px'} style={{ minWidth: 28 }} />}
            iconBackgroundColor={Colors.concrete}
            subtitle="Not Started"
            value={aggregates.notStarted}
            valueStyle={{ fontSize: '16px' }}
            secondaryValue={Math.floor((aggregates.notStarted / aggregates.total) * 100)}
            secondaryValueSuffix="%"
            containerStyles={{ padding: '16px', gap: 12 }}
          />
        </Col>
      </Row>
    </ContainerDiv>
  )
}
