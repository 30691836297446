import moment from 'moment'
import { DataObject } from 'src/models/DataObject'

export class IntegratedClariOrganization extends DataObject {
  static apiEndpoint: string = '/feed/clari/'

  id: string
  apiKey: string
  apiSecret: string

  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
