import { DataObject } from 'src/models/DataObject'
import moment from 'moment'
import { Author } from 'src/models/Author'
import { sharedDataStore } from 'src/store/DataStore'

export type RubricType = 'single_select' | 'multi_select' | 'short_text' | 'long_text'
export type RubricGradeType = 'binary' | 'senary'

export type RubricCriterion = {
  title: string
  description: string[]
}

export type Point = {}

export class Rubric extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
  }

  static apiEndpoint = '/api/rubrics/'

  id: string
  author?: Author
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  criteria: RubricCriterion[]
  points: string[][] = []
  title: string

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdminOrManager()

  getRubricPoints = () => {
    return (this.points.length - 1) * this.criteria.length
  }
}
