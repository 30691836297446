import React, { Component } from 'react'
import { SearchBox } from 'src/components/Common/Select/SearchBox'
import { observer } from 'mobx-react'
import { Field, FieldProps } from 'formik'
import { arrayWrapper } from 'src/utils/format'
import { ContainerDiv, FjFormItem, PillList, PillOption, PillType } from 'src/components/Common'
import { DefaultOptionType } from 'antd/lib/select'
import { computed, makeObservable, observable } from 'mobx'
import { sharedDataStore } from 'src/store/DataStore'
import { FeedTag } from 'src/models/FeedTag'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Hub } from 'src/models/Hub'
import sparkles from 'src/assets/icons/sparkles.png'

export type setFieldValueType = (field: string, value: any) => void

interface FjMultiSelectProps {
  name: string
  multiple?: boolean
  error?: boolean
  placeholder?: string
  limit?: number
  optionsMap?: Map<string, string>
  initialOptions?: DefaultOptionType[]
  handleSearch?: (value: string) => Promise<DefaultOptionType[]>
  getSelectedOption?: (value: string) => PillOption | Promise<PillOption>
  pillType?: PillType
  // retainOptions prop is used for disabling removal of specific option.
  retainOptions?: string[]
  onChange?: (value: string[]) => void
}

@observer
export class FjMultiSelect extends React.Component<FjMultiSelectProps> {
  multiple?: boolean

  static defaultProps = {
    multiple: true,
    limit: 50,
    initialOptions: [],
  }

  handleSelect = (itemKey: string, fieldValue: string[], setFieldValue: setFieldValueType) => {
    const curValue: any = [...arrayWrapper(fieldValue)]
    let newValue = curValue
    if (itemKey) {
      if (!this.props.multiple) {
        newValue = itemKey
      } else if (!newValue.includes(itemKey)) {
        newValue.push(itemKey)
      }
    } else {
      newValue = this.props.multiple ? newValue : newValue[0]
    }
    // Assigning null when array length is zero
    // Formik does not handle validation properly
    // (only in case of empty form submission) for array type fields
    if (newValue && !newValue.length) {
      newValue = null
    }
    // need to call setFieldValue on every call of handleSelect
    // As we call handleSelect(null) on onChange of our input field to
    // override the Formik's implicit setFieldValue on onChange of Formik field
    setFieldValue(this.props.name, newValue)
    this.props.onChange?.(newValue)
  }

  onitemRemove = (itemKey: string, fieldValue: string[], setFieldValue: setFieldValueType) => {
    const curValue = [...arrayWrapper(fieldValue)]
    let newValue = curValue.filter((l) => l !== itemKey)
    // if multiple = false then we need to setFieldValue to be string instead of string[]
    newValue = this.props.multiple ? newValue : newValue[0]
    if (!(newValue && newValue.length)) {
      newValue = null
    }
    setFieldValue(this.props.name, newValue)
    this.props.onChange?.(newValue)
  }

  render() {
    const { name, error, placeholder, initialOptions, pillType, retainOptions, handleSearch, getSelectedOption } =
      this.props
    return (
      <Field name={name}>
        {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
          let fieldValue = arrayWrapper(value || [])
          return (
            <ContainerDiv display="flex" flexDirection="column" gap="8px">
              <SearchBox
                name={name}
                initialOptions={initialOptions}
                error={error}
                handleSearch={handleSearch}
                handleSelect={(itemKey: string) => this.handleSelect(itemKey, fieldValue, setFieldValue)}
                placeholder={placeholder}
                selectedKeys={fieldValue}
              />
              {fieldValue.length > 0 ? (
                <ContainerDiv display="flex" flexDirection="row" gap="10px" alignItems="center" flexWrap="wrap">
                  <PillList
                    pillType={pillType}
                    values={fieldValue}
                    getSelectedOption={getSelectedOption}
                    onClose={(itemKey: string) => this.onitemRemove(itemKey, fieldValue, setFieldValue)}
                    retainOptions={retainOptions}
                  />
                </ContainerDiv>
              ) : undefined}
            </ContainerDiv>
          )
        }}
      </Field>
    )
  }
}

interface GroupSelectorProps {
  name: string
  selectableGroups?: string[]
  retainOptions?: string[]
  placeholder?: string
  excludePublicUserGroup?: boolean
  onChange?: (value: string[]) => void
}

export class GroupSelector extends Component<GroupSelectorProps> {
  constructor(props: GroupSelectorProps) {
    super(props)
    makeObservable(this)
  }

  @computed get options() {
    const groups = this.props.excludePublicUserGroup
      ? sharedDataStore.user.nonPublicUserGroups
      : sharedDataStore.user.groups
    let options = groups.map((group) => ({ value: group.id, label: group.name }))
    if (this.props.selectableGroups) {
      options = options.filter((group) => this.props.selectableGroups.includes(group.value))
    }
    return options
  }

  handleGroupSearch = async (value: string = '') => {
    return this.options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()))
  }

  getSelectedOption = (value: string) => ({
    key: value,
    label: sharedDataStore.user.groups.find((o) => o.id === value).name,
  })

  render() {
    const { name, placeholder, retainOptions, onChange } = this.props
    return (
      <FjMultiSelect
        pillType="group"
        placeholder={placeholder || 'Select groups'}
        initialOptions={this.options}
        handleSearch={this.handleGroupSearch}
        getSelectedOption={this.getSelectedOption}
        name={name}
        retainOptions={retainOptions}
        onChange={onChange}
      />
    )
  }
}

interface TagSelectorProps {
  showAITooltip?: boolean
}

export class TagSelector extends Component<TagSelectorProps> {
  handleTagSearch = async (value: string = '') => {
    value = value.trim().replaceAll('#', '')
    try {
      const data: FeedTag[] = await FeedTag.list({ search: value })
      const searchValueExists = data.find((t: FeedTag) => t.tag === value)
      const optionData: DefaultOptionType[] = data.map((t: FeedTag) => ({ value: t.tag, label: t.tag }))
      if (!searchValueExists && value) optionData.unshift({ value: value.toLocaleLowerCase(), label: value })
      return optionData
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  render() {
    return (
      <FjFormItem
        name="tags"
        fieldtitle="Tags"
        tiptitle={
          sharedDataStore.user.company.enableTagsSuggestion && this.props.showAITooltip
            ? 'Flockjay AI will automatically attempt to add tags. You will receive a notification when they are ready.'
            : undefined
        }
        tipIcon={<img src={sparkles} alt="sparkles" width={20} />}
      >
        <FjMultiSelect pillType="tag" name="tags" placeholder="Add tags" handleSearch={this.handleTagSearch} />
      </FjFormItem>
    )
  }
}
interface CustomFieldSelectorProps {
  name: string
}

export class CustomFieldSelector extends Component<CustomFieldSelectorProps> {
  handleTagSearch = async (value: string = '') => {
    if (!value) return []
    value = value.trim()
    return [{ value, label: value }]
  }

  render() {
    return (
      <FjMultiSelect
        pillType="customField"
        name={this.props.name}
        placeholder="Add a field option"
        handleSearch={this.handleTagSearch}
      />
    )
  }
}

interface HubSelectorProps {
  name: string
  onChange?: (value: string[]) => void
}

@observer
export class HubSelector extends Component<HubSelectorProps> {
  @observable initialOptions = []
  constructor(props: HubSelectorProps) {
    super(props)
    makeObservable(this)
  }

  async componentDidMount() {
    try {
      this.initialOptions = await this.handleHubSearch()
    } catch (err) {
      // do nothing
    }
  }

  handleHubSearch = async (value: string = '') => {
    value = value.trim().replaceAll('#', '')
    try {
      const { data }: { data: Hub[] } = await Hub.list({ search: value, fields: 'id,name' })
      return data.map((hub: Hub) => ({ value: hub.id, label: hub.name }))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getSelectedOption = async (value: string) => {
    const hub: Hub = await Hub.get(value, undefined, undefined, { fields: 'id,name' })
    return { key: hub.id, label: hub.name }
  }

  render() {
    return (
      <FjMultiSelect
        pillType="hub"
        name={this.props.name}
        placeholder="Add hubs"
        initialOptions={this.initialOptions}
        handleSearch={this.handleHubSearch}
        getSelectedOption={this.getSelectedOption}
        onChange={this.props.onChange}
      />
    )
  }
}
