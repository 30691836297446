import { Divider, Tooltip } from 'antd'
import React, { useState } from 'react'
import { ContainerDiv, DefaultButton, AddToQueueButton } from 'src/components/Common'
import { ContentMenu } from 'src/components/Feed/ContentMenu'
import { ILibraryCardProps } from 'src/components/Library/LibraryCard'
import { Colors } from 'src/constants/colors'
import { getMenuContext } from 'src/utils/getMenuContext'
import { QueueElement } from 'src/models/QueueItem'
import StarFilled from 'src/assets/icons/StarFilledYellow.svg'
import { MoreHorizontal, Star } from 'react-feather'
import { observer } from 'mobx-react'
import { Template } from 'src/models/Template'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ViewMode } from 'src/store/DataStore'

type LibraryActionProps = {
  obj: ILibraryCardProps['obj']
  insideQueue?: boolean
  viewMode?: ViewMode
  showActionsMenu?: boolean
  insideDraft: boolean
  insidePromotedSearch?: boolean
  hideAddToCollection?: boolean
  hideAddToFavorite?: boolean
  hideAddToQueue?: boolean
  handlePromotedSearchChangeSuccess?: () => Promise<void>
  onArchiveSuccess?(): void
  onDeleteSuccess?(): void
  onEditSuccess?(obj: ILibraryCardProps['obj']): void
  onCreateTemplateSuccess?(template: Template): void
}

export const LibraryActions = observer(
  ({ obj, insideQueue, viewMode = 'card', showActionsMenu = true, ...props }: LibraryActionProps) => {
    const [favoriteButtonDisabled, setFavoriteButtonDisabled] = useState(false)
    const {
      targetObj,
      isOpportunity,
      isHub,
      isPage,
      canAddToQueue,
      canAddToFavourites,
      canEdit,
      canAddToCollection,
      canAddToHub,
      canAddToPromotedSearch,
      canRemoveFromPromotedSearch,
      canDelete,
      canDuplicate,
      canArchive,
      handleToggleFavourite,
    } = getMenuContext(obj, props)

    const showActionMenu =
      canEdit ||
      canAddToCollection ||
      canAddToHub ||
      canAddToPromotedSearch ||
      canRemoveFromPromotedSearch ||
      canDelete ||
      canDuplicate ||
      canArchive ||
      canAddToFavourites ||
      canAddToQueue

    const handleFavoriteClicked = async (e: React.MouseEvent) => {
      try {
        setFavoriteButtonDisabled(true)
        await handleToggleFavourite({ domEvent: e })
      } catch (err) {
        sharedAppStateStore.handleError(err)
      } finally {
        setFavoriteButtonDisabled(false)
      }
    }

    if (isOpportunity) return null

    return (
      <>
        {showActionsMenu && showActionMenu && (
          <>
            {viewMode === 'card' && !isHub && !isPage && (
              <Divider style={{ margin: '0', borderColor: Colors.magnolia }} />
            )}
            <ContainerDiv
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={viewMode === 'card' ? '5px 10px' : undefined}
            >
              {viewMode === 'card' ? (
                <>
                  {canAddToFavourites && (
                    <Tooltip title={targetObj.isFavourited ? 'Remove from Favorites' : 'Add to Favorites'}>
                      <DefaultButton
                        size="small"
                        clicked={handleFavoriteClicked}
                        disabled={favoriteButtonDisabled}
                        image={
                          targetObj.isFavourited ? (
                            <img src={StarFilled} alt="StarFilled" width={16} height={16} />
                          ) : (
                            <Star color={Colors.cornflowerBlue} size={16} />
                          )
                        }
                        style={{
                          width: '100%',
                          borderRight: canAddToQueue ? `1px solid ${Colors.magnolia}` : 'none',
                        }}
                      />
                    </Tooltip>
                  )}
                  {canAddToQueue && (
                    <AddToQueueButton
                      size="small"
                      obj={targetObj as QueueElement}
                      color={Colors.cornflowerBlue}
                      insideQueue={insideQueue}
                      style={{ borderRight: showActionMenu ? `1px solid ${Colors.magnolia}` : 'none' }}
                    />
                  )}
                </>
              ) : null}
              {showActionMenu && (
                <ContentMenu
                  obj={obj}
                  placement="bottomLeft"
                  {...props}
                  hideAddToFavorite={viewMode === 'card'}
                  hideAddToQueue={viewMode === 'card'}
                >
                  <DefaultButton
                    size="small"
                    clicked={(e) => e.preventDefault()}
                    image={<MoreHorizontal color={Colors.cornflowerBlue} size={16} />}
                    style={{ width: '100%' }}
                  />
                </ContentMenu>
              )}
            </ContainerDiv>
          </>
        )}
      </>
    )
  }
)
