import moment from 'moment'
import { observable, makeObservable } from 'mobx'
import { DataObject } from 'src/models/DataObject'

export class Certificate extends DataObject {
  static apiEndpoint: string = '/feed/certificate/'

  constructor() {
    super()
    makeObservable(this)
  }

  id: string = ''
  @observable name: string = ''
  @observable content: string = ''
  createdAt: moment.Moment
  lastUpdated: moment.Moment
}
