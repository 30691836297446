import React from 'react'
import { SortableContainer, SortableHandle } from 'react-sortable-hoc'
import { FjCard } from 'src/components/Common'
import { Drag } from 'src/components/Icons/Drag'
import { Colors } from 'src/constants/colors'

export const SortableContainerWrapper = SortableContainer<{ children: React.ReactNode; rounded?: boolean }>(
  ({ children, rounded }) => {
    if (rounded)
      return (
        <FjCard overflow="hidden" padding="0">
          {children}
        </FjCard>
      )

    return (
      <FjCard
        overflow="hidden"
        padding="0"
        borderRadius="0"
        border="none"
        borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
      >
        {children}
      </FjCard>
    )
  }
)

export const DragHandle = SortableHandle(() => (
  <Drag width={18} height={18} style={{ cursor: 'pointer', minWidth: 18 }} />
))
