import React from 'react'
import { LinkedIn } from 'react-linkedin-login-oauth2'

import { DefaultButton, Icon } from 'src/components/Common'
import { Paths } from 'src/constants/navigation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Auth } from 'src/utils/Auth'

import LinkedInIcon from 'src/assets/icons/LinkedIn.svg'

interface LinkedinLoginButtonProps {
  isLogin: boolean
  successHandler?(data: any): void | Promise<void>
  disabled?: boolean
}

export class LinkedinLoginButton extends React.Component<LinkedinLoginButtonProps> {
  handleLoginSuccess = async (data: any) => {
    await sharedAppStateStore.wrapAppLoading(Auth.handleLinkedinOAuthSuccess(data, this.props.isLogin))
  }

  handleLoginFailure = (err: { error: string; errorMessage: string }) => {
    if (err.error === 'user_cancelled_authorize') return
    sharedAppStateStore.handleError(new Error(err.errorMessage))
  }

  render() {
    const { successHandler, isLogin } = this.props

    return (
      <LinkedIn
        clientId="86tn6vkd9ztuy0"
        onFailure={this.handleLoginFailure}
        onSuccess={successHandler ? successHandler : this.handleLoginSuccess}
        redirectUri={Paths.getLinkedInOAuthRedirectURL()}
        scope="r_liteprofile r_emailaddress"
        renderElement={({ onClick, disabled }) => (
          <DefaultButton
            clicked={onClick}
            disabled={disabled || this.props.disabled}
            buttonType="primary"
            style={{ whiteSpace: 'nowrap' }}
            image={<Icon src={LinkedInIcon} style={{ verticalAlign: 'top' }} />}
            title={`${isLogin ? 'Sign in with' : 'Connect'} LinkedIn`}
          />
        )}
      />
    )
  }
}
