import moment from 'moment'
import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import { LearningPathElement } from 'src/models/LearningPath'
import { AppearsInType, getFeedContentData, LearningContentType } from 'src/utils/content'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { Colors } from 'src/constants/colors'
import { computed, makeObservable } from 'mobx'

export type CoachingMomentStatus = 'under_review' | 'assigned' | 'accepted'

export class CoachingMoment extends DataObject {
  static OVERRIDE_MAPPINGS = {
    user: (data) => Author.fromData(data.user),
    manager: (data) => Author.fromData(data.manager),
    learningContents: (data) =>
      data.learningContents ? data.learningContents.map((c) => getFeedContentData(c.contentData, c.contentType)) : [],
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'coachingmoment',
  }
  static apiEndpoint: string = '/feed/coachingmoment/'
  static shouldUseCache: boolean = true

  @computed get author() {
    return this.user
  }

  learningContentType: LearningContentType = 'coachingmoment'

  getBulkEditFields = () => []

  id: string
  title: string
  managerReview: string
  condition: string
  status: CoachingMomentStatus
  meetingScheduled: boolean
  link: string
  user: Author
  manager: Author
  learningContents: LearningPathElement[]
  progress: number
  createdAt: moment.Moment
  lastUpdated: moment.Moment
  appearsIn: AppearsInType[]

  // These properties don’t actually exist on this model
  // They are here because TS doesn’t retain class information when checks are made from another function
  isFavourited?: false
  sfMetadata?: SfOpportunity

  constructor() {
    super()
    makeObservable(this)
  }

  getHeroBgColor = () => Colors.caper
  getContentTypeTagColor = () => Colors.wildWillowapprox
  getTagBgColor = () => Colors.tusk2
  getTagTitle = () => 'Coaching'

  imgSrc = () => {
    const firstElement = this.learningContents[0]
    // @ts-ignore
    if (firstElement && firstElement.imgSrc) return firstElement.imgSrc()
    return null
  }

  canAddToCollection = () => false

  canDelete = () => false

  canEdit = () => false

  canArchive = () => false

  canAddToLearningPath = () => false

  canAddToHub = () => false

  canAddToPromotedSearch = () => false

  canDuplicate = () => false

  hasOpportunity = () => false

  hasStageName = () => false

  canCreateTemplate = () => false
}
