import moment from 'moment'
import momentTz from 'moment-timezone'
import { templateFormatter } from 'input-format'

import { PHONE_NUMBER_LENGTH } from 'src/utils/validation'
import { HYPERLINK_REGEX, HYPERLINK_EMAIL_REGEX, URL_REGEX } from 'src/constants/regex'
import { showNotification } from 'src/hoc/Notification'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { frontendURL } from 'src/network/FlockjayProvider'

const template = '(xxx) xxx-xxxx'
const format = templateFormatter(template)

export const formatPhoneNumberForServer = (phoneNumber: string) => phoneNumber.replace(/\D/g, '')

export const formatPhoneNumber = (phoneNumber?: string, keyPressed?: string) => {
  if (!phoneNumber) return ''
  if (phoneNumber.length <= PHONE_NUMBER_LENGTH && phoneNumber[0] !== '1') {
    phoneNumber.replace(/-/g, '')
    if (phoneNumber.slice(-2) === ') ' && keyPressed === 'Backspace') {
      return phoneNumber.substring(0, phoneNumber.length - 2)
    }
    const parsedPhoneNumber = formatPhoneNumberForServer(phoneNumber)
    return format(parsedPhoneNumber)['text']
  }
  return phoneNumber
}

export const formatDateForServer = (date: moment.Moment, format: string = 'YYYY-MM-DD hh:mm:ssZ') => date.format(format)

export const formatDateTime = (date: momentTz.Moment, format?: string) => {
  return momentTz.tz(date, momentTz.tz.guess()).format(format || 'MMMM DD, h:mm a z')
}

export const arrayWrapper = (val: any) => (typeof val === 'string' ? [val] : val || [])

export const toBase64 = (file: File | Blob, trimHeader: boolean = false): Promise<string> =>
  new Promise((res, rej) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (trimHeader) {
        const str = reader.result as string
        res(str.substring(str.indexOf(',') + 1))
      } else {
        res(reader.result as string)
      }
    }
    reader.onerror = () => rej(reader.error)
  })

export const messageTimeFormat = 'h:mm A DD-MM-YYYY'

export const getSortedField = (field: any[], sortByField: string, order: number = 1) =>
  field.sort((a, b) => order * (a[sortByField] - b[sortByField]))

export const slice = (obj: object, keys: string[]) => {
  return keys.reduce((acc, key) => {
    if (key in obj) acc[key] = obj[key]
    return acc
  }, {})
}

export const removeKeys = (obj: object, keys: string[]) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!keys.includes(key)) acc[key] = obj[key]
    return acc
  }, {})
}

export const filterModelFieldNames = (obj: object, excludeFields: string[] = []) =>
  Object.getOwnPropertyNames(obj).filter((cur) => {
    if (typeof obj[cur] === 'function' || excludeFields.includes(cur)) return false
    return true
  }, [])

export const filterModelFieldsObj = (obj: object, excludeFields: string[] = []) =>
  Object.getOwnPropertyNames(obj).reduce((acc, cur) => {
    if (typeof obj[cur] === 'function' || excludeFields.includes(cur)) return acc
    acc[cur] = obj[cur]
    return acc
  }, {})

export const stringBooleanMapping = (field?: string | boolean) => {
  if (typeof field === 'boolean') {
    return field
  } else if (typeof field === 'string') {
    if (field.toLocaleLowerCase() === 'true') return true
    else if (field.toLocaleLowerCase() === 'false') return false
  }
  return undefined
}

export const distinct = (array: any[], field: string) =>
  Array.from(new Map(array.map((cur) => [cur[field], cur])).values())

export const formatExternalLink = (link: string) => (link.startsWith('http') ? link : `https://${link}`)

export const arrayFromMap = (map: Map<string, string>) => Array.from(map.entries())

export const linkify = (text: string, openInNewTab: boolean = true) => {
  return text
    .replace(HYPERLINK_REGEX, (url) => `<a href=${url} ${openInNewTab ? ' target="_blank"' : ''}>${url}</a>`)
    .replace(
      HYPERLINK_EMAIL_REGEX,
      (email) => `<a href="mailto:${email}" ${openInNewTab ? ' target="_blank"' : ''}>${email}</a>`
    )
}

export const formatCurrency = (amount: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount)
}

export const titleCase = (str: string, separator: string = ' ') => {
  return str
    .split(separator)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

export const snakify = (str: string) => str.trim().toLowerCase().replace(/\s+/g, '_')

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const camelize = (str: string, separator = ' ') => {
  const splitted = str.split(separator)
  if (splitted.length === 1) return splitted[0]
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  )
}

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  const outputData = outputArray.map((_, index) => rawData.charCodeAt(index))

  return outputData
}

export const getTagsList = (body: string) => {
  /* eslint-disable */

  const matches = body.match(/data-tags="#(.*?)"/gi)

  // Format of matched tags are "data-tags="#tag""
  // 12 is the number of string in front of actual tag name
  return matches ? matches.map((matchedStr) => matchedStr.slice(12, matchedStr.length - 1)) : []
}

export const camelToSentenceCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const formatOrderedString = (num?: number) => {
  if (!num) return ''
  if (num === 1) return '1st'
  if (num === 2) return '2nd'
  if (num === 3) return '3rd'
  return `${num}th`
}

export const deepEquals = (a: any, b: any) => {
  if (a === null || b === null) return a === b
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false
    let ok = true
    for (let i = 0; i < a.length; i++) {
      ok = deepEquals(a[i], b[i])
      if (!ok) return false
    }
    return true
  } else if (moment.isMoment(a) && moment.isMoment(b)) {
    return a.toISOString() === b.toISOString()
  } else if (typeof a === 'object' && typeof b === 'object') {
    if (Object.keys(a).length !== Object.keys(b).length) return false
    let ok = true
    for (const key of Object.keys(a)) {
      ok = deepEquals(a[key], b[key])
      if (!ok) return false
    }
    return true
  }
  return a === b
}

export const parseStringBoolean = (str?: string) => {
  if (str === undefined || str === null) return false
  if (['1', 'true'].includes(str.toLocaleLowerCase())) return true
  else if (['0', 'false'].includes(str.toLocaleLowerCase())) return false
  return Boolean(str)
}

export const combineArraysUniquely = (a: string[], b: string[]) => {
  return [...new Set([...a, ...b])]
}

export const cleanFilenameForUrl = (filename: string) => {
  const urlFriendlyChars = /^[a-zA-Z0-9_\-\.\!~\*'\(\)]*$/
  return filename
    .split('')
    .filter((char) => urlFriendlyChars.test(char))
    .join('')
}

export const pluralize = (val: string, count: number, suffix: string = 's') => (count === 1 ? val : `${val}${suffix}`)

export const getIntersection = (arr1: string[], arr2: string[]) => {
  if (!arr1 || !arr2) return null
  return [...arr1.filter((item) => arr2.includes(item))]
}

export const isValidURL = (val: string): boolean => {
  if (!val) return undefined
  return URL_REGEX.test(val)
}

export const getDomainFromURL = (url: string) => {
  let domain = new URL(url)
  return domain.hostname.replace('www.', '')
}

export const createQueryString = (params: object) =>
  params && Object.keys(params).length
    ? `?${Object.entries(params)
        .filter(([_, val]) => val !== undefined && val !== null)
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
        .join('&')}`
    : ''

export const encodeS3URL = (urlString: string) => {
  const encodings = {
    '+': '%2B',
    '!': '%21',
    '"': '%22',
    '#': '%23',
    $: '%24',
    '&': '%26',
    "'": '%27',
    '(': '%28',
    ')': '%29',
    '*': '%2A',
    ',': '%2C',
    ':': '%3A',
    ';': '%3B',
    '=': '%3D',
    '?': '%3F',
    '@': '%40',
    ' ': '%20',
  }
  const url = new URL(urlString)
  const path = Object.keys(encodings).reduce(
    (prev, key) => prev.replaceAll(key, encodings[key]),
    urlString.split(url.origin)[1].substring(1) // <= remove first '/' from path
  )
  return `${url.origin}/${path}`
}

export const getChangedValues = (values: object, initialValues: object) => {
  const delta = {}
  Object.keys(values).forEach((key) => {
    if (!deepEquals(values[key], initialValues[key])) {
      delta[key] = values[key]
    }
  })
  return delta
}

export const clearFalseyValues = (obj: object) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) acc[key] = obj[key]
    return acc
  }, {})
}

export const convertXmlToJson = (xmlString: string) => {
  const jsonData = {}
  for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
    const key = result[1] || result[3]
    const value = result[2] && convertXmlToJson(result[2])
    jsonData[key] = (value && Object.keys(value).length ? value : result[2]) || null
  }
  return jsonData as any
}

export const truncate = (value: string, limit = 10) => {
  if (value.length <= limit) return value
  return `${value.substring(0, limit)}...`
}

export const formatDateToPST = (date: moment.Moment | null) => {
  if (!date) return null
  return moment.tz(date, 'America/Los_Angeles')?.endOf('day').toISOString(true)
}

export const formatDuration = (duration: number) => {
  let formattedStr = 'mm:ss'
  if (duration >= 3600) formattedStr = 'HH:mm:ss'
  return duration ? moment.duration(duration, 'seconds').format(formattedStr, { trim: false }) : '--:--:--'
}

export const containsJS = (input: string) => !!input.toLocaleLowerCase().match(/((src\=\"?javascript\:)|(\<script))/)

export const shuffle = (inArr: any[]) => {
  const arr = [...inArr]
  let currentIndex = arr.length

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    let randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]]
  }

  return arr
}

export const copyToClipboard = (text: string, msg?: string, modalTitle?: string) => {
  if (sharedAppStateStore.isInCanvas) {
    sharedAppStateStore.copyClipboardModalProps = {
      text,
      modalTitle,
      onCancel: () => (sharedAppStateStore.copyClipboardModalProps = undefined),
    }
  } else {
    navigator.clipboard.writeText(text)
    showNotification({ message: msg || 'Copied to clipboard!' })
  }
}

export const extractContentFromHTML = (html: string) => {
  const span = document.createElement('span')
  span.innerHTML = html
  return span.textContent || span.innerText
}

export const removeDuplicates = (items: Array<any>) => {
  const existingItems = new Set()
  return items.filter((item) => {
    if (existingItems.has(item)) return false
    existingItems.add(item)
    return true
  })
}

/**
 * Sorts all array values in an object's properties alphabetically.
 * @example
 * const input = {
 *   fruits: ['banana', 'apple', 'cherry'],
 *   nested: {
 *     items: ['zucchini', 'artichoke', 'broccoli'],
 *   }
 * };
 * const output = sortArraysInObject(input);
 * console.log(output);
 * // Output:
 * // {
 * //   fruits: ['apple', 'banana', 'cherry'],
 * //   nested: {
 * //     items: ['artichoke', 'broccoli', 'zucchini'],
 * //   }
 * // }
 */
export const sortArraysInObject = (obj: object) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      // Sort the array only if it's an array
      if (obj[key].every((item) => typeof item === 'string')) {
        obj[key] = [...obj[key]].sort((a, b) => a.localeCompare(b))
      }
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively call the function for nested objects
      sortArraysInObject(obj[key])
    }
  }
  return obj
}

export const apiFileUrlToFJFileUrl = (url?: string) => {
  if (!url) return
  return `${frontendURL}/s3${new URL(url).pathname}`
}
