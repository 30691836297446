import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'

const DRIFT_REQUIRED_PATHS = [Paths.feed]
const GMAP_REQUIRED_PATHS = [Paths.profile]

type LoadScriptProps = {
  scriptId: string
  scriptSrc: string
  callback?: () => void
}

export class ScriptsLoadingWrapper extends React.Component<RouteComponentProps & React.PropsWithChildren> {
  loadScript = ({ scriptId, scriptSrc, callback }: LoadScriptProps) => {
    const existingScript = document.getElementById(scriptId)
    if (!existingScript) {
      const script = document.createElement('script')
      script.id = scriptId
      script.type = 'text/javascript'
      script.src = scriptSrc
      document.head.appendChild(script)

      script.onload = () => {
        if (callback) callback()
      }
    }
  }

  loadGoogleMapApi = (callback) => {
    this.loadScript({
      scriptId: 'googleMapApi',
      callback,
      scriptSrc: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCHBt6WyMRHi538hA2b4x2yfiILrc8KR5A&libraries=places',
    })
  }

  loadDriftWidget = () => {
    this.loadScript({ scriptId: 'driftWidget', scriptSrc: '/drift.js' })
  }

  componentDidMount() {
    this.loadScripts()
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadScripts()
    }
  }

  loadScripts = () => {
    if (this.isRequiredPath(DRIFT_REQUIRED_PATHS)) {
      this.loadDriftWidget()
    }
    if (this.isRequiredPath(GMAP_REQUIRED_PATHS)) {
      this.loadGoogleMapApi(() => {
        sharedAppStateStore.googleMapApiLoaded = true
      })
    }
  }

  isRequiredPath = (requirePaths: string[]) => {
    const pagePath = this.getPagePath(this.props.location.pathname)
    const requirePagePaths = requirePaths.map((path) => this.getPagePath(path))
    return requirePagePaths.includes(pagePath)
  }

  getPagePath = (path) => {
    const paths = path.split('/')
    if (paths.length >= 2) return paths[1]
    return paths[0] || ''
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScriptsLoadingWrapper)
