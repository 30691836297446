import React from 'react'
import { ContainerDiv, FjAvatar, FjText, FormHeaderText } from 'src/components/Common'
import { QueueList } from 'src/components/Feed/Queue'
import { User } from 'src/models/User'
import { sharedDataStore } from 'src/store/DataStore'

export type QueueModalProps = {
  user: User
}

export const QueueModal = ({ user }: QueueModalProps) => {
  return (
    <ContainerDiv>
      <FormHeaderText
        heading={
          <ContainerDiv display="flex" alignItems="center" gap={12}>
            <FjAvatar author={user} size={28} />
            <FjText fontSize="20px" fontWeight="bold">
              {user.id !== sharedDataStore.user.id ? `${user.fullName}'s Tasks` : 'My Tasks'}
            </FjText>
          </ContainerDiv>
        }
      />{' '}
      <QueueList scrollableTargetId="queue-modal" height="calc(50vh)" user={user} showHeader showDivider bigProgress />
    </ContainerDiv>
  )
}
