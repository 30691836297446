import moment from 'moment'
import { observable, makeObservable } from 'mobx'

import { Author } from 'src/models/Author'
import { Favouritable } from 'src/models/Favouritable'
import { FeedComment } from 'src/models/FeedComment'
import PostType from 'src/models/enums/PostType'
import { Prompt } from 'src/models/Prompt'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { Course } from 'src/models/Course'
import { sharedDataStore } from 'src/store/DataStore'
import { AppearsInType, getFeedContentData, LearningContentType } from 'src/utils/content'
import { Colors } from 'src/constants/colors'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import Route from 'src/network/Route'
import MethodTypes from 'src/models/enums/MethodTypes'

export interface VideoMetaData {
  url?: string
  thumbnailUrl: string
  duration: number
  embedUrl: string
}

export type ReviewStatus = 'accepted' | 'in_review' | 'rejected'

const parseLinkedObject = (data: any) => {
  if (!data.linkedObject) return undefined
  return getFeedContentData(data.linkedObject, data.linkedContentType)
}

export class FeedPost extends Favouritable {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    prompt: (data) => (data.prompt ? Prompt.fromData(data.prompt) : undefined),
    playlist: (data) => (data.playlist ? FeedPlaylist.fromData(data.playlist) : undefined),
    latestComments: (data) => (data.latestComments ? data.latestComments.map((c) => FeedComment.fromData(c)) : []),
    linkedObject: parseLinkedObject,
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    tags: (data) => (data.tags ? data.tags : []),
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'feedpost',
  }
  static apiEndpoint: string = '/feed/posts/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'feedpost'

  getBulkEditFields = () => ['groupIds', 'sfMetadata', 'expiryDate']

  id: string = ''
  @observable title: string = ''
  @observable body: string = ''
  bodyPreviewText?: string
  author: Author = new Author()
  @observable commentCount: number = 0
  @observable likeCount: number = 0
  @observable liked: boolean = false
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  @observable tags: string[] = []
  @observable groupIds: string[] = []
  @observable isPublic: boolean = false
  @observable latestComments: FeedComment[]
  sfMetadata?: SfOpportunity
  videoUrl?: string
  videoMetadata?: VideoMetaData
  thumbnailUrl?: string
  urlPreview?: {
    url: string
    title: string
    imageSource: string
    description: string
  }
  postType: PostType = PostType.STANDARD
  linkedContentType?: 'prompt' | 'playlist' | 'learningpath' | 'course'
  linkedObjectId?: string
  @observable linkedObject?: Prompt | FeedPlaylist | LearningPath | Course
  prompt?: Prompt
  playlist?: FeedPlaylist

  progress: number
  status: ReviewStatus = 'accepted'
  publishedAt: moment.Moment = moment()

  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  appearsIn: AppearsInType[]
  learningContentId: string

  constructor() {
    super()
    makeObservable(this)
  }

  static getViewsSummaryByUser = async (assetId: string, queryParams?: any) => {
    const { path } = FeedPost.formatActionEndpoint(assetId, undefined, 'views_summary_by_user')
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, queryParams))
  }

  frontendQueryParamKey = () => 'postId'

  getDisplayObjectName = () => 'post'

  likeButtonClicked = async () => {
    const responseData = await this.post('react', { reaction_type: 'like' })
    const updatedPost: FeedPost = FeedPost.fromData(responseData)
    this.liked = updatedPost.liked
    this.likeCount = updatedPost.likeCount
  }

  likeCountDisplay = () => {
    if (this.likeCount === 0) return ''
    return `${this.likeCount}`
  }

  updateLatestCommentsIfExists = (comment: FeedComment) => {
    const comments = [...this.latestComments]
    const index = this.latestComments.findIndex((c) => c.id === comment.id)
    if (index !== -1) {
      comments[index] = comment
      this.latestComments = comments
    }
  }

  getHeroBgColor = () => Colors.cruise
  getContentTypeTagColor = () => Colors.riptide
  getTagBgColor = () => Colors.whiteIce2
  getTagTitle = () => 'Post'

  imgSrc = () => this.thumbnailUrl

  canEdit = () =>
    sharedDataStore.user.id === this.author.authorID ||
    this.linkedObject?.canEdit() ||
    sharedDataStore.user.isFaasAdmin()

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate && this.expiryDate.diff(moment(), 'days') <= 7

  canOpenAnalyticsModal = () => {
    if (!this.author || !this.id) return false
    return sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdminOrManager()
  }

  canDelete = () => sharedDataStore.user.isFaasAdminOrManager() || this.canEdit()

  canAddToCollection = () => !this.linkedContentType

  canAddToLearningPath = () => !this.linkedContentType && sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => !this.linkedContentType && sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => false

  getTrackingElementId = () => `feedpostelement-${this.id}`

  getViewTrackingEventType = () => 'post_viewed'

  getViewTrackingObjIdKey = () => 'post_id'

  viewTrackingThresholdReached = (start: moment.Moment) => true

  isAccepted = () => this.status === 'accepted'

  isInReview = () => this.status === 'in_review'

  isRejected = () => this.status === 'rejected'

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  canCreateTemplate = () => false
}
