import moment from 'moment'
import { DataObject } from 'src/models/DataObject'
import { Author } from 'src/models/Author'
import { JsonTree } from '@react-awesome-query-builder/antd'
import { sharedDataStore } from 'src/store/DataStore'

export class CoachingMomentTrigger extends DataObject {
  static OVERRIDE_MAPPINGS = {
    author: ({ author }) => (author ? Author.fromData(author) : undefined),
  }

  static apiEndpoint: string = '/feed/coachingmomenttrigger/'

  id: string
  title: string
  author: Author
  isActive: true
  preCondition: object
  queryTree: JsonTree

  createdAt: moment.Moment
  lastUpdated: moment.Moment

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  canArchive = () => false
}
