import { ColProps, Divider } from 'antd'
import { Field, FieldProps, Formik } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'
import { Component } from 'react'
import { Search } from 'react-feather'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  ContainerDiv,
  FeedEmptyContent,
  FjCheckBox,
  FjCheckboxGroup,
  FjFormItem,
  FjInput,
  FjText,
  Loader,
  setFieldValueType,
} from 'src/components/Common'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { Colors } from 'src/constants/colors'
import { Asset } from 'src/models/Asset'
import { Call } from 'src/models/Call'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { FeedPost } from 'src/models/FeedPost'
import { LearningPath } from 'src/models/LearningPath'
import { Prompt } from 'src/models/Prompt'
import { QueueElement } from 'src/models/QueueItem'
import { User } from 'src/models/User'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { debounce } from 'src/utils/debounce'

const DEFAULT_USER_PAGE_SIZE = 24
const DIVIDER_STYLES: React.CSSProperties = { margin: '2px 0px', height: '4px' }

const UserCheckboxDisplay: React.FC<{ user: User }> = ({ user }) => {
  return (
    <ContainerDiv marginVertical>
      <AuthorDisplay author={user} avatarSize={36} fontSize="medium" fontWeight="semi-bold" onClick={() => {}} />
    </ContainerDiv>
  )
}

interface UserCheckboxGroupState {
  isLoading: boolean
  users: User[]
  searchTerm: string
  pageNum: number
  hasMoreUsers: boolean
}

interface UserCheckboxGroupProps {
  name: string
  item: QueueElement
  disabledOptionKeys?: string[]
  colProps?: ColProps
  apiQueryParams?: object
}

@observer
export class UserCheckboxGroup extends Component<UserCheckboxGroupProps, UserCheckboxGroupState> {
  static defaultProps = {
    colProps: {
      md: 8,
      sm: 12,
      xs: 24,
    },
  }
  state: UserCheckboxGroupState = {
    isLoading: true,
    users: [],
    searchTerm: '',
    pageNum: 1,
    hasMoreUsers: false,
  }

  getUsersList = debounce(async () => {
    try {
      this.setState((state) => ({ ...state, isLoading: true }))
      const { item, apiQueryParams } = this.props
      const queryParams = {
        is_active: true,
        page_size: DEFAULT_USER_PAGE_SIZE,
        search: this.state.searchTerm,
        page: this.state.pageNum,
        access_role: 'standard,admin,manager,partner',
        ...apiQueryParams,
      }
      // We check if queueItems has groups
      // if it has groups, we show users inside the groups
      // if it doesn't have, we show all users
      if (
        item instanceof FeedPost ||
        item instanceof Prompt ||
        item instanceof Course ||
        item instanceof LearningPath ||
        item instanceof FeedPlaylist ||
        item instanceof Asset ||
        item instanceof Call
      ) {
        const groupsToShow = sharedDataStore.user.groups
          .filter((group) => item.groupIds.includes(group.id))
          .filter((group) => !group.isAllPublicUsersGroup())
        queryParams['group'] = groupsToShow.map((group) => group.id).join(',')
        // We don't need to fetch users if there is no group, that means, it only includes All Public Users group.
        if (groupsToShow.length === 0) return
      }
      const { data: users, next } = await User.list(queryParams)
      this.setState((state) => ({
        ...state,
        hasMoreUsers: Boolean(next),
        users: state.pageNum !== 1 ? [...state.users, ...users] : users,
      }))
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.setState((state) => ({ ...state, isLoading: false }))
    }
  })

  componentDidMount() {
    this.getUsersList()
  }

  handleUserIdsChange = (fieldValue: string[], setFieldValue: setFieldValueType) => (checkedIds: string[]) => {
    const preSelectedUserIds = fieldValue
    const checkedIdsSet = new Set(checkedIds)

    const unCheckedIdsSet = new Set(this.state.users.map((user) => user.id))
    for (const id of checkedIdsSet) {
      if (unCheckedIdsSet.has(id)) unCheckedIdsSet.delete(id)
    }

    const selectedUserIds = new Set([...preSelectedUserIds, ...checkedIdsSet])
    const newUserIds = [...selectedUserIds].filter((id) => !unCheckedIdsSet.has(id))
    setFieldValue(this.props.name, newUserIds)
  }

  selectAllUsers = (fieldValue: string[], setFieldValue: setFieldValueType) => {
    const preSelectedUserIds = fieldValue
    setFieldValue(this.props.name, [...new Set([...preSelectedUserIds, ...this.state.users.map((user) => user.id)])])
  }

  handleUserSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((state) => ({
      ...state,
      pageNum: 1,
      searchTerm: e.target.value,
    }))
    this.getUsersList()
  }

  fetchMoreUsers = () => {
    this.setState((state) => ({
      ...state,
      pageNum: state.pageNum + 1,
    }))
    this.getUsersList()
  }

  render() {
    const usersOptionMap = new Map(this.state.users.map((user) => [user.id, <UserCheckboxDisplay user={user} />]))
    return (
      <Field name={this.props.name}>
        {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
          const fieldValue = value || []
          return (
            <ContainerDiv>
              <ContainerDiv marginBottom={'20px'} textAlign="left">
                <Formik initialValues={{}} onSubmit={() => {}}>
                  <FjInput
                    prefix={<Search size={16} />}
                    name="keyword"
                    placeholder="Search users"
                    value={this.state.searchTerm}
                    onChange={this.handleUserSearch}
                    style={{ width: '300px', marginTop: '-5px' }}
                  />
                </Formik>
                {usersOptionMap.size > 0 ? (
                  <FjCheckBox
                    name={`all-users`}
                    style={{ display: 'flex', fontSize: '16px', margin: '10px 0' }}
                    onChange={(e) =>
                      e.target.checked
                        ? this.selectAllUsers(fieldValue, setFieldValue)
                        : setFieldValue(this.props.name, this.props.disabledOptionKeys ?? [])
                    }
                  >
                    <FjText
                      fontSize="medium"
                      textAlign="left"
                      fontWeight="semi-bold"
                      wordBreak="break-word"
                      color={Colors.abbey}
                      marginLeft={'10px'}
                    >
                      Select All
                    </FjText>
                  </FjCheckBox>
                ) : null}
              </ContainerDiv>
              <Divider style={DIVIDER_STYLES} />
              {this.state.isLoading && this.state.pageNum === 1 ? (
                <Loader containerStyle={{ height: 300 }} />
              ) : (
                <FjFormItem name={this.props.name}>
                  {usersOptionMap.size === 0 ? (
                    <FeedEmptyContent title="No users found" imgWidth="12%" />
                  ) : (
                    <ContainerDiv id="users-scrollable-target" height={300} overflow="auto">
                      <InfiniteScroll
                        dataLength={usersOptionMap.size}
                        next={this.fetchMoreUsers}
                        hasMore={this.state.hasMoreUsers}
                        style={{ overflow: 'hidden' }}
                        loader={<Loader />}
                        scrollableTarget="users-scrollable-target"
                      >
                        <FjCheckboxGroup
                          disabledOptionKeys={this.props.disabledOptionKeys}
                          name={this.props.name}
                          optionsMap={usersOptionMap}
                          checkboxStyle={{ width: '100%' }}
                          onChange={this.handleUserIdsChange(fieldValue, setFieldValue)}
                          style={{ width: '100%' }}
                          colProps={this.props.colProps}
                        />
                      </InfiniteScroll>
                    </ContainerDiv>
                  )}
                </FjFormItem>
              )}
            </ContainerDiv>
          )
        }}
      </Field>
    )
  }
}
