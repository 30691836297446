import React from 'react'
import { Tooltip } from 'antd'
import { Copy } from 'react-feather'
import { copyToClipboard } from 'src/utils/format'

export const CopyToClipboard: React.FC<{ text: string; modalTitle?: string }> = ({ text, modalTitle = 'Link' }) => {
  return (
    <Tooltip title="Copy to clipboard">
      <Copy size={16} onClick={() => copyToClipboard(text, undefined, modalTitle)} style={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}
