import React, { Suspense, lazy } from 'react'
import { observer } from 'mobx-react'
import { Row, Col } from 'antd'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { FormHeaderText, FjFormItem, FjInput, FjSelect, Option, FormActionButtons, Loader } from 'src/components/Common'
import { combineValidations, isRequired, isMaxLength, isFullName } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { User } from 'src/models/User'
import { pronounsOptions } from 'src/constants/pronouns'
import { UserSelect } from 'src/components/Feed/UserSelect'
const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

const JOB_TITLE_MAX_LENGTH = 255
const FULL_NAME_MAX_LENGTH = 60

interface ProfileEditFormProps {
  user: User
  onSuccess?(): void
  onCancel?(): void
}

@observer
export class ProfileEditForm extends React.Component<ProfileEditFormProps> {
  handleSubmit = async (data: any) => {
    try {
      await this.props.user.save({ ...data, pronouns: data['pronouns'] ?? '' }, true, true, { expand: 'manager' })
      User.updateWithProfile(this.props.user)
      if (this.props.onSuccess) this.props.onSuccess()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  getInitialValues = () => {
    const { fullName, pronouns, jobPosition, bio, manager } = this.props.user
    return {
      fullName,
      pronouns,
      jobPosition,
      bio,
      managerId: manager?.id,
    }
  }

  render() {
    return (
      <>
        <FormHeaderText heading="Edit Profile" />
        <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
          <Form>
            <Row gutter={8}>
              <Col span={16}>
                <FjFormItem
                  name="fullName"
                  fieldtitle="Full Name*"
                  validate={combineValidations(isRequired, isMaxLength(FULL_NAME_MAX_LENGTH), isFullName)}
                >
                  <FjInput name="fullName" placeholder="Full Name" size="large" />
                </FjFormItem>
              </Col>
              <Col span={8}>
                <FjFormItem name="pronouns" fieldtitle="Pronouns">
                  <FjSelect name="pronouns" placeholder="Select Your Pronouns" size="large" allowClear>
                    {pronounsOptions.map((option) => {
                      return (
                        <Option key={option} value={option}>
                          {option}
                        </Option>
                      )
                    })}
                  </FjSelect>
                </FjFormItem>
              </Col>
            </Row>
            <FjFormItem name="jobPosition" fieldtitle="Job Title" validate={isMaxLength(JOB_TITLE_MAX_LENGTH)}>
              <FjInput name="jobPosition" placeholder="Job Title" />
            </FjFormItem>
            <FjFormItem name="bio" fieldtitle="Bio">
              <Suspense fallback={<Loader />}>
                <FJCKEditor name="bio" placeholder="Bio" showRecordVideoBtn />
              </Suspense>
            </FjFormItem>
            <Row gutter={8}>
              <Col span={12}>
                <FjFormItem name="email" fieldtitle="Email">
                  <FjInput name="email" placeholder={this.props.user.email} disabled />
                </FjFormItem>

                {/*<FjFormItem name="twitter" fieldtitle="Twitter URL" validate={isURI}>
                  <FjInput name="twitter" placeholder="https://www.twitter.com/YourName" />
                </FjFormItem> */}
              </Col>
              {!this.props.user.isFaasPublicOrPartner() ? (
                <Col md={12}>
                  <FjFormItem fieldtitle="Manager" name="managerId">
                    <UserSelect
                      excludeUserIds={[this.props.user?.id]}
                      initialLabel={this.props.user?.manager?.fullName}
                      name="managerId"
                      placeholder="Manager"
                    />
                  </FjFormItem>
                </Col>
              ) : null}
            </Row>
            <FormActionButtons submitButtonLabel="Save" onCancel={this.props.onCancel} />
          </Form>
        </Formik>
      </>
    )
  }
}
