import React, { useCallback, useEffect, useState } from 'react'
import { IntegratedClariOrganization } from 'src/models/IntegratedClariOrganization'
import {
  ContainerDiv,
  DefaultButton,
  FjFormItem,
  FjInput,
  FjText,
  FormActionButtons,
  FormHeaderText,
  Loader,
} from '../Common'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { Form, Formik } from 'formik'
import { isRequired } from 'src/utils/validation'
import { showNotification } from 'src/hoc/Notification'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import { FORM_CONTAINER_MODAL_ACTIONS, FormContainerModal } from 'src/components/Common/FormContainerModal'

type ViewState = 'initialLoad' | 'loading' | 'error' | 'idle'

export const IntegratedClariOrganizationForm: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const [viewState, setViewState] = useState<ViewState>('initialLoad')
  const [integratedClariOrganization, setintegratedClariOrganization] = useState(new IntegratedClariOrganization())

  const fetchIntegratedClariOrganization = async () => {
    try {
      const { integratedClariOrganizationId } = sharedDataStore.user.company
      if (!integratedClariOrganizationId) {
        setViewState('idle')
        return
      }
      setViewState('loading')
      const integratedClariOrganization = await IntegratedClariOrganization.get(integratedClariOrganizationId)
      setintegratedClariOrganization(integratedClariOrganization)
      setViewState('idle')
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
      setViewState('error')
    }
  }

  useEffect(() => {
    fetchIntegratedClariOrganization()
  }, [])

  const handleSubmit = useCallback(
    async (data) => {
      try {
        await sharedAppStateStore.wrapAppLoading(integratedClariOrganization.save(data))
        sharedDataStore.user.company.integratedClariOrganizationId = integratedClariOrganization.id
        showNotification({ message: 'Clari integration information saved!' })
        handleClose()
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    },
    [integratedClariOrganization, handleClose]
  )

  return (
    <ContainerDiv>
      <FormHeaderText heading="Clari Integration" />
      {viewState === 'idle' ? (
        <Formik
          initialValues={{
            apiKey: integratedClariOrganization.apiKey,
            apiSecret: integratedClariOrganization.apiSecret,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <FjFormItem fieldtitle="API Key*" name="apiKey" validate={isRequired}>
              <FjInput name="apiKey" placeholder="Enter API Key" />
            </FjFormItem>
            <FjFormItem fieldtitle="API Secret*" name="apiSecret" validate={isRequired}>
              <FjInput name="apiSecret" placeholder="Enter API Secret" type="password" />
            </FjFormItem>
            <FormActionButtons />
          </Form>
        </Formik>
      ) : viewState === 'error' ? (
        <>Something unexpected happened. Try refreshing your browser</>
      ) : (
        <Loader />
      )}
    </ContainerDiv>
  )
}

@observer
export class ClariIntegrationSettings extends React.Component {
  @observable showClariIntegrationForm = false

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  handleCloseClariForm = () => (this.showClariIntegrationForm = false)

  showClariForm = () => (this.showClariIntegrationForm = true)

  render() {
    return (
      <ContainerDiv display="flex" gap={10} alignItems="center" justifyContent="space-between">
        <FormContainerModal
          open={this.showClariIntegrationForm}
          handleClose={this.handleCloseClariForm}
          action={FORM_CONTAINER_MODAL_ACTIONS.IntegratedClariOrganizationForm}
          formComponentProps={{ handleClose: this.handleCloseClariForm }}
        />
        <FjText fontWeight="semi-bold">Integrate with Clari:</FjText>
        <DefaultButton
          title={sharedDataStore.user.company.integratedClariOrganizationId ? 'Update Configuration' : 'Configure'}
          clicked={this.showClariForm}
          buttonType="primary"
        />
      </ContainerDiv>
    )
  }
}
