import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { observer } from 'mobx-react'
import React, { Suspense, lazy } from 'react'
import { FeedTask } from 'src/models/FeedTask'
import { combineValidations, isRequired } from 'src/utils/validation'
import { ContainerDiv, FjFormItem, FormActionButtons, FormHeaderText, Loader } from 'src/components/Common'
const FJCKEditor = lazy(() => import('src/components/Common/FJCKEditor'))

export interface FeedTaskFormProps {
  feedTask?: FeedTask
  onCancel(): void
  onSuccess: (task: FeedTask) => void
  isFeedTaskUpdated?: boolean
  setFeedTaskIsUpdated: (data: string) => void
}

@observer
export class FeedTaskForm extends React.Component<FeedTaskFormProps> {
  handleSubmit = (data: any) => {
    const task = this.props.feedTask || new FeedTask()
    task.save(data)
    this.props.onSuccess(task)
  }

  getInitialValues = () => ({ title: this.props.feedTask?.title ?? '' })

  render() {
    return (
      <ContainerDiv>
        <FormHeaderText heading="Add Custom Task" />
        <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize>
          <Form>
            <FjFormItem
              name="title"
              fieldtitle="Description*"
              className="content-editor"
              validate={combineValidations(isRequired)}
            >
              <Suspense fallback={<Loader />}>
                <FJCKEditor
                  name="title"
                  placeholder=""
                  handleChange={(data) => this.props.setFeedTaskIsUpdated(data)}
                />
              </Suspense>
            </FjFormItem>
            <FormActionButtons submitButtonLabel="Save" />
          </Form>
        </Formik>
      </ContainerDiv>
    )
  }
}
