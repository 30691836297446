import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Colors } from 'src/constants/colors'
import { FeedPost } from 'src/models/FeedPost'
import { ContainerDiv, DefaultLink, FjProgress, FjText } from 'src/components/Common'
import ClapOutlinedWhite from 'src/assets/icons/ClapOutlinedWhite.svg'
import { FeedContentImageCard } from 'src/components/Library/FeedContentImageCard'
import { LearningPath } from 'src/models/LearningPath'
import { Divider, Dropdown, Tooltip } from 'antd'
import { getFeedContentTargetLink, getFeedContentTitle } from 'src/utils/content'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { showNotification } from 'src/hoc/Notification'
import { sharedDataStore } from 'src/store/DataStore'
import { QueueItem } from 'src/models/QueueItem'
import { Call } from 'src/models/Call'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import { Asset } from 'src/models/Asset'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { Hub } from 'src/models/Hub'
import { mobileQuery, tabletQuery } from 'src/pages/public/common'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { AlertCircle, MoreHorizontal, X } from 'react-feather'
import { ContentTag } from 'src/components/Feed/ContentTag'
import { ProgressUser } from 'src/components/Learn/ContentProgressDetails'
import { DealRoom } from 'src/models/DealRoom'
import { Page } from 'src/models/Page'

type LibraryCardHorizontalProps = {
  // TODO: refactor to use centralized union type
  obj:
    | FeedPost
    | Course
    | FeedPlaylist
    | Prompt
    | Call
    | LearningPath
    | Asset
    | QueueItem
    | Hub
    | SfOpportunity
    | CoachingMoment
    | DealRoom
    | Page
  // TODO: remove classroomPath stuff from Classroom - #183447044
  classroomPath?: object
  queueItem?: string
  border?: string
  showReaction?: boolean
  showAuthor?: boolean
  showDivider?: boolean
  titleColor?: string
  clapIcon?: string
  // insideQueue reflects that LibraryCardHorizontal component is rendered from Queue related component
  insideQueue?: boolean
  insideDraft?: boolean
  onClick?: (e: React.MouseEvent) => void
  progressUser?: ProgressUser
  bigProgress?: boolean
}

const LibraryCardHorizontal: React.FC<LibraryCardHorizontalProps> = ({
  obj,
  classroomPath,
  queueItem,
  showReaction,
  showAuthor,
  titleColor = Colors.blackLight,
  showDivider,
  clapIcon = ClapOutlinedWhite,
  insideQueue,
  insideDraft,
  onClick,
  border,
  bigProgress,
  progressUser,
  ...rest
}) => {
  const isMobile = useMediaQuery(mobileQuery)
  const isTablet = useMediaQuery(tabletQuery)
  const isQueueItem = obj instanceof QueueItem
  const targetObj = isQueueItem ? obj.linkedObject : obj
  const isFeedPost = targetObj instanceof FeedPost
  const isHub = targetObj instanceof Hub
  const isDealRoom = targetObj instanceof DealRoom
  const isCoachingMoment = targetObj instanceof CoachingMoment
  const isCall = targetObj instanceof Call
  const isPage = targetObj instanceof Page

  const title = getFeedContentTitle(targetObj)
  const linkTarget = getFeedContentTargetLink(targetObj, {
    queueItemToHighlight: queueItem,
    isQueue: !!queueItem,
    isAuthoring: insideDraft || (isCoachingMoment && sharedDataStore.user.isFaasAdminOrManager()),
    ...classroomPath,
  })

  const canRemoveFromQueue =
    !isHub &&
    !isDealRoom &&
    insideQueue &&
    sharedDataStore.userCanModifyQueueItem(targetObj.id) &&
    targetObj.progress !== 1
  const showCardMenu = canRemoveFromQueue

  const cardMenuContent = () => {
    const handleRemoveFromQueue = async () => {
      if (isHub || isDealRoom || isPage) return
      const userId = isQueueItem ? obj.userId : sharedDataStore.user.id
      try {
        await QueueItem.removeFromQueue(targetObj, [userId])
        const completed = isQueueItem ? obj.linkedObject.progress === 1 : false
        sharedDataStore.refreshQueue({ user_id: userId, completed: completed })
        showNotification({
          message: `${getFeedContentTitle(targetObj)} has been removed from your tasks`,
        })
      } catch (err) {
        sharedAppStateStore.handleError(err)
      }
    }

    return (
      <>
        {showCardMenu && canRemoveFromQueue ? (
          <ContainerDiv zIndex={2} position="absolute" top={4} right={4}>
            <Dropdown
              menu={{
                items: [
                  {
                    icon: <X size={20} color={Colors.cornflowerBlue} />,
                    key: 'remove-from-tasks',
                    label: 'Remove from Tasks',
                    onClick: handleRemoveFromQueue,
                  },
                ],
              }}
              placement="bottomLeft"
            >
              <MoreHorizontal size={20} color={Colors.cottonSeed} />
            </Dropdown>
          </ContainerDiv>
        ) : null}
      </>
    )
  }

  const handleClickProgress = (e) => {
    if (!sharedDataStore.user.isFaasAdminOrManager()) return
    e.preventDefault()
    if (targetObj instanceof Course || targetObj instanceof LearningPath) {
      sharedAppStateStore.contentProgressDetailsModalProps = {
        user: progressUser || sharedDataStore.user,
        obj: targetObj,
      }
    }
  }

  const hasDueDate = isQueueItem && obj.dueDate

  return (
    <>
      <ContainerDiv position="relative" cursor="pointer">
        {cardMenuContent()}
        <DefaultLink key={obj.id} to={linkTarget} onClick={onClick} style={{ textDecoration: 'none' }}>
          <ContainerDiv display="flex" alignItems="stretch" position="relative" overflow="hidden">
            <div style={{ flex: 1, maxWidth: '142px' }}>
              <FeedContentImageCard obj={targetObj} width={'142px'} compressView border={border} {...rest} />
              {(isTablet || !bigProgress) && !isHub && !isDealRoom && targetObj.progress > 0 && (
                <div style={{ marginTop: '-16px', marginBottom: '-6px' }}>
                  <FjProgress
                    percent={Math.floor(targetObj.progress * 100)}
                    trailColor={Colors.brownGray}
                    showInfo={false}
                  />
                </div>
              )}
            </div>
            <ContainerDiv
              textAlign="left"
              display="flex"
              flexDirection="column"
              alignItems="start"
              marginLeft={isMobile ? '5px' : '10px'}
              width={isTablet || !bigProgress ? '100%' : '30%'}
              height="100%"
              overflow="hidden"
            >
              <ContainerDiv textAlign="left" marginBottom="5px">
                <ContentTag obj={targetObj} includeFileType />
              </ContainerDiv>
              <Tooltip title={title}>
                <FjText color={titleColor} fontWeight="bold500" textAlign="left" rows={hasDueDate ? 1 : 2}>
                  {title}
                </FjText>
              </Tooltip>

              {showReaction && isFeedPost ? (
                <FjText color={Colors.white} fontSize="12px">
                  <img src={clapIcon} alt="like" height="17px" style={{ padding: '0px 4px' }} />
                  {targetObj.likeCountDisplay()}
                </FjText>
              ) : null}
              {showAuthor && !isCoachingMoment && !isCall ? (
                <AuthorDisplay author={targetObj.author} avatarSize={20} />
              ) : null}
              {hasDueDate && (
                <ContainerDiv display="flex" alignItems="center" gap={4} textAlign="left">
                  {obj.isOverdue() && <AlertCircle size={12} color={Colors.clementine} />}
                  <FjText textAlign="left" fontSize="small" color={obj.isOverdue() ? Colors.clementine : Colors.tapa}>
                    Due: {obj.getDueDateDisplay()}
                  </FjText>
                </ContainerDiv>
              )}
            </ContainerDiv>
            {bigProgress && !isTablet && !isHub && !isDealRoom ? (
              <ContainerDiv
                display="flex"
                alignItems="center"
                width="30%"
                marginLeft="1rem"
                onClick={handleClickProgress}
              >
                <FjProgress
                  size="default"
                  percent={Math.floor(targetObj.progress * 100)}
                  trailColor={Colors.brownGray}
                  showInfo={false}
                  height="12px"
                />
                <FjText fontSize="small" fontWeight="bold" width="65px" marginLeft="4px" whiteSpace="nowrap">
                  {`${Math.floor(targetObj.progress * 100)} %`}
                </FjText>
              </ContainerDiv>
            ) : null}
          </ContainerDiv>
        </DefaultLink>
      </ContainerDiv>
      {showDivider ? <Divider style={{ margin: '12px 0', borderColor: Colors.sharkOpacity10 }} /> : null}
    </>
  )
}

export default LibraryCardHorizontal
