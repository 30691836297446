import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { Trash } from 'react-feather'
import { Colors } from 'src/constants/colors'
import { ContainerDiv, FjText } from 'src/components/Common'
import { Module } from 'src/models/Module'
import { Submodule } from 'src/models/Submodule'
import { SortableContainerWrapper, DragHandle } from 'src/components/Sortable/SortableContainerWrapper'
import { SubmoduleTagTitle } from 'src/components/Sortable/SubmoduleTagTitle'

export interface SortableSubmoduleItemProps {
  isAuthoring: boolean
  submodule: Submodule
  handleDelete: (submodule: Submodule) => void
  submoduleClicked: (submodule: Submodule) => void
}

const SortableSubmoduleItem = SortableElement<SortableSubmoduleItemProps>(
  ({ isAuthoring, submodule, submoduleClicked, handleDelete }: SortableSubmoduleItemProps) => {
    return (
      <ContainerDiv
        textAlign="left"
        display="flex"
        gap="16px"
        backgroundColor={Colors.white}
        borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
        padding="16px"
        overflow="hidden"
      >
        <DragHandle />
        <SubmoduleTagTitle
          isAuthoring={isAuthoring}
          submodule={submodule}
          submoduleClicked={submoduleClicked}
          handleDelete={handleDelete}
        />
      </ContainerDiv>
    )
  }
)

interface SortableModuleItemProps {
  module: Module
  itemClicked: (module: Module) => void
  handleDelete: (module: Module) => void
  canDelete: boolean
}

const SortableModuleItem = SortableElement<SortableModuleItemProps>(
  ({ module, itemClicked, handleDelete, canDelete }: SortableModuleItemProps) => {
    return (
      <ContainerDiv
        textAlign="left"
        display="flex"
        backgroundColor={Colors.white}
        padding="18px"
        overflow="hidden"
        borderBottom={`solid 1px ${Colors.sharkOpacity10}`}
      >
        <DragHandle />
        <ContainerDiv
          display="flex"
          flexDirection="column"
          cursor="pointer"
          margin="0 15px 0 10px"
          flexGrow={1}
          onClick={() => itemClicked(module)}
        >
          <ContainerDiv display="flex" alignItems="center" gap={16}>
            <FjText textAlign="left" display="block" fontSize="18px" color={Colors.fuscousGrey} fontWeight="bold">
              {module.title}
            </FjText>
          </ContainerDiv>
        </ContainerDiv>
        {canDelete && (
          <Trash
            color={Colors.burntSienna}
            size={18}
            onClick={(e) => {
              e.stopPropagation()
              handleDelete(module)
            }}
          />
        )}
      </ContainerDiv>
    )
  }
)

interface SortableModuleListProps {
  isAuthoring: boolean
  modules: Module[]
  itemClicked: (item: Module | Submodule) => void
  updateModules: (modules: Module[]) => void
  handleDelete: (item: Module | Submodule) => void
}

export const SortableModuleList: React.FC<SortableModuleListProps> = ({
  isAuthoring,
  modules,
  updateModules,
  itemClicked,
  handleDelete,
}) => {
  const flattenedData = modules.map((module) => [Module.fromData({ ...module }), ...module.submodules]).flat()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const moduleMoved = flattenedData[oldIndex] instanceof Module
    const submoduleMoved = flattenedData[oldIndex] instanceof Submodule
    let targetIndex = newIndex

    // Submodule can't be first
    if (moduleMoved && oldIndex === 0 && flattenedData[oldIndex + 1] instanceof Submodule) targetIndex = oldIndex
    else if (submoduleMoved && newIndex === 0) targetIndex = 1

    const newFlattenedData = arrayMoveImmutable(flattenedData, oldIndex, targetIndex)
    const newModuleList = newFlattenedData.reduce((acc, item) => {
      if (item instanceof Module) {
        item.submodules = []
        acc.push(item)
      } else {
        acc[acc.length - 1].submodules.push(item)
      }
      return acc
    }, [])
    updateModules([...newModuleList])
  }

  return (
    <SortableContainerWrapper onSortEnd={onSortEnd} useDragHandle>
      {flattenedData.map((item, i) => {
        const key = `sortable-item-${i}`
        if (item instanceof Module) {
          return (
            <SortableModuleItem
              key={key}
              itemClicked={itemClicked}
              module={item}
              index={i}
              canDelete={i !== 0}
              handleDelete={handleDelete}
            />
          )
        } else {
          return (
            <SortableSubmoduleItem
              key={key}
              index={i}
              submodule={item}
              submoduleClicked={itemClicked}
              handleDelete={handleDelete}
              isAuthoring={isAuthoring}
            />
          )
        }
      })}
    </SortableContainerWrapper>
  )
}
