import React, { Component } from 'react'
import moment from 'moment'

import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  FjCard,
  ContainerDiv,
  PageTitle,
  Loader,
  AvatarList,
  FeedEmptyContent,
  RoundedBlueButton,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { Row, Col, Select, Affix, SelectProps } from 'antd'
import styled from 'styled-components'
import { FeedPost, ReviewStatus } from 'src/models/FeedPost'
import { FeedComment } from 'src/models/FeedComment'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { FeedCard } from 'src/components/Feed/FeedCard'
import { CollapsedFeedPostForm } from 'src/components/Feed/FeedPostForm'
import { TextSize, FontWeight } from 'src/components/Common/Styles'
import { sharedDataStore } from 'src/store/DataStore'
import { sharedScrollStore } from 'src/store/ScrollStore'
import { Prompt } from 'src/models/Prompt'
import { APIProvider } from 'src/network/APIProvider'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { LearningPath } from 'src/models/LearningPath'
import { showNotification } from 'src/hoc/Notification'
import { Asset } from 'src/models/Asset'
import { GroupFilter } from 'src/components/Common/GroupFilter'
import { ArrowUp, ChevronDown, ChevronUp, Filter } from 'react-feather'
import { sharedQueryClient } from 'src/store/QueryClient'

const NEW_POST_POLLING_INTERVAL = 120000
const BACK_TO_TOP_THRESHOLD = 800

const BackToTopButton = styled.button`
  border: 2px solid ${Colors.cornflowerBlue};
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  color: ${Colors.cornflowerBlue};
  width: 65px;
  cursor: pointer;

  :hover {
    color: ${Colors.chambray};
    border-color: ${Colors.chambray};
  }
`

const CustomSelect = (props: SelectProps) => {
  return (
    <Select {...props} suffixIcon={<ChevronDown size={20} style={{ minWidth: 20 }} />}>
      {props.children}
    </Select>
  )
}

export const FilterSelect = styled(CustomSelect)`
  border-radius: 20px;
  border: solid 1px ${(props) => (props.value ? '#508BEF' : Colors.sharkOpacity10)};
  background: ${(props) => (props.value ? Colors.zumthor : 'white')};
  min-width: ${(props) => (props.style && props.style.minWidth ? props.style.minWidth : '80px')} !important;
  text-align: left;
  &:focus {
    border: none;
    outline: none;
    color: red !important;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px !important;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  & .ant-select-arrow {
    color: ${(props) => (props.value ? Colors.cornflowerBlue : undefined)} !important;
  }
  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  & .ant-select-selection-item {
    font-size: 12px;
    font-weight: 500;
    line-height: unset !important;
    color: ${(props) => (props.value ? '#508BEF' : '#74716F;')} !important;
  }
`

export const FilterSelectLarge = styled(CustomSelect)`
  min-width: ${(props) => (props.style && props.style.minWidth ? props.style.minWidth : '200px')} !important;
  text-align: left;
  &:focus {
    border: none;
    outline: none;
  }
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  & .ant-select {
    color: ${Colors.cornflowerBlue} !important;
  }
  & .ant-select-arrow {
    color: ${Colors.cornflowerBlue} !important;
    font-weight: 600;
    font-size: 14px;
  }
  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }
  & .ant-select-selection-item {
    font-size: ${TextSize.medium};
    color: ${Colors.cornflowerBlue} !important;
    font-weight: ${FontWeight.semiBold} !important;
  }
`

export interface IFeedPostListProps {
  title?: string
  tag?: string
  authorId?: string
  showPostForm?: boolean
  showVisiblityOptions?: boolean
  pollForNewPosts?: boolean
  showViewedFilterOptions?: boolean
  statusFilter?: ReviewStatus
  showGroupFilter?: boolean
  apiQueryParams?: object
}

type SeenFilterOptions = 'all' | 'unseen'

@observer
export class FeedPostList extends Component<IFeedPostListProps> {
  @observable loading = true
  @observable newPosts: FeedPost[] = [] // posts to be added when user clicks "See new posts"
  intervalId?: number
  @observable feedPosts: FeedPost[] = []
  @observable visiblity: 'all' | 'private' = 'private'
  @observable seen: SeenFilterOptions = undefined
  @observable hasMorePosts: boolean = false
  @observable showHideModal = false
  @observable promptId?: string = null
  @observable showBackToTopButton = false
  @observable selectedGroup: string = undefined
  currentPage: number = 1
  actionPost?: FeedPost = undefined

  componentDidMount = async () => {
    if (this.props.showViewedFilterOptions && !sharedAppStateStore.isMobile) {
      this.seen = 'all'
    }
    this.fetchFeedList(true)
    sharedDataStore.handleContentCreated = this.handleContentCreated
    window.addEventListener('scroll', this.documentDidScroll)
  }

  constructor(props: IFeedPostListProps) {
    super(props)
    makeObservable(this)
  }

  componentWillUnmount() {
    sharedDataStore.handleContentCreated = undefined
    this.stopPollingForNewPosts()
    window.removeEventListener('scroll', this.documentDidScroll)
  }

  componentDidUpdate(prevProps: Readonly<IFeedPostListProps>) {
    if (this.props.tag !== prevProps.tag) this.fetchFeedList()
  }

  documentDidScroll = (e: Event) => {
    if (window.scrollY >= BACK_TO_TOP_THRESHOLD) this.showBackToTopButton = true
    else this.showBackToTopButton = false
  }

  fetchFeedList = async (isReload: boolean = true) => {
    if (isReload) this.loading = true
    try {
      if (isReload) this.currentPage = 1
      let queryParams = { ...this.props.apiQueryParams }
      if (this.visiblity === 'all') queryParams['is_public'] = true
      if (this.seen === 'unseen') queryParams['exclude_viewed'] = true
      if (!!this.props.authorId) queryParams['author'] = this.props.authorId
      if (!!this.props.tag) queryParams['tag'] = this.props.tag
      if (this.props.statusFilter) queryParams['status'] = this.props.statusFilter
      if (this.selectedGroup) queryParams['group'] = this.selectedGroup
      const { data, next: nextPageUrl } = await FeedPost.list({
        ...queryParams,
        page: this.currentPage,
        exclude_hidden: true,
      })
      this.feedPosts = isReload ? [...data] : [...this.feedPosts, ...data]
      this.hasMorePosts = !!nextPageUrl
      if (isReload && this.props.pollForNewPosts) this.startPollingForNewPosts()
    } catch (e) {
      sharedAppStateStore.handleError(e)
    }
    this.loading = false
  }

  filterByGroup = (groupId) => {
    this.selectedGroup = groupId
    this.fetchFeedList(true)
  }

  startPollingForNewPosts = () => {
    this.stopPollingForNewPosts()
    this.intervalId = window.setInterval(this.pollForNewPosts, NEW_POST_POLLING_INTERVAL)
  }

  stopPollingForNewPosts = () => {
    if (this.intervalId) window.clearInterval(this.intervalId)
    this.intervalId = undefined
  }

  pollForNewPosts = async () => {
    // backend is filtering by gt but with milliseconds sometimes the latest post shows up so adding 1 second here
    // to make sure there are no false positives
    const newestPublishedAt =
      this.feedPosts.length && this.feedPosts[0].publishedAt.add(moment.duration(1, 'second')).toISOString()
    if (newestPublishedAt) {
      const { data } = await FeedPost.list(
        {
          published_at_after: newestPublishedAt,
          group: this.selectedGroup,
          exclude_hidden: true,
          ...this.props.apiQueryParams,
        },
        undefined,
        undefined,
        undefined,
        undefined,
        false
      )
      this.newPosts = data
      if (this.newPosts.length) {
        this.stopPollingForNewPosts()
        sharedQueryClient.invalidateQueries({ queryKey: [FeedPost.apiEndpoint], refetchType: 'none' })
      }
    } else {
      this.fetchFeedList(true)
    }
  }

  refreshFeed = () => {
    sharedScrollStore.scrollToTop()
    this.newPosts = []
    this.fetchFeedList(true)
  }

  handlePostAdded = (feedPost: FeedPost) => {
    const index = this.feedPosts.findIndex((post) => post.id === feedPost.id)
    const commonGroups = feedPost.groupIds.filter((groupId) => sharedDataStore.user.groupIds.includes(groupId))
    if (!this.selectedGroup || commonGroups.includes(this.selectedGroup)) {
      if (index === -1) {
        this.feedPosts.unshift(feedPost)
      } else {
        const posts = [...this.feedPosts]
        posts[index] = feedPost
        this.feedPosts = posts
        this.actionPost = undefined
      }
    }
  }

  handlePromptPostDeleted = (feedPost: FeedPost) => {
    this.handleCloseFeedPostModal()
    this.feedPosts = this.feedPosts.filter((post) => post.id !== feedPost.id)
  }

  onDeleteSuccess = async (obj: FeedPost | Course | FeedPlaylist | LearningPath | Prompt) => {
    this.feedPosts = this.feedPosts.filter((post) => post.id !== obj.id)
  }

  onHidePostSuccess = async (obj: FeedPost) => {
    this.feedPosts = this.feedPosts.filter((p) => p.id !== obj!.id)
  }

  visiblityChanged = (visiblityVal: 'all' | 'private') => {
    this.visiblity = visiblityVal
    this.fetchFeedList()
  }

  filterUnseen = (unseenVal: SeenFilterOptions) => {
    this.seen = unseenVal
    this.fetchFeedList()
  }

  handleOpenFeedPostModal = (autoStartRecord: boolean = false, shareProTip: boolean = false) => {
    sharedAppStateStore.feedPostModalProps = {
      onSuccess: this.handleContentCreated,
      onCancel: this.handleCloseFeedPostModal,
      autoStartRecord: autoStartRecord,
      feedPost: this.actionPost,
      shareProTipClicked: shareProTip,
    }
  }

  handleOpenFeedPostModalForPrompt = (prompt: Prompt, autoStartRecord = false) => {
    sharedAppStateStore.feedPostModalProps = {
      onSuccess: this.handleContentCreated,
      onCancel: this.handleCloseFeedPostModal,
      autoStartRecord,
      prompt: prompt,
    }
  }

  handleCloseFeedPostModal = () => {
    this.promptId = null
    this.actionPost = undefined
    sharedAppStateStore.feedPostModalProps = undefined
    sharedAppStateStore.promptModalProps = undefined
  }

  handleContentCreated = (obj?: FeedPost | Prompt | Asset) => {
    if (!obj || obj instanceof Prompt) this.fetchFeedList(true)
    else if (obj.isAccepted() && obj instanceof FeedPost && !obj.prompt?.hideResponseFromFeed) this.handlePostAdded(obj)
    sharedAppStateStore.feedPostModalProps = undefined
    sharedAppStateStore.promptModalProps = undefined
  }

  onArchiveSuccess = (feedPost: FeedPost) => {
    this.feedPosts = this.feedPosts.filter((p) => p.id !== feedPost.id)
  }

  onEditSuccess = (obj: FeedPost | Prompt) => {
    if (obj instanceof Prompt) {
      this.feedPosts = [...this.feedPosts].map((item) =>
        item.linkedObject?.id === obj.id ? FeedPost.fromData({ ...item, linkedObject: obj }) : item
      )
    } else if (obj instanceof FeedPost) {
      this.feedPosts = [...this.feedPosts].map((item) => (item.id === obj.id ? obj : item))
    }
  }

  onToggleFavourite = async (feedPost: FeedPost) => {
    this.feedPosts = [...this.feedPosts].map((item) => (item.id === feedPost.id ? feedPost : item))
  }

  updateStatus = async (feedPost: FeedPost, status: ReviewStatus) => {
    try {
      // Backend clears body if not sent in patch request
      const { body } = feedPost
      await sharedAppStateStore.wrapAppLoading(feedPost.save({ body, status }))
      this.feedPosts = this.feedPosts.filter((post) => post.id !== feedPost.id)
      showNotification({
        message: `"${feedPost.title}" has been ${status}`,
        type: status === 'rejected' ? 'error' : 'success',
      })
      sharedDataStore.user.postsToReviewCount -= 1
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  confirmHidePost = (feedPost: FeedPost) => {
    this.actionPost = feedPost
    this.showHideModal = true
  }

  fetchMorePosts = () => {
    this.currentPage = this.currentPage + 1
    this.fetchFeedList(false)
  }

  visibilityOptions = () =>
    new Map([
      ['all', 'All posts'],
      ['private', `Only ${sharedDataStore.user.company.name} Posts`],
    ])

  filterUnseenOptions = () =>
    new Map([
      ['all', 'All Posts'],
      ['unseen', 'New Posts'],
    ])

  showReactionsModal = async (post: FeedPost, comment?: FeedComment) => {
    const reactions = await APIProvider.getLikedBy(post, comment)
    if (reactions.length) {
      sharedAppStateStore.modalProps = {
        title: `${reactions.length} reactions`,
        children: (
          <AvatarList objs={reactions} showNames onClickLink={() => (sharedAppStateStore.modalProps = undefined)} />
        ),
      }
    }
  }

  render() {
    return (
      <FjCard textAlign="left" width="100%" height="max-content">
        <Row justify="center">
          <Col span={24}>
            <ContainerDiv
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={sharedAppStateStore.isMobile ? '15px' : undefined}
            >
              {this.props.showGroupFilter && sharedDataStore.user.isPartOfManyGroups() ? (
                <ContainerDiv>
                  <GroupFilter
                    type="large"
                    selectedGroup={this.selectedGroup}
                    onGroupSelect={this.filterByGroup}
                    defaultLabel="My Updates"
                  />
                </ContainerDiv>
              ) : null}
              {this.props.showViewedFilterOptions && (
                <ContainerDiv>
                  <FilterSelect
                    value={this.seen}
                    onChange={this.filterUnseen}
                    style={{ width: 'max-content' }}
                    placeholder={<Filter color={Colors.cornflowerBlue} size={16} />}
                  >
                    {Array.from(this.filterUnseenOptions()).map(([key, value]) => (
                      <Select.Option key={key} value={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                </ContainerDiv>
              )}
            </ContainerDiv>
            {this.props.showPostForm && !sharedAppStateStore.isMobile && (
              <ContainerDiv marginBottom="10px">
                <CollapsedFeedPostForm
                  openModal={this.handleOpenFeedPostModal}
                  closeModal={this.handleCloseFeedPostModal}
                />
              </ContainerDiv>
            )}
            {this.loading ? (
              <Loader />
            ) : (
              <Row>
                {this.props.title && (
                  <Col xs={24} style={{ padding: '5px 0px 12px 25px' }}>
                    <ContainerDiv textAlign="left">
                      <PageTitle fontWeight="semi-bold" fontSize="35px" textAlign="left" color={Colors.chambray}>
                        {this.props.title}
                      </PageTitle>
                    </ContainerDiv>
                  </Col>
                )}
                <Col span={24}>
                  {this.newPosts.length ? (
                    <Affix offsetTop={10} style={{ marginBottom: '15px', textAlign: 'center' }}>
                      <RoundedBlueButton onClick={this.refreshFeed}>
                        {' '}
                        <ArrowUp size={16} /> New posts
                      </RoundedBlueButton>
                    </Affix>
                  ) : null}
                  <ContainerDiv>
                    {this.feedPosts.length === 0 && (
                      <>
                        {this.props.statusFilter === 'in_review' ? (
                          <ContainerDiv paddingVertical="20px">
                            <FeedEmptyContent
                              title="You have no posts to review"
                              subtitleLine1="You're all caught up! 🎉"
                              imgWidth="30%"
                            />
                          </ContainerDiv>
                        ) : (
                          <FeedEmptyContent title="No Posts to show" imgMinWidth="200px" imgWidth="20%" />
                        )}
                      </>
                    )}

                    {this.feedPosts.length ? (
                      <InfiniteScroll
                        dataLength={this.feedPosts.length}
                        next={this.fetchMorePosts}
                        hasMore={this.hasMorePosts}
                        style={{ overflowY: 'hidden' }}
                        loader={<Loader />}
                      >
                        {this.feedPosts.map((feedPost) => {
                          return (
                            <ContainerDiv key={feedPost.id} marginBottom="15px">
                              <FeedCard
                                obj={feedPost}
                                onArchiveSuccess={() => this.onArchiveSuccess(feedPost)}
                                onEditSuccess={this.onEditSuccess}
                                onToggleFavourite={this.onToggleFavourite}
                                onDeleteSuccess={() => this.onDeleteSuccess(feedPost)}
                                onHidePostSuccess={() => this.onHidePostSuccess(feedPost)}
                                respondToPrompt={(prompt, autoStartRecord) =>
                                  this.handleOpenFeedPostModalForPrompt(prompt || feedPost.prompt, autoStartRecord)
                                }
                                showReactionsModal={this.showReactionsModal}
                                updateContentStatus={this.props.statusFilter === 'in_review' && this.updateStatus}
                              />
                            </ContainerDiv>
                          )
                        })}
                      </InfiniteScroll>
                    ) : null}
                  </ContainerDiv>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {this.showBackToTopButton ? (
          <Affix offsetBottom={1} style={{ marginLeft: sharedAppStateStore.isMobile ? 'unset' : '-70px' }}>
            <BackToTopButton onClick={sharedScrollStore.scrollToTop}>
              <ChevronUp size={16} />
              Back to top
            </BackToTopButton>
          </Affix>
        ) : null}
      </FjCard>
    )
  }
}
