import React from 'react'
import styled from 'styled-components'
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom'
import { TextCustomProps, getFontSize, getFontWeight, TextSize, FontWeight } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { CSSProps } from 'src/components/Common/Text'

interface DefaultLinkProps extends LinkProps {
  color?: 'teal' | 'linkBlue' | 'black' | 'navyBlue' | string
  underline?: boolean
}

type IDefaultLinkProps = DefaultLinkProps

export const DefaultLink: React.FC<IDefaultLinkProps> = ({
  color = Colors.rollingStone,
  underline,
  ...restProps
}: IDefaultLinkProps) => {
  if (color === 'teal') color = Colors.robinsEggBlue
  if (color === 'linkBlue') color = Colors.lightDodgerBlue
  if (color === 'black') color = Colors.shark
  if (color === 'navyBlue') color = Colors.chambray

  const LinkComponent = styled(Link)`
    color: ${color};
    text-decoration: none;
    :hover {
      color: ${color};
      cursor: pointer;
      text-decoration: ${underline ? 'underline' : 'none'};
    }
  `

  return <LinkComponent onClick={(e) => e.stopPropagation()} {...restProps} />
}

export const BoldLink = styled(Link)`
  font-weight: bold;
`

const StyledNavLink = (Comp: any) => styled(Comp)`
  color: ${Colors.shark};
  padding: 0 15px;
  font-size: ${TextSize.default};

  :hover {
    color: ${Colors.robinsEggBlue};
  }
`

interface DefaultNavExternalLinkProps extends NavLinkProps {
  openInNewTab?: boolean
  external?: boolean
  isMobile?: boolean
}

export const DefaultNavLink: React.FC<DefaultNavExternalLinkProps> = (props) => {
  let NL = StyledNavLink(NavLink)

  if (props.external) {
    NL = StyledNavLink(Anchor)
    return (
      <NL onClick={(e) => e.stopPropagation()} href={props.to} target={props.openInNewTab && '_blank'} {...props}>
        {props.children}
      </NL>
    )
  }

  return (
    <NL
      onClick={(e) => e.stopPropagation()}
      activeStyle={{
        color: Colors.royalBlue,
        fontWeight: FontWeight.semiBold,
        borderBottom: `3px solid ${Colors.dodgerBlue}`,
        height: '100%',
      }}
      {...props}
    >
      {props.children}
    </NL>
  )
}

interface ExternalLinkProps {
  type?: 'tealTeal' | 'navyTeal' | 'whiteWhite' | 'orangeTeal' | 'navyNavy' | 'blackBlack' | 'blueTeal' | 'tapaTapa'
  hoverColor?: string
  hoverValid?: boolean | string
  underline?: boolean
  hoverUnderline?: boolean
}

type AnchorProps = ExternalLinkProps & TextCustomProps & CSSProps

export const Anchor = styled.a<AnchorProps>`
  float: ${(props) => props.float};
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => getFontWeight(props)};
  color: ${(props) => {
    if (props.type) {
      switch (props.type) {
        case 'tealTeal':
          return props.type === 'tealTeal' ? Colors.robinsEggBlue : Colors.robinsEggBlue
        case 'navyTeal':
          return props.type === 'navyTeal' ? Colors.outerSpace : Colors.robinsEggBlue
        case 'whiteWhite':
          return props.type === 'whiteWhite' ? Colors.white : Colors.robinsEggBlue
        case 'orangeTeal':
          return props.type === 'orangeTeal' ? Colors.neonCarrot : Colors.robinsEggBlue
        case 'navyNavy':
          return props.type === 'navyNavy' ? Colors.outerSpace : Colors.robinsEggBlue
        case 'blackBlack':
          return props.type === 'blackBlack' ? Colors.shark : Colors.robinsEggBlue
        case 'blueTeal':
          return props.type === 'blueTeal' ? Colors.dodgerBlue : Colors.robinsEggBlue
        case 'tapaTapa':
          return props.type === 'tapaTapa' ? Colors.tapa : Colors.robinsEggBlue
      }
    }
    return undefined
  }} !important;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  :hover {
    cursor: ${(props) => {
      if (props.hoverValid !== undefined) {
        if (props.hoverValid) {
          return 'pointer'
        } else {
          return 'default'
        }
      } else {
        return 'pointer'
      }
    }};
    color: ${(props) => {
      if (props.hoverColor) {
        return props.hoverColor
      } else if (props.type === 'navyNavy') {
        return Colors.outerSpace
      } else if (props.type) {
        return Colors.robinsEggBlue
      }
      return undefined
    }};
    text-decoration: ${(props) => (props.underline || props.hoverUnderline ? 'underline' : 'none')};
  }
`

export const DefaultSidebarLink = (props: NavLinkProps) => (
  <DefaultNavLink
    {...props}
    style={{
      display: 'flex',
      marginLeft: '10px',
      marginRight: '10px',
      marginBottom: '15px',
      color: Colors.white,
    }}
    activeStyle={{
      color: Colors.royalBlue,
      fontWeight: FontWeight.semiBold,
    }}
  />
)

const StyledNewPublicNavLink = (Comp: any) => styled(Comp)`
  color: black;
  border-bottom: 1px solid transparent;
  margin: 0 7px;
  padding: 2px 1px;
  transition: border-bottom 0.25s;
  pointer-events: auto;
  :hover {
    color: black;
    border-bottom: 1px solid black;
  }
`

export const NewPublicNavLink: React.FC<DefaultNavExternalLinkProps> = ({
  isMobile,
  external,
  openInNewTab,
  ...rest
}) => {
  let NL = StyledNewPublicNavLink(NavLink)
  if (external) {
    NL = StyledNewPublicNavLink(Anchor)
    return (
      <NL
        onClick={(e) => e.stopPropagation()}
        href={rest.to}
        target={openInNewTab && '_blank'}
        style={{ fontSize: isMobile ? 20 : null, fontWeight: FontWeight.semiBold, color: rest.color }}
      >
        {rest.children}
      </NL>
    )
  }
  return (
    <NL
      exact
      activeStyle={{ borderBottom: '2px solid black', fontWeight: isMobile ? FontWeight.bold : FontWeight.semiBold }}
      style={{ fontSize: isMobile ? 20 : null, fontWeight: FontWeight.semiBold, color: rest.color }}
      {...rest}
    />
  )
}
