import { DataObject } from './DataObject'
import { sharedDataStore } from 'src/store/DataStore'
import humps from 'humps'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { makeObservable, observable } from 'mobx'
import { sortArraysInObject } from 'src/utils/format'

export const CONFIG_STORE_KEY = 'configs'

export const roleOptions = [
  { label: 'Standard', value: 'standard', key: 'standard', id: 'standard' },
  { label: 'Admin', value: 'admin', key: 'admin', id: 'admin' },
  { label: 'Manager', value: 'manager', key: 'manager', id: 'manager' },
  { label: 'Public', value: 'public', key: 'public', id: 'public' },
  { label: 'Partner', value: 'partner', key: 'partner', id: 'partner' },
]

export const statusOptions = [
  { label: 'Active', value: 'active', key: 'active', id: 'active' },
  { label: 'Pending', value: 'pending', key: 'pending', id: 'pending' },
]

export const templateFilterOptions = [
  { label: 'Template', value: 'true', key: 'true', id: 'true' },
  { label: 'Non-Template', value: 'false', key: 'false', id: 'false' },
]

export const contentTypeOptions = [
  { label: 'Asset', value: 'asset', key: 'asset', id: 'asset' },
  { label: 'Call', value: 'call', key: 'call', id: 'call' },
  { label: 'Course', value: 'course', key: 'course', id: 'course' },
  { label: 'Collection', value: 'playlist', key: 'playlist', id: 'playlist' },
  { label: 'Deal Room', value: 'dealroom', key: 'dealroom', id: 'dealroom' },
  { label: 'Hub', value: 'hub', key: 'hub', id: 'hub' },
  { label: 'Learning Path', value: 'learningpath', key: 'learningpath', id: 'learningpath' },
  { label: 'Page', value: 'page', key: 'page', id: 'page' },
  { label: 'Post', value: 'feedpost', key: 'feedpost', id: 'feedpost' },
  { label: 'Prompt', value: 'prompt', key: 'prompt', id: 'prompt' },
]

export const archiveStatusOptions = [
  { label: 'Live', value: 'live', key: 'live', id: 'live' },
  { label: 'Archived', value: 'archived', key: 'archived', id: 'archived' },
]

export const sharingPermissionOptions = [
  { label: 'Internal', value: 'true', key: 'true', id: 'true' },
  { label: 'External', value: 'false', key: 'false', id: 'false' },
]

export type SelectOption = Map<string, string>

export type CustomMetadataFieldOptions = {
  [key: string]: string[]
}

export class Config extends DataObject {
  static apiEndpoint: string = '/api/configs/'

  industry: SelectOption
  companySize: SelectOption

  interviewStage: SelectOption
  opportunityStageNames: string[]
  assetFileTypes: string[]
  @observable learningContentCustomFields: CustomMetadataFieldOptions = {}
  @observable libraryCustomFields: object = {}
  libraryOverviewPageId: string
  scimFields: string[]

  constructor() {
    super()
    makeObservable(this)
  }

  static getConfigs = async () => {
    let data
    const configFromLocalStorage = localStorage.getItem(CONFIG_STORE_KEY)
    if (configFromLocalStorage && sharedAppStateStore.initialConfigsLoaded) {
      data = JSON.parse(configFromLocalStorage)
    } else {
      sharedAppStateStore.initialConfigsLoaded = true
      data = await Config.list()
      localStorage.setItem(CONFIG_STORE_KEY, JSON.stringify(data))
    }
    const config = new Config()
    for (const [key, val] of Object.entries(data)) {
      if (val && Array.isArray(val)) {
        if (['opportunityStageNames', 'assetFileTypes', 'scimFields'].includes(key)) {
          config[key] = val as string[]
        } else {
          config[key] = new Map((val as string[]).map((op) => [op[0], op[1]]))
        }
      }
      if (['learningContentCustomFields', 'libraryCustomFields'].includes(key)) {
        config[key] = humps.decamelizeKeys(sortArraysInObject(val as object)) as CustomMetadataFieldOptions
      }
      if (key === 'libraryOverviewPageId') {
        config[key] = val as string
      }
    }
    sharedDataStore.config = config
  }

  static refreshConfig = async () => {
    localStorage.removeItem(CONFIG_STORE_KEY)
    await Config.getConfigs()
  }

  getCustomMetadataFieldOptions = (key: string) => {
    key = key.replace('custom_fields__', '')
    return [...(this.learningContentCustomFields[key] ?? [])]
      .sort((a, b) => a.localeCompare(b))
      .map((val) => ({
        key: val,
        id: val,
        value: val,
        label: val,
      }))
  }
}
