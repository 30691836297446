import { Image, Tag } from 'antd'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { ContainerDiv, FjText } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { mobileQuery } from 'src/pages/public/common'
import { getDomainFromURL } from 'src/utils/format'

interface URLPreviewProps {
  url: string
  title: string
  imageSource: string
  description: string
}

export const URLPreview: React.FC<URLPreviewProps> = ({ url, title, imageSource, description }) => {
  const isMobile = useMediaQuery(mobileQuery)
  const showFullURL = () => !(title || description || imageSource)

  return (
    <a
      href={url}
      style={{ wordBreak: 'break-all', fontSize: isMobile ? '12px' : '14px' }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ContainerDiv
        display="flex"
        flexDirection="column"
        borderRadius="8px"
        border={`2px solid ${Colors.alto}`}
        overflow="hidden"
      >
        {imageSource ? (
          <Image
            src={imageSource}
            alt={title}
            preview={false}
            width="100%"
            style={{ objectFit: 'cover', maxHeight: '300px' }}
          />
        ) : null}
        <ContainerDiv
          display="flex"
          flexDirection="column"
          gap="3px"
          padding="10px"
          backgroundColor={Colors.wildSand}
          alignItems="flex-start"
        >
          {showFullURL() ? (
            <ContainerDiv display="flex" flexDirection="column" gap="5px" alignItems="flex-start">
              <Tag color={Colors.cornflowerBlue}>
                <FjText color={Colors.white} fontWeight="bold" fontSize="10px">
                  LINK
                </FjText>
              </Tag>
              <FjText color={Colors.riverBed} fontWeight="bold500">
                {url}
              </FjText>
            </ContainerDiv>
          ) : (
            <FjText color={Colors.riverBed} fontWeight="bold" textTransform="uppercase">
              {getDomainFromURL(url)}
            </FjText>
          )}
          {title.length ? (
            <FjText color={Colors.outerSpace} fontWeight="bold500">
              {title}
            </FjText>
          ) : null}
          {description.length ? (
            <FjText
              width="95%"
              textAlign="left"
              textOverflow="ellipsis"
              display="block"
              overflow="hidden"
              fontSize={isMobile ? '12px' : '14px'}
              lineHeight="18px"
              color={Colors.shuttleGray}
            >
              {description}
            </FjText>
          ) : null}
        </ContainerDiv>
      </ContainerDiv>
    </a>
  )
}
