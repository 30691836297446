export const VideoStatusMessage = {
  uploading: 'Your video submission is uploading.',
  verified: 'Your video submission is being verified.',
  processing: `Your video is processing.`,
  processed: `Your video has been processed.`,
  success: 'Your video submission has been saved.',
}

export const VideoEmbedUrl = {
  loom: 'https://www.loom.com/embed/',
  youtube: 'https://www.youtube.com/embed/',
  vimeo: 'https://player.vimeo.com/video/',
  flockjay: 'https://fj-file-uploads.s3.us-east-2.amazonaws.com/',
}

export enum VIDEO_TYPE {
  LOOM = 'loom',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  FLOCKJAY = 'flockjay',
}
