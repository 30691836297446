import { DataObject } from 'src/models/DataObject'
import moment from 'moment-timezone'
import { observable, computed, makeObservable } from 'mobx'
import { formatDateTime } from 'src/utils/format'
import { User } from 'src/models/User'

export class LiveSession extends DataObject {
  static OVERRIDE_MAPPINGS = {
    attendees: (data) => (data.attendees ? User.fromData(data.attendees) : []),
  }
  static apiEndpoint: string = '/api/live_sessions/'

  id: string
  @observable meetingUrl?: string
  @observable meetingTime: moment.Moment
  @observable attendees: User[] = []
  recordingUrls: string[]
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get meetingEndTime() {
    return moment(this.meetingTime).add(1, 'hours')
  }

  notStarted = () => moment().diff(this.meetingTime, 'seconds') < 0

  isLiveNow = () => !this.notStarted() && !this.isEnded()

  isEnded = () => moment().diff(this.meetingEndTime) >= 1

  isRecorded = () => this.recordingUrls && this.recordingUrls.length

  isEndedWithoutRecord = () => this.isEnded() && !this.isRecorded()

  startTimeFromNow = (withoutSuffix?: boolean) => moment(this.meetingTime).fromNow(withoutSuffix)

  meetingTimeStr = (formatStr?: string) => formatDateTime(this.meetingTime, formatStr)

  meetingEndTimeStr = (formatStr?: string) => {
    return formatDateTime(this.meetingEndTime, formatStr)
  }
}
