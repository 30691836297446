import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { ConfirmDialog } from 'src/components/Common/Modals'
import { FeedPost } from 'src/models/FeedPost'
import { getFeedContentTitle } from 'src/utils/content'
import { showNotification } from 'src/hoc/Notification'
import { ArchiveableElement, DuplicatableElement } from 'src/utils/getMenuContext'
import { Asset } from 'src/models/Asset'
import { buttonType } from 'src/components/Common/Button'

export interface SharedConfirmDialogProps {
  onConfirm(): void
  onCancel?(): void
  title?: string
  content?: string | JSX.Element
  confirmButtonTitle?: string
  cancelButtonTitle?: string
  confirmButtonType?: buttonType
}

@observer
export class SharedConfirmDialog extends Component<{}> {
  render() {
    const { onConfirm, onCancel, title, content, ...props } = sharedAppStateStore.sharedConfirmDialogProps

    const closeDialog = () => {
      sharedAppStateStore.sharedConfirmDialogProps = undefined
    }
    return (
      <>
        {sharedAppStateStore.sharedConfirmDialogProps ? (
          <ConfirmDialog
            open={!!sharedAppStateStore.sharedConfirmDialogProps}
            title={title}
            content={content}
            onCancel={() => {
              onCancel?.()
              closeDialog()
            }}
            onConfirm={() => {
              closeDialog()
              onConfirm()
            }}
            {...props}
          />
        ) : null}
      </>
    )
  }
}

export const handleOpenDuplicateModal = (obj: DuplicatableElement) => {
  let content = `Are you sure you want to duplicate this ${obj.getTagTitle().toLowerCase()}?`
  if (obj instanceof Asset && obj.hasGoogleDriveMetadata() && obj.googleDriveMetadata['id']) {
    content += ' Duplicating this asset will also duplicate the file in Google Drive.'
  }
  sharedAppStateStore.sharedConfirmDialogProps = {
    onConfirm: () => sharedAppStateStore.wrapAppLoading(obj.duplicate()),
    title: `Duplicate ${obj.getTagTitle()}`,
    content,
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  }
}

export const handleOpenArchiveModal = (obj: ArchiveableElement, content?: string, onArchiveSuccess?: () => void) => {
  const archiveContent = async () => {
    const targetObj = obj instanceof FeedPost && obj.linkedObject ? obj.linkedObject : obj
    const title = getFeedContentTitle(targetObj)
    await targetObj.patch('archive')
    showNotification({
      message: `${title} has been archived.`,
    })
    onArchiveSuccess?.()
  }

  sharedAppStateStore.sharedConfirmDialogProps = {
    onConfirm: () => archiveContent(),
    title: 'Archive Content',
    content:
      content ??
      'Archiving content removes content from everywhere in Flockjay. Admins may filter for archived content in the library. Do you wish to proceed?',
    confirmButtonTitle: 'Yes',
    cancelButtonTitle: 'No',
  }
}
