import React from 'react'
import { Tooltip } from 'antd'
import { ContainerDiv, DefaultLink } from 'src/components/Common'
import { User } from 'src/models/User'
import { AuthorDisplay } from 'src/components/Common/AuthorDisplay'
import { Author } from 'src/models/Author'
import { Paths } from 'src/constants/navigation'

type AvatarSize = 'large' | 'small' | 'default' | number
type AvatarSource = User | Author

export const AvatarList: React.FC<{
  objs: AvatarSource[]
  avatarSize?: AvatarSize
  max?: number
  showNames?: boolean
  onClickLink?: () => {}
  horizontal?: boolean
}> = ({ objs, avatarSize, max, showNames = false, onClickLink = () => {}, horizontal }) => {
  const contributorIds = new Set()
  let users: AvatarSource[] = []

  const getUserId = (obj: AvatarSource) => (obj instanceof User ? obj.id : obj.authorID)

  objs.forEach((obj) => {
    const id = getUserId(obj)
    if (contributorIds.has(id)) return
    contributorIds.add(id)
    users.push(obj)
  })

  if (max) users = users.slice(0, max)

  return (
    <ContainerDiv display="flex" flexDirection={horizontal ? 'row' : 'column'}>
      {users.map((user) => (
        <Tooltip overlay={!showNames && user.fullName} key={`avatar-list-${getUserId(user)}`}>
          <DefaultLink style={{ margin: '5px 1px' }} to={Paths.getProfilePath(getUserId(user))} onClick={onClickLink}>
            <AuthorDisplay
              author={user}
              avatarSize={avatarSize || 'default'}
              fontWeight="semi-bold"
              showName={showNames}
              onClick={onClickLink}
              fontSize="medium"
            />
          </DefaultLink>
        </Tooltip>
      ))}
    </ContainerDiv>
  )
}
