import { Send } from 'react-feather'
import { Tooltip } from 'antd'
import React from 'react'
import { Colors } from 'src/constants/colors'
import { ContainerDiv, FjText, DefaultButton } from 'src/components/Common'

type RespondToPromptProps = {
  question: string
  onRespondClicked: () => void
  expandable?: boolean
}

const RespondToPrompt = ({ question, onRespondClicked, expandable }: RespondToPromptProps) => {
  const handleRespondClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onRespondClicked()
  }

  return (
    <ContainerDiv
      borderRadius="8px"
      marginBottom="10px"
      textAlign="left"
      padding="12px 16px 12px 16px"
      backgroundColor={Colors.athensGray}
      overflow="hidden"
      display="flex"
      alignItems="flex-start"
      gap="24px"
    >
      <Tooltip title={question}>
        <FjText textAlign="left" fontWeight="bold500" rows={2}>
          {question}
        </FjText>
      </Tooltip>
      <DefaultButton
        buttonType="primary"
        style={{ marginLeft: 'auto' }}
        size="small"
        title="Respond"
        image={<Send size={12} />}
        clicked={handleRespondClick}
      />
    </ContainerDiv>
  )
}

export default RespondToPrompt
