import { notification } from 'antd'
import { ArgsProps, IconType } from 'antd/lib/notification'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Utils } from '@react-awesome-query-builder/antd'
import React, { ReactElement } from 'react'
import { DefaultButton } from 'src/components/Common'
import { CheckCircle, Info, X, XCircle } from 'react-feather'
import { Colors } from 'src/constants/colors'

const notificationIcons: { [type in IconType]: ReactElement } = {
  success: <CheckCircle size={20} color={Colors.pastelGreen} />,
  info: <Info size={20} color={Colors.anakiwa} />,
  warning: <Info size={20} color={Colors.texasRose} />,
  error: <XCircle size={20} color={Colors.outrageousOrange} />,
}

interface NotificationProps extends ArgsProps {
  target?: string
  clickToView?: boolean
}

export const showNotification = ({
  clickToView,
  message,
  type = 'success',
  target,
  ...restProps
}: NotificationProps) => {
  const key = Utils.uuid()
  const handleNotificationClick = () => {
    if (target) sharedAppStateStore.navigate(target)
    notification.close(key)
  }

  notification[type]({
    key,
    message: clickToView ? (
      <>
        {message}
        {` `}
        <DefaultButton
          buttonType="text"
          style={{ whiteSpace: 'nowrap', marginTop: '-2px' }}
          clicked={handleNotificationClick}
          title="View"
        />
      </>
    ) : (
      message
    ),
    onClick: handleNotificationClick,
    style: {
      cursor: 'pointer',
    },
    icon: notificationIcons[type],
    closeIcon: <X size={20} color={Colors.cottonSeed} />,
    ...restProps,
  })
}
