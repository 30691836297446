import React, { useCallback, useEffect, useState } from 'react'
import { SharedContent } from 'src/models/SharedContent'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { FormHeaderText, Loader } from 'src/components/Common'
import { SharedContentTable } from 'src/components/Feed/SharedContentTable'

export type SharedLinkModalProps = {
  apiQueryParams: object
  heading: string
}

export const SharedLinkModal = ({ apiQueryParams, heading }: SharedLinkModalProps) => {
  const [sharedLinks, setSharedLinks] = useState([])
  const [loading, setLoading] = useState(false)

  const getSharedLinks = useCallback(async () => {
    try {
      setLoading(true)
      const links = await SharedContent.list(apiQueryParams)
      setSharedLinks(links)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      setLoading(false)
    }
  }, [apiQueryParams])

  useEffect(() => {
    getSharedLinks()
  }, [getSharedLinks])

  return (
    <>
      <FormHeaderText heading={heading} />
      {loading ? <Loader /> : <SharedContentTable showContentTitle existingLinks={sharedLinks} />}
    </>
  )
}
