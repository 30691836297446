import moment from 'moment'
import humps from 'humps'
import { observable, makeObservable, computed } from 'mobx'
import { Author } from 'src/models/Author'
import { FeedPost } from 'src/models/FeedPost'
import { Favouritable } from 'src/models/Favouritable'
import { Course } from 'src/models/Course'
import { FeedPlaylist } from 'src/models/FeedPlaylist'
import { Prompt } from 'src/models/Prompt'
import { FeedTask } from 'src/models/FeedTask'
import { AppearsInType, hasOpenedConfettiModal, LearningContentType } from 'src/utils/content'
import { Call } from 'src/models/Call'
import { sharedDataStore } from 'src/store/DataStore'
import { Asset } from 'src/models/Asset'
import { SfOpportunity } from 'src/models/SfOpportunity'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { Paths } from 'src/constants/navigation'
import { FlockjayProvider } from 'src/network/FlockjayProvider'
import MethodTypes from 'src/models/enums/MethodTypes'
import Route from 'src/network/Route'
import { Colors } from 'src/constants/colors'
import { ALL_PUBLIC_USERS_GROUP } from 'src/models/Group'
import { Certificate } from 'src/models/Certificate'
import { MiniHub } from 'src/models/Hub'

export type LearningPathElementType = 'course' | 'task' | 'playlist' | 'prompt' | 'feedpost' | 'call' | 'asset'

export type LearningPathElement = FeedPost | Course | FeedPlaylist | Prompt | FeedTask | Call | Asset

export interface LearningPathContent {
  contentType: LearningPathElementType
  contentData: LearningPathElement
  id: string
}

export class LearningPath extends Favouritable {
  static OVERRIDE_MAPPINGS = {
    author: (data) => (data.author ? Author.fromData(data.author) : undefined),
    tags: (data) => (data.tags ? data.tags : []),
    groupIds: (data) => (data.groupIds ? data.groupIds : []),
    hubs: (data) => (data.hubs ? data.hubs : []),
    customFields: (data) => (data.customFields ? humps.decamelizeKeys(data.customFields) : {}),
    sfMetadata: ({ sfMetadata }) =>
      Object.keys(sfMetadata ?? {}).length > 0 ? SfOpportunity.fromData(sfMetadata) : undefined,
    expiryDate: (data) => (data.expiryDate ? moment.tz(data.expiryDate, 'America/Los_Angeles') : null),
    appearsIn: (data) => data.appearsIn ?? [],
    learningContentType: () => 'learningpath',
  }
  static apiEndpoint: string = '/feed/learningpath/'
  static shouldUseCache: boolean = true

  learningContentType: LearningContentType = 'learningpath'

  getBulkEditFields = () => [
    'groupIds',
    'tags',
    'hubs',
    'requireSequentialCompletion',
    'sfMetadata',
    'expiryDate',
    'customFields',
  ]

  constructor() {
    super()
    makeObservable(this)
  }

  id: string = ''
  @observable title: string = ''
  @observable description: string = ''
  @observable thumbnailUrl: string
  @observable isFavourited = false
  @observable expiryDate: moment.Moment
  requireSequentialCompletion: boolean
  learningPathContents: LearningPathContent[]
  tags: string[] = []
  @observable groupIds: string[]
  @observable hubs: MiniHub[] = []
  author: Author = new Author()
  createdAt: moment.Moment = moment()
  lastUpdated: moment.Moment = moment()
  progress: number
  publishedAt: moment.Moment
  customFields: object
  sfMetadata?: SfOpportunity
  appearsIn: AppearsInType[]
  certificate: Certificate
  userCertificatePdfUrl: string
  isUserEligibleForCertificate: boolean
  learningContentId: string

  @computed get hubIds() {
    return this.hubs.map((hub) => hub.id)
  }

  firstLockedContentIndex = () => {
    if (sharedDataStore.user.isFaasAdmin()) return this.learningPathContents.length - 1
    const foundIndex = this.learningPathContents.findIndex((content) => content.contentData.progress < 1)
    const firstLockedContentIndex = foundIndex > -1 ? foundIndex : this.learningPathContents.length
    return firstLockedContentIndex
  }

  calculateCompletionPercentage = (): number => {
    return Math.floor(this.progress * 100)
  }

  currentSteps = (): {
    lastCompletedStep: LearningPathContent
    nextStep: LearningPathContent
    subsequentStep: LearningPathContent
  } => {
    const lastCompletedStep = this.lastCompletedStep()
    const nextStep = this.nextStep()
    const subsequentStep = this.subsequentStep()
    return { lastCompletedStep, nextStep, subsequentStep }
  }

  lastCompletedStep = (): LearningPathContent =>
    this.learningPathContents
      .slice()
      .reverse()
      .find((content) => content.contentData.progress === 1)

  nextStep = (): LearningPathContent =>
    this.learningPathContents.find(
      (content) => content.contentData.progress === null || content.contentData.progress < 1
    )

  subsequentStep = (): LearningPathContent =>
    this.learningPathContents
      .slice(
        this.learningPathContents.findIndex(
          (content) => content.contentData.progress === null || content.contentData.progress < 1
        ) + 1
      )
      .find((content) => content.contentData.progress < 1)

  getHeroBgColor = () => Colors.blueChalk3
  getContentTypeTagColor = () => Colors.bilobaFlower
  getTagBgColor = () => Colors.blueChalk
  getTagTitle = () => 'Learning Path'

  getLockedFromIndex = () => {
    const lockedFromIndex = this.learningPathContents.findIndex((s) => s.contentData.progress !== 1)
    return lockedFromIndex === -1 ? this.learningPathContents.length : lockedFromIndex + 1
  }

  imgSrc = () => this.thumbnailUrl

  canEdit = () => sharedDataStore.user.id === this.author.authorID || sharedDataStore.user.isFaasAdmin()

  isExpired = () => {
    if (this.expiryDate) return this.expiryDate < moment()
    return false
  }

  canArchive = () => this.canEdit() && !this.isExpired()

  isExpiringSoon = () => this.canArchive() && this.expiryDate?.diff(moment(), 'days') <= 7

  canDelete = () => this.canEdit()

  canAddToCollection = () => false

  canAddToLearningPath = () => sharedDataStore.user.isFaasAdminOrManagerOrPartner()

  canAddToHub = () => sharedDataStore.user.isFaasAdminOrPartner()

  canAddToPromotedSearch = () => sharedDataStore.user.isFaasAdmin()

  canDuplicate = () => this.canEdit()

  canSharePublicly = () => {
    const allPublicUsersGroup = sharedDataStore.user.groups.find((group) => group.name === ALL_PUBLIC_USERS_GROUP)
    if (!allPublicUsersGroup) return false
    return !this.groupIds.includes(allPublicUsersGroup.id) && sharedDataStore.user.isFaasAdmin()
  }

  duplicate = async () => {
    const learningPath: LearningPath = await LearningPath.get(this.id)
    const learningPathContents = learningPath.learningPathContents?.map((content) => ({
      objectId: content.contentData.id,
      contentType: content.contentType,
    }))

    const duplicatedLearningPath = new LearningPath()
    const newLearningPathData = {
      title: `Copy of ${this.title}`,
      description: this.description,
      thumbnailUrl: this.thumbnailUrl,
      tags: this.tags,
      groupIds: this.groupIds,
      customFields: this.customFields,
      learningPathContents: learningPathContents,
    }
    await duplicatedLearningPath.save(newLearningPathData)
    sharedAppStateStore.navigate(Paths.getLearningPathPath({ id: duplicatedLearningPath.id, isAuthoring: true }), true)
  }

  hasOpportunity = () => !!this.sfMetadata?.id

  hasStageName = () => !!this.sfMetadata?.stageName

  hasCustomFields = () => !!Object.keys(this.customFields).length

  canCreateTemplate = () => false

  getProgressDetails = async (userId: string) => {
    const { path } = LearningPath.formatActionEndpoint(this.id, undefined, `progress/${userId}`)
    return await FlockjayProvider(new Route(MethodTypes.GET, path, {}, {}, {}))
  }

  getCertificatePdfUrl = async () => {
    return await LearningPath.get(this.id, undefined, undefined, { fields: 'user_certificate_pdf_url' })
  }

  shouldOpenConfettiModal = () => {
    return this.isUserEligibleForCertificate && !hasOpenedConfettiModal(this)
  }
}
