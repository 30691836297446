import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ContentTypeIcon, getFeedContentType, LearningContent } from 'src/utils/content'
import { mobileQuery } from 'src/pages/public/common'
import { ContainerDiv, DefaultLink, FjText } from 'src/components/Common'
import { CoachingMoment } from 'src/models/CoachingMoment'
import { FeedTask } from 'src/models/FeedTask'
import { Hub } from 'src/models/Hub'
import { Page } from 'src/models/Page'
import { Colors } from 'src/constants/colors'
import { Lock } from 'react-feather'
import { Paths } from 'src/constants/navigation'
import { Tooltip } from 'antd'

interface ContentTagProps {
  obj: LearningContent | Hub | Page | FeedTask | CoachingMoment
  size?: 'small' | 'normal'
  upperCase?: boolean
  includeFileType?: boolean
  showTemplate?: boolean
  locked?: boolean
  showTagTitle?: boolean
}

export const ContentTag = ({
  obj,
  size = 'normal',
  upperCase,
  includeFileType,
  showTemplate,
  locked,
  showTagTitle = true,
}: ContentTagProps) => {
  const isMobile = useMediaQuery(mobileQuery)
  const tagTitle = obj.getTagTitle({ includeFileType, showTemplate })

  return (
    <ContainerDiv
      display="flex"
      alignItems="center"
      borderRadius="28px"
      padding={isMobile || size === 'small' ? '0 8px 0 7px' : '4px 12px 4px 10px'}
      gap={6}
      backgroundColor={locked ? Colors.concrete : obj.getTagBgColor()}
      width="fit-content"
    >
      {locked ? (
        <Lock color={Colors.tapa} size={size === 'small' ? 12 : 14} />
      ) : (
        <Tooltip title={!showTagTitle ? tagTitle : null}>
          <ContentTypeIcon obj={obj} size={size === 'small' ? 12 : 14} />
        </Tooltip>
      )}
      {showTagTitle ? (
        <FjText
          color={locked ? Colors.tapa : Colors.shark}
          fontSize="small"
          fontWeight="bold500"
          userSelect="none"
          whiteSpace="nowrap"
          textTransform={upperCase ? 'uppercase' : 'unset'}
        >
          {tagTitle}
        </FjText>
      ) : null}
    </ContainerDiv>
  )
}

interface BorderlessContentTagProps {
  obj: LearningContent | Hub | Page | FeedTask | CoachingMoment
  color?: string
  iconSize?: number
  textSize?: string
  includeFileType?: boolean
  showTemplate?: boolean
}

export const BorderlessContentTag = ({
  obj,
  color = Colors.chambray,
  iconSize = 16,
  textSize = '14px',
  includeFileType,
  showTemplate,
}: BorderlessContentTagProps) => {
  if (!obj) return null
  const tagTitle = obj.getTagTitle({ includeFileType, showTemplate })
  return (
    <DefaultLink to={Paths.getLibraryPath(getFeedContentType(obj))}>
      <ContainerDiv textAlign="left" display="flex" alignItems="center" gap={8}>
        <ContentTypeIcon obj={obj} size={iconSize} color={color} />
        <FjText color={color} fontSize={textSize} fontWeight="semi-bold">
          {tagTitle}
        </FjText>
      </ContainerDiv>
    </DefaultLink>
  )
}
