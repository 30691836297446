import Joi from 'joi'
import {
  EMAIL_REGEX,
  FULL_NAME_REGEX,
  GMAIL_REGEX,
  PERSONAL_EMAILS_REGEX_ARRAY as PMAIL_REGEX_ARRAY,
  URL_REGEX,
  ZOOM_URL_REGEX,
} from 'src/constants/regex'
import moment from 'moment'
import { MIN_PASSWORD_LENGTH } from 'src/constants/app'

type ValidationResult = string | undefined | Promise<any>
export type ValidationFn = (val: any) => ValidationResult

export const PHONE_NUMBER_LENGTH = 14

export const combineValidations = (...args: ValidationFn[]): ValidationFn => {
  return (val: any) => {
    for (const fn of args) {
      const err = fn(val)
      if (err) return err
    }
    return undefined
  }
}

const evaluateErr = (err?: Error): ValidationResult => {
  return err ? err.message : undefined
}

/*
  We aren't using Joi here .required() without type information (e.g. .string())
  only checks for the presence of a value meaning empty strings, and null are
  acceptable values
*/
export const isRequired: ValidationFn = (val: any) => {
  const msg = 'This field is required'
  if (typeof val === 'boolean') {
    return undefined
  } else if (Array.isArray(val)) {
    return val.length ? undefined : msg
  } else if (typeof val === 'string') {
    return val.trim().length ? undefined : msg
  } else if (typeof val === 'number') {
    return undefined
  }
  return !!val ? undefined : msg
}

export const isEmail: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.string()
      .regex(EMAIL_REGEX)
      .error(() => new Error('Please provide a valid email'))
      .validate(val).error
  )
}

export const isFullName: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.string()
      .regex(FULL_NAME_REGEX)
      .error(() => new Error('Please provide a valid full name'))
      .validate(val).error
  )
}

export const isGmail: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.string()
      .regex(GMAIL_REGEX)
      .error(() => new Error('Please provide a valid Gmail address'))
      .validate(val).error
  )
}

export const isBusinessEmail: ValidationFn = (val: any) => {
  if (!val) return undefined

  for (let mailRegEx of PMAIL_REGEX_ARRAY) {
    const error = Joi.string().regex(mailRegEx).validate(val).error
    if (error === undefined) return evaluateErr(new Error('Please provide a valid business email address'))
  }

  return evaluateErr(undefined)
}

export const isURI: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.string()
      .regex(URL_REGEX)
      .error(() => new Error('Please provide a valid URL'))
      .validate(val).error
  )
}

export const isZoomURI: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.string()
      .regex(ZOOM_URL_REGEX)
      .error(() => new Error('Please provide a valid Zoom link'))
      .validate(val).error
  )
}

export const isMinLength = (min: number): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.string()
        .min(min)
        .error(() => new Error(`This value is too short (min. ${min} characters)`))
        .validate(val).error
    )
  }
}

export const isMaxLength = (max: number): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.string()
        .max(max)
        .error(() => new Error(`This value is too long (max ${max} characters)`))
        .validate(val).error
    )
  }
}

export const isRegex = (regexp: RegExp): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.string()
        .regex(regexp)
        .error(() => new Error('Value is invalid'))
        .validate(val).error
    )
  }
}

export const includeCapitalLetter: ValidationFn = (val: any) => {
  return evaluateErr(
    Joi.string()
      .regex(/^(?=.*[A-Z])/)
      .error(() => new Error('Input must include at least 1 capital letter'))
      .validate(val).error
  )
}
export const includeNumber: ValidationFn = (val: any) => {
  return evaluateErr(
    Joi.string()
      .regex(/^(?=.*[0-9])/)
      .error(() => new Error('Input must include at least 1 number'))
      .validate(val).error
  )
}
export const includeSpecialCharacter: ValidationFn = (val: any) => {
  return evaluateErr(
    Joi.string()
      .regex(/^(?=.*[!@#$%^&*])/)
      .error(() => new Error('Input must include at least 1 special letter'))
      .validate(val).error
  )
}

export const isPassword: ValidationFn = combineValidations(
  isRequired,
  includeCapitalLetter,
  includeNumber,
  includeSpecialCharacter,
  isMinLength(MIN_PASSWORD_LENGTH)
)

export const isNumber: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.number()
      .error(() => new Error('Value must be a number'))
      .validate(val).error
  )
}
export const isInteger: ValidationFn = (val: any) => {
  if (!val) return undefined
  return evaluateErr(
    Joi.number()
      .integer()
      .error(() => new Error('Value must be an integer'))
      .validate(val).error
  )
}

export const isMinValue = (min: number): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.number()
        .min(min)
        .error(() => new Error(`Value must be greater than or equal to ${min}`))
        .validate(val).error
    )
  }
}

export const isMaxValue = (max: number): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.number()
        .max(max)
        .error(() => new Error(`Value must be less than ${max}`))
        .validate(val).error
    )
  }
}

export const isPhoneNumber: ValidationFn = (val: any) => {
  if (!val) return undefined
  return String(val).startsWith('1') ? 'Value must be a valid phone number' : undefined
}

export const isEqualTo = (ref: any, refName: string): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return evaluateErr(
      Joi.string()
        .equal(ref)
        .error(() => new Error(`Value must match field: ${refName}`))
        .validate(val).error
    )
  }
}

export const isFile: ValidationFn = (val: any) => {
  if (!val) return
  return val instanceof File || val instanceof Blob ? undefined : 'Please select a file'
}

// NOTE: acceptedTypes should be MIME types (e.g. image/jpeg, image/png, application/pdf, etc.)
export const acceptedFileTypes = (...acceptedTypes: string[]) => {
  let extensions = ''
  acceptedTypes.forEach((t) => {
    extensions += ` .${t.substring(t.indexOf('/') + 1)}`
  })
  return (file: any) => {
    if (!file) return undefined
    return acceptedTypes.includes(file.type)
      ? undefined
      : `Please select one of the following file types: ${extensions}`
  }
}

// NOTE: Expects file size in MB
export const maxFileSize = (size: number): ValidationFn => {
  return (val: any) => {
    if (!val) return undefined
    return val.size / 1024 / 1024 <= size ? undefined : `File must be smaller than ${size}MB`
  }
}

export const isObjectPartiallyEmpty = (obj: object, keys: string[]): boolean =>
  keys.some((key) => {
    // returns true if found atleast one key which is not filled in object
    const curValue = obj[key]
    // curValue == null checks for both undefined/null/blank string
    // second condition checks if the value is array then it must not be empty
    if (curValue == null || (Array.isArray(curValue) && !curValue.length) || curValue === '') return true
    return false
  })

export const isSameOrBefore = (from: string, to: string) => {
  if (!from || !to || moment(from).isSameOrBefore(moment(to))) return undefined
  return 'Start date should be before end date'
}

export const isSameOrAfter = (from: string, to: string) => {
  const msg = 'End date should be after start date'
  if (!from && to) return msg
  if (!to || moment(to).isSameOrAfter(moment(from))) return undefined
  return msg
}

export const validateIntervalDates = (from: string, to: string) => {
  const errors = {} as any
  const startDateError = isSameOrBefore(from, to)
  const endDateError = isSameOrAfter(from, to)
  if (startDateError) errors.startDate = startDateError
  if (endDateError) errors.endDate = endDateError
  return errors
}
