import { SfOpportunity } from 'src/models/SfOpportunity'

export const getOppTemplatePost = (opportunity: SfOpportunity, template: string) => {
  switch (template) {
    case 'win_story':
      return {
        title: `${opportunity.name}'s Win Story`,
        body: `<p>Hi Team. I recently closed the deal with ${opportunity.name}! Here's a quick recap about the win.</p>
        <p>[Add 90 sec video recap - Use Record Video feature above]</p>
        <p>[Q1: What are the 2-3 primary reasons the client chose XYZ to help its organization?]</p>
        <ul><li>&nbsp;</li></ul>
        <p>[Q2: What did we do during the sales cycle that was most critical in winning this deal?]</p>
        <ul><li>&nbsp;</li></ul>
        <p>[Q3: What business outcomes and impact does the client expect from us?]</p>
        <ul><li>&nbsp;</li></ul>
        <p>[Q4: Please provide links to any important documentation (proposals, RFPs, slides, POVs, etc) for this deal]</p>
        <ul><li>&nbsp;</li></ul>
        <p>&nbsp;</p>
      `,
      }
    case 'stage_change':
      return {
        title: `${opportunity.name}'s Stage Progression`,
        body: `<p>I recently moved ${opportunity.name} to the ${opportunity.stageName} stage. Here's a quick recap.</p>
            <p>[Add 90 sec video recap - Use Record Video feature above] </p>
            <p>[Q1: What are the 2-3 primary reasons that led to ${opportunity.stageName}?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q2: What did we do during the sales cycle that was most critical to getting to the next stage?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q3: What business outcomes and impact does the client expect from us?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q4: Please provide links to any important documentation (proposals, RFPs, slides, POVs, etc) for this deal]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q5: What are the next steps? Are there requirements from MEDDICC framework that are missing?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>&nbsp;</p>
          `,
      }
    case 'resting_deal':
      return {
        title: `${opportunity.name}'s Update`,
        body: `<p>Here's a quick update about the ${opportunity.name} opportunity.</p>
            <p>[Add 90 sec video recap - Use Record Video feature above]</p>
            <p>[Q1: What are the 2-3 primary reasons that led to ${opportunity.stageName}?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q2: What can we do during the sales cycle that is most critical to getting to the next stage?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q3: What business outcomes and impact does the client expect from us?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q4: Please provide links to any important documentation (proposals, RFPs, slides, POVs, etc) for this deal]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q5: What are the next steps? Are there requirements from MEDDICC framework that are missing?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>&nbsp;</p>
          `,
      }
    case 'deal_closed':
      return {
        title: `${opportunity.name}'s Deal Story`,
        body: `<p>Hi Team. I recently closed the deal with ${opportunity.name}! Here's a quick recap about the win.</p>
            <p>[Add 90 sec video recap - Use Record Video feature above]</p>
            <p>[Q1: What are the 2-3 primary reasons the client chose XYZ to help its organization?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q2: What did we do during the sales cycle that was most critical in winning this deal?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q3: What business outcomes and impact does the client expect from us?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q4: Please provide links to any important documentation (proposals, RFPs, slides, POVs, etc) for this deal]</p>
            <ul><li>&nbsp;</li></ul>
            <p>&nbsp;</p>
          `,
      }
    default:
      return {
        title: `${opportunity.name}'s Deal Story`,
        body: `<p>Hi Team. I recently closed the deal with ${opportunity.name}! Here's a quick recap about the win.</p>
            <p>[Add 90 sec video recap - Use Record Video feature above]</p>
            <p>[Q1: What are the 2-3 primary reasons the client chose XYZ to help its organization?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q2: What did we do during the sales cycle that was most critical in winning this deal?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q3: What business outcomes and impact does the client expect from us?]</p>
            <ul><li>&nbsp;</li></ul>
            <p>[Q4: Please provide links to any important documentation (proposals, RFPs, slides, POVs, etc) for this deal]</p>
            <ul><li>&nbsp;</li></ul>
            <p>&nbsp;</p>
          `,
      }
  }
}
