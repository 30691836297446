import moment from 'moment'
import { getQueryParam } from 'src/utils/urlParams'
import { sharedDataStore } from 'src/store/DataStore'
import { User } from 'src/models/User'
import { APIProvider } from 'src/network/APIProvider'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { FjEvent } from 'src/models/FjEvent'

const TRACKING_DATA_KEYS = ['partner_apply_code']
const writeKey: string = '83TJOSWuM9XQuVFGsrc9r0wZ25ymqwQL'

export class Analytics {
  static getWriteKey() {
    return process.env.REACT_APP_SEGMENT_WRITE_KEY ? process.env.REACT_APP_SEGMENT_WRITE_KEY : writeKey
  }

  static shouldTrack() {
    return Analytics.getWriteKey() && process.env.NODE_ENV && process.env.NODE_ENV !== 'development'
  }

  static readUtmData = () => {
    const utmParameters = window.location.href.match(/(utm_[a-z]+)/gi) || []
    if (Object.keys(utmParameters).length > 0) sharedDataStore.utmData = {}
    utmParameters.forEach((parameter) => {
      if (getQueryParam(parameter)) sharedDataStore.utmData[parameter] = getQueryParam(parameter)
    })
  }

  static readTrackingCodes = async () => {
    TRACKING_DATA_KEYS.forEach((codeKey) => {
      const code = getQueryParam(codeKey)
      if (code) sharedDataStore.trackingData[codeKey] = code
    })
  }

  static loadAnalytics() {
    Analytics.readUtmData()
    Analytics.readTrackingCodes()
    if (Analytics.shouldTrack()) {
      window.analytics.load(Analytics.getWriteKey())
    }
  }

  static trackFlockjayEvent = async (eventType: string, user?: string, data?: object) => {
    await APIProvider.postEvent({ eventType, user, data })
  }

  static trackPage() {
    if (Analytics.shouldTrack()) {
      window.analytics.page()
    }
  }

  static trackEvent(name: string, properties: object) {
    if (Analytics.shouldTrack()) {
      window.analytics.track(name, properties)
    }
  }

  static trackIdentify(
    user?: User,
    applicationData?: { phoneNumber: string; email: string; fullName: string; user: string },
    includeOptIn: boolean = false
  ) {
    if (!user && !applicationData) return

    let traits: object, userId: string
    if (user) {
      traits = {
        firstName: user.getFirstName(),
        lastName: user.getLastName(),
        phone: `+1${user.phoneNumber}`,
        email: user.primaryEmail,
      }
      userId = user.id
    } else {
      const [firstName, lastName] = applicationData.fullName.split(' ')
      traits = {
        firstName,
        lastName,
        phone: `+1${applicationData.phoneNumber}`,
        email: applicationData.email,
      }
      userId = applicationData.user
    }

    if (includeOptIn) {
      traits['optIn'] = [
        {
          channel: 'voice',
          source: 'application',
          text: `By checking here and clicking “Continue”, I agree to receive marketing and customer service
                  communications by automatic telephone dialing system or other technology, including calls,
                   pre-recorded messages, and recurring text messages from Company and its representatives at the
                    telephone number provided. Consent is not a condition of purchase. Reply HELP for help and STOP
                     to cancel texts. Msg & data rates may apply`,
          timestamp: new Date().toISOString(),
          subscribed: true,
          'send subscription confirmation sms': true,
        },
        {
          channel: 'sms',
          source: 'application',
          text: `By checking here and clicking “Continue”, I agree to receive marketing and customer service 
            communications by automatic telephone dialing system or other technology, including calls, pre-recorded 
            messages, and recurring text messages from Company and its representatives at the telephone number provided. 
            Consent is not a condition of purchase. Reply HELP for help and STOP to cancel texts. Msg & data rates may 
            apply`,
          timestamp: new Date().toISOString(),
          subscribed: true,
          'send subscription confirmation sms': true,
        },
      ]
    }
    window.analytics.identify(userId, traits)
  }

  static login = async (user: string, isRefresh: boolean) => {
    await Analytics.trackFlockjayEvent('login', user, { is_refresh: isRefresh })
  }

  static search = async (user: string, data: object) => {
    await Analytics.trackFlockjayEvent('search', user, { ...data, searchStr: data['searchStr']?.trim() })
  }

  static saveLocalTrackingData = () => {
    Analytics.saveAllViewEvents()
    Analytics.saveVideoWatchData()
  }

  static saveAllViewEvents = () => {
    Object.keys(sharedDataStore.viewTrackingData).forEach((trackingId) => {
      Analytics.saveViewEvent(trackingId)
    })
  }

  static saveViewEvent = async (trackingId: string) => {
    try {
      const { eventType, user, ...data } = sharedDataStore.viewTrackingData[trackingId]
      await Analytics.trackFlockjayEvent(eventType, user, data)
      delete sharedDataStore.viewTrackingData[trackingId]
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  static saveVideoWatchData = async () => {
    const data = Object.values(sharedDataStore.videoWatchData).map((val) => {
      return {
        event_type: 'video_watched',
        user: sharedDataStore.user.id,
        data: val,
      }
    })
    if (!data.length) return
    try {
      await APIProvider.postEvent(data)
      sharedDataStore.videoWatchData = {}
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  static trackAssetDownload = async (user: string, assetId: string, context: object = {}) => {
    await Analytics.trackFlockjayEvent('asset_download', user, { assetId, ...context })
  }

  static trackAISummaryFeedback = async (
    user: string,
    rating: string,
    summary: string,
    feedback?: string,
    context: object = {}
  ) => {
    try {
      const eventData = { rating, summary, ...context }
      if (feedback) eventData['feedback'] = feedback
      await Analytics.trackFlockjayEvent('ai_summary_feedback', user, eventData)
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }
}

type VideoEventHandler = (e: Event) => any | Promise<any>

export class MediaTracker {
  mediaElement: HTMLVideoElement | HTMLAudioElement
  context: object
  event: FjEvent
  watchEvents: any[]
  mediaFullyConsumed?: () => void

  constructor(
    mediaElement: HTMLVideoElement | HTMLAudioElement,
    context: object,
    onPlay?: VideoEventHandler,
    onPause?: VideoEventHandler,
    mediaFullyConsumed?: () => void
  ) {
    this.mediaElement = mediaElement
    this.context = context
    this.mediaElement.onplay = this.createPlayHandler(onPlay)
    this.mediaElement.onpause = this.createStopHandler(onPause)
    this.watchEvents = []
    this.mediaFullyConsumed = mediaFullyConsumed
  }

  saveWatchEvent = async () => {
    const eventType = this.mediaElement instanceof HTMLVideoElement ? 'video_watched' : 'audio_listened'
    try {
      const newEvent = this.event ? FjEvent.fromData({ ...this.event }) : new FjEvent()
      await newEvent.save({
        eventType,
        user: sharedDataStore.user.id,
        data: [...this.watchEvents],
      })
      this.event = newEvent
      if (this.isMediaFullyConsumed()) this.mediaFullyConsumed?.()
    } catch (err) {
      sharedAppStateStore.handleError(err, undefined, false)
    }
  }

  createPlayHandler = (onPlay?: VideoEventHandler) => {
    return (e: Event) => {
      if (onPlay) onPlay(e)
      this.watchEvents.push({
        ...this.context,
        start: this.mediaElement.currentTime,
        start_timestamp: moment().toISOString(),
      })
      this.saveWatchEvent()
    }
  }

  createStopHandler = (eventHandler?: VideoEventHandler) => {
    return (e: Event) => {
      if (eventHandler) eventHandler(e)
      this.watchEvents[this.watchEvents.length - 1]['stop'] = this.mediaElement.currentTime
      this.watchEvents[this.watchEvents.length - 1][`stop_timestamp`] = moment().toISOString()
      this.saveWatchEvent()
    }
  }

  isMediaFullyConsumed = () => {
    let currentEnd = -1
    for (let interval of [...this.watchEvents].sort((a, b) => a['start'] - b['start'])) {
      if (!(Math.abs(interval['start'] - currentEnd) <= 4) && interval['start'] >= currentEnd) return false
      currentEnd = Math.max(currentEnd, interval['stop'])
    }
    return currentEnd >= Math.round(this.mediaElement.duration) - 5
  }
}
