import styled from 'styled-components'
import { PaddingProps, getPaddingTop, getPaddingRight, getPaddingLeft, getPaddingBottom } from 'src/components/Common'
import { MarginProps, getMarginTop, getMarginRight, getMarginBottom, getMarginLeft } from 'src/components/Common'
import { Colors } from 'src/constants/colors'

export const Bar = styled.div<PaddingProps & MarginProps>`
  background-color: ${Colors.outerSpace};
  text-align: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: ${(props) => getMarginTop(props)};
  margin-right: ${(props) => getMarginRight(props)};
  margin-bottom: ${(props) => getMarginBottom(props)};
  margin-left: ${(props) => getMarginLeft(props)};
  padding-top: ${(props) => getPaddingTop(props)};
  padding-right: ${(props) => getPaddingRight(props)};
  padding-bottom: ${(props) => getPaddingBottom(props)};
  padding-left: ${(props) => getPaddingLeft(props)};
`
