import React, { Component } from 'react'
import { Formik } from 'formik'
import { Form, Switch } from 'formik-antd'
import { Utils as QbUtils, AntdConfig, ImmutableTree, Config } from '@react-awesome-query-builder/antd'
import { CoachingMomentTrigger } from 'src/models/CoachingMomentTrigger'
import { observer } from 'mobx-react'
import { makeObservable, observable } from 'mobx'
import {
  ContainerDiv,
  FjQueryBuilderField,
  FjFormItem,
  FjInput,
  FormActionButtons,
  FormHeaderText,
  Loader,
} from 'src/components/Common'
import { isRequired } from 'src/utils/validation'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore } from 'src/store/DataStore'
import { arrayFromMap, titleCase } from 'src/utils/format'

const InitialConfig = AntdConfig

export interface ICoachingMomentTriggerFormProps {
  onSuccess: (trigger: CoachingMomentTrigger) => Promise<void>
  onCancel: () => void
  trigger?: CoachingMomentTrigger
}

@observer
export class CoachingMomentTriggerForm extends Component<ICoachingMomentTriggerFormProps> {
  queryBuilderConfig: Config
  @observable isLoading = false

  constructor(props: ICoachingMomentTriggerFormProps) {
    super(props)
    makeObservable(this)
    this.queryBuilderConfig = this.createQueryBuilderConfig()
  }

  createQueryBuilderConfig = () => {
    return {
      ...InitialConfig,
      fields: {
        source: {
          type: 'multiselect',
          label: 'Source',
          operators: ['multiselect_equals', 'multiselect_contains'],
          fieldSettings: {
            listValues: ['salesforce', 'salesloft', 'clari'].map((val) => ({ value: val, title: titleCase(val) })),
            allowCustomValues: false,
          },
        },
        bestCase: {
          type: 'number',
          label: 'Best Case (# of deals)',
        },
        businessIssues: {
          type: 'text',
          label: 'Business Issues',
          operators: ['equals', 'starts_with', 'ends_with', 'like', 'not_like', 'is_empty', 'is_not_empty'],
        },
        cadence: {
          type: 'multiselect',
          label: 'Cadence',
          operators: [
            'multiselect_equals',
            'multiselect_not_equals',
            'multiselect_contains',
            'multiselect_not_contains',
          ],
          fieldSettings: {
            listValues: [
              { value: 'aws_sales_eaders_11-23', title: 'AWS Sales Leaders 11-23' },
              { value: 'aws_sales_eaders_11-23_t1', title: 'AWS Sales Enablement 11-23 Tier 1' },
              { value: 'aws_sales_eaders_11-23_t2', title: 'AWS Sales Enablement 11-23 Tier 2' },
            ],
            allowCustomValues: false,
          },
        },
        committed: {
          type: 'number',
          label: 'Committed (# of deals)',
        },
        conversionRate: {
          type: 'number',
          label: 'Conversion Rate',
          fieldSettings: {
            min: 0,
            max: 100,
            step: 10,
          },
        },
        duration: {
          type: 'number',
          label: 'Duration (days)',
          defaultValue: 30,
          operators: ['equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
          fieldSettings: {
            min: 10,
            max: 90,
            step: 10,
          },
        },
        industry: {
          type: 'multiselect',
          label: 'Industry',
          operators: [
            'multiselect_equals',
            'multiselect_not_equals',
            'multiselect_contains',
            'multiselect_not_contains',
          ],
          fieldSettings: {
            listValues: arrayFromMap(sharedDataStore.config.industry)
              .filter(([value, _]) => value !== 'any')
              .map(([value, title]) => ({ value, title }))
              .sort((a, b) => a.value.localeCompare(b.value)),
            allowCustomValues: false,
          },
        },
        mostLikely: {
          type: 'number',
          label: 'Most Likely (# of deals)',
        },
        personalizationRate: {
          type: 'number',
          label: 'Personalization Rate',
          fieldSettings: {
            min: 0,
            max: 100,
            step: 10,
          },
        },
        stage: {
          type: 'multiselect',
          label: 'Stage',
          operators: [
            'multiselect_equals',
            'multiselect_not_equals',
            'multiselect_contains',
            'multiselect_not_contains',
          ],
          fieldSettings: {
            listValues: sharedDataStore.config.opportunityStageNames.map((stage) => ({ value: stage, title: stage })),
            allowCustomValues: false,
          },
        },
        stepsDue: {
          type: 'number',
          label: 'Steps Due',
        },
      },
    }
  }

  getInitialValues = () => {
    const { trigger } = this.props
    return {
      title: trigger?.title || undefined,
      queryTree: trigger ? QbUtils.loadTree(trigger.queryTree) : undefined,
      isActive: trigger ? trigger.isActive : true,
    }
  }

  handleSubmit = async (data: any) => {
    const { queryTree, ...formData } = data
    const trigger: CoachingMomentTrigger = this.props.trigger
      ? CoachingMomentTrigger.fromData({ ...this.props.trigger })
      : new CoachingMomentTrigger()
    await sharedAppStateStore.wrapAppLoading(
      trigger.save({
        ...formData,
        preCondition: QbUtils.jsonLogicFormat(queryTree, this.queryBuilderConfig).logic,
        queryTree: QbUtils.getTree(queryTree),
      })
    )
    this.props.onSuccess(trigger)
  }

  validateQueryTree = (immutableTree: ImmutableTree) => {
    const { logic } = QbUtils.jsonLogicFormat(immutableTree, this.queryBuilderConfig)
    if (!logic) return 'This field is required'
  }

  render() {
    const { trigger } = this.props
    return (
      <ContainerDiv textAlign="left">
        <FormHeaderText heading={trigger ? `Edit Trigger: ${trigger.title}` : 'Create Trigger'} />
        {this.isLoading ? (
          <Loader />
        ) : (
          <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit}>
            <Form>
              <ContainerDiv display="flex" alignItems="center" gap="20px">
                <FjFormItem fieldtitle="Title*" name="title" validate={isRequired} style={{ width: '80%' }}>
                  <FjInput name="title" placeholder="Title" />
                </FjFormItem>
                <FjFormItem fieldtitle="Active" name="isActive" style={{ margin: '0 auto' }}>
                  <Switch name="isActive" />
                </FjFormItem>
              </ContainerDiv>
              <FjFormItem fieldtitle="Trigger" name="queryTree" validate={this.validateQueryTree}>
                <FjQueryBuilderField name="queryTree" config={this.queryBuilderConfig} />
              </FjFormItem>
              <FormActionButtons submitButtonLabel="Save" />
            </Form>
          </Formik>
        )}
      </ContainerDiv>
    )
  }
}
