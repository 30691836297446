import { Progress, ProgressProps } from 'antd'
import { Colors } from 'src/constants/colors'
import styled from 'styled-components'

interface FjProgressProps {
  height?: string
  infoTextColor?: string
}

export const FjProgress = styled(Progress)<ProgressProps & FjProgressProps>`
  .ant-progress-bg {
    height: ${(props) => props.height} !important;
    background-color: ${(props) => (props.percent === 100 ? Colors.pastelGreen : Colors.texasRose)} !important;
  }
  .ant-progress-status-success .ant-progress-bg {
    background-color: ${Colors.deYork} !important;
  }
  .ant-progress-text {
    color: ${(props) => props.infoTextColor};
  }
`
FjProgress.defaultProps = {
  infoTextColor: 'rgba(0, 0, 0, 0.85)',
  trailColor: Colors.mercury,
  height: '6px',
}
