import React from 'react'
import { observer } from 'mobx-react'
import { Field, FieldProps } from 'formik'
import { FjTag } from 'src/components/Common/Tag'
import { setFieldValueType } from 'src/components/Common'
import { GoogleLocationAutocomplete } from 'src/components/Common/Select/GoogleLocationAutocomplete'
import { arrayWrapper } from 'src/utils/format'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { AlertCircle } from 'react-feather'

interface FjLocationSelectProps {
  name: string
  multiple?: boolean
  placeholder?: string
}

// To use FjLocationSelect in a new page, make sure to add path to GMAP_REQUIRED_PATHS in ScriptsLoadingWrapper.tsx
@observer
export class FjLocationSelect extends React.Component<FjLocationSelectProps> {
  multiple?: boolean

  static defaultProps = {
    multiple: true,
  }

  onPlaceSelect = (place: string, fieldValue: string[] | string, setFieldValue: setFieldValueType) => {
    const curValue = arrayWrapper(fieldValue)
    let newValue = curValue
    if (place) {
      if (!this.props.multiple) {
        newValue = place
      } else if (!newValue.includes(place)) {
        newValue.push(place)
      }
    } else {
      newValue = this.props.multiple ? newValue : newValue[0]
    }
    // Assigning null when array length is zero
    // Formik does not handle validation properly
    // (only in case of empty form submission) for array type fields
    if (newValue && !newValue.length) {
      newValue = null
    }
    // need to call setFieldValue on every call of onPlaceSelect
    // As we call onPlaceSelect(null) on onChange of our input field to
    // override the Formik's implicit setFieldValue on onChange of Formik field
    setFieldValue(this.props.name, newValue)
  }

  onPlaceUnselect = (key: string, place: string, fieldValue: string[] | string, setFieldValue: setFieldValueType) => {
    const curValue = arrayWrapper(fieldValue)
    let newValue = curValue.filter((l) => l !== place)
    // if multiple = false then we need to setFieldValue to be string instead of string[]
    newValue = this.props.multiple ? newValue : newValue[0]
    if (!(newValue && newValue.length)) {
      newValue = null
    }
    setFieldValue(this.props.name, newValue)
  }

  render() {
    const isGoogleMapLoaded = sharedAppStateStore.googleMapApiLoaded
    return (
      <Field name={this.props.name}>
        {({ field: { value }, form: { setFieldValue } }: FieldProps) => {
          const fieldValue = value || []
          return (
            <>
              {isGoogleMapLoaded ? (
                <GoogleLocationAutocomplete
                  name={this.props.name}
                  onPlaceSelect={(itemKey: string) => this.onPlaceSelect(itemKey, fieldValue, setFieldValue)}
                  placeholder={this.props.placeholder}
                />
              ) : (
                <AlertCircle size={16} />
              )}
              {arrayWrapper(fieldValue).map((location) => (
                <FjTag
                  key={location}
                  text={location}
                  closable
                  onTagClose={(key: string, place: string) =>
                    this.onPlaceUnselect(key, place, fieldValue, setFieldValue)
                  }
                />
              ))}
            </>
          )
        }}
      </Field>
    )
  }
}
