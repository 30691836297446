import React, { Component, ReactElement, useMemo } from 'react'
import { computed, observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Select } from 'antd'
import { ContainerDiv, DefaultButton, FjText, Loader } from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { LibraryCardRow } from 'src/components/Library/LibraryCardRow'
import { sharedDataStore } from 'src/store/DataStore'
import { FilterSelect } from 'src/components/Feed/FeedPostList'
import InfiniteScroll from 'react-infinite-scroll-component'
import LibraryCardHorizontal from 'src/components/Feed/LibraryCardHorizontal'
import { QueueItem } from 'src/models/QueueItem'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { User } from 'src/models/User'
import { showNotification } from 'src/hoc/Notification'
import { Bell } from 'react-feather'

type FilterBy = 'assigned' | 'completed'

interface QueueFilterProps {
  value: FilterBy
  onFilterChange: (filterBy: FilterBy) => void
}

const QueueFilter: React.FC<QueueFilterProps> = ({ value, onFilterChange }) => {
  const visibilityOptions = useMemo(
    () =>
      new Map([
        ['assigned', 'Incomplete'],
        ['completed', 'Completed'],
      ]),
    []
  )

  return (
    <ContainerDiv textAlign="left">
      <FilterSelect
        value={value}
        onChange={onFilterChange}
        style={{ textAlign: 'left', fontSize: '14px' }}
        popupClassName="filter-dropdown"
      >
        {Array.from(visibilityOptions).map(([key, value]) => (
          <Select.Option key={key} value={key}>
            {value}
          </Select.Option>
        ))}
      </FilterSelect>
    </ContainerDiv>
  )
}

interface QueueHeaderProps {
  filterBy: FilterBy
  onFilterChange: (value: FilterBy) => void
  reminderButton?: ReactElement
}

const QueueHeader: React.FC<QueueHeaderProps> = ({ filterBy, onFilterChange, reminderButton }) => {
  return (
    <ContainerDiv display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
      <ContainerDiv textAlign="right">{reminderButton}</ContainerDiv>
      <QueueFilter value={filterBy} onFilterChange={onFilterChange} />
    </ContainerDiv>
  )
}

interface NoQueueProps {
  name: string
  filterBy: FilterBy
}

const NoQueue: React.FC<NoQueueProps> = ({ name, filterBy }) => {
  return (
    <ContainerDiv display="flex" alignItems="center" justifyContent="center" paddingBottom="30px">
      <FjText color={Colors.blackLight} fontWeight="semi-bold" whiteSpace="initial" textAlign="left" fontSize="medium">
        {`${name ? `${name} doesn't` : 'You do not'} have any ${
          filterBy === 'assigned' ? 'incomplete' : 'completed'
        } tasks. `}
        <span role="img" aria-label="tada">
          🎉
        </span>
      </FjText>
    </ContainerDiv>
  )
}

interface QueueRowProps {
  userId?: string
  name?: string
  queueItemToHighlight?: string
}
@observer
export class QueueRow extends Component<QueueRowProps> {
  @observable isLoading = false
  @observable filterBy: FilterBy = 'assigned'

  constructor(props: QueueRowProps) {
    super(props)
    makeObservable(this)
  }

  componentDidMount(): void {
    this.fetchData()
  }

  getUserId = () => this.props.userId || sharedDataStore.user.id

  fetchData = async () => {
    await sharedDataStore.refreshQueue(
      {
        user_id: this.getUserId(),
        completed: this.filterBy === 'completed',
      },
      (loading) => (this.isLoading = loading)
    )
  }

  onFilterChange = (value: FilterBy) => {
    this.filterBy = value
    this.fetchData()
  }

  render() {
    const queueItems = sharedDataStore.getQueueForUser(this.getUserId())[this.filterBy].items
    const isOwner = !this.props.userId || this.props.userId === sharedDataStore.user.id
    return (
      <div
        style={{
          textAlign: 'center',
          borderRadius: '20px',
          backgroundColor: Colors.magnolia,
          padding: '15px',
          height: 'max-content',
          minWidth: '100%',
          marginBottom: '20px',
        }}
      >
        <QueueHeader filterBy={this.filterBy} onFilterChange={this.onFilterChange} />
        {this.isLoading ? (
          <Loader />
        ) : queueItems.length ? (
          <LibraryCardRow
            insideQueue
            differentUserQueue={!isOwner}
            objs={queueItems}
            queueItemToHighlight={this.props.queueItemToHighlight}
          />
        ) : (
          <NoQueue name={this.props.name} filterBy={this.filterBy} />
        )}
      </div>
    )
  }
}

interface QueueListProps {
  filterBy?: FilterBy
  showDivider?: boolean
  maxContentLen?: number
  height?: string
  scrollableTargetId?: string
  bigProgress?: boolean
  showHeader?: boolean
  user?: User
}

@observer
export class QueueList extends Component<QueueListProps> {
  @observable isLoading = false
  @observable filterBy: FilterBy = 'assigned'
  page = 1

  constructor(props) {
    super(props)

    makeObservable(this)

    if (props.filterBy) {
      this.filterBy = props.filterBy
    }
  }

  @computed get filteredQueue() {
    return sharedDataStore.getQueueForUser(this.getUserId())[this.filterBy]
  }

  @computed get queueItems() {
    if (!this.filteredQueue) return []

    return this.props.maxContentLen
      ? this.filteredQueue.items.slice(0, this.props.maxContentLen)
      : this.filteredQueue.items
  }

  @computed get sendReminderButtonDisabled() {
    return this.isLoading || sharedDataStore.getQueueForUser(this.getUserId()).sentRecentQueueItemsReminder
  }

  componentDidMount(): void {
    this.fetchData()
  }

  fetchData = async () => {
    await sharedDataStore.refreshQueue(
      {
        page_size: this.props.maxContentLen,
        user_id: this.getUserId(),
        completed: this.filterBy === 'completed',
        page: this.page,
      },
      (loading: boolean) => {
        if (this.page === 1) this.isLoading = loading
      }
    )
  }

  fetchNextPage = () => {
    this.page += 1
    this.fetchData()
  }

  queueItemDisplay = (item: QueueItem, isOwner: boolean) => {
    const { id, fullName } = this.props.user || {}
    const handleQueueItemClick = () => {
      sharedAppStateStore.showTaskDrawer = false
      sharedAppStateStore.queueModalProps = undefined
      sharedAppStateStore.resetBreadcrumbs()
    }

    return (
      <LibraryCardHorizontal
        key={`queue-item-list-${item.linkedObjectId}`}
        obj={item}
        queueItem={isOwner ? item.linkedObjectId : null}
        showDivider={this.props.showDivider}
        insideQueue
        onClick={handleQueueItemClick}
        bigProgress={this.props.bigProgress}
        progressUser={{ id, fullName }}
      />
    )
  }

  getUserId = () => this.props.user?.id || sharedDataStore.user.id

  reminderButtonVisible = () => {
    return (
      sharedDataStore.user.isFaasAdminOrManager() &&
      sharedDataStore.user.id !== this.getUserId() &&
      sharedDataStore.getQueueForUser(this.getUserId()).assigned.items.length !== 0
    )
  }

  sendManagerQueueItemReminder = async () => {
    try {
      await User.sendManagerQueueItemReminder(this.getUserId())
      showNotification({ message: 'Reminder was successfully sent!' })
      this.fetchData()
    } catch (err) {
      sharedAppStateStore.handleError(err)
    }
  }

  onFilterChange = (value: FilterBy) => {
    this.filterBy = value
    this.page = 1
    this.fetchData()
  }

  render() {
    const isOwner = this.props.user?.id === sharedDataStore.user.id
    return (
      <>
        {this.props.showHeader ? (
          <QueueHeader
            filterBy={this.filterBy}
            onFilterChange={this.onFilterChange}
            reminderButton={
              this.reminderButtonVisible() && (
                <DefaultButton
                  buttonType="secondary"
                  title={
                    sharedDataStore.getQueueForUser(this.getUserId()).sentRecentQueueItemsReminder
                      ? 'Reminder Sent!'
                      : 'Send Reminder'
                  }
                  disabled={this.sendReminderButtonDisabled}
                  clicked={this.sendManagerQueueItemReminder}
                  image={<Bell size={16} />}
                  size="small"
                />
              )
            }
          />
        ) : null}
        {this.isLoading ? (
          <ContainerDiv height={this.props.height}>
            <Loader />
          </ContainerDiv>
        ) : this.queueItems.length ? (
          this.props.maxContentLen ? (
            this.queueItems.map((item) => this.queueItemDisplay(item, isOwner))
          ) : (
            <ContainerDiv id={this.props.scrollableTargetId} height={this.props.height} overflow="auto">
              <InfiniteScroll
                dataLength={this.queueItems.length}
                next={this.fetchNextPage}
                hasMore={this.filteredQueue.hasMore}
                style={{ overflow: 'hidden' }}
                loader={<Loader />}
                scrollableTarget={this.props.scrollableTargetId}
              >
                {this.queueItems.map((item) => this.queueItemDisplay(item, isOwner))}
              </InfiniteScroll>
            </ContainerDiv>
          )
        ) : (
          <ContainerDiv id={this.props.scrollableTargetId} height={this.props.height} overflow="auto">
            <NoQueue name={this.props.user?.fullName} filterBy={this.filterBy} />
          </ContainerDiv>
        )}
      </>
    )
  }
}
