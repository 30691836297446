import React from 'react'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { FJStyledAutoComplete, FJStyledInput } from 'src/components/Common/FjInput'
import { DefaultOptionType } from 'rc-select/lib/Select'
import { deepEquals } from 'src/utils/format'

interface SearchBoxProps {
  name: string
  handleSearch?: (value: string) => Promise<DefaultOptionType[]>
  handleSelect(value: string): void
  placeholder?: string
  error?: boolean
  initialOptions?: DefaultOptionType[]
  selectedKeys: string[]
}

@observer
export class SearchBox extends React.Component<SearchBoxProps> {
  @observable inputValue: string
  @observable options: DefaultOptionType[]

  constructor(props: SearchBoxProps) {
    super(props)
    makeObservable(this)
    this.inputValue = ''
    this.options = this.getUnselectedOptions()
  }

  componentDidMount = () => {
    // calling handleSelect(null) sets the same selected value
    // to field, So in case on mount if it's value is "[]" then it gets set to "null"
    // So that Formik can validate it
    this.props.handleSelect(null)
  }

  componentDidUpdate = (prevProps: SearchBoxProps) => {
    if (
      (this.props.selectedKeys &&
        (prevProps.selectedKeys && prevProps.selectedKeys.length) !== this.props.selectedKeys.length) ||
      !deepEquals(this.props.initialOptions, prevProps.initialOptions)
    ) {
      this.options = this.getUnselectedOptions()
    }
  }

  handleValueChange = (val: string) => {
    this.inputValue = val
    this.props.handleSelect(null)
  }

  handleSelect = (value: string) => {
    this.props.handleSelect(value)
    this.inputValue = ''
    this.options = this.getUnselectedOptions()
  }

  getUnselectedOptions = (query: string = '') => {
    const { selectedKeys, initialOptions } = this.props
    const selected = selectedKeys || []
    const options = initialOptions.filter(
      (o) => !selected.includes(`${o.value}`) && `${o.label}`.toLowerCase().includes(query.toLowerCase())
    )
    return options
  }

  handleSearch = async (query: string) => {
    if (this.props.handleSearch) {
      this.options = await this.props.handleSearch(query)
      this.options = this.options.filter((o) => !this.props.selectedKeys.includes(`${o.value}`))
    } else {
      this.options = this.getUnselectedOptions(query)
    }
  }

  handleBlur = () => {
    this.inputValue = ''
    this.props.handleSelect(null)
    this.options = this.getUnselectedOptions()
  }

  render() {
    return (
      <FJStyledAutoComplete
        name={this.props.name}
        options={this.options}
        onSearch={this.handleSearch}
        onSelect={this.handleSelect}
        onChange={this.handleValueChange}
        onBlur={this.handleBlur}
        value={this.inputValue}
        style={{ width: 280 }}
      >
        <FJStyledInput name={`${this.props.name}-input`} placeholder={this.props.placeholder || '(optional)'} />
      </FJStyledAutoComplete>
    )
  }
}
