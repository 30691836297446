// Use https://chir.ag/projects/name-that-color/ for naming

export const Colors = {
  white: '#FFFFFF',
  approxWhite: '#FDFDFD',
  neonCarrot: '#FF8A29',
  robinsEggBlue: '#09D1BE',
  silverTree: '#6EBD97',
  anakiwa: '#91D5FF',
  jungleGreen: '#29A496',
  deYork: '#7AC8A3',
  fuscousGrey: '#4B4946',
  athensGray: '#F9F9FA',
  concrete: '#F3F3F3',
  darkIron: '#CDD0D4',
  outerSpace: '#303740',
  outerSpaceOpacity90: '#303740E6',
  scarlet: '#FF1F11',
  hawkesBlue: '#D6E3FB',
  lightDodgerBlue: '#1890FF',
  apple: '#35c23c',
  dodgerBlue: '#468AFF',
  emerald: '#5BD59A',
  transparentEmerald: 'rgba(91, 213, 154, .2)',
  texasRose: '#FFB54D',
  visVis: '#FEEFA1',
  goldenSand: '#F2DE71',
  caper: '#E5EEB2',
  wildWillowapprox: '#BFCA7E',
  shark: '#1D1E21',
  sharkOpacity12: '#1d1e211f',
  sharkOpacity10: '#1d1e211a',
  sharkOpacity25: '#1d1e2140',
  sharkOpacity50: '#1d1e2180',
  riverBed: '#455154',
  blackSqueeze: '#ECF8F7',
  royalBlue: '#2F80ED',
  lightGray: '#E3E3E3',
  oxfordBlue: '#344253',
  tallPoppy: '#BF2929',
  shuttleGray: '#556273',
  alizarinCrimson: '#E02929',
  black: '#000000',
  blackOpacity4: '#0000000a',
  paleSky: '#6A727B',
  wildSand: '#F4F4F4',
  mercury: '#E7E5E4',
  silver: '#BFBFBF',
  alto: '#D8D8D8',
  tapa: '#74716F',
  rollingStone: '#7A7F86',
  gray: '#828282',
  cornflowerBlue: '#508BEF',
  squirrel: '#887F74',
  titanWhite: '#ebf1fa',
  whiteLilac: '#f6f8fc',
  magnolia: '#f4f6fc',
  abbey: '#56575A',
  fuscousGray: '#4C4A47',
  cottonSeed: '#B7B6B5',
  cinnabar: '#E53935',
  outrageousOrange: '#FF5B37',
  sunsetOrange: '#FF4D4F',
  clementine: '#EF6C00',
  tulipTree: '#E6A345',
  grandis: '#FFD394',
  karry: '#FFEBCF',
  downy: '#66D09D',
  tusk: '#E0F5CA',
  tusk2: '#EFF5CA',
  como: '#4C7B65',
  sandstone: '#7A7268',
  burntSienna: '#EA615D',
  chambray: '#30538F',
  midnightBlue: '#002766',
  zorba: '#9D968E',
  periwinkleGray: '#ABBFE1',
  blackLight: '#353331',
  brownGray: '#E4DFD9',
  cloudy: '#AEA79E',
  whiteIce: '#DFF9EC',
  whiteIce2: '#DFFAF3',
  chablis: '#FFF1F0',
  portage: '#8BABEF',
  macaroni: '#FFC573',
  apricot: '#EF8886',
  zumthor: '#EEF6FF',
  purpleHeart: '#773DE0',
  mauve: '#ECA0FA',
  blueChalk: '#F2EBFF',
  blueChalk2: '#F1E7FF',
  blueChalk3: '#E9DCFF',
  blueChalk4: '#F9D8FF',
  whitePointer: '#FAE8FF',
  buttermilk: '#FFF4BA',
  pippin: '#FFDFDD',
  teaGreen: '#CEF2BD',
  pastelGreen: '#7CD37A',
  cruise: '#C2F0E4',
  riptide: '#91E7D3',
  bilobaFlower: '#B89AEB',
  onahau: '#C4E9FF',
  caramel: '#FFDDAE',
  rajah: '#F4B960',
  doublePearlLusta: '#FCECD2',
  frenchPass: '#CAE8FD',
  frenchPass2: '#B9E7FD',
  solitude: '#ECF5FE',
  malibu: '#7ED2FB',
  confetii: '#E6CE4B',
}
