import React, { Component } from 'react'
import styled from 'styled-components'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Menu } from 'antd'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { FjText, Icon, TextCustomProps } from 'src/components/Common'
import { PaddingProps, MarginProps } from 'src/components/Common/Styles'
import { Colors } from 'src/constants/colors'

interface DefaultMenuItemProps extends MenuItemProps {
  label?: string
  image?: string | null
  textColor?: string
  hoverColor?: string
  icon?: React.ReactNode
  hoverIcon?: React.ReactNode
}

export const BasicMenuItem = styled(Menu.Item)`
  background-color: ${Colors.white};
  color: ${Colors.cornflowerBlue};
  :hover {
    cursor: pointer;
    color: ${Colors.white};
  }
`

type IDefaultMenuItemProps = DefaultMenuItemProps & PaddingProps & MarginProps & TextCustomProps

export class DefaultMenuItemVM {
  @observable menuItemHoverBtn: boolean = false

  constructor() {
    makeObservable(this)
  }
}

@observer
export class DefaultMenuItem extends Component<IDefaultMenuItemProps> {
  vm = new DefaultMenuItemVM()
  render() {
    const color = this.props.textColor ? this.props.textColor : Colors.white
    const hoverColor = this.props.hoverColor || Colors.cornflowerBlue
    const icon = this.props.icon || null
    const hoverIcon = this.props.hoverIcon || icon

    const { hoverIcon: _hoverIcon, hoverColor: _hoverColor, ...restProps } = this.props
    return (
      <BasicMenuItem
        {...restProps}
        onClick={(e) => {
          e.domEvent.stopPropagation()
          this.props.onClick(e)
        }}
        onMouseEnter={() => {
          this.vm.menuItemHoverBtn = true
        }}
        onMouseLeave={() => {
          this.vm.menuItemHoverBtn = false
        }}
        style={{
          backgroundColor: `${this.vm.menuItemHoverBtn ? hoverColor : Colors.white} `,
        }}
        icon={this.vm.menuItemHoverBtn ? hoverIcon : icon}
      >
        {this.props.image ? <Icon src={this.props.image} marginRight="5px" /> : null}
        <FjText
          textAlign="center"
          paddingVertical="5px"
          paddingHorizontal="5px"
          color={this.vm.menuItemHoverBtn ? color : Colors.cornflowerBlue}
        >
          {this.props.label}
        </FjText>
      </BasicMenuItem>
    )
  }
}

export const SubmoduleMenuItem = styled(Menu.Item)`
  &.ant-menu-item-disabled {
    border-right: 1px solid ${Colors.magnolia} !important;
    border-color: ${Colors.magnolia} !important;
  }

  :hover {
    background-color: ${Colors.whiteLilac} !important;
  }
`
