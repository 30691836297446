import React, { Component } from 'react'
import { computed, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Route, RouteProps } from 'react-router-dom'
import { Login } from 'src/components/Auth/Login'
import Role from 'src/models/enums/Role'
import { NotFound } from 'src/pages/NotFound'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import { sharedDataStore, AuthStateType } from 'src/store/DataStore'
import { Analytics } from 'src/utils/Analytics'
import { AccessRole } from 'src/models/User'

const DefaultRoute: React.FC<RouteProps> = (props) => {
  Analytics.trackPage()
  sharedAppStateStore.setPageFound()
  return <Route {...props} />
}

interface FjRouteProps extends RouteProps {
  private?: boolean
  allowedRoles?: Role[]
  accessRoles?: AccessRole[]
  restrictedAccessRoles?: AccessRole[]
}

@observer
export class FjRoute extends Component<FjRouteProps> {
  constructor(props: FjRouteProps) {
    super(props)
    makeObservable(this)
  }

  @computed get authState() {
    let state: AuthStateType = sharedDataStore.authState
    if (state === 'unauthorized') {
      return state
    }
    if (this.props.allowedRoles && this.props.allowedRoles.length && state !== 'loading') {
      this.props.allowedRoles.includes(sharedDataStore.user.role) || sharedDataStore.user.isAdmin()
        ? (state = 'authorized')
        : (state = 'not-found')
    }
    if (this.props.accessRoles && this.props.accessRoles.length && state !== 'loading') {
      this.props.accessRoles.includes(sharedDataStore.user.accessRole) ? (state = 'authorized') : (state = 'not-found')
    }
    if (this.props.restrictedAccessRoles && this.props.restrictedAccessRoles.length && state !== 'loading') {
      this.props.restrictedAccessRoles.includes(sharedDataStore.user.accessRole)
        ? (state = 'not-found')
        : (state = 'authorized')
    }
    return state
  }

  trackPage = () => {
    Analytics.trackPage()
    sharedAppStateStore.setPageFound()
  }

  render() {
    switch (this.authState) {
      case 'loading':
        return <></>
      case 'not-found':
        return <Route {...this.props} component={NotFound} />
      case 'unauthorized':
        if (!this.props.private) return <DefaultRoute {...this.props} />
        if (sharedAppStateStore.externalAcademy?.getCustomLoginUrl(this.props.location.pathname))
          window.location.replace(sharedAppStateStore.externalAcademy.getCustomLoginUrl(this.props.location.pathname))
        else return <DefaultRoute {...this.props} component={() => <Login loginHandler={() => this.forceUpdate()} />} />
        break
      case 'authorized':
        return <DefaultRoute {...this.props} />
    }
  }
}
