import React, { Component } from 'react'
import { Row, Dropdown, MenuProps } from 'antd'
import { observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'
import { FeedPost } from 'src/models/FeedPost'
import { FeedComment } from 'src/models/FeedComment'
import { Asset } from 'src/models/Asset'
import { sharedAppStateStore } from 'src/store/AppStateStore'
import {
  DefaultButton,
  ContainerDiv,
  FjText,
  ReactButton,
  FjAvatar,
  ThreeDotMenuButton,
  DefaultLink,
} from 'src/components/Common'
import { Colors } from 'src/constants/colors'
import { PostCommentForm } from 'src/components/Feed/PostCommentForm'
import { FontWeight } from 'src/components/Common/Styles'
import { Paths } from 'src/constants/navigation'
import { sharedDataStore } from 'src/store/DataStore'
import { FeedHTMLBodyParser } from 'src/components/Common/HTMLExpandablePreview'

export type CommentableItem = FeedPost | Asset

interface CommentContentProps {
  comment: FeedComment
  obj: CommentableItem
  deleteComment: (comment: FeedComment, onDelete?: (comment: FeedComment) => void) => void
  parentComment?: FeedComment
  onDeleteComment?: (comment: FeedComment) => void
  commentEdited?: (comment: FeedComment) => void
  showReactionsModal?: (obj: CommentableItem, comment?: FeedComment) => void
}

@observer
export class CommentContent extends Component<CommentContentProps> {
  @observable isEditing = false
  // Todo handle disabling logic to ReactButton - Story - #187532422
  @observable likeButtonDisabled = false
  isMobile = sharedAppStateStore.isMobile

  canEdit = () =>
    sharedDataStore.user.id === this.props.comment.author.authorID ||
    sharedDataStore.user.isAdmin() ||
    sharedDataStore.user.isFaasAdmin()

  editComment = () => (this.isEditing = true)

  commentEdited = (comment: FeedComment) => {
    const { commentEdited } = this.props
    this.isEditing = false
    commentEdited && commentEdited(comment)
  }

  menuOptions: MenuProps = {
    items: [
      this.props.commentEdited && this.props.comment.author.authorID === sharedDataStore.user.id
        ? {
            onClick: this.editComment,
            key: 'comment-edit-button',
            label: 'Edit',
          }
        : null,
      {
        onClick: () => this.props.deleteComment(this.props.comment, this.props.onDeleteComment),
        key: 'comment-delete-button',
        label: 'Delete',
      },
    ],
  }

  likeButtonClicked = async () => {
    try {
      this.likeButtonDisabled = true
      const { comment, obj } = this.props
      await comment.like(obj)
    } catch (err) {
      sharedAppStateStore.handleError(err)
    } finally {
      this.likeButtonDisabled = false
    }
  }

  constructor(props: CommentContentProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { comment, parentComment, obj } = this.props

    const attachUsername = (str: string) => {
      const username = `<a href="${Paths.getProfilePath(
        comment.author.authorID
      )}" style="cursor: pointer;font-weight: ${FontWeight.semiBold};margin-bottom: 2px;">${
        comment.author.fullName
      }  <span style="color: ${Colors.tapa}; font-size: 12px">${comment.lastUpdated.fromNow()}</span> </a> `

      // In case when comment has video first rather than text
      if (str.indexOf('<figure') === 0) {
        return `<p>${username}</p><br />${str}`
      }

      return str.replace('<p>', `<p style="margin-bottom: unset;">${username}<br />`)
    }

    return (
      <ContainerDiv>
        <Row>
          <ContainerDiv textAlign="left" flex={1} display="flex" flexDirection="row" minWidth="150px">
            {sharedDataStore.user.isFaasPublicOrPartner() ? (
              <FjAvatar size={38} author={comment.author} style={{ marginRight: '16px' }} />
            ) : (
              <DefaultLink to={Paths.getProfilePath(comment.author.id)}>
                <FjAvatar size={38} author={comment.author} style={{ marginRight: '16px' }} onClick={() => {}} />
              </DefaultLink>
            )}
            {this.isEditing ? (
              <a href={Paths.getProfilePath(comment.author.authorID)} style={{ fontWeight: FontWeight.semiBold }}>
                {comment.author.getFirstName()}
              </a>
            ) : (
              <ContainerDiv textAlign="left" borderRadius="4px" display="flex" flex={1} alignItems="start" gap={8}>
                <ContainerDiv flex={1} textAlign="left">
                  <FjText
                    fontSize={this.isMobile ? '12px' : '14px'}
                    lineHeight="18px"
                    textAlign="left"
                    whiteSpace="break-spaces"
                    className="video-comment"
                  >
                    {FeedHTMLBodyParser(attachUsername(comment.body))}
                  </FjText>
                </ContainerDiv>
                <ContainerDiv display="flex">
                  <ContainerDiv display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <ReactButton
                      reactionType="like"
                      reacted={comment.liked}
                      clicked={this.likeButtonClicked}
                      disabled={this.likeButtonDisabled}
                    />
                    <DefaultButton
                      buttonType="text"
                      title={comment.likeCountDisplay()}
                      clicked={
                        this.props.showReactionsModal ? () => this.props.showReactionsModal(obj, comment) : () => {}
                      }
                    />
                  </ContainerDiv>
                  {this.canEdit() && (
                    <Dropdown trigger={['hover']} menu={this.menuOptions} placement="bottomLeft">
                      <ThreeDotMenuButton />
                    </Dropdown>
                  )}
                </ContainerDiv>
              </ContainerDiv>
            )}
          </ContainerDiv>
          {this.isEditing && (
            <ContainerDiv width="100%">
              <PostCommentForm
                commentAdded={this.commentEdited}
                existingComment={comment}
                obj={obj}
                parentComment={parentComment}
                cancelClicked={() => (this.isEditing = false)}
              />
            </ContainerDiv>
          )}
        </Row>
      </ContainerDiv>
    )
  }
}

interface ParentCommentProps {
  comment: FeedComment
  obj?: CommentableItem
  commentAdded: () => void
  deleteComment: (comment: FeedComment) => void
  onDeleteComment: (comment: FeedComment) => void
  commentEdited?: (comment: FeedComment) => void
  showReactionsModal?: (obj: CommentableItem, comment?: FeedComment) => void
}

@observer
class ParentComment extends Component<ParentCommentProps> {
  @observable showReplies: boolean = false
  @observable childComments: FeedComment[] = []
  isMobile = sharedAppStateStore.isMobile

  onChildDelete = (comment: FeedComment) => {
    this.props.comment.replyCount -= 1
    this.childComments = this.childComments.filter((c) => c.id !== comment.id)
  }

  commentAdded = (comment: FeedComment) => {
    this.childComments.push(comment)
    this.showReplies = true
    this.props.commentAdded()
  }

  toggleDisplayingReply = () => {
    this.showReplies = !this.showReplies
    if (this.showReplies) this.fetchReplies()
  }

  fetchReplies = async () => {
    try {
      const { comment, obj } = this.props
      this.childComments = await FeedComment.getComments({ parent_id: comment.id }, obj)
    } catch (err) {
      sharedAppStateStore.handleError(err, 'Unable to retrieve comments')
    }
  }

  replyEdited = (comment: FeedComment) => {
    const comments = [...this.childComments]
    const index = comments.findIndex((c) => c.id === comment.id)
    if (index !== -1) {
      comments[index] = comment
      this.childComments = comments
    }
  }

  constructor(props: ParentCommentProps) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { comment, obj } = this.props
    const showRepliesText = `${!this.showReplies ? 'View' : 'Hide'} ${comment.replyCount} replies`

    return (
      <ContainerDiv>
        <CommentContent
          obj={obj}
          comment={this.props.comment}
          deleteComment={this.props.deleteComment}
          onDeleteComment={this.props.onDeleteComment}
          commentEdited={this.props.commentEdited}
          showReactionsModal={this.props.showReactionsModal}
        />
        <ContainerDiv textAlign="left" marginBottom="5px" marginLeft="54px">
          <DefaultButton buttonType="text" title="Reply" clicked={() => (this.showReplies = !this.showReplies)} />
          {comment.replyCount > 0 && (
            <DefaultButton
              style={{ marginLeft: 18 }}
              buttonType="text"
              title={showRepliesText}
              clicked={this.toggleDisplayingReply}
            />
          )}
        </ContainerDiv>

        {this.showReplies && (
          <ContainerDiv marginLeft="40px" paddingBottom="20px">
            {this.childComments.map((c) => (
              <ContainerDiv key={c.id} marginTop="18px">
                <CommentContent
                  obj={obj}
                  parentComment={comment}
                  comment={c}
                  deleteComment={this.props.deleteComment}
                  onDeleteComment={this.onChildDelete}
                  // only allow editing if editing is allowed by parent
                  commentEdited={this.props.commentEdited && this.replyEdited}
                  showReactionsModal={this.props.showReactionsModal}
                />
              </ContainerDiv>
            ))}
            <PostCommentForm
              obj={this.props.obj}
              parentComment={comment}
              commentAdded={this.commentAdded}
              submitButtonTitle="Reply"
            />
          </ContainerDiv>
        )}
      </ContainerDiv>
    )
  }
}

interface CommentListProps {
  obj: CommentableItem
  commentsList: FeedComment[]
  deleteComment: (comment: FeedComment) => void
  onDeleteComment: (comment: FeedComment) => void
  commentEdited?: (comment: FeedComment) => void
  showReactionsModal?: (obj: CommentableItem, comment?: FeedComment) => void
}

@observer
export class CommentList extends Component<CommentListProps> {
  render() {
    const { commentsList } = this.props
    return (
      <ContainerDiv>
        {commentsList && commentsList.length > 0 && (
          <ContainerDiv paddingTop="10px" textAlign="left">
            {commentsList
              .filter((c) => c.isParent())
              .map((c, index) => (
                <ContainerDiv key={c.id} marginTop={index > 0 ? '18px' : undefined}>
                  <ParentComment
                    comment={c}
                    obj={this.props.obj}
                    commentAdded={() => (c.replyCount = c.replyCount + 1)}
                    deleteComment={this.props.deleteComment}
                    onDeleteComment={this.props.onDeleteComment}
                    commentEdited={this.props.commentEdited}
                    showReactionsModal={this.props.showReactionsModal}
                  />
                </ContainerDiv>
              ))}
          </ContainerDiv>
        )}
      </ContainerDiv>
    )
  }
}
