import { AxiosResponse } from 'axios'
import moment from 'moment'
import { showNotification } from 'src/hoc/Notification'
import { APIProvider } from 'src/network/APIProvider'

export const handleCSVExportResponse = async (res: AxiosResponse, fileName: string) => {
  if (res.status === 202) {
    showNotification({
      message:
        'Your report is processing and can take up to 30 minutes to create. We will notify you when your report is ready for download!',
    })
    return
  }
  const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
  const dateString = moment().format('MM-DD-YYYY')
  fileName = `${fileName}_${dateString}.csv`
  await APIProvider.downloadFile(fileUrl, fileName)
}
