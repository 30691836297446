import { LxpObject } from 'src/models/LxpObject'
import { Submodule } from 'src/models/Submodule'
import { computed, makeObservable } from 'mobx'

export class Module extends LxpObject {
  static OVERRIDE_MAPPINGS = {
    submodules: (data) => (data.submodules ? Submodule.fromData(data.submodules) : []),
  }
  static apiEndpoint = 'api/course/:courseId/module/'

  constructor() {
    super()
    makeObservable(this)
  }

  @computed get isCompleted() {
    return this.progressPercentage === 1
  }

  id: string
  title: string
  progressPercentage: number
  courseId: string
  submodules: Submodule[] = []

  formatForCourseSave = () => ({ id: this.id, submoduleIds: this.submodules.map((s) => s.id) })

  save = async (newData?: Object, updateObject?: boolean) => {
    if (this.id) return await super.save(newData, updateObject)
    return await super.post('', newData, true, '/api/module/')
  }
}
